const paymentTransactions = {
    list:{
        search:'Search by branch name or appointment id',
        filters:{
            transactionsStatusPlaceholder:'Transactions type',
            transactionsStatusLabel:'Transactions type',
            fromDatePlaceholder:'From date',
            fromDateLabel:'From date',
            toDatePlaceholder:'To date',
            toDateLabel:'To date',
        },
        transactionsTypes:{
            cash:'Cash',
            online:'Online',
            admin_resolution:'Resolved by admin',
        },
        showEqu:'Show settlement equation',
        hideEqu: 'Hide settlement equation',
        transactionDetails: 'Transaction details',
        transationId: 'Transaction id',
        exportTransactions: 'Export payment transactions'
        
    }

}

export default paymentTransactions;