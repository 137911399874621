const serviceProviderCategories = {
  serviceProviderCategoriesList: "فئات مقدمي الخدمات",
  list: {
    addedSpecialties: "التخصصات المضافة", 
    addedServiceProviders: "مقدمي الخدمات",
    search: "ابحث عن فئة",
    noDataSubTitle: "لا توجد فئات لعرضها حتى الآن",
    noDataDescription: "ابدأ الآن بإضافة فئات",
    CreateCategoriesButton: "إضافة فئة جديدة",
    filter: {
      filtersPlaceHolders: {
        filterBySpecialty: "التخصص", 
      },
    },
  },
  addCategory: {
    title: "أضف فئة جديدة",
    labels: {
      enTitle: "الاسم بالانجليزية",
      arTitle: "الاسم بالعربية",
      uploadLogo: "الشعار",
    },
    placeholders: {
      enTitle: "أدخل اسم الفئة بالانجليزية",
      arTitle: "أدخل اسم الفئة بالعربية",
    },
  },
  editCategory: {
    title: "تعديل الفئة",
    labels: {
      edit: "حفظ",
    },
  },
  deleteCategory: {
    title: "حذف الفئة",
    deleteConfirmationMsg: "هل أنت متأكد من حذف هذه الفئة ؟ ",
  },
  viewCategory: {
    title: "تفاصيل الفئة",
    noProvider: "لم يتم إضافة مقدمي الخدمة لهذه الفئة",
    noSpecialty: "لم يتم إضافة تخصصات لهذه الفئة",
    noCategory: "لم يتم إضافة أي فئة حتى الآن",
  },
};

export default serviceProviderCategories;
