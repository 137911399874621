const successMsgs = {
  changePassword: "Password is changed successfully",
  forgetPassword: "We have sent you a new password",
  deletedSuccessfully: "Successfully deleted",
  addedSuccessfully: "Added successfully",
  editedSuccessfully: "Edited successfully",
  generatedSuccessfully: "Generated successfully",
  copiedSuccessfully: "Copied Successfully",
  cancelledSuccessfully: "Cancelled successfully",
  requestSentSuccessfully: "Request sent successfully",
  exportTransactions: "Payment transactions report email sent",
};

export default successMsgs;
