import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Map from '../../../components/GoogleMaps/Map';
import Breadcrumbs from '../../../components/BreadCrumbs';
import messages from '../../../assets/locale/messages';
import { setPageMainTitle } from '../../../utils/Helpers';
import { ROUTE_PATHS } from '../../../utils/RoutesPaths';
import { getGoogleMapsPolygon } from '../../../store/GoogleMapCoords/actions';
import './CoverageAreas.scss';
import { ARABIC_REGEX, ENGLISH_REGEX, NAME_REGEX } from '../../../utils/Patterns';

const CoverageAreas = (props) => {
  const lang = useSelector((state) => state.locale.lang);
  const { providerid } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { branches, pagesTitles, shared } = messages[lang];
  const [coords, setCoords] = useState(null);
  //   perviousCoords should be retruned later on to use select
  const [previousCoords, setpreviousCoords] = useState(null);
  const [polygonClicked, setPolygonClicked] = useState(false);
  const [oldPolygons, setOldPolygons] = useState(null);
  const [viewOnlyMood, setViewOnlyMood] = useState(false);
  const [viewBackBTN, setViewBackBTN] = useState(false);
  const [removeDataFromViewExceptMap, setRemoveDataFromViewExceptMap] = useState(false);

  useEffect(() => {
    if (props.location?.state?.otherZones) {
      setOldPolygons(props.location?.state?.otherZones);
    }
  }, [props.location?.state?.otherZones]);

  useEffect(() => {
    if (props.location?.state?.viewOnly) {
      setViewOnlyMood(true);
      setViewBackBTN(true);
    }
  }, [props.location?.state]);

  useEffect(() => {
    if (props?.viewMapInModelOnly === true) {
      setViewOnlyMood(true);
      setViewBackBTN(false);
      setRemoveDataFromViewExceptMap(true);
    }
  }, [props]);

  const getCurrentCoords = () => {
    if (!coords) {
      navigator.geolocation.getCurrentPosition((result) => {
        setCoords([
          {
            lat: result.coords.latitude,
            lng: result.coords.longitude,
          },
          {
            lat: result.coords.latitude + 0.02,
            lng: result.coords.longitude,
          },
          {
            lat: result.coords.latitude + 0.02,
            lng: result.coords.longitude + 0.02,
          },
          {
            lat: result.coords.latitude,
            lng: result.coords.longitude,
          },
        ]);
      });
    }
    return coords;
  };

  const getPolygon = (polygon) => {
    // get me the coords when i move the marker event emmitter
    setPolygonClicked(true);
    setCoords(polygon);
  };

  const getSavedPolygon = () => {
    if (props.location?.state?.zone) {
      const newCoords = props.location?.state?.zone?.latlng.map((singleCoord) => ({
        lat: +singleCoord.lat,
        lng: +singleCoord.lng,
      }));
      return newCoords;
    }
    if (props.viewOnlyPolygon) {
      const newCoords = props.viewOnlyPolygon?.latlng.map((singleCoord) => ({
        lat: +singleCoord.lat,
        lng: +singleCoord.lng,
      }));
      return newCoords;
    }
    return [];
  };

  const previouslyCreatedPolygons = () => {
    if (props.location?.state?.otherZones) {
      const oldZones = props.location?.state?.otherZones?.map((zone) =>
        zone?.latlng.map((singleCoord) => ({
          lat: +singleCoord.lat,
          lng: +singleCoord.lng,
        }))
      );
      return oldZones;
    }
    return [];
  };

  useEffect(() => {
    setPageMainTitle('serviceProvidersManagement');
  }, []);

  useEffect(() => {
    if (props.location?.state?.zone?.name_en || props.location?.state?.zone?.name_ar) {
      // set area name
      setFieldTouched({
        locationName: true,
      });
      setFieldTouched({
        locationNameAr: true,
      });
      setValues({
        locationName: props.location?.state?.zone?.name_en,
        locationNameAr: props.location?.state?.zone?.name_ar,
      });
    }
  }, []);

  useEffect(() => {
    if (props.location?.state?.zone?.name_en || props.location?.state?.zone?.name_ar) {
      // set area name
      setFieldTouched({
        locationName: true,
        locationNameAr: true,
      });
      setValues({
        locationName: props.location?.state?.zone?.name_en,
        locationNameAr: props.location?.state?.zone?.name_ar,
      });
    }
  }, []);

  useEffect(() => {
    if (!previousCoords) {
      getCurrentCoords();
    }
  }, []);
  const { values, setFieldValue, setValues, handleSubmit, touched, errors, setFieldTouched, dirty, isValid } =
    useFormik({
      initialValues: {
        locationName: '',
        locationNameAr: '',
      },
      validationSchema: Yup.object({
        locationName: Yup.string()
          .required('locationNameRequired')
          .matches(NAME_REGEX, 'nameNotFollowREGEX')
          .matches(ENGLISH_REGEX, 'englishLettersOnly'),
        locationNameAr: Yup.string()
          .required('locationNameArRequired')
          .matches(NAME_REGEX, 'nameNotFollowREGEX')
          .matches(ARABIC_REGEX, 'arabicLettersOnly'),
      }),
      onSubmit: ({ locationName, locationNameAr }) => {
        if (oldPolygons !== null) {
          let finalPolygons = [
            ...oldPolygons,
            {
              name_en: locationName?.trimStart(),
              name_ar: locationNameAr?.trimStart(), 
              latlng: coords,
              _destroy: false,
              id: props.location?.state?.zone?.id,
              FE_id: new Date().getTime(),
            },
          ];
          dispatch(getGoogleMapsPolygon(finalPolygons));
        } else {
          dispatch(
            getGoogleMapsPolygon([
              {
                name_en: locationName,
                name_ar: locationNameAr,
                latlng: coords,
                id: props.location?.state?.zone?.id,
                FE_id: new Date().getTime(),
                _destroy: false,
              },
            ])
          );
        }
        history.goBack();
      },
    });

  return (
    <div className="coverage-areas-page">
      <div className="page-container">
        <div className="header mb-4">
          {!removeDataFromViewExceptMap && (
            <Breadcrumbs
              list={[
                {
                  label: pagesTitles['AddBranch'],
                  path: ROUTE_PATHS.addBranch,
                },
                {
                  label: pagesTitles['SupportedRegions'],
                  path: ROUTE_PATHS.addBranchSupportedRegions.replace(':id', providerid),
                },
              ]}
              BreadCrumbHasBackIcon={true}
            />
          )}
        </div>
        <div className="google-map-section">
          <Map
            isMarker={false}
            hasSearch={true}
            isPolygon={true}
            getPolygon={getPolygon}
            savedPolygon={getSavedPolygon()}
            previouslyCreatedPolygons={previouslyCreatedPolygons()}
            isTooltip={true}
            resetMap={true}
            viewOnly={viewOnlyMood}
            resetButtonText={
              <div>
                <span>
                  <SettingsBackupRestoreIcon />
                </span>
                <span>{shared.ResetPolygon}</span>
              </div>
            }
            hideSearchBarAndBTNReset={removeDataFromViewExceptMap}
          />
        </div>
        {!removeDataFromViewExceptMap && (
          <form noValidate onSubmit={handleSubmit}>
            <div className="row pt-4 bg-white no-gutter">
              <div className="col-12">
                <Input
                  id="locationName"
                  label={branches.addBranch.labels.addName}
                  name="locationName"
                  type="text"
                  placeholder={branches.addBranch.placeholders.addName}
                  required
                  onChange={(value) => {
                    setFieldTouched('locationName');
                    setFieldValue('locationName', value);
                  }}
                  value={values['locationName']}
                  inputWrapperClass="mb-4"
                  labelClassName="font-medium mb-2 text-primary"
                  isInputHasErr={!!(touched['locationName'] && errors['locationName'])}
                  errMsg={errors['locationName']}
                  disabled={viewOnlyMood}
                />
                <Input
                  id="locationNamear"
                  label={branches.addBranch.labels.addNameAr}
                  name="locationNamear"
                  type="text"
                  placeholder={branches.addBranch.placeholders.addNameAr}
                  required
                  onChange={(value) => {
                    setFieldTouched('locationNameAr');
                    setFieldValue('locationNameAr', value);
                  }}
                  value={values['locationNameAr']}
                  inputWrapperClass="mb-4"
                  labelClassName="font-medium mb-2 text-primary"
                  isInputHasErr={!!(touched['locationNameAr'] && errors['locationNameAr'])}
                  errMsg={errors['locationNameAr']}
                  disabled={viewOnlyMood}
                />
              </div>
            </div>
            <div className="SaveChanges text-end">
              {!viewBackBTN && (
                <Button
                  label={
                    <span className=" w-25">
                      <span className="ps-1">{shared.saveAndProcced}</span>
                    </span>
                  }
                  type="submit"
                  labelClass="fsize-16 text-white"
                  disabled={!dirty || !isValid || !polygonClicked}
                  onClick={() => {}}
                />
              )}
              {viewBackBTN && (
                <Button
                  label={
                    <span className=" w-25">
                      <span className="ps-1">{shared.back}</span>
                    </span>
                  }
                  type="button"
                  labelClass="fsize-16 text-white"
                  onClick={() => {
                    history.goBack();
                  }}
                />
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CoverageAreas;
