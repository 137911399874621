import dayjs from "dayjs";
import "dayjs/locale/ar-sa";
import "dayjs/locale/en";

import store from "../store";

export const renderFormatedDate = (date, format = "DD/MM/YYYY") => {
  const lang = store.getState().locale.lang;
  if (lang === "ar") dayjs.locale("ar-sa");

  if (date) return dayjs(date).format(format);
};
