const paymentTransactions = {
    list:{
        search:' ابحث باستخدام رقم الحجز أو اسم الفرع ',
        filters:{
            transactionsStatusPlaceholder:'نوع المعاملات',
            transactionsStatusLabel:'نوع المعاملات',
            fromDatePlaceholder:'من تاريخ',
            fromDateLabel:'من تاريخ',
            toDatePlaceholder:'إلى تاريخ',
            toDateLabel:'إلى تاريخ',
        },
        transactionsTypes:{
            cash:'نقدي',
            online:'دفع إلكتروني',
            admin_resolution:'حل بواسطة المسؤول',
        },
        showEqu:'اظهر معادلة التسوية',
        hideEqu: 'اخفي معادلة التسوية',
        transactionDetails: 'تفاصيل المعاملة',
        transationId: 'رقم المعاملة',
        exportTransactions: 'تحميل معاملات الدفع'
    }
}

export default paymentTransactions;