import {
  GET_BRANCH_RESPONSE,
  ADD_BRANCH_DATA_REQUEST,
  ADD_BRANCH_RESPONSE,
  GET_ADDRESS_FROM_COORDS_RESPONSE,
  GET_BRANCH_SERVICES_RESPONSE,
  GET_BRANCHES_RESPONSE,
  GET_REQUEST_STATUS,
  GET_BRANCH_EMPLOYEE_REQUEST_STATUS,
  GET_SERVICES_RESPONSE,
  GET_SPECIALTIES_RESPONSE,
  EDIT_BRANCH_STATUS_REQUEST,
  GET_BRANCH_REVIEWS_RESPONSE,
  GET_BRANCH_REVIEW_DETAILS_RESPONSE,
  BRANCH_REVIEW_REQUEST_STATUS,
  GET_PUBLISH_BRANCH_SERVICE_REQUEST_STATUS,
  GET_DELETE_BRANCH_SPECIALITY_REQUEST_STATUS,
  GET_DELETE_BRANCH_SERVICE_REQUEST_STATUS
} from './types';

const INITIAL_STATE = {
  listBranches: {
    list: [],
    meta: {},
  },
  addEditViewBranch: {
    branchDetails: {},
    requestStatus: null,
    employees: {
      employeesList: {
        list: [],
        meta: {},
      },
      addEditViewEmployee: {
        employeeDetails: {},
        requestStatus: null,
      },
    },
    specialties: {
      specialtiesList: {
        list: [],
        meta: {},
      },
      addEditViewSpecialty: {
        specialtyDetails: {},
        requestStatus: null,
      },
    },
    services: {
      servicesList: {
        list: [],
        meta: {},
      },
      addEditViewService: {
        serviceDetails: {},
        requestStatus: null,
      },
    },
    reviews: {
      reviewsList: {
        list: [],
        meta: {},
      },
      addEditViewReview: {
        reviewDetails: {},
        requestStatus: null,
      },
    },
    helpers: {
      addressFromCoords: {},
      addedBranch: {},
      savedBranchData: {},
      branchServices: {},
      branchCreatedId: null,
    },
  },
  publishBranchServiceStatusRequest:null,
  deleteBranchServiceStatusRequest:null,
  deleteBranchSpecialityStatusRequest: null,
};

const branches = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BRANCH_RESPONSE:
      return {
        ...state,
        addEditViewBranch: {
          ...state.addEditViewBranch,
          branchDetails: action.payload?.branchDetails,
        },
      };
    case GET_BRANCH_SERVICES_RESPONSE:
      return {
        ...state,
        addEditViewBranch: {
          ...state.addEditViewBranch,
          helpers: {
            ...state.addEditViewBranch.helpers,
            branchServices: action.payload.branch_services,
          },
        },
      };
    case GET_ADDRESS_FROM_COORDS_RESPONSE:
      return {
        ...state,
        addEditViewBranch: {
          ...state.addEditViewBranch,
          helpers: {
            ...state.addEditViewBranch.helpers,
            addressFromCoords: action.payload,
          },
        },
      };
    case EDIT_BRANCH_STATUS_REQUEST:
      return {
        ...state,
        addEditViewBranch: {
          ...state.addEditViewBranch,
          requestStatus: action.payload === null ? null : action.payload,
        },
      };
    case ADD_BRANCH_RESPONSE:
      return {
        ...state,
        addEditViewBranch: {
          ...state.addEditViewBranch,
          helpers: {
            ...state.addEditViewBranch.helpers,
            addedBranch: action.payload === null ? null : action.payload,
            branchCreatedId: action.payload?.id || null,
          },
        },
      };
    case ADD_BRANCH_DATA_REQUEST:
      return {
        ...state,
        addEditViewBranch: {
          ...state.addEditViewBranch,
          helpers: {
            ...state.addEditViewBranch.helpers,
            savedBranchData: action.payload,
          },
        },
      };
    case GET_BRANCHES_RESPONSE:
      return {
        ...state,
        listBranches: {
          ...state.listBranches,
          list: action.payload.list,
          meta: action.payload.meta,
        },
      };
    case GET_REQUEST_STATUS:
      return {
        ...state,
        addEditViewBranch: {
          ...state.addEditViewBranch,
          requestStatus: action.payload === null ? null : action.payload,
        },
      };
    case GET_BRANCH_EMPLOYEE_REQUEST_STATUS:
      return {
        ...state,
        addEditViewBranch: {
          ...state.addEditViewBranch,
          employees: {
            ...state.addEditViewBranch.employees,
            addEditViewEmployee: {
              ...state.addEditViewBranch.employees.addEditViewEmployee,
              requestStatus: action.payload === null ? null : action.payload,
            },
          },
        },
      };
    case GET_SERVICES_RESPONSE:
      return {
        ...state,
        addEditViewBranch: {
          ...state.addEditViewBranch,
          services: {
            ...state.addEditViewBranch.services,
            servicesList: {
              ...state.addEditViewBranch.services.servicesList,
              list: action.payload.list,
              meta: action.payload.meta,
            },
          },
        },
      };
    case GET_SPECIALTIES_RESPONSE:
      return {
        ...state,
        addEditViewBranch: {
          ...state.addEditViewBranch,
          specialties: {
            ...state.addEditViewBranch.specialties,
            specialtiesList: {
              ...state.addEditViewBranch.specialties.specialtiesList,
              list: action.payload.list,
              meta: action.payload.meta,
            },
          },
        },
      };
    case GET_BRANCH_REVIEWS_RESPONSE:
      return {
        ...state,
        addEditViewBranch: {
          ...state.addEditViewBranch,
          reviews: {
            ...state.addEditViewBranch.reviews,
            reviewsList: {
              ...state.addEditViewBranch.reviews.reviewsList,
              list: action.payload.list,
              meta: action.payload.meta,
            },
          },
        },
      };
    case GET_BRANCH_REVIEW_DETAILS_RESPONSE:
      return {
        ...state,
        addEditViewBranch: {
          ...state.addEditViewBranch,
          reviews: {
            ...state.addEditViewBranch.reviews,
            addEditViewReview: {
              ...state.addEditViewBranch.reviews.addEditViewReview,
              reviewDetails: action.payload,
            },
          },
        },
      };
    case BRANCH_REVIEW_REQUEST_STATUS:
      return {
        ...state,
        addEditViewBranch: {
          ...state.addEditViewBranch,
          reviews: {
            ...state.addEditViewBranch.reviews,
            addEditViewReview: {
              ...state.addEditViewBranch.reviews.addEditViewReview,
              requestStatus: action.payload === null ? null : action.payload,
            },
          },
        },
      };
    case GET_PUBLISH_BRANCH_SERVICE_REQUEST_STATUS:
      return {
        ...state,
        publishBranchServiceStatusRequest: action.payload === null ? null : action.payload,
      };
      case GET_DELETE_BRANCH_SERVICE_REQUEST_STATUS:
        return {
          ...state,
          deleteBranchServiceStatusRequest: action.payload === null ? null : action.payload,
        };
      case GET_DELETE_BRANCH_SPECIALITY_REQUEST_STATUS:
        return {
          ...state,
          deleteBranchSpecialityStatusRequest: action.payload === null ? null : action.payload,
        };
      default:
        return state;
  }
};

export default branches;
