import React from "react";
import PropTypes from "prop-types";
import {Switch as MaterialSwitch} from '@mui/material';

import "./Switch.scss";

const Switch = ({ checked, onChange, disabled }) => {
  return (
    <div className="icon-wrapper">
      <MaterialSwitch
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default Switch;
