import React from "react";

export const BranchDetailsTabIcon = () => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="96" height="96" rx="46.1957" fill="white" />
    <g clipPath="url(#clip0_3_12)">
      <path
        d="M48.3 61.9C48.4 62.4 48.6 62.9 48.8 63.4C48 63.5 47.2 63.5 46.3 63.5H36C27.1 63.5 23.3 59.7 23.3 50.8V42.3C23.3 41.9 23.6 41.5 24.1 41.5C24.5 41.5 24.9 41.8 24.9 42.3V50.8C24.8 59 27.8 62 36 62H46.2C47 62 47.7 62 48.3 61.9Z"
        fill="#888D94"
        stroke="#888D94"
        strokeWidth="0.7"
      />
      <path
        d="M59 42.3V48.2C58.5 48.2 58 48.2 57.5 48.3V42.3C57.5 41.9 57.8 41.5 58.3 41.5C58.8 41.5 59 41.9 59 42.3Z"
        fill="#888D94"
        stroke="#888D94"
        strokeWidth="0.7"
      />
      <path
        d="M41.1 44.5C39.2 44.5 37.5 43.8 36.3 42.4C35.1 41 34.5 39.3 34.7 37.4L36 24.7C36 24.3 36.3 24 36.7 24H45.6C46 24 46.3 24.3 46.3 24.7L47.6 37.4C47.8 39.3 47.2 41.1 46 42.4C44.7 43.8 43 44.5 41.1 44.5ZM37.4 25.5L36.2 37.5C36.1 39 36.5 40.3 37.4 41.4C38.3 42.4 39.7 43 41.1 43C42.5 43 43.9 42.4 44.8 41.4C45.7 40.4 46.2 39 46 37.5L44.8 25.5H37.4V25.5Z"
        fill="#888D94"
        stroke="#888D94"
        strokeWidth="0.7"
      />
      <path
        d="M45.9 63.5H36.4C36 63.5 35.6 63.2 35.6 62.7V58C35.6 54.4 37.5 52.5 41.1 52.5C44.7 52.5 46.6 54.4 46.6 58V62.8C46.6 63.2 46.3 63.5 45.9 63.5ZM37.1 62H45.1V58C45.1 55.2 43.9 54 41.1 54C38.3 54 37.1 55.2 37.1 58V62V62ZM53.1 44.5C49.6 44.5 46.4 41.6 46.1 38.1L44.8 24.8C44.8 24.6 44.8 24.4 45 24.2C45.1 24.1 45.3 24 45.5 24H51.3C56.5 24 58.9 26.1 59.6 31.5L60.1 36.8C60.3 38.9 59.7 40.8 58.4 42.3C57.1 43.7 55.2 44.5 53.1 44.5ZM46.3 25.5L47.6 38C47.9 40.8 50.4 43 53.1 43C54.7 43 56.2 42.4 57.3 41.2C58.3 40 58.8 38.5 58.7 36.9L58.2 31.7C57.6 27 55.9 25.5 51.4 25.5H46.3V25.5ZM29.1 44.5C27 44.5 25.2 43.7 23.8 42.2C22.5 40.7 21.8 38.8 22.1 36.7L22.6 31.5C23.3 26.1 25.6 24 30.9 24H36.7C36.9 24 37.1 24.1 37.3 24.2C37.4 24.4 37.5 24.6 37.5 24.8L36.2 38.1C35.7 41.6 32.6 44.5 29.1 44.5ZM30.9 25.5C26.3 25.5 24.7 27 24 31.7L23.5 36.9C23.3 38.5 23.8 40.1 24.9 41.2C25.9 42.4 27.4 43 29.1 43C31.9 43 34.4 40.8 34.6 38L35.8 25.5H30.9Z"
        fill="#888D94"
        stroke="#888D94"
        strokeWidth="0.7"
      />
      <path
        d="M70.9 70.9C70.7 70.9 70.5 70.8 70.4 70.7L68.4 68.7C68.1 68.4 68.1 67.9 68.4 67.6C68.7 67.3 69.2 67.3 69.5 67.6L71.5 69.6C71.8 69.9 71.8 70.4 71.5 70.7C71.3 70.8 71.1 70.9 70.9 70.9ZM60.4 69.9C57.7 69.9 55.1 68.8 53.2 66.9C51.3 65 50.2 62.4 50.2 59.7C50.2 57 51.3 54.4 53.2 52.5C55.1 50.6 57.7 49.5 60.4 49.5C61.8 49.5 63.1 49.8 64.3 50.3C65.6 50.8 66.7 51.6 67.6 52.5C68.6 53.5 69.3 54.6 69.8 55.8C70.3 57 70.6 58.4 70.6 59.7C70.6 61.1 70.3 62.4 69.8 63.6C69.3 64.9 68.5 66 67.6 66.9C66.6 67.9 65.5 68.6 64.3 69.1C63.1 69.6 61.8 69.9 60.4 69.9ZM60.4 50.9C58.1 50.9 55.9 51.8 54.2 53.5C52.5 55.2 51.6 57.3 51.6 59.7C51.6 62 52.5 64.2 54.2 65.9C55.9 67.6 58.1 68.5 60.4 68.5C61.6 68.5 62.7 68.3 63.7 67.8C64.8 67.4 65.7 66.7 66.5 65.9C67.3 65.1 68 64.1 68.4 63.1C68.8 62 69.1 60.9 69.1 59.8C69.1 58.7 68.9 57.5 68.4 56.5C68 55.4 67.3 54.5 66.5 53.7C65.7 52.9 64.7 52.2 63.7 51.8C62.7 51.1 61.6 50.9 60.4 50.9Z"
        fill="#888D94"
        stroke="#888D94"
        strokeWidth="0.7"
      />
    </g>
    <defs>
      <clipPath id="clip0_3_12">
        <rect
          width="49.7"
          height="46.9"
          fill="white"
          transform="translate(22 24)"
        />
      </clipPath>
    </defs>
  </svg>
);
