const branches = {
  addBranch: {
    firstTab: 'Branch details',
    secondTab: 'Specialties & services',
    thirdTab: 'Branch employees',
    addBranch: 'Add new branch',
    saveAndSendPublishRequest:'Save & send publish request',
    labels: {
      enTitle: 'Branch name (En)',
      arTitle: 'Branch name (Ar)',
      branchImage: 'Branch image',
      phone: 'Phone Number',
      workingHours: 'Working hours',
      location: 'Location',
      ServicedGender: 'Serviced gender',
      paymenMethods: 'Payment Methods',
      addName: 'District name (En)',
      addNameAr: 'District name (Ar)',
      applyToAllWokringDays: 'Copy these working Hours to all days?',
      applyToAllWokringDaysAction: 'Yes,Copy',
      male: 'Male',
      female: 'Female',
      bothGender: 'Both genders',
      online: 'Online payment',
      cash: 'Cash',
      both: 'Online payment and cash',
      contryNameEn: 'Country name (EN)',
      contryNameAr: 'Country name (AR)',
      city: 'City',
      region: 'District',
      streetNumber: 'Street number',
      streetName: 'Street name',
      buildingNumber: 'Building number',
      postalNumber: 'Postal code',
      secondaryNumber: 'Secondary number',
      landmark: 'Landmark',
      addSepicialtiesAndServices: 'Add specialties & services',
      addServicesOnSpecialty: 'No services found, Please add services on this specialty',
      addEmployee: 'Add employee',
      editEmployee: 'Edit employee',
      employessDetails: 'Employee details',
      deleteEmployee: 'Delete Employee',
      deleteEmployeeMessage: 'Are you sure you want to delete this employee?',
      confirmCancel: 'Cancel Confirmation',
      confirmCancelMessage: 'Unsaved data will be lost, Are you sure you want to cancel?',
      AddSpecialties: 'Add specialties',
    },
    placeholders: {
      enTitle: 'Enter branch english title',
      arTitle: 'Enter branch arabic title',
      branchImage: 'Upload image',
      phone: 'Enter phone number',
      location: 'Select offered location',
      ServicedGender: 'Select gender',
      paymenMethods: 'Enter allowed payment method',
      addName: 'Enter district name in english',
      addNameAr: 'Enter district name in arabic',
      searchForLocations: 'Search for location',
      from: 'From',
      to: 'To',
      atHome: `At customer's place`,
      atBranch: 'Branch location',
      supportedRegions: 'Supported districts',
      selectFromMap: 'Select from map',
      contryNameEn: 'Enter/Select Country in english',
      contryNameAr: 'أدخل/ اختر أسم البلد باللغه العربيه',
      city: 'Enter/Select City',
      region: 'Enter/Select district',
      streetNumber: 'Enter street number',
      streetName: 'Enter street name',
      buildingNumber: 'Building number',
      postalNumber: 'Postal code',
      secondaryNumber: 'Secondary number',
      landmark: 'Landmark',
    },
    specialtiesAndServices: {
      onlyNumbersAreAllowed: 'Only numbers are allowed',
      fromIsRequired: 'From is required',
      currencyIsRequired: 'Currency is required',
      durationIsRequired: 'Duration is required',
      feesRange: 'Fees range',
      from: 'From',
      to: 'To',
      currency: 'Currency',
      duration: 'Duration',
      minutes: 'Minutes',
      description: 'Description',
      location: 'Location',
      first_name: 'Employee first name in english',
      last_name: 'Employee last name in english',
      first_name_ar: 'Employee first name in arabic',
      last_name_ar: 'Employee last name in arabic',
      assignServices: 'Assign services',
      assignedServices:'Assigned services',
      deleteSpecialty: 'Delete specialty',
      publishRequest: 'Publish request',
      publishedRequest: 'Send publish request', 
      deleteSpecialtyMsg: 'Are you sure you want to delete this specialty?',
      deleteService: 'Delete service',
      deleteServiceMsg: 'Are you sure you want to delete this service?',
      employeeName: 'Employee name',
      employeeFirstNameEn: 'Employee first name in english',
      employeeLastNameEn: 'Employee last name in english',
      employeeFirstNameAr: 'Employee first name in arabic',
      employeeLastNameAr: 'Employee last name in arabic',
      branchName: 'Branch name',
      note:'Note that',
      deleteServiceNote:'Your branch will be deactivated if this is the last remaining service.',
      deletSpecialityNote:'Your branch will be deactivated if this is the last remaining specialty.',
      deactivateService: 'Deactivate service',
      deactivateServiceMsg:'You may still find this service included in existing upcoming bookings',
      deactivateServiceNote:'Your branch will be deactivated if this is the last remaining service'
    },
    validationPopupTitle:'Confirmation to edit',
    validationPopupContent:'This action may result in a conflict with existing upcoming bookings, do you want to proceed?',
    workingHoursHint: 'Employees working hours may get affected if branch working hours changed',
    locationHint:'Employees offered locations may get affected if branch offered locations changed',
  },
  list: {
    search: 'Search for branch name or location',
    noDataDescription: 'Start by adding a new branch',
    filter: {
      filtersPlaceHolders: {
        filterByRating: 'Rating',
        filterByLocations: 'Locations',
        filterByServices: 'Services',
        filterByServiceProvider: 'Service Provider',
        AtHome: `At customer's place`,
        AtBranch: 'At branch',
        both: 'At branch & Home',
      },
    },
    atHomeService: `At customer's place service`,
    suspendedByDeactivateBranch: 'This branch will be deactivated starting from',
  },
  viewBranch: {
    title: 'Branch details',
    firstTab: 'Branch details',
    secondTab: 'specialties & services',
    thirdTab: 'Branch Employees',
    fourthTab: 'Bookings',
    fifthTab: 'Reviews',
    rejectionFlag: 'Publish request is rejected',
    seeMore: 'See more',
    rejectionReason: 'Rejection reason',
    sendPublishRequest: 'Send a publish request',
    requestPublishing:'Requested publishing',
    published:'Published',
    labels: {
      enTitle: 'Branch name (En)',
      arTitle: 'Branch name (Ar)',
      phone: 'Phone Number',
      category: 'Category',
      serviceProvider: 'Service provider',
      rating: 'Ratings',
      noRating: 'No ratings yet',
      noCategory: 'No category yet',
      noProvider: 'No service provider yet',
      workingHours: 'Working hours',
      location: 'Location',
      home: "At customer's place",
      branch: 'Branch location',
      supportedRegions: 'Supported regions',
      viewOnMap: 'View on map',
      none: 'None',
      servicedGender: 'Serviced gender',
      paymenMethods: 'Payment Methods',
    },
    branchReviews: {
      customerName: 'Customer’s name',
      appointmentId: 'Appointment ID',
      reviewsContent: 'Review content',
      submittedBranchRating: 'Submitted branch rating',
      submittedEmployeeRating: 'Submitted employees ratings',
      noSubmittedEmployeeRating: 'No submitted employees rating',
      actions: 'Actions',
      deleteReview: 'Delete review',
      deleteReviewMsg: 'Are you sure you want to delete this review?',
    },
  },
  deleteBranch: {
    title: 'Delete branch',
    titleSpecialty: 'Delete branch specialty',
    titleService: 'Delete branch service',
    titleEmployee: 'Delete branch employee',
    noDataDescription: 'Start by adding a new branch',
    deleteConfirmationMsg: 'Are you sure you want to delete this branch?',
    deleteServiceConfirmationMsg: 'Are you sure you want to delete this service?',
    deleteSpecialtyConfirmationMsg: 'Are you sure you want to delete this specialty?',
    deleteEmployeeConfirmationMsg: 'Are you sure you want to delete this employee?',
  },
};

export default branches;
