import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { PASSWORD_REGEX } from "../../../utils/Patterns";
import messages from "../../../assets/locale/messages";
import { changePasswordRequest } from "../../../store/Auth/actions";
import "./ChangePassword.scss";

const ChangePassword = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { changePassword } = messages[lang];
  const dispatch = useDispatch();

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required("oldPasswordRequired"),
      new_password: Yup.string()
        .required("newPasswordRequired")
        .matches(PASSWORD_REGEX, "passwordNotFollowREGEX"),
      confirm_password: Yup.string()
        .required("confirmNewPasswordRequired")
        .oneOf([Yup.ref("new_password"), null], "passwordIsNotMatched"),
    }),
    onSubmit: ({ old_password, new_password, confirm_password }) => {
      dispatch(
        changePasswordRequest({
          data:{
            old_password,
            new_password,
          },
          from:'Auth'
          
        })
      );
    },
  });

  return (
    <div className="change-password-page w-100">
      <h3 className="title font-bold fsize-32 mb-2 text-primary">
        {changePassword.title}{" "}
        <span className="text-secondary">{changePassword.subTitle}</span>
      </h3>
      <form className="w-100" onSubmit={handleSubmit} noValidate>
        <Input
          id="oldPassword"
          label={changePassword.labels.oldPassword}
          name="old_password"
          type="password"
          placeholder={changePassword.placeholders.oldPassword}
          required={true}
          onChange={(value) => {
            setFieldTouched("old_password");
            setFieldValue("old_password", value.trim());
          }}
          value={values["old_password"]}
          inputClass="mb-1"
          labelClassName="font-medium mb-2"
          inputWrapperClass="my-4"
          isInputHasErr={!!(touched["old_password"] && errors["old_password"])}
          errMsg={errors["old_password"]}
        />
        <Input
          id="newPassword"
          label={changePassword.labels.newPassword}
          name="new_password"
          type="password"
          placeholder={changePassword.placeholders.newPassword}
          required={true}
          onChange={(value) => {
            setFieldTouched("new_password");
            setFieldValue("new_password", value.trim());
          }}
          value={values["new_password"]}
          inputClass="mb-1"
          labelClassName="font-medium mb-2"
          inputWrapperClass="my-4"
          isInputHasErr={!!(touched["new_password"] && errors["new_password"])}
          errMsg={errors["new_password"]}
        />
        <Input
          id="confirmNewPassword"
          label={changePassword.labels.confirmNewPassword}
          name="confirm_password"
          type="password"
          placeholder={changePassword.placeholders.confirmNewPassword}
          required={true}
          onChange={(value) => {
            setFieldTouched("confirm_password");
            setFieldValue("confirm_password", value.trim());
          }}
          value={values["confirm_password"]}
          inputClass="mb-1"
          labelClassName="font-medium mb-2"
          inputWrapperClass="my-4"
          isInputHasErr={
            !!(touched["confirm_password"] && errors["confirm_password"])
          }
          errMsg={errors["confirm_password"]}
        />
        <Button
          label={changePassword.actionBTN}
          type="submit"
          labelClass="fsize-16 text-white py-1 fweight-600 border-radius-10"
          className="mb-4"
          block
          disabled={!dirty || !isValid}
        />
      </form>
    </div>
  );
};
export default ChangePassword;
