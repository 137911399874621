const bookings ={
    list: {
        bookingList: 'Booking list',
        search: 'Search by appointment id or customer name',
        filter:{
            filtersPlaceHolders:{
                filterByBranchName: 'Branch name',
                filterByServiceProvider: 'Service provider',
                filterByStartDate:'From date',
                filterByEndDate:'To date',
                filterByBookingsStatus:'Status',
                dateLabel:'Date'
            }
        },
        noData: 'There is no bookings yet'
    },
    status: {
        upcoming: 'Upcoming', 
        ongoing: 'Ongoing', 
        pending_payment: 'Pending payment', 
        completed: 'Done', 
        canceled:'Cancelled'
    },
    details: {
        booking: 'Booking',
        appointmentId: 'Appointment id ',
        date: 'Date',
        status: 'Status',
        paymentMethod: 'Payment method',
        cash: 'Cash',
        online:'Online',
        location: 'Location',
        in_place:'At branch ',
        home: "At customer's place",
        providerName: 'Service provider name ',
        branchName: 'Branch name',
        customerName: 'Customer name',
        deletedBranch:'Deleted branch',
        customerPhoneNumber:'Customer phone number',
        customerNote:'Customer’s notes',
        noNotes:'No notes added',
        services: 'Services',
        emptyState:'There is no services yet !',
        feesRange:'Fees range',
        fees:'Fees',
        sar: ' SAR ', 
        duration: 'Duration',
        from:'From ',
        to:' To ',
        description: 'Description',
        employeeName: ' Employee',
        customerReported: 'Customer has reported that "Employee did not show up"',
        employeeReporeted: 'Employee has reported that "Customer did not show up"',
        reported: 'Reported',
        employeeDidNotShowUp: 'Employee did not show up',
        customerDidiNotShowUP: 'Customer did not show up',
        remining:'Remaining',
        markAsDone: 'Mark as done',
        resolve: 'Employee showed up',
        editFees: 'Edit fees',
        feesInputLabel:'Enter the required fees value for ',
        service: ' service',
        feesHint: 'Exact value should start from ',
        feesInputPlaceholder: 'Required fees',
        cancelUpcomingPopupContent: 'Are you sure you want to cancel this appointment ?',
        cancelBooking: "Cancel appointment",
        cancelService: "Cancel service",
        refuseToCancelPopupContent: 'This appointment can not be cancelled; since it has done service.',
        bookingCancelationReasonLabel: 'Select the reason to cancel this appointment',
        serviceCancelationReasonLabel: 'Select the reason to cancel this service',
        cancellationReason: 'Cancellation reason',
        cancelledBy: 'Cancelled by',
        Admin:'Admin',
        ServiceProviderManager:'Service provider manager',
        Employee:'Employee',
        Customer:'Customer',
        addAnotherService: 'Add another service',
        customerHasPaid:'Customer has paid',
        customerHasPaidPopupContent:'Are you sure that customer has paid ?',
        totalAmountWithoutVAT: 'Total amount without VAT',
        VAT: 'VAT%',
        totalAmountWithVAT: 'Total amount with VAT',
        paymentConfirmedBy: 'Payment confirmed by',
        problemLabel: ' A problem has been reported ',
    },
    addAnotherService:{
        searchPlaceholder: 'Search by service title',
        specialtyFilterPlaceholder: 'specialty',
        noData:'There is no more services',
        mins: 'Mins',
        employeePlaceHolder: 'No employee is selected yet',
        employeeLabel: 'Select employee',
        employeeList:'Employee list',
        employeeSearchPlaceolder:'Search by employee name or employee number',
        counterLabel: 'services selected',
        seeMore: 'See more',
        noEmployeePlaceholder: 'This service can not be selected since it has no assigned employees'


    }
};

export default bookings;