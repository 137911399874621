const settings = {
    viewSettings:{
        changePassword:'تغير كلمة المرور',
        cancellationPolicy:'سياسة الإلغاء',
        editPofile:'تعديل الملف الشخصي'
    },
    cancellationPolicy:{
        cancellationDuration: 'مدة الإلغاء',
        cancelationDurationlabel: 'السماح بالإلغاء قبل الموعد حتى',
        consecutiveCancellations: 'الإلغاءات المتتالية',
        consecutiveCancellationsText:'يسمح للعميل بإلغاء المواعيد المتتالية على التوالي',
        maxNumberOfConsecutiveCancellations: 'الحد الأقصى لعدد الإلغاءات المتتالية',
        numberOfDaysForPreventingFurtherCancelllations: 'عدد الأيام لمنع المزيد من الإلغاءت',
        days: 'أيام',
        hours:'ساعات',
        hoursPlaceholders: 'ادخل عدد الساعات',
        maxNumberPlaceholder: 'ادخل الحد الأقصى من الإلغاءات المتتالية',
        daysPlaceholder: 'ادخل عدد الأيام',
        hint: '   وسيُسمح للعميل بإلغاء المواعيد المتتالية حتى هذا الحد دون استكمال أي موعد بينهما ويجب أن يبدأ هذا الحد من 2 ',
        maxNumberErrorMsg: 'يجب أن يبدأ الحد الأقصى لعدد الإلغاءات المتتالية من 2',
       
    },
    changePassword:{
        changePassword:'تغير كلمة المرور'
    },
    editProfile:'تعديل الملف الشخصي'
    


};

export default settings;