export const ROUTE_PATHS = {
  // auth
  login: '/login',
  forgetPassword: '/forget-password',
  tempPassword: '/temporary-password',
  changePassword: '/change-password',
  setupProfile: '/setup-profile',
  // branches
  branches: '/branches',
  addBranch: '/branches/add',
  editBranch: '/branches/:id/edit',
  viewBranch: '/branches/:id',
  addBranchSupportedRegions: '/branches/:id/add-edit-branch/coverage-regions',
  addBranchBranchLocation: '/branches/:id/add-edit-branch/branch-location',
  // employees
  employees: '/employees',
  viewEmployee: '/employees/:id',
  editEmployees: '/employees/:id/edit',
  // bookings
  bookings: '/bookings',
  viewBooking: '/bookings/:id',
  addAnotherServices:'/bookings/:id/add-another-service',
  // settings 
  settings: '/settings',
  changePasswordFromSettings:'/settings/change-password',
  editProfile:'/settings/edit-profile',
  //transactions
  transactions: '/payment-transactions',
  // notification
  notifications: "/notifications",
};
