import { GET_COORDS, GET_POLYON, SAVE_BRANCH_DATA } from "./types";

// list
export const getGoogleMapsCoords = (payload) => ({
  type: GET_COORDS,
  payload,
});

export const getGoogleMapsPolygon = (payload) => ({
  type: GET_POLYON,
  payload,
});

export const saveBranchData = (payload) => ({
  type: SAVE_BRANCH_DATA,
  payload,
});