import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory} from "react-router-dom";
import { Typography, Divider} from '@mui/material';
import {
    AccessTimeOutlined,
    Person2Outlined,
    ArticleOutlined,
    CreditCardOutlined,
    Add,
    Edit,
    Close
    }from '@mui/icons-material';

import { getBookingRequest, getOtherServicesRequest, updateBookingRequest } from '../../../../store/Bookings/actions'
import { getEmployeesRequest } from '../../../../store/Employees/actions'
import { getBranchSpecialtiesLookUpRequest } from '../../../../store/LookUps/actions'
import { setPageMainTitle, textContainOnlySpaces } from "../../../../utils/Helpers";
import messages from '../../../../assets/locale/messages';
import { ROUTE_PATHS } from '../../../../utils/RoutesPaths';
import SearchInput from '../../../../components/SearchInput';
import FilterLabel from '../../../../components/Filter/FilterLabel';
import FilterInput from '../../../../components/Filter/FilterInput';
import EmptyState from '../../../../components/EmptyState';
import Button from '../../../../components/Button';
import Breadcrumbs from '../../../../components/BreadCrumbs'
import Modal from '../../../../components/Modal'
import EmployeesPopup from './EmployeesPopup';
import './AddAnthorService.scss';

const AddAnotherService = () => {
    
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const lang = useSelector((state) => state.locale.lang);
    const isLoading = useSelector((state) => state.loader); 
    const bookingData = useSelector((state) => state.bookings.bookingDetails);
    const specialtiesList = useSelector((state) => state.lookUps.branchSpecialties)
    const servicesList = useSelector((state)=> state.bookings.otherServices.list)
    const meta = useSelector((state)=> state.bookings.otherServices.meta)
    const employees = useSelector((state) => state.employees.list)
    const { bookings, shared } = messages[lang];
    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [filterSpecialtyValue, setFilterSpecialtyValue] = useState(null)
    const [specialtiesOptions, setSpecialtiesOptions] = useState([]);
    const [showEmployeePopup, setShowEmployeePopup] = useState(false);
    const [selectedServiceId, setSelectedServiceId] = useState(null)
    const [servicesEmployees, setServicesEmployees ] = useState([]);
    const [serviceCounter, setServiceCounter] = useState(0);
    const [employeeId, setEmployeeId] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)
    const [showNoEmployeesPopup, setShowNoEmployeesPopup] = useState(false);
     
    useEffect(() => {
        setPageMainTitle('bookingsManagement');
        dispatch(getBookingRequest(id));
        setPage(1)
        getOtherServices(
            1,
            searchValue,
            filterSpecialtyValue?.id
        );
           
    },[lang])

    useEffect(()=>{
        if(Object.keys(bookingData).length){
            dispatch(getBranchSpecialtiesLookUpRequest({
                page:-1,
                branch_id: bookingData?.branch?.id,
            }));
        }
    },[lang, bookingData])

    useEffect(() => {
        getOtherServices(
            page,
            searchValue,
            filterSpecialtyValue?.id
        );
    },[page])

    useEffect(() => {
        setPage(1)
        getOtherServices(
            1,
            searchValue,
            filterSpecialtyValue?.id
        );
    },[filterSpecialtyValue])

    useEffect(() => {
        if ((searchValue.length >= 3 || searchValue.length === 0) && !textContainOnlySpaces(searchValue)){
            setPage(1)
            getOtherServices(
                1,
                searchValue,
                filterSpecialtyValue?.id
            ); 
        }
    },[searchValue])

    useEffect(() => {
        if(specialtiesList && specialtiesList?.length){
            setSpecialtiesOptions(specialtiesList.map(specialty => {
                return {
                    id: specialty.id,
                    label: lang === 'en' ? specialty.specialty_title_en : specialty.specialty_title_ar, 
                }
            }))
        }
    },[lang, specialtiesList])

    useEffect(() => {
        if (filterSpecialtyValue) {
            setFilterSpecialtyValue(
                specialtiesOptions.find((option) => option.id === filterSpecialtyValue.id)
            );
          }
    },[lang, specialtiesOptions])

    useEffect(()=>{
       const selectedServices = servicesEmployees.filter(service => service.serviceId)
       setServiceCounter(selectedServices.length)
    },[servicesEmployees])

    const getOtherServices = (
        page,
        search,
        specialityId, 
    ) => {
        dispatch(getOtherServicesRequest(
            {
                page:page,
                items:10,
                appointment_id:id,
                title:search,
                branch_specialty_id:specialityId
            }
            ));
    }

    const handleSelectEmployee = (service) => {
        if(service.has_assigned_employees){
            setIsEditMode(false)
            setSelectedServiceId(service.id)
            setEmployeeId(null)
            if(servicesList && servicesList.length){
                dispatch(getEmployeesRequest({
                    page:-1,
                    branch_service_id: service.id
                }))
                setShowEmployeePopup(true);
            }
        }else{
            setShowNoEmployeesPopup(true);
        } 
    };

    const handleDeselectEmployee = (serviceId) => {
        const selectedServices = servicesEmployees?.filter(service=> service.serviceId != serviceId)
        setServicesEmployees([...selectedServices]);
    };

    const handleEditEmployee = (service) => {
        setIsEditMode(true)
        setSelectedServiceId(service.serviceId)
        setEmployeeId(service.employeeId)
        if(servicesList && servicesList.length){
            dispatch(getEmployeesRequest({
                page:-1,
                branch_service_id: service.serviceId
            }))
            setShowEmployeePopup(true);
        }
    };

    const handleGoBack = () => {
        history.push(ROUTE_PATHS.viewBooking.replace(':id', id));
    };

    const handleDisableButton = () => {
        const returnedService = servicesEmployees?.find(service => service.serviceId)
        if(typeof(returnedService)==="undefined"){
            return true
        }else{
            return false
        }
    }

    const handleSubmitServices = () => {
        const params = servicesEmployees.map(param=>{
            return {
                branch_service_id: param.serviceId,
                employee_id: param.employeeId,
            }
        })
        dispatch(updateBookingRequest({
            data:{
                id:id,
                params:{
                    appointment_branch_services_attributes: params
                }
            },
            requestType:"add another service" 
        }));
    };

    const renderEmployeeName = (serviceDetails) => {
        const service = servicesEmployees?.find(service => service.serviceId === serviceDetails.id)
        if(service?.employeeId){
            return (
                <div className= "d-flex justify-content-between flex-wrap" >
                    <div className="d-flex gap-2">
                        <div>
                            {lang === 'en' ? service.employeeNameEn : service.employeeNameAr}
                        </div>
                        {service?.employeeId && (
                            <div 
                                className="text-primary pointer"
                                onClick={()=>{handleEditEmployee(service)}}
                            >
                                <Edit/>
                            </div>
                        )}
                    </div>   
                    <Button
                        label={
                            <span><Close/></span>
                        }
                        labelClass="text-white"
                        onClick={() => {handleDeselectEmployee(serviceDetails.id)}}
                    />
                </div>
            )
        }else{
            return (
                <div className= "d-flex justify-content-between flex-wrap">
                    <div>{bookings.addAnotherService.employeePlaceHolder}</div>
                    <Button
                        label={
                            <span><Add/></span>
                        }
                        labelClass="text-white"
                        onClick={() => {handleSelectEmployee(serviceDetails)}}
                    />
                </div>
            )
        }  
    };

    const renderHeaderSection = (showActions) => (
        <>
            <div className="mb-4">
                <Breadcrumbs
                    list={[
                        {
                            path: ROUTE_PATHS.viewBooking.replace(':id',id),
                            label:  bookings.details.booking + '(' + bookingData?.appointment_number+')'
                        },
                        {
                            path: "#",
                            label: bookings.details.addAnotherService
                        },
                    ]}
                    className="mb-4 fsize-28 fweight-600"
                />
                <div className="title-search d-flex  justify-content-between flex-column flex-lg-row flew-wrap">
                    {showActions && (
                        <SearchInput
                            className="filter-label-wrapper"
                            placeholder={bookings.addAnotherService.searchPlaceholder}
                            name="search"
                            value={searchValue}
                            onChange={(value) => {
                                 setSearchValue(value);
                            }}
                        />)
                    } 
                    {showActions && ( 
                        <div className= 'd-flex justify-content-end filter-container w-100' >
                            <div className="mt-2">
                                <FilterLabel />
                            </div>
                            <FilterInput
                                placeholder={bookings.addAnotherService.specialtyFilterPlaceholder}
                                options={specialtiesOptions}
                                value={filterSpecialtyValue}
                                name="specialty Filter"
                                onChange={(value) => {
                                    setFilterSpecialtyValue(value);
                                }}
                                filterWrapperClass="w-100"
                            />
                        </div>)
                    }
                </div>
            </div>
        </>
    )

    const renderServicesList = () => {
        if (
            !isLoading &&
            servicesList?.length === 0 &&
            !searchValue &&
            filterSpecialtyValue === null   
           ){
            return (
              <>
                {renderHeaderSection(false)}
                <div className="bg-white rounded-4 categoriess-page d-flex justify-content-center align-items-center flex-column">
                  <EmptyState
                    subTitle={bookings.addAnotherService.noData}
                    showActionButton={false}
                    NoDataListWithNoActionBtn={true}
                  />
                </div>
              </>
            );
        } else if (
            !isLoading &&
            (searchValue ||
             filterSpecialtyValue != null) &&
             servicesList?.length === 0
          ) {
            return (
              <>
                {renderHeaderSection(true)}
                <div className="bg-white rounded-4 categoriess-page d-flex justify-content-center align-items-center flex-column  mt-3">
                  <EmptyState subTitle={shared.noSearch} showActionButton={false} />
                </div>
              </>
            );
        }else {
            return (
                <>
                    {renderHeaderSection(true)}
                    <div className="d-flex justify-content-end gap-1 fsize-16 text-gray mx-3 mb-2">
                        <span>{serviceCounter}</span>
                        <span>{bookings.addAnotherService.counterLabel}</span>
                    </div>
                    <div className="bg-white rounded-4 p-3">
                        {servicesList?.map(service => (
                            <div className="bg-light-blue rounded-4 mb-3 p-2">
                                <div className="row p-2">
                                    <div className="fsize-22 fweight-600 mb-1 mb-md-1 col-lg-12 col-12">
                                        { lang === 'en' ? service.title_en : service.title_ar }
                                    </div>
                                    <div className="mb-1 mb-md-1 col-12 col-lg-2">
                                        <div className="text-gray fsize-18 d-flex gap-2">
                                            <div><CreditCardOutlined/></div> 
                                            <div>{service.fees_to ? bookings.details.feesRange : bookings.details.fees}:</div>
                                        </div>
                                    </div>
                                    <div className="mb-1 mb-md-1 col-12 col-lg-10">
                                        <div className="text-gray fsize-18">
                                            {service.fees_to && (
                                                <div>
                                                    {bookings.details.from}
                                                    {service.fees_from ? service.fees_from : '-'}
                                                    <span> {lang === 'en' ? service.currency.symbol : service.currency.name_ar} -</span>
                                                    {bookings.details.to}
                                                    {service.fees_to ? service.fees_to : '-'}
                                                    <span> {lang === 'en' ? service.currency.symbol : service.currency.name_ar} </span>
                                                </div>)
                                            }
                                            {!service.fees_to && (
                                                <div>
                                                    {service.fees_from ? service.fees_from : '-'}
                                                    <span> {lang === 'en' ? service.currency.symbol : service.currency.name_ar}</span>
                                                </div>)
                                            }
                                        </div>
                                    </div>
                                    <div className="mb-1 mb-md-1 col-12 col-lg-2">
                                        <div className="text-gray fsize-18 d-flex gap-2">
                                            <div><AccessTimeOutlined/></div> 
                                            <div>{bookings.details.duration}:</div>
                                        </div>
                                    </div>
                                    <div className="mb-1 mb-md-1 col-12 col-lg-10">
                                        <div className="text-gray fsize-18 d-flex gap-2 ">
                                            <div>{service.duration_mins}</div>
                                            <div>{bookings.addAnotherService.mins}</div>
                                        </div>
                                    </div>
                                    {service.description && (
                                        <>
                                            <div className="mb-1 mb-md-1 col-12 col-lg-2">
                                                <div className="text-gray fsize-18 d-flex gap-2">
                                                    <div><ArticleOutlined/></div>
                                                    <div>{bookings.details.description}:</div>
                                                </div>
                                            </div>
                                            <div className="mb-1 mb-md-1 col-12 col-lg-10">
                                                <div className="text-gray fsize-18">
                                                    {lang === 'en' ? service.description_en : service.description_ar}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="col-lg-12 col-12 px-3">
                                        <Divider className="my-2"/>
                                    </div>
                                    <div className="mb-1 mb-md-1 col-12 col-lg-2">
                                        <div className="text-gray fsize-18 d-flex gap-2 mb-1 mb-md-1">
                                            <div><Person2Outlined/></div>
                                            <div>{bookings.details.employeeName}:</div>
                                        </div>
                                    </div>
                                    <div className="mb-1 mb-md-1 col-12 col-lg-10">
                                        <div className="text-gray fsize-18">
                                            {renderEmployeeName(service)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))} 
                    </div>
                    {(meta?.pages > 1 && page < meta?.pages) && (
                        <div className="d-flex justify-content-center mt-2 mx-3 fweight-500 fsize-16">
                            <u
                                className="see-more-button text-primary pointer"
                                onClick={()=>{
                                    setPage(page+1)
                                }}
                            >
                                {bookings.addAnotherService.seeMore}
                            </u>
                        </div>
                    )}                   
                    <div className="d-flex justify-content-end mt-5 gap-3">
                        <Button 
                            label={shared.cancel}
                            outlined
                            labelClass="text-secondary px-4"
                            onClick={()=>{handleGoBack()}}
                        />
                        <Button
                            label={shared.save}
                            btnStyle="secondary"
                            labelClass="text-white px-4"
                            onClick={()=>{handleSubmitServices()}}
                            disabled={handleDisableButton()}
                        />
                    </div>
                    <EmployeesPopup
                        open={showEmployeePopup}
                        setOpen={setShowEmployeePopup}
                        servicesEmployees={servicesEmployees}
                        setServicesEmployees={setServicesEmployees}
                        selectedServiceId={selectedServiceId}
                        employeeId={employeeId}
                        isEditMode={isEditMode}
                    />
                    <Modal
                        open={showNoEmployeesPopup}
                        handleClose={()=>{setShowNoEmployeesPopup(false)}}
                        contentClass="my-2 pb-2 fweight-500 fsize-16 text-primary"
                        content={<span>{bookings.addAnotherService.noEmployeePlaceholder}</span>}
                        confirmBtnTxt={shared.cancel}
                        isContentText={false}
                        handleConfirm={()=>{setShowNoEmployeesPopup(false)}}
                        maxWidth="sm"
                        actionsClass=" px-4"
                        confirmBtnClass="btn-secondary fsize-14 text-white py-2 px-5"
                    />
                </>
            )
        }
    }
    return(<>{renderServicesList()}</>)
}

export default AddAnotherService;