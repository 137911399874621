import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';

import {
  BranchDetailsTabIcon,
  BranchDetailsTabIconDone,
  EmployeesTabIcon,
  EmployeesTabInprogress,
  EmployeesTabDone,
  BranchDetailsTabInprogress,
  SpecialtiesServicesTabIcon,
  SpecialtiesServicesTabInprogress,
  SpecialtiesServicesTabDone,
} from '../../../utils/Icons';
import { setPageMainTitle } from '../../../utils/Helpers';
import { ROUTE_PATHS } from '../../../utils/RoutesPaths';
import Stepper from '../../../components/Stepper';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import messages from '../../../assets/locale/messages';
import AddEditBranchDetails from './AddEditBranchDetails';
import AddEditServicesDetails from './AddEditServicesDetails';
import AddEditEmployeesDetails from './AddEditEmployeesDetails';

const AddEditBranch = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const tabNumber = urlParams.get('CurrentTab');
  const history = useHistory();
  const { id } = useParams();
  const lang = useSelector((state) => state.locale.lang);
  const { pagesTitles, branches, shared } = messages[lang];
  const createdBranch = useSelector((state) => state.branches.addEditViewBranch.helpers.addedBranch);
  const [tabIndex, setTabIndex] = useState(props?.location?.state?.setTabIndex ?? 0);
  const [btnClicked, setBtnClicked] = useState(null);
  const [renderList, setRenderList] = useState(false);
  const [renderSubTitle, setRenderSubTitle] = useState(null);
  const [pageTitle, setPageTitle] = useState('addProviderBranch');
  const [renderServiceTitle, setRenderServiceTitle] = useState(null);
  const [showLeaveWarningModal, setShowLeaveWarningModal] = useState(false);
  const [hideAddBtn, setHideAddBtn] = useState(false);

  const stepperItems = [
    {
      label: branches.addBranch.firstTab,
      icon: [<BranchDetailsTabIcon />, <BranchDetailsTabInprogress />, <BranchDetailsTabIconDone />],
    },
    {
      label: branches.addBranch.secondTab,
      icon: [<SpecialtiesServicesTabIcon />, <SpecialtiesServicesTabInprogress />, <SpecialtiesServicesTabDone />],
    },
    {
      label: branches.addBranch.thirdTab,
      icon: [<EmployeesTabIcon />, <EmployeesTabInprogress />, <EmployeesTabDone />],
    },
  ];

  useEffect(() => {
    if (props?.location?.state === undefined) {
    } else if (props?.location?.state?.setPageTitle !== undefined) {
      setPageTitle(props?.location?.state?.setPageTitle);
    }
    if (props?.location?.state?.setTabIndex) {
      if (tabIndex !== props?.location?.state?.setTabIndex) { 
        if (props?.location?.state?.setTabIndex > 2) {
          // case to handle booking and reviews tab redirection
          setTabIndex(1);
        } else {
          setTabIndex(props?.location?.state?.setTabIndex);
        }
      } else {
        history.push({
          search: `?CurrentTab=${tabIndex}`,
          state: { ...props?.location?.state, setTabIndex: null },
        });
      }
    }
  }, [createdBranch, props]);

  useEffect(() => {
    if (props?.location?.state?.setPageTitleEn !== undefined || props?.location?.state?.setPageTitleAr !== undefined) {
      setPageTitle(lang === 'en' ? props?.location?.state?.setPageTitleEn : props?.location?.state?.setPageTitleAr);
    }
  }, [lang]);

  useEffect(() => {
    setPageMainTitle('branchesManagement'); 
  }, []);

  useEffect(() => {
    switch (tabIndex) {
      case 0:
        createdBranch == null || (Object.keys(createdBranch).length == 0 && setPageTitle('addProviderBranch'));
        // createdBranch !== null && Object.keys(createdBranch).length !== 0 && setPageTitle('editProviderBranch');
        setBtnClicked(null);
        setRenderSubTitle(null);
        setRenderServiceTitle(null);
        break;
      case 1:
        setPageTitle(
          props?.location?.state?.setPageTitle !== undefined
            ? props?.location?.state?.setPageTitle
            : 'addProviderBranchSpecialties'
        );
        setBtnClicked(null);
        setRenderSubTitle(null);
        setRenderServiceTitle(null);
        setRenderList(false);
        break;
      case 2:
        setPageTitle(
          props?.location?.state?.setPageTitle !== undefined
            ? props?.location?.state?.setPageTitle
            : 'addProviderBranchEmployees'
        );
        setBtnClicked(null);
        setRenderSubTitle(null);
        setRenderServiceTitle(null);
        setRenderList(true);
        break;
      default:
        break;
    }
    if (props?.location?.state?.setTabIndex) {
      setTabIndex(props?.location?.state?.setTabIndex ?? tabIndex);
    }
    history.push({
      search: `?CurrentTab=${tabIndex}`,
      state: { ...props?.location?.state, setTabIndex: null },
    });
  }, [tabIndex]);

  const onSubTitleChange = (title) => {
    setRenderList(false);
    setRenderSubTitle(title);
  };

  const onServiceTitleChange = (title) => {
    setRenderList(false);
    setRenderServiceTitle(title);
  };

  const refreshCurrentTab = () => {
    setTabIndex(tabIndex === 0 ? 1 : tabIndex === 1 ? 0 : tabIndex === 2 ? 1 : tabIndex);
    setTimeout(() => {
      setTabIndex(tabIndex === 0 ? 1 : tabIndex === 1 ? 2 : tabIndex === 2 ? tabIndex : 1);
    }, 200);
  };

  const handleCloseShowLeaveWarningModal = () => {
    setShowLeaveWarningModal(false);
  };

  const handleConfirmShowLeaveWarning = () => {
    history.push(ROUTE_PATHS.branches);
    setShowLeaveWarningModal(false);
  };

  const removeMainAddBtn = (value) => {
    setHideAddBtn(value);
  };

  return (
    <>
      <div className="add-branch-page">
        <div className="header d-flex flex-column flex-md-row mb-4">
          <Typography variant="h4" sx={{ flexGrow: 1 }} className={`page-title mb-4`}>
            <div className="d-flex flex-column flex-md-row fsize-24 flex-wrap">
              <div
                className={`${renderSubTitle !== null && 'pointer'}`}
                onClick={() => {
                  setBtnClicked('list');
                  setRenderSubTitle(null);
                  setRenderServiceTitle(null);
                  setRenderSubTitle(null);
                  setRenderServiceTitle(null);
                  setRenderList(true);
                }}>
                {props?.location?.state?.setPageTitle !== undefined && props?.location?.state?.noTranslationForTitle
                  ? pageTitle
                  : pagesTitles[pageTitle]}
              </div>
              {renderSubTitle !== null && (
                <div
                  className="pointer"
                  onClick={() => {
                    setBtnClicked('listSpecialties');
                    onServiceTitleChange(null);
                  }}>
                  / {branches.addBranch.labels[renderSubTitle]}
                </div>
              )}
              {renderServiceTitle !== null && renderSubTitle !== null && <div> / {renderServiceTitle}</div>}
            </div>
          </Typography>
          <div className="ms-auto">
            {tabIndex === 2 && renderSubTitle == null && !hideAddBtn && (
              <Button
                onClick={() => {
                  setBtnClicked('');
                  setBtnClicked('add');
                }}
                type="button"
                block
                label={
                  <span className="d-flex align-items-center">
                    <Add fontSize="large" />
                    <span className="ps-1 ">{branches.addBranch.labels.addEmployee}</span>
                  </span>
                }
                labelClass="fsize-16 text-white py-1"
              />
            )}
            {tabIndex === 2 && renderSubTitle === 'employessDetails' && (
              <div className="d-flex gap-2">
                <Button
                  onClick={() => {
                    setBtnClicked('delete');
                  }}
                  color="info"
                  outlined
                  label={
                    <span className="d-flex align-items-center">
                      <Delete fontSize="small" />
                      <span>{shared.delete}</span>
                    </span>
                  }
                  className="btn-secondary"
                  labelClass="fsize-14 btn-info-generate-password"
                />
                <Button
                  onClick={() => {
                    setBtnClicked('edit');
                  }}
                  type="button"
                  label={
                    <span className="d-flex align-items-center">
                      <Edit fontSize="small" />
                      <span className="ps-1">{shared.edit}</span>
                    </span>
                  }
                  labelClass="fsize-16 text-white py-1"
                />
              </div>
            )}
            {tabIndex === 1 && !hideAddBtn && (
              <Button
                onClick={() => {
                  setTabIndex(1);
                  setRenderList(true);
                  setBtnClicked('listSpecialties');
                }}
                type="button"
                block
                label={
                  <span className="d-flex align-items-center flex-wrap justify-content-center">
                    <Add fontSize="large" />
                    <span className="ps-1 ">{branches.addBranch.labels.addSepicialtiesAndServices}</span>
                  </span>
                }
                labelClass="fsize-16 text-white py-1"
              />
            )}
          </div>
        </div>
        <div className="add-branch-stepper">
          <Stepper
            steps={stepperItems}
            handleSelectStep={(index) => setTabIndex(index)}
            currentStep={tabIndex}
            preventGoingBack={false}
            haveBranchId={createdBranch?.id ? true : false}
            haveServices={createdBranch?.specialties?.length > 0 ? true : false}
          />
        </div>
        <div className="page-container mt-5 bg-white rounded-4">
          {tabIndex === 0 && (
            <AddEditBranchDetails
              setShowLeaveWarningModal={setShowLeaveWarningModal}
              parentProps={props}
              proceedToNextStep={() => setTabIndex(1)}
            />
          )}
        </div>
        <div className="page-container mt-5 bg-white rounded-4">
          {tabIndex === 1 && (
            <AddEditServicesDetails
              changeSubTitle={onSubTitleChange}
              changeServiceTitle={onServiceTitleChange}
              renderList={renderList}
              btnClicked={btnClicked}
              proceedToNextStep={() => setTabIndex(2)}
              ressetBtnClicked={() => setBtnClicked(null)}
              backToPrevStep={() => setTabIndex(0)}
              parentProps={props}
              removeMainAddBtn={removeMainAddBtn}
            />
          )}
        </div>
        <div className="page-container mt-5">
          {tabIndex === 2 && (
            <AddEditEmployeesDetails 
              btnClicked={btnClicked}
              changeSubTitle={onSubTitleChange}
              renderList={renderList}
              ressetBtnClicked={() => setBtnClicked(null)}
              refreshCurrentTab={refreshCurrentTab}
              backToPrevStep={() => setTabIndex(1)}
              parentProps={props}
              removeMainAddBtn={removeMainAddBtn}
            />
          )}
        </div>
      </div>
      <Modal
        open={showLeaveWarningModal}
        handleClose={handleCloseShowLeaveWarningModal}
        title={branches.addBranch.labels.confirmCancel}
        contentClass="mb-5 pb-2 text-primary"
        content={<span>{branches.addBranch.labels.confirmCancelMessage}</span>}
        confirmBtnTxt={shared.confirm}
        maxWidth="xs"
        cancelBtnTxt={shared.cancel}
        isContentText={false}
        handleConfirm={handleConfirmShowLeaveWarning}
        handleCancel={handleCloseShowLeaveWarningModal}
        modalClass="delete-services-modal"
        actionsClass="container px-4"
        confirmBtnClass="btn-secondary fsize-14 text-white py-2 "
        cancelBtnClass="fsize-14 py-2"
      />
    </>
  );
};

export default AddEditBranch;
