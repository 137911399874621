import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Add, Close, ImageOutlined, KeyboardArrowDown, KeyboardArrowUp, ContentCopy, ErrorOutlineOutlined } from '@mui/icons-material';
import PhoneInput from 'react-phone-input-2';
import ar from 'react-phone-input-2/lang/ar.json';
import Input from '../../../../components/Input';
import Checkbox from '../../../../components/Checkbox';
import TimePicker from '../../../../components/TimePicker';
import Upload from '../../../../components/Upload';
import Select from '../../../../components/Select';
import Button from '../../../../components/Button';
import Tooltip from '../../../../components/Tooltip';
import Accordion from '../../../../components/Accordion';
import messages from '../../../../assets/locale/messages';
import { ROUTE_PATHS } from '../../../../utils/RoutesPaths';
import { getGoogleMapsPolygon, saveBranchData, getGoogleMapsCoords } from '../../../../store/GoogleMapCoords/actions';
import {
  addBranchDataInStore,
  addBranchRequest,
  addBranchResponse,
  editBranchRequest,
  getAddressFromCoordsRequest,
  getBranchRequest,
  getRequestStatus,
} from '../../../../store/Branches/actions';
// import { getSpecialtiesResponse } from '../../../../store/Specialties/actions';
import { ALL_BUT_NO_ARABIC, ALL_BUT_NO_ENGLISH, NUMBERS_REGEX } from '../../../../utils/Patterns';
import { generateUniqueFileName, uploadToS3, directories } from '../../../../utils/Aws-S3';
import {
  genderList,
  genderListAr,
  paymentMethodsList,
  paymentMethodsListAr,
  weekDays,
  weekDaysAr,
} from '../../../../utils/Constants';
import 'react-phone-input-2/lib/style.css';
import Modal from '../../../../components/Modal';

import './AddEditBranchDetails.scss';

const AddEditBranchDetails = ({ setShowLeaveWarningModal, parentProps, proceedToNextStep }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { providerid } = useParams();
  const { id } = useParams();
  const lang = useSelector((state) => state.locale.lang);
  const { branches, shared } = messages[lang];
  const selectedLocation = useSelector((state) => state.googleMapCoords.coords);
  const addedPolygonLocation = useSelector((state) => state.googleMapCoords.polygon);
  const savedBranchData = useSelector((state) => state.googleMapCoords.savedBranchData);
  const AddedEditedPolygon = useSelector((state) => state.googleMapCoords.polygon);
  const createdBranchData = useSelector((state) => state.branches.addEditViewBranch.helpers.savedBranchData); // mapped data To render
  const createdBranch = useSelector((state) => state.branches.addEditViewBranch.helpers.addedBranch); //original BE data
  const branchCreatedId = useSelector((state) => state.branches.addEditViewBranch.helpers.branchCreatedId); //branch created Id
  const addressFromCoords = useSelector((state) => state.branches.addEditViewBranch.helpers.addressFromCoords);
  const branchDataById = useSelector((state) => state.branches.addEditViewBranch.branchDetails);
  const requestStatus = useSelector((state) => state.branches.addEditViewBranch.requestStatus);

  const [branchLogo, setBranchLogo] = useState(null);
  const [arrowRotate, setArrowRotate] = useState(false);
  const [atHome, setAtHome] = useState(false);
  const [atBranch, setAtBranch] = useState(false);
  const [gender, setGender] = useState(null);
  const [payment, setPayemnt] = useState(null);
  const [showEditMood, setShowEditMood] = useState(false);
  const [haveAtleastOneWorkingDay, setHaveAtleastOneWorkingDay] = useState(false);
  const [haveAtleastOneLocationSelected, setHaveAtleastOneLocationSelected] = useState(false);
  const [inputLocalization, setInputLocalization] = useState(lang === 'en' ? {} : ar);
  const [showEditValidationPopup, setShowEditValidationPopup] = useState(false)
  const [intialFormValue, setIntialFormValue] = useState({
    name_en: '',
    name_ar: '',
    image: null,
    phone_number: '',
    dial_code: '',
    // At Branch
    contryNameEn: '',
    city: '',
    region: '',
    street_name: '',
    building_number: '',
    postal_code: '',
    secondary_number: '',
    land_mark: '',
    // end at branch
    branch_working_hours_attributes: [
      {
        id: 0,
        label: shared.days.sat,
        value: weekDays.sat,
        isChosen: false,
        timeSlots: [],
      },
      {
        id: 1,
        label: shared.days.sun,
        value: weekDays.sun,
        isChosen: false,
        timeSlots: [],
      },
      {
        id: 2,
        label: shared.days.mon,
        value: weekDays.mon,
        isChosen: false,
        timeSlots: [],
      },
      {
        id: 3,
        label: shared.days.tus,
        value: weekDays.tus,
        isChosen: false,
        timeSlots: [],
      },
      {
        id: 4,
        label: shared.days.wed,
        value: weekDays.wed,
        isChosen: false,
        timeSlots: [],
      },
      {
        id: 5,
        label: shared.days.thurs,
        value: weekDays.thurs,
        isChosen: false,
        timeSlots: [],
      },
      {
        id: 6,
        label: shared.days.fri,
        value: weekDays.fri,
        isChosen: false,
        timeSlots: [],
      },
    ],
    branch_covered_zones_attributes: [],
    serviced_gender: null,
    payment_methods: null,
  });

  const servicedGenderList = [
    { id: 0, label: branches.addBranch.labels.male, value: genderList.male },
    { id: 1, label: branches.addBranch.labels.female, value: genderList.female },
    { id: 2, label: branches.addBranch.labels.bothGender, value: genderList.bothGender },
  ];
  const servicedGenderListAr = [
    { id: 0, label: genderListAr.male, value: genderList.male },
    { id: 1, label: genderListAr.female, value: genderList.female },
    { id: 2, label: genderListAr.bothGender, value: genderList.bothGender },
  ];

  const paymentMethodsOptions = [
    { id: 0, label: branches.addBranch.labels.online, value: paymentMethodsList.online },
    { id: 1, label: branches.addBranch.labels.cash, value: paymentMethodsList.cash },
    { id: 2, label: branches.addBranch.labels.both, value: paymentMethodsList.both },
  ];
  const paymentMethodsOptionsAr = [
    { id: 0, label: paymentMethodsListAr.online, value: paymentMethodsList.online },
    { id: 1, label: paymentMethodsListAr.cash, value: paymentMethodsList.cash },
    { id: 2, label: paymentMethodsListAr.both, value: paymentMethodsList.both },
  ];
  const {
    values,
    setFieldValue,
    setValues,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
    setTouched,
    setFieldError,
  } = useFormik({
    initialValues: intialFormValue,
    validationSchema: Yup.object({
      name_en: Yup.string().required('nameRequired').matches(ALL_BUT_NO_ARABIC, 'englishLettersOnly'),
      name_ar: Yup.string().required('arNameRequired').matches(ALL_BUT_NO_ENGLISH, 'arabicLettersOnly'),
      image: Yup.mixed(),
      phone_number: Yup.string(),
      contryNameEn: Yup.string().when([],{
        is: () => atBranch,
        then: Yup.string().required('required'),
        otherwise: Yup.string().notRequired()
      }),
      city: Yup.string().when([],{
        is: () => atBranch,
        then: Yup.string().required('required'),
        otherwise: Yup.string().notRequired()
      }),
      region: Yup.string().when([],{
        is: () => atBranch,
        then: Yup.string().required('required'),
        otherwise: Yup.string().notRequired()
      }),
      street_name: Yup.string().when([],{
        is: () => atBranch,
        then: Yup.string().required('required'),
        otherwise: Yup.string().notRequired()
      }),
      building_number: Yup.string().when([],{
        is: () => atBranch,
        then: Yup.string().required('required'),
        otherwise: Yup.string().notRequired()
      }),
      secondary_number: Yup.string().nullable(),
      postal_code: Yup.string().when([],{
        is: () => atBranch,
        then: Yup.string().matches(NUMBERS_REGEX, 'numbersOnly').min(5).max(5),
        otherwise: Yup.string().notRequired()
      }),
      land_mark: Yup.string().nullable(),
      branch_working_hours_attributes: Yup.array().min(1, 'pickOneWorkingDayAtLeast'),
      branch_covered_zones_attributes: Yup.array(),
      serviced_gender: Yup.object().nullable().required('genderRequired'),
      payment_methods: Yup.object().nullable().required('paymentMenthodRequired'),
    }),
    onSubmit: async ({
      name_en,
      name_ar,
      image,
      phone_number,
      dial_code,
      contryNameEn,
      region,
      city,
      street_name,
      building_number,
      postal_code,
      secondary_number,
      land_mark,
      branch_working_hours_attributes,
      branch_covered_zones_attributes,
      serviced_gender,
      payment_methods,
    }) => {
      let branchData = {
        name_en,
        name_ar,
        phone_number: phone_number != '' ? phone_number.replace(dial_code, '') : '',
        dial_code: '+' + dial_code,
        service_provider_id: +providerid,
        serviced_gender: serviced_gender.value.toLowerCase(),
        payment_methods: payment_methods.value.toLowerCase(),
      };
      setPayemnt(payment_methods);
      if (serviced_gender) {
        if (serviced_gender.id === 2) {
          branchData.serviced_gender = 'both_genders';
        } else {
          branchData.serviced_gender = serviced_gender.value.toLowerCase();
        }
        setGender(serviced_gender);
      }
      if (image) {
        branchData.image = image;
      } else {
        branchData.image = null;
      }

      if(atBranch && atHome){
        branchData.offered_location = 'both';
        //branch location data
        branchData.country_id = Math.floor(Math.random() * 140);
        branchData.country = contryNameEn ;
        branchData.city = city;
        branchData.region = region;
        branchData.street = street_name;
        branchData.building_number = building_number
        branchData.secondary_number = secondary_number ?? null
        branchData.land_mark = land_mark ?? '';
        branchData.postal_code = postal_code === '' ? null : +postal_code;
        branchData.lat = selectedLocation?.lat;
        branchData.lng = selectedLocation?.lng;
        //home location data
        branchData.branch_covered_zones_attributes = branch_covered_zones_attributes
      }else if (atBranch && !atHome){
        branchData.offered_location = 'in_place';
        //branch location data
        branchData.country_id = Math.floor(Math.random() * 140);
        branchData.country = contryNameEn ;
        branchData.city = city;
        branchData.region = region;
        branchData.street = street_name;
        branchData.building_number = building_number
        branchData.secondary_number = secondary_number ?? null
        branchData.land_mark = land_mark ?? '';
        branchData.postal_code = postal_code === '' ? null : +postal_code;
        branchData.lat = selectedLocation?.lat;
        branchData.lng = selectedLocation?.lng;
        //home location data
        branchData.branch_covered_zones_attributes = branch_covered_zones_attributes
      }else if (!atBranch && atHome){
        branchData.offered_location = 'home';
        //branch location data
        branchData.country_id = Math.floor(Math.random() * 140);
        branchData.country = '' ;
        branchData.city = '';
        branchData.region = '';
        branchData.street = null;
        branchData.building_number = null;
        branchData.secondary_number = null;
        branchData.land_mark = '';
        branchData.postal_code = null
        branchData.lat = null;
        branchData.lng = null;
        //home location data
        branchData.branch_covered_zones_attributes = branch_covered_zones_attributes
      }

      // filter the intail array to get the days that are chosen
      let workingDays = branch_working_hours_attributes.filter((day) => {
        if (day.isChosen || day.timeSlots.length > 0) {
          return day;
        }
      });

      // filter the time slots to get the slots that are not null
      let workingDaysFilteredSlots = workingDays.map((day) => {
        let filteredSlots = day.timeSlots.filter((slot) => {
          if (slot.starts_at !== null && slot.ends_at !== null) {
            return day;
          }
        });
        day.timeSlots = filteredSlots;
        return day;
      });
      // map the slots shape to the required shape
      let mappedWorkingDays = workingDaysFilteredSlots.map((day) => {
        let mappedSlots = day.timeSlots.map((slot) => {
          return {
            starts_at: slot.starts_at || '00:00:00',
            ends_at: slot.ends_at || '00:00:00',
          };
        });
        let finalObject = {
          intervals: mappedSlots,
          _destroy: !day.isChosen,
          week_day: day.value.toLowerCase(),
        };

        return finalObject;
      });

      // final array to get the required data
      if (createdBranch?.branch_working_hours?.length > 0) {
        mappedWorkingDays.map((day) => {
          let found = createdBranch?.branch_working_hours?.find((d) => d.week_day === day.week_day);
          if (found) {
            day.id = found.id;
          }
        });
      }
      branchData.branch_working_hours_attributes = mappedWorkingDays;
      if (atHome) {
        let x = branchData?.branch_covered_zones_attributes?.filter(
          (word) => word.id || (!word.id && word._destroy !== true)
        );
        branchData.branch_covered_zones_attributes = x;
      }
      // despatch AddBranch action
      if (createdBranch !== null && Object.keys(createdBranch).length !== 0) {
        dispatch(
          editBranchRequest({
            id: createdBranch?.id,
            data: branchData,
          })
        );
        setTimeout(() => {
          if (requestStatus) {
            dispatch(getGoogleMapsPolygon([]));
          }
        }, 700);
        dispatch(addBranchDataInStore(values));
      } else {
        dispatch(addBranchRequest(branchData));
        dispatch(addBranchDataInStore(values));
      }
    },
  });

  useEffect(() => {
    return () => {
      dispatch(getRequestStatus(null));
    };
  }, []);

  useEffect(() => {
    if (!requestStatus) {
      setTimeout(() => {
        dispatch(getRequestStatus(null));
      }, 1000);
    } else {
      if (parentProps?.location?.state?.cameFrom) {
        history.push(ROUTE_PATHS.viewBranch.replace(':id', id));
      } else if (branchCreatedId !== null) {
        proceedToNextStep();
      }
    }
  }, [requestStatus]);

  useEffect(() => {
    if (parentProps?.location?.state !== undefined) {
      if (parentProps?.location?.state?.branchDataToEdit) {
        // EditMood
        setShowEditMood(true);
        dispatch(getBranchRequest(parentProps?.location?.state?.branchDataToEdit?.id || id));
      }
    }
  }, [parentProps]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (branchDataById) {
      setTimeout(() => {
        setEditValues();
      }, 500);
    }
  }, [branchDataById]);

  const setEditValues = () => {
    let branchDataToBeEdited = branchDataById;
    dispatch(addBranchResponse(branchDataToBeEdited));

    let mappedBranchDataToEdit = {};
    if (branchDataToBeEdited?.id) {
      mappedBranchDataToEdit.id = branchDataToBeEdited?.id;
    }
    if (branchDataToBeEdited?.name_en) {
      mappedBranchDataToEdit.name_en = branchDataToBeEdited?.name_en || '';
    }
    if (branchDataToBeEdited?.name_ar) {
      mappedBranchDataToEdit.name_ar = branchDataToBeEdited?.name_ar || '';
    }
    if (branchDataToBeEdited?.image) {
      mappedBranchDataToEdit.image = branchDataToBeEdited?.image;
    }
    if (branchDataToBeEdited?.dial_code){
      mappedBranchDataToEdit.dial_code = branchDataToBeEdited?.dial_code?.replace(/\+/g, '');
      mappedBranchDataToEdit.phone_number = branchDataToBeEdited?.dial_code?.replace(/\+/g, '') + branchDataToBeEdited?.phone_number;
    } 

    if(branchDataToBeEdited?.offered_location === 'in_place'){
      mappedBranchDataToEdit.building_number = branchDataToBeEdited?.building_number;
      mappedBranchDataToEdit.postal_code = branchDataToBeEdited?.postal_code === null ? '' : branchDataToBeEdited?.postal_code;
      mappedBranchDataToEdit.secondary_number = branchDataToBeEdited?.secondary_number;
      mappedBranchDataToEdit.city = branchDataToBeEdited?.city;
      mappedBranchDataToEdit.contryNameEn = branchDataToBeEdited?.country;
      mappedBranchDataToEdit.land_mark = branchDataToBeEdited?.land_mark;
      mappedBranchDataToEdit.region = branchDataToBeEdited?.region;
      mappedBranchDataToEdit.street_name = branchDataToBeEdited?.street;
      setAtBranch(true);
      setAtHome(false);
      setArrowRotate(true);
    }else if(branchDataToBeEdited?.offered_location === 'home'){
      mappedBranchDataToEdit.branch_covered_zones_attributes = branchDataToBeEdited?.branch_covered_zones?.map((zone) => ({
        id: zone.id,
        name_en: zone.name_en,
        name_ar: zone.name_ar,
        latlng: zone.polygon,
        _destroy: false,
      }));
      setAtBranch(false);
      setAtHome(true);
      setArrowRotate(true);
    }else if(branchDataToBeEdited?.offered_location === 'both'){
      mappedBranchDataToEdit.building_number = branchDataToBeEdited?.building_number;
      mappedBranchDataToEdit.postal_code = branchDataToBeEdited?.postal_code === null ? '' : branchDataToBeEdited?.postal_code;
      mappedBranchDataToEdit.secondary_number = branchDataToBeEdited?.secondary_number;
      mappedBranchDataToEdit.city = branchDataToBeEdited?.city;
      mappedBranchDataToEdit.contryNameEn = branchDataToBeEdited?.country;
      mappedBranchDataToEdit.land_mark = branchDataToBeEdited?.land_mark;
      mappedBranchDataToEdit.region = branchDataToBeEdited?.region;
      mappedBranchDataToEdit.street_name = branchDataToBeEdited?.street;
      mappedBranchDataToEdit.branch_covered_zones_attributes = branchDataToBeEdited?.branch_covered_zones?.map((zone) => ({
        id: zone.id,
        name_en: zone.name_en,
        name_ar: zone.name_ar,
        latlng: zone.polygon,
        _destroy: false,
      }));
      setAtBranch(true);
      setAtHome(true);
      setArrowRotate(true);
    }
    if (branchDataToBeEdited?.payment_methods) {
      mappedBranchDataToEdit.payment_methods = paymentMethodsOptions?.find(
        (item) => item.value === branchDataToBeEdited?.payment_methods
      );
      setFieldTouched('serviced_gender', true);
    }
    if (branchDataToBeEdited?.serviced_gender) {
      if (branchDataToBeEdited?.serviced_gender === 'both_genders') {
        setFieldValue('serviced_gender', servicedGenderList[2]);
        setGender(mappedBranchDataToEdit.serviced_gender);
        mappedBranchDataToEdit.serviced_gender = servicedGenderList[2];
      } else {
        mappedBranchDataToEdit.serviced_gender = servicedGenderList?.find(
          (gender) => gender.value.toLowerCase() === branchDataToBeEdited?.serviced_gender
        );
        setFieldValue('serviced_gender', mappedBranchDataToEdit.serviced_gender);
        setFieldTouched('serviced_gender', true);
        setGender(mappedBranchDataToEdit.serviced_gender);
      }
    }

    if (branchDataToBeEdited?.branch_working_hours?.length > 0) {
      let branch_working_hours_attributes = [
        {
          id: 0,
          label: shared.days.sat,
          value: weekDays.sat,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 1,
          label: shared.days.sun,
          value: weekDays.sun,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 2,
          label: shared.days.mon,
          value: weekDays.mon,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 3,
          label: shared.days.tus,
          value: weekDays.tus,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 4,
          label: shared.days.wed,
          value: weekDays.wed,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 5,
          label: shared.days.thurs,
          value: weekDays.thurs,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 6,
          label: shared.days.fri,
          value: weekDays.fri,
          isChosen: false,
          timeSlots: [],
        },
      ];
      branchDataToBeEdited?.branch_working_hours?.forEach((item) => {
        branch_working_hours_attributes.map((day) => {
          if (lang === 'en') {
            if (day.label.toLowerCase() === item.week_day) {
              day.isChosen = true;
              day.timeSlots = item.intervals;
            }
          } else {
            if (day.value.toLowerCase() === item.week_day) {
              day.isChosen = true;
              day.timeSlots = item.intervals;
            }
          }
          return day;
        });
      });
      mappedBranchDataToEdit.branch_working_hours_attributes = branch_working_hours_attributes;
    }
    setValues(mappedBranchDataToEdit);
    dispatch(addBranchDataInStore(mappedBranchDataToEdit));
  };

  useEffect(() => {
    checkIsUserSelectOneWorkingDayAtLeast(values.branch_working_hours_attributes);
    checkIsUserSelectOneLocationAtLeast();
    if (values.payment_methods) {
      setFieldTouched('payment_methods');
    }
    if (values.serviced_gender) {
      setFieldTouched('serviced_gender');
    }
  }, [
    values.payment_methods,
    values.serviced_gender,
    values.contryNameEn,
    values.city,
    values.region,
    values.branch_working_hours_attributes,
    values.branch_covered_zones_attributes,
    atBranch,
    atHome,
  ]);

  useEffect(() => {
    if (createdBranchData) {
      if (Object.keys(createdBranchData).length !== 0) {
        setValues(createdBranchData);
        if (createdBranchData?.serviced_gender) {
          setFieldValue('serviced_gender', createdBranchData?.serviced_gender);
          setGender(createdBranchData?.serviced_gender);
          setFieldTouched('serviced_gender', true);
        }
        if (createdBranchData?.payment_methods) {
          setFieldValue('payment_methods', createdBranchData?.payment_methods);
          setPayemnt(createdBranchData?.paymenMethods);
          setFieldTouched('payment_methods', true);
        }
        setValues(createdBranchData);
        if (createdBranchData?.branch_covered_zones_attributes?.length > 0) {
          setAtHome(true);
          setArrowRotate(true);
        }
        if (createdBranchData.contryNameEn !== '' || selectedLocation) {
          if (Object.keys(selectedLocation).length !== 0) {
            setAtBranch(true);
            setArrowRotate(true);
          }else if (createdBranchData.contryNameEn === ''){
            setAtBranch(false);
          }
        }
      }
    }
    if (!showEditMood && !createdBranchData?.branch_working_hours_attributes && !createdBranchData?.payment_methods) {
      if (savedBranchData && Object.keys(savedBranchData)?.length > 0) { 
        let newSavedDataWithPolygon = savedBranchData;
        if (addedPolygonLocation?.length > 0) {
          newSavedDataWithPolygon.branch_covered_zones_attributes = [];
          newSavedDataWithPolygon.branch_covered_zones_attributes = addedPolygonLocation;
        }
        dispatch(addBranchDataInStore(newSavedDataWithPolygon));
        setValues(newSavedDataWithPolygon);
      } else {
        setValues(intialFormValue);
      }
    }
    if (parentProps?.location?.state?.branchDataToEdit && savedBranchData !== null) {
      if (Object.keys(savedBranchData).length !== 0) {
        setValues(savedBranchData);
        if(savedBranchData.branch_covered_zones_attributes || AddedEditedPolygon.length > 0){
          let savedZones = savedBranchData.branch_covered_zones_attributes?.filter(zone => !zone._destroy)
          let polygonZones = AddedEditedPolygon?.filter(zone => !zone._destroy)
          if(savedZones?.length > 0 || polygonZones?.length > 0){
           setAtHome(true)
          }else if (savedZones?.length === 0 || polygonZones.length === 0){
           setAtHome(false)
          }
        }
        dispatch(addBranchDataInStore(savedBranchData));
        if (AddedEditedPolygon != null && AddedEditedPolygon.length > 0) {
          setFieldValue('branch_covered_zones_attributes', AddedEditedPolygon);
        }
        if (addressFromCoords != null && Object.keys(selectedLocation).length !== 0) {
          setFieldValue('contryNameEn', addressFromCoords.country);
          setFieldValue('city', addressFromCoords.city);
          setFieldValue('region', addressFromCoords.region);
        }
      }
    }
  }, [createdBranchData]);

  useEffect(() => {
    if (addressFromCoords != null) {
      setFieldValue('contryNameEn', addressFromCoords.country);
      setFieldValue('city', addressFromCoords.city);
      setFieldValue('region', addressFromCoords?.region);
    }
  }, [addressFromCoords]);

  useEffect(() => {
    if (createdBranch !== null) {
      if (AddedEditedPolygon != null && AddedEditedPolygon.length > 0) {
        setFieldValue(
          'branch_covered_zones_attributes',
          AddedEditedPolygon.map((zone) => {
            return {
              name_en: zone.name_en,
              name_ar: zone.name_ar,
              latlng: zone.latlng,
              id: createdBranch?.branch_covered_zones?.find((z) => {
                let item;
                if (zone.FE_id && z.FE_id) {
                  if (z.FE_id === zone.FE_id) {
                    item = z;
                  }
                } else if (zone.id && z.id) {
                  if (z.id === zone.id) {
                    item = z;
                  }
                }
                return item;
              })?.id,
              _destroy: zone._destroy,
              FE_id: zone?.FE_id,
            };
          })
        );
      }
    } else {
      if (AddedEditedPolygon != null && AddedEditedPolygon.length > 0) {
        AddedEditedPolygon.map((item) => {
          if (!item._destroy) {
            item._destroy = false;
          }
          return item;
        });
        setFieldValue('branch_covered_zones_attributes', AddedEditedPolygon);
      }
    }
  }, [AddedEditedPolygon]);

  useEffect(() => {
    if (savedBranchData !== null) {
      if (savedBranchData.contryNameEn !== '' || selectedLocation) {
        if (Object.keys(selectedLocation).length !== 0) {
          setArrowRotate(true);
          setAtBranch(true);
        }
      }
      if (AddedEditedPolygon?.length > 0) {
        let polygonZones = AddedEditedPolygon?.filter(zone => !zone._destroy)
        if(polygonZones?.length > 0){
          setArrowRotate(true);
          setAtHome(true);
        }
      }
      setValues(savedBranchData);
    }
  }, [savedBranchData]);


  useEffect(() => {
    if(!savedBranchData?.region?.includes(addressFromCoords?.region)){
      setFieldValue('street_name','')
      setFieldTouched("street_name",false);
      setFieldValue('building_number','')
      setFieldTouched("building_number",false);
      setFieldValue('secondary_number','')
      setFieldTouched("secondary_number",false);
      setFieldValue('postal_code','')
      setFieldTouched("postal_code",false);
      setFieldValue('land_mark','')
      setFieldTouched("land_mark",false);
    }
  }, [values['region']]);

  useEffect(() => {
    if (createdBranch !== null) {
      if (AddedEditedPolygon != null && AddedEditedPolygon.length > 0) {
        setFieldValue(
          'branch_covered_zones_attributes',
          AddedEditedPolygon.map((zone) => {
            return {
              name_en: zone.name_en,
              name_ar: zone.name_ar,
              latlng: zone.latlng,
              id: createdBranch?.branch_covered_zones?.find((z) => {
                let item;
                if (zone.FE_id && z.FE_id) {
                  if (z.FE_id === zone.FE_id) {
                    item = z;
                  }
                } else if (zone.id && z.id) {
                  if (z.id === zone.id) {
                    item = z;
                  }
                }
                return item;
              })?.id,
              _destroy: zone._destroy,
              FE_id: zone?.FE_id,
            };
          })
        );
      }
    } else {
      if (AddedEditedPolygon != null && AddedEditedPolygon.length > 0) {
        AddedEditedPolygon.map((item) => {
          if (!item._destroy) {
            item._destroy = false;
          }
          return item;
        });
        setFieldValue('branch_covered_zones_attributes', AddedEditedPolygon);
      }
    }
  }, [AddedEditedPolygon]);

  useEffect(() => {
    if (selectedLocation) {
      if (Object.keys(selectedLocation).length !== 0) {
        getAdressFromCoordsAPI();
      }
    }
  }, [selectedLocation]);

  useEffect(() => {
    let isBranchSaved = false;
    let isBranchCreated = false;

    if (!savedBranchData) {
      isBranchSaved = false;
    } else if (typeof savedBranchData === 'object' && Object.keys(savedBranchData).length === 0) {
      isBranchSaved = false;
    } else {
      isBranchSaved = true;
    }

    if (!createdBranchData) {
      isBranchCreated = false;
    } else if (typeof createdBranchData === 'object' && Object.keys(createdBranchData).length === 0) {
      isBranchCreated = false;
    } else {
      isBranchCreated = true;
    }

    if (!isBranchSaved && !isBranchCreated) {
      setValues(intialFormValue);
    }
  }, []);

  useEffect(() => {
    if (lang === 'en') {
      if (values['serviced_gender']) {
        setFieldValue(
          'serviced_gender',
          servicedGenderList.find((option) => option.id === values['serviced_gender'].id)
        );
      }
      if (values['payment_methods']) {
        setFieldValue(
          'payment_methods',
          paymentMethodsOptions.find((option) => option.id === values['payment_methods'].id)
        );
      }
      setInputLocalization({});
    } else {
      if (values['serviced_gender']) {
        setFieldValue(
          'serviced_gender',
          servicedGenderListAr.find((option) => option.id === values['serviced_gender'].id)
        );
      }
      if (values['payment_methods']) {
        setFieldValue(
          'payment_methods',
          paymentMethodsOptionsAr.find((option) => option.id === values['payment_methods'].id)
        );
      }
      setInputLocalization(ar);
    }
  }, [lang]);

  const handleEditArea = (zone) => {
    dispatch(saveBranchData(values));
    history.push({
      pathname: ROUTE_PATHS.addBranchSupportedRegions.replace(':id', providerid),
      state: {
        otherZones: values['branch_covered_zones_attributes'].filter((otherZone) => otherZone.latlng !== zone.latlng),
        zone,
      },
    });
  };
  const checkIsUserSelectOneWorkingDayAtLeast = (weekdays) => {
    setHaveAtleastOneWorkingDay(weekdays?.some((day) => day.isChosen));
  };
  const checkIsUserSelectOneLocationAtLeast = () => {
    let checkSecondCase = false;
    if (atHome) {
      if (values['branch_covered_zones_attributes']?.length > 0) {
        let hasAtleastOneCoveragedZone = values['branch_covered_zones_attributes'].some((zone) => {
          if (zone._destroy !== true) {
            return true;
          } else {
            return false;
          }
        });
        if (hasAtleastOneCoveragedZone) {
          setHaveAtleastOneLocationSelected(true);
        } else {
          checkSecondCase = true;
        }
      } else {
        checkSecondCase = true;
      }
    } else {
      checkSecondCase = true;
    }
    if (checkSecondCase) {
      if (atBranch && values?.contryNameEn && values?.city) {
        setHaveAtleastOneLocationSelected(true);
        checkSecondCase = false;
      } else {
        setHaveAtleastOneLocationSelected(false);
        checkSecondCase = false;
      }
    }
  };
  const handleAddArea = () => {
    dispatch(saveBranchData(values));
    dispatch(addBranchDataInStore(values));
    history.push({
      pathname: ROUTE_PATHS.addBranchSupportedRegions.replace(':id', providerid),
      state: {
        otherZones: values['branch_covered_zones_attributes'],
      },
    });
  };

  const getAdressFromCoordsAPI = () => {
    dispatch(getAddressFromCoordsRequest(selectedLocation));
  };

  const changeDayToIsChosen = (dayIsCheck, i) => {
    let oldDataOfWeekDays = JSON.parse(JSON.stringify(values['branch_working_hours_attributes']));
    oldDataOfWeekDays[i].isChosen = dayIsCheck;
    setFieldTouched('branch_working_hours_attributes');
    setFieldValue('branch_working_hours_attributes', oldDataOfWeekDays);
    checkIsUserSelectOneWorkingDayAtLeast(oldDataOfWeekDays);
  };

  const renderTimeSlots = (day) => {
    if (day) {
      return day.timeSlots?.length > 0 ? (
        <div className="d-flex flex-column gap-3">
          {day.timeSlots.map((slot, i) => {
            return (
              <div key={slot.id || i}>
                <div className={`d-flex gap-2 align-items-center adjust-small-screens`} key={slot.id}>
                  <TimePicker
                    label={branches.addBranch.placeholders.from}
                    timeSlot={slot.starts_at}
                    onChange={(time) => {
                      changeSlotTime(day, time, i, 'starts_at');
                    }}
                    className={`${lang === 'en' ? 'timePicker' : 'timePicker reverse-style-ar'}`}
                  />
                  <div className="fsize-22"> - </div>
                  <TimePicker
                    label={branches.addBranch.placeholders.to}
                    timeSlot={slot.ends_at}
                    onChange={(time) => {
                      changeSlotTime(day, time, i, 'ends_at');
                    }}
                    className={`${lang === 'en' ? 'timePicker' : 'timePicker reverse-style-ar'}`}
                  />
                  {i > 0 && (
                    <div
                      className="pointer"
                      onClick={() => {
                        removeSlot(day, i);
                      }}>
                      <Close fontSize="large" />
                    </div>
                  )}
                  {day.timeSlots?.length >= 1 && day.timeSlots?.length < 3 && !i > 0 && (
                    <div className="add-btn-wrapper">
                      <Button
                        label={
                          <span className="d-flex align-items-center">
                            <Add fontSize="large" />
                          </span>
                        }
                        labelClass="fsize-16 text-white"
                        onClick={() => {
                          addNewSlot(day);
                        }}
                        btnStyle="gray"
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <>{renderFisrtTimeSlot(day)}</>
      );
    } else {
      return;
    }
  };

  const renderFisrtTimeSlot = (day) => {
    if (day) {
      day.timeSlots.push({
        id: 1,
        starts_at: null,
        ends_at: null,
      });
    }
    return;
  };

  const copyToAllWorkingDays = (activeDays, slotToCopy) => {
    let modifyWeekDays = values['branch_working_hours_attributes'];
    modifyWeekDays.forEach((day) => {
      if (day.id !== activeDays.id) {
        day.isChosen = true;
        day.timeSlots = [];
        day.isTimeEnter = false;
        activeDays.timeSlots.forEach((slot) => {
          day.timeSlots.push({ ...slot });
        });
      }
    });
    activeDays.isTimeEnter = false;
    setFieldTouched('branch_working_hours_attributes');
    setFieldValue('branch_working_hours_attributes', modifyWeekDays);
  };

  const addNewSlot = (day) => {
    let modifyWeekDays = values['branch_working_hours_attributes'];
    let targetedDay = modifyWeekDays.find((oldDay) => {
      return oldDay.id === day.id;
    });
    targetedDay.timeSlots.push({
      id: targetedDay.timeSlots[targetedDay.timeSlots.length - 1].id++,
      starts_at: '',
      ends_at: '',
    });
    setFieldTouched('branch_working_hours_attributes');
    setFieldValue('branch_working_hours_attributes', modifyWeekDays);
  };

  const changeSlotTime = (day, time, indexOfSlot, fromOrTo) => {
    let modifyWeekDays = values['branch_working_hours_attributes'];
    let targetedDay = modifyWeekDays.find((oldDay) => {
      return oldDay.id === day.id;
    });
    let targetedSlot = targetedDay.timeSlots.find((slot, i) => i === indexOfSlot);
    if (fromOrTo === 'starts_at') {
      targetedSlot.starts_at = time;
    } else {
      targetedSlot.ends_at = time;
    }
    if (targetedSlot.starts_at && targetedSlot.ends_at) {
      targetedDay.isTimeEnter = true;
    }
    setFieldTouched('branch_working_hours_attributes');
    setFieldValue('branch_working_hours_attributes', modifyWeekDays);
  };

  const removeSlot = (day, indexOfRemovedSlot) => {
    let modifyWeekDays = values['branch_working_hours_attributes'];
    let targetedDay = modifyWeekDays.find((oldDay) => {
      return oldDay.id === day.id;
    });
    targetedDay.timeSlots.splice(indexOfRemovedSlot, 1);
    targetedDay.isTimeEnter = true;
    setFieldTouched('branch_working_hours_attributes');
    setFieldValue('branch_working_hours_attributes', modifyWeekDays);
  };

  const deleteDataOfBranchLocation = ()  => {
    setFieldValue('contryNameEn','')
    setFieldTouched("contryNameEn",false);
    setFieldValue('city','')
    setFieldTouched("city",false);
    setFieldValue('region','')
    setFieldTouched("region",false);
    setFieldValue('street_name','')
    setFieldTouched("street_name",false);
    setFieldValue('building_number','')
    setFieldTouched("building_number",false);
    setFieldValue('secondary_number','')
    setFieldTouched("secondary_number",false);
    setFieldValue('postal_code','')
    setFieldTouched("postal_code",false);
    setFieldValue('land_mark','')
    setFieldTouched("land_mark",false);
    dispatch(getGoogleMapsCoords({}));
  };

  const renderLocationBody = () => {
    return (
      <div className="location-body px-4">
        <div className="text-primary">
          <Checkbox
            color="secondary"
            label={branches.addBranch.placeholders.atHome}
            labelClass="font-reguler text-dark fsize-16"
            name={'At Home'}
            id={'At Home'}
            checked={atHome}
            onChange={(IsChecked) => {
              setAtHome(IsChecked);
              if(!IsChecked){
                delteAllAreas(values['branch_covered_zones_attributes']);
              }
            }}
          />
          {atHome && (
            <div className="text-primary">
              <div className="d-flex align-items-center gap-4 ms-4 ps-2">
                <span className="text-dark font-medium">
                  {branches.addBranch.placeholders.supportedRegions}
                  <span className="err-asterisk"> * </span>
                </span>
                <Button
                  label={
                    <span className="d-flex align-items-center">
                      <Add fontSize="large" />
                      <span>{shared.add}</span>
                    </span>
                  }
                  labelClass="fsize-16 text-white"
                  onClick={() => {
                    handleAddArea();
                  }}
                />
              </div>
              <div className="d-flex flex-column gap-2 ms-4 mt-4">
                {values['branch_covered_zones_attributes']?.map(
                  (area, i) =>
                    !area._destroy && (
                      <div
                        className="d-flex gap-2 align-items-center bg-white rounded py-3 px-3"
                        key={area.id || area.FE_id || i}>
                        <span className="font-reguler text-dark fsize-16">
                          {lang === 'en' ? area?.name_en : area?.name_ar}
                        </span>
                        <Edit
                          id={lang === 'en' ? area?.name_en : area?.name_ar}
                          fontSize="small"
                          className="pointer ms-3 me-2"
                          onClick={() => {
                            handleEditArea(area);
                          }}
                        />
                        <Delete
                          className="pointer"
                          onClick={() => {
                            deleteArea(values['branch_covered_zones_attributes'], area);
                          }}
                        />
                      </div>
                    )
                )}
              </div>
            </div>
          )}
        </div>
        <div className="divider my-4"></div>
        <div className="text-primary">
          <div className="d-flex justify-content-between">
            <Checkbox
              color="secondary"
              label={branches.addBranch.placeholders.atBranch}
              labelClass="font-reguler text-dark fsize-16"
              name={'At branch'}
              id={'At branch'}
              checked={atBranch}
              onChange={(IsChecked) => {
                setAtBranch(IsChecked);
                if(!IsChecked){
                  deleteDataOfBranchLocation();
                }
              }}
            />
            {atBranch && (
              <div
                className="text-primary text-underline pointer mt-2"
                onClick={() => {
                  dispatch(saveBranchData(values));
                  history.push({
                    pathname: ROUTE_PATHS.addBranchBranchLocation.replace(':id', providerid),
                    state: {
                      // coords:
                      //   Object.keys(selectedLocation).length > 0
                      //     ? selectedLocation
                      //     : showEditMood
                      //     ? { lat: branchDataById?.lat, lng: branchDataById?.lng }
                      //     : null,
                      coordsData:
                        Object.keys(selectedLocation).length
                          ? {coords: {...selectedLocation}}
                          : showEditMood && branchDataById?.lat && branchDataById?.lng
                          ? {coords: { lat: branchDataById?.lat, lng: branchDataById?.lng }}
                          : null,
                      isEditMode : showEditMood
                    },
                  });
                }}>
                {branches.addBranch.placeholders.selectFromMap}
              </div>
            )}
          </div>
          {atBranch && (
            <div className="ms-4 ps-2 mt-4">
              <Input
                id="English-contry-name"
                label={branches.addBranch.labels.contryNameEn}
                name="English-contry-name"
                type="text"
                placeholder={branches.addBranch.placeholders.contryNameEn}
                required
                onChange={(value) => {
                  setFieldTouched('contryNameEn');
                  setFieldValue('contryNameEn', value);
                }}
                value={values['contryNameEn']}
                inputWrapperClass="mb-3 select-bg-white"
                labelClassName="text-dark font-medium mb-2"
                isInputHasErr={!!(touched["contryNameEn"] && errors["contryNameEn"])}
                errMsg={'countryRequired'}
                disabled
              />
              <Input
                id="City"
                label={branches.addBranch.labels.city}
                name="City"
                type="text"
                placeholder={branches.addBranch.placeholders.city}
                required
                onChange={(value) => {
                  setFieldTouched('city');
                  setFieldValue('city', value);
                }}
                value={values['city']}
                inputWrapperClass="mb-3 select-bg-white"
                labelClassName="text-dark font-medium mb-2"
                isInputHasErr={!!(touched["city"] && errors["city"])}
                errMsg={'cityRequired'}
                disabled
              />
              <Input
                id="Region"
                label={branches.addBranch.labels.region}
                name="Region"
                type="text"
                placeholder={branches.addBranch.placeholders.region}
                required
                onChange={(value) => {
                  setFieldTouched('region');
                  setFieldValue('region', value);
                }}
                value={values['region']}
                inputWrapperClass="mb-3 select-bg-white"
                labelClassName="text-dark font-medium mb-2"
                isInputHasErr={!!(touched["region"] && errors["region"])}
                errMsg={'regionRequired'}
              />
              <Input
                id="street name"
                label={branches.addBranch.labels.streetName}
                name="streen name"
                type="text"
                placeholder={branches.addBranch.placeholders.streetName}
                required={atBranch ? true : false}
                onChange={(value) => {
                  setFieldTouched('street_name');
                  setFieldValue('street_name', value);
                }}
                value={values['street_name']}
                inputWrapperClass="mb-3 select-bg-white"
                labelClassName="text-dark font-medium mb-2"
                isInputHasErr={atBranch && !!(touched["street_name"] && errors["street_name"])}
                errMsg={'streetNameRequired'}
              />
              <Input
                id="buildig number"
                label={branches.addBranch.labels.buildingNumber}
                name="buildig number"
                type="text"
                placeholder={branches.addBranch.placeholders.buildingNumber}
                required={atBranch ? true : false}
                onChange={(value) => {
                  setFieldTouched('building_number');
                  setFieldValue('building_number', value);
                }}
                value={values['building_number']}
                inputWrapperClass="my-3 select-bg-white"
                labelClassName="text-dark font-medium mb-2"
                isInputHasErr={!!(touched["building_number"] && errors["building_number"])}
                errMsg={'buildingNumberRequired'}
              />
              <Input
                id="secondary_number"
                label={branches.addBranch.labels.secondaryNumber}
                name="secondary_number"
                type="text"
                placeholder={branches.addBranch.placeholders.secondaryNumber}
                onChange={(value) => {
                  setFieldTouched('secondary_number');
                  setFieldValue('secondary_number', value);
                }}
                value={values['secondary_number']}
                inputWrapperClass="my-3 select-bg-white"
                labelClassName="text-dark font-medium mb-2"
              />
              <Input
                id="postal_code"
                label={branches.addBranch.labels.postalNumber}
                name="postal_code"
                type="text"
                placeholder={branches.addBranch.placeholders.postalNumber}
                onChange={(value) => {
                  setFieldTouched('postal_code');
                  setFieldValue('postal_code', value);
                }}
                value={values['postal_code']}
                inputWrapperClass="my-3 select-bg-white"
                labelClassName="text-dark font-medium mb-2"
                isInputHasErr={values['postal_code'] ? !!(errors['postal_code']) : !!(touched["postal_code"] && errors["postal_code"])}
                errMsg={'postalCodeNumberOnly'}
              />
              <Input
                id="landMark"
                label={branches.addBranch.labels.landmark}
                name="landMark"
                type="text"
                placeholder={branches.addBranch.placeholders.landmark}
                onChange={(value) => {
                  setFieldTouched('land_mark');
                  setFieldValue('land_mark', value);
                }}
                value={values['land_mark']}
                inputWrapperClass="mb-3 select-bg-white"
                labelClassName="text-dark font-medium mb-2"
                isInputHasErr={!!(touched['land_mark'] && errors['land_mark'])}
                errMsg={errors['land_mark']}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const deleteArea = (list, targetedArea) => {
    if (list.length > 0) {
      let updatedList = list.map((item) => {
        if (item.id) {
          if (item.id === targetedArea.id) {
            item._destroy = true;
          }
          return item;
        } else {
          if (item.FE_id === targetedArea.FE_id) {
            item._destroy = true;
          }
          return item;
        }
      });
      setFieldValue(['branch_covered_zones_attributes'], updatedList);
      dispatch(getGoogleMapsPolygon(updatedList));
      if (updatedList.length === 1) {
        if (updatedList[0]._destroy === true) setAtHome(false);
        dispatch(getGoogleMapsPolygon([]));
      }
      const deletedAreas = updatedList.filter(area => area._destroy)
      if(deletedAreas.length === updatedList.length){
        setAtHome(false)
      }
    }
  };

  const delteAllAreas = (list) => {
    if (list.length > 0) {
      let updatedList = list.map((item) => {
        item._destroy = true;
        return item;
      });
      setFieldValue(['branch_covered_zones_attributes'], updatedList);
      dispatch(getGoogleMapsPolygon(updatedList));
    }
  }

  const UploadImage = async (image) => {
    const data = await uploadToS3(image, generateUniqueFileName(image.name), directories.branch_images);
    setFieldValue('image', data?.link);
  };

  const renderWorkingHoursBody = () => {
    return (
      <>
        {values['branch_working_hours_attributes']?.map((day, i) => {
          return (
            <div className="d-flex flex-column justify-content-between py-1" key={day.id || i}>
              <div className="woring-hours-day ">
                <div className="d-flex gap-3 align-items-center">
                  <div className="flex-basis-25">
                    <Checkbox
                      color="secondary"
                      label={lang === 'en' ? renderDayLabel(day) : renderDayLabel(day)}
                      labelClass="font-reguler text-dark fsize-16"
                      name={day.label}
                      id={day.label}
                      checked={day.isChosen}
                      onChange={(elementIsChecked) => {
                        setFieldTouched('branch_working_hours_attributes');
                        changeDayToIsChosen(elementIsChecked, i);
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-column gap-3">
                      {day.isChosen ? (
                        <span className="time-slots">{renderTimeSlots(day)}</span>
                      ) : (
                        <span className="time-slots text-red fweight-600">{shared.closed}</span>
                      )}
                    </div>
                    {day.isTimeEnter && day.isChosen && (
                      <div
                        className="copy-style copy-working-hours d-flex align-items-center gap-2 text-primary pointer mt-2"
                        id={day.id}
                        onClick={(e) => {
                          copyToAllWorkingDays(day);
                        }}>
                        <ContentCopy />
                        {branches.addBranch.labels.applyToAllWokringDays}
                        <span className="text-secondary text-underline">
                          {branches.addBranch.labels.applyToAllWokringDaysAction}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const renderDayLabel = (day) => {
    switch (day.id) {
      case 0:
        return lang === 'en' ? weekDays.sat : weekDaysAr.sat;
      case 1:
        return lang === 'en' ? weekDays.sun : weekDaysAr.sun;
      case 2:
        return lang === 'en' ? weekDays.mon : weekDaysAr.mon;
      case 3:
        return lang === 'en' ? weekDays.tus : weekDaysAr.tus;
      case 4:
        return lang === 'en' ? weekDays.wed : weekDaysAr.wed;
      case 5:
        return lang === 'en' ? weekDays.thurs : weekDaysAr.thurs;
      case 6:
        return lang === 'en' ? weekDays.fri : weekDaysAr.fri;

      default:
        return '-';
    }
  };

  const chooseCustomerPlaceWithArea = () => {
    const areas = values['branch_covered_zones_attributes']
    const deletedAreas = areas?.filter(area => area._destroy)
    if(deletedAreas?.length === areas?.length && atHome){
      return true;
    }else {
      return false;
    }
  }

  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className="px-2 px-md-5 bg-white rounded-4 py-4">
        <div className="row mt-4">
          <div className="col-12">
            <Input
              id="name_en"
              label={branches.addBranch.labels.enTitle}
              placeholder={branches.addBranch.placeholders.enTitle}
              name="name_en"
              type="text"
              required
              onChange={(value) => {
                setFieldTouched('name_en');
                setFieldValue('name_en', value);
              }}
              value={values['name_en']}
              inputWrapperClass="mb-4"
              labelClassName="text-dark font-medium mb-2"
              isInputHasErr={!!(touched['name_en'] && errors['name_en'])}
              errMsg={errors['name_en']}
            />
          </div>
          <div className="col-12">
            <Input
              id="name_ar"
              label={branches.addBranch.labels.arTitle}
              name="name_ar"
              type="text"
              placeholder={branches.addBranch.placeholders.arTitle}
              required
              onChange={(value) => {
                setFieldTouched('name_ar');
                setFieldValue('name_ar', value);
              }}
              value={values['name_ar']}
              inputWrapperClass="mb-4"
              labelClassName="text-dark font-medium mb-2"
              isInputHasErr={!!(touched['name_ar'] && errors['name_ar'])}
              errMsg={errors['name_ar']}
            />
          </div>
          <div className="col-12">
            <span className="text-dark font-medium">{branches.addBranch.labels.branchImage}</span>
            <Tooltip
              title={<span className="fsize-12">{shared.filesAccepted} </span>}
              content={
                <span className="text-bg-primary-mid fsize-14 font-reguler mx-2">{shared.acceptedFormatLabel}</span>
              }></Tooltip>
            <Upload
              id="image"
              label={
                <div className="mt-2 pt-1 d-flex flex-column align-items-center">
                  <ImageOutlined fontSize="large" />
                  <span>{branches.addBranch.placeholders.branchImage}</span>
                </div>
              }
              onChange={(img, value) => {
                setBranchLogo(img);
                setFieldValue('image', value);
                UploadImage(value);
              }}
              img={branchLogo || values['image']}
              name="image"
              required={false}
              isInputHasErr={!!(touched['image'] && errors['image'])}
              errMsg={errors['image']}
            />
          </div>
          <div className="col-12 my-4">
            <div className="text-dark font-medium mb-2">{branches.addBranch.labels.phone}</div>
            {Object.keys(inputLocalization).length == 0 ? (
              <PhoneInput
                name="phone_number"
                type="text"
                className={`${lang === 'ar' && 'phone-arabic-dir'}`}
                value={values['phone_number']}
                country={'sa'}
                localization={inputLocalization}
                onChange={(phone_number, countryData) => {
                  setFieldTouched('phone_number');
                  setFieldValue('phone_number', phone_number);
                  setFieldValue('dial_code', countryData.dialCode);
                }}
                isInputHasErr={!!(touched['phone_number'] && errors['phone_number'])}
                errMsg={errors['phone_number']}
              />
            ) : (
              <>
                <PhoneInput
                  name="phone_number"
                  type="text"
                  className={`${lang === 'ar' && 'phone-arabic-dir'}`}
                  value={values['phone_number']}
                  country={'sa'}
                  localization={inputLocalization}
                  onChange={(phone_number, countryData) => {
                    setFieldTouched('phone_number');
                    setFieldValue('phone_number', phone_number);
                    setFieldValue('dial_code', countryData.dialCode);
                  }}
                  isInputHasErr={!!(touched['phone_number'] && errors['phone_number'])}
                  errMsg={errors['phone_number']}
                />
              </>
            )}
          </div>
          {/* Working Hours */}
          <div className="col-12">
            <span className="text-dark font-medium d-flex align-items-center">
              <div className="mt-1">
                {branches.addBranch.labels.workingHours}
                <span className="err-asterisk"> * </span>
              </div>
              <div className="mx-1">
                <Tooltip 
                  title={branches.addBranch.workingHoursHint}
                  content={<span><ErrorOutlineOutlined color="secondary"/></span>}
                />
              </div> 
            </span>
            <div className="fsize-12 text-error font-medium">
              {touched['branch_working_hours_attributes'] && errors['branch_working_hours_attributes']}
            </div>
            <div className="working-hours-section text-primary">{renderWorkingHoursBody()}</div>
          </div>
          {/* Location */}
          <div className="col-12 mt-4">
            <span className="text-dark font-medium d-flex align-items-center">
              <div className="mt-1">
                {branches.addBranch.labels.location}
                <span className="err-asterisk"> * </span>
              </div>
              <div className="mx-1">
                <Tooltip 
                  title={branches.addBranch.locationHint}
                  content={<span><ErrorOutlineOutlined color="secondary"/></span>}
                />
              </div>
            </span>
            <div className="fsize-12 text-error font-medium">
              {touched['branch_working_hours_attributes'] && errors['branch_working_hours_attributes']}
            </div>
            <Accordion
              wrapperClassName={'location-section'}
              label={<span>{branches.addBranch.placeholders.location}</span>}
              labelClassName={'text-primary opacity05'}
              body={renderLocationBody()}
              isExpanded={arrowRotate}
              actionButton={arrowRotate ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              arrowRotate={arrowRotate}
              setArrowRotateValue={setArrowRotate}
            />
          </div>
          <div className="col-12 mt-4">
            <Select
              name="serviced_gender"
              labelClassName="font-medium mb-2 text-primary"
              label={branches.addBranch.labels.ServicedGender}
              options={servicedGenderList}
              inputClassName="input-wrapper"
              placeholder={branches.addBranch.placeholders.ServicedGender}
              required
              value={values['serviced_gender'] || gender}
              onChange={(value) => {
                setFieldTouched('serviced_gender');
                setFieldValue('serviced_gender', value);
                setGender(value);
              }}
              hasError={!!(touched['serviced_gender'] && errors['serviced_gender'])}
              errMsg={errors['serviced_gender']}
            />
          </div>
          <div className="col-12 mt-4">
            <Select
              name="payment_methods"
              labelClassName="font-medium mb-2 text-primary"
              label={branches.addBranch.labels.paymenMethods}
              options={paymentMethodsOptions}
              inputClassName="input-wrapper"
              placeholder={branches.addBranch.placeholders.paymenMethods}
              required
              value={values['payment_methods'] || payment}
              onChange={(value) => {
                setFieldTouched('payment_methods');
                setFieldValue('payment_methods', value);
                setPayemnt(value);
              }}
              hasError={!!(touched['payment_methods'] && errors['payment_methods'])}
              errMsg={errors['payment_methods']}
            />
          </div>
        </div>
        <div className="mt-4 py-4 d-flex justify-content-end align-items-center gap-2">
          {!showEditMood && (
            <Button
              onClick={() => {
                if (
                  values.name_ar != null ||
                  values.name_en != null ||
                  values.city !== '' ||
                  values.building_number !== '' ||
                  values.secondary_number !== '' ||
                  values.postal_code !== '' ||
                  values.land_mark !== '' ||
                  values.branch_covered_zones_attributes.length > 0 ||
                  values.contryNameEn !== '' ||
                  values.phone_number != null ||
                  values.serviced_gender !== null ||
                  values.payment_methods !== null
                ) {
                  setShowLeaveWarningModal(true);
                }
              }}
              outlined
              type="button"
              color={'info'}
              label={shared.cancel}
              labelClass="fsize-16 py-1 fweight-600"
            />
          )}
          <Button
            onClick={() => {
              if(showEditMood){setShowEditValidationPopup(true)}}}
            type={!showEditMood ? "sumbit" : 'button'}
            label={!showEditMood ? shared.saveAndProcced : shared.save}
            labelClass="fsize-16 text-white py-1  fweight-600"
            disabled={
              createdBranch?.id
              ? !isValid || !haveAtleastOneWorkingDay || !haveAtleastOneLocationSelected || chooseCustomerPlaceWithArea()
              : !dirty || !isValid || !haveAtleastOneWorkingDay || !haveAtleastOneLocationSelected || chooseCustomerPlaceWithArea()
            }
          />
        </div>
      </div>
      <Modal
        open={showEditValidationPopup}
        handleClose={()=>{setShowEditValidationPopup(false)}}
        title={<span className="fsize-26 fweight-400">{branches.addBranch.validationPopupTitle}</span>}
        contentClass="mb-3 pb-2 text-primary"
        content={<span className="fsize-18 fweight-400">{branches.addBranch.validationPopupContent}</span>}
        confirmBtnTxt={shared.procced}
        cancelBtnTxt={shared.cancel}
        isContentText={false}
        handleConfirm={()=>{
          handleSubmit();
          setShowEditValidationPopup(false);
        }}
        handleCancel={()=>{setShowEditValidationPopup(false)}}
        maxWidth="sm"
        actionsClass=" px-4"
        confirmBtnClass="btn-secondary fsize-14 text-white py-2 px-5"
        cancelBtnClass="fsize-14 py-2 text-secondary px-5 "
      />
    </form>
  );
};

export default AddEditBranchDetails;
