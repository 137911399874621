import React from "react";

export const BookingsTabInprogressIcon = () => (
  <svg width="122" height="123" viewBox="0 0 122 123" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1230_3037)">
      <rect
        x="4"
        y="4"
        width="114"
        height="114"
        rx="57"
        fill="url(#paint0_linear_1230_3037)"
        fillOpacity="0.1"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter1_d_1230_3037)">
      <rect x="14" y="13" width="96" height="96" rx="46.1957" fill="url(#paint1_linear_1230_3037)" />
    </g>
    <path
      d="M54.2778 41V47.1667M70.7222 41V47.1667M45.0278 55.5739H79.9722M81 54.3611V71.8333C81 78 77.9167 82.1111 70.7222 82.1111H54.2778C47.0833 82.1111 44 78 44 71.8333V54.3611C44 48.1944 47.0833 44.0833 54.2778 44.0833H70.7222C77.9167 44.0833 81 48.1944 81 54.3611Z"
      stroke="white"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70.0999 65.0503H70.1184M70.0999 71.217H70.1184M62.4943 65.0503H62.5149M62.4943 71.217H62.5149M54.8867 65.0503H54.9073M54.8867 71.217H54.9073"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <filter
        id="filter0_d_1230_3037"
        x="0"
        y="0.8"
        width="122"
        height="122"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.8" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.247059 0 0 0 0 0.278431 0 0 0 0 0.321569 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1230_3037" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1230_3037" result="shape" />
      </filter>
      <filter
        id="filter1_d_1230_3037"
        x="10"
        y="9.8"
        width="104"
        height="104"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.8" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.247059 0 0 0 0 0.278431 0 0 0 0 0.321569 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1230_3037" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1230_3037" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_1230_3037" x1="4" y1="118" x2="139.621" y2="118" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3F4752" />
        <stop offset="0.375" stopColor="#3F4752" />
        <stop offset="1" stopColor="#888D94" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1230_3037"
        x1="14"
        y1="109"
        x2="128.207"
        y2="109"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#3F4752" />
        <stop offset="0.375" stopColor="#3F4752" />
        <stop offset="1" stopColor="#888D94" />
      </linearGradient>
    </defs>
  </svg>
);
