const shared = {
  home: 'الرئيسية',
  language: 'EN',
  admins: 'المديرين',
  lookups: 'العمليات',
  BranchesManagement: 'إدارة الفروع',
  Branches: 'الفروع',
  employees: 'الموظفين',
  BranchesManagement: 'إدارة الفروع',
  bookings: 'الحجوزات',
  settings: 'الإعدادات',
  notifications: 'الإشعارات',
  serviceProviderCategories: 'فئات مقدمي الخدمات',
  noReviewsAdded: 'لا يوجد تقييمات مضافة حتى الان',
  serviceProviders: 'مقدمي الخدمات',
  specialties: 'التخصصات',
  services: 'الخدمات',
  logout: 'تسجيل الخروج',
  lang: 'English',
  back: 'الرجوع',
  noSearch: 'عفوا، لم يتم العثور على نتائج بحثك!',
  noServicesAddedyet: 'عفوا، لم يتم اضافة خدمات بعد',
  noSpecialtiesOnCategoryyet: 'عفوا، لا يحتوي هذا التصنيف على أي تخصصات',
  noEmployeesAddedyet: 'عفوا، لم يتم اضافة موظفين بعد',
  noData: 'لا توجد بيانات حتى الان',
  noDataToShow: 'لا توجد بيانات',
  actions: 'أفعال',
  edit: 'تعديل',
  add: 'اضافة',
  view: 'تفاصيل',
  delete: 'حذف',
  save: 'حفظ',
  procced:'متابعة',
  saveAndProcced: 'حفظ و متابعة',
  saveAndPublish: 'حفظ و طلب نشر',
  cancel: 'الغاء',
  submit: 'أرسل',
  previous: 'السابق',
  filter: 'تصفية',
  confirm: 'تأكيد',
  copy: 'نسخ',
  copied: 'تم النسخ',
  lists: {
    enTitle: 'الاسم بالانجليزية',
    arTitle: 'الاسم بالعربية',
    enName: 'الاسم بالانجليزية',
    arName: 'الاسم بالعربية',
    name: 'الاسم',
    email: 'البريد الالكتروني',
    phone: 'رقم الهاتف',
    image: 'الصورة',
    categories: 'الفئات',
    status: 'الحالة',
    category: 'الفئة',
    branchName: 'اسم الفرع',
    location: 'عنوان',
    rating: 'التقييم',
    providerName: 'اسم مقدم الخدمة',
    appointmentId: 'رقم الحجز',
    customerName: 'اسم العميل',
    date: 'التاريخ',
    transactionType:'نوع المعاملة',
    paidAmount:'المبلغ المدفوع',
  },
  filesAccepted: 'الحجم الأقصى: 2MB، الصيغات المقبولة: jpg، jpeg، png، و bmp',
  acceptedFormatLabel: 'الصيغات المقبولة',
  noOptions: 'لا يوجد خيارات',
  generalErrMsg: 'لقد حدث خطأ ما!',
  days: {
    sat: 'السبت',
    sun: 'الاحد',
    mon: 'الاثنين',
    tus: 'الثلاثاء',
    wed: 'الاربعاء',
    thurs: 'الخميس',
    fri: 'الجمعة',
  },
  closed: 'مغلق',
  polygonHint: 'لتأكيد منطقتك، انقر داخل المضلع بعد الانتهاء',
  ResetPolygon: 'إعادة تعيين المضلع',
  serviceProvider: 'مقدم الخدمة',
  transactions: 'معاملات الدفع',
};

export default shared;
