import React from "react";

export const EditBgIcon = (props) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="35" height="35" rx="11" fill="#F8F9FC" />
      <path
        d="M16 7.25146H14C9 7.25146 7 9.25146 7 14.2515V20.2515C7 25.2515 9 27.2515 14 27.2515H20C25 27.2515 27 25.2515 27 20.2515V18.2515"
        stroke="url(#paint0_linear_1054_1200)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0399 8.27123L13.1599 16.1512C12.8599 16.4512 12.5599 17.0412 12.4999 17.4712L12.0699 20.4812C11.9099 21.5712 12.6799 22.3312 13.7699 22.1812L16.7799 21.7512C17.1999 21.6912 17.7899 21.3912 18.0999 21.0912L25.9799 13.2112C27.3399 11.8512 27.9799 10.2712 25.9799 8.27123C23.9799 6.27123 22.3999 6.91123 21.0399 8.27123V8.27123Z"
        stroke="url(#paint1_linear_1054_1200)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9102 9.40137C20.2417 10.5788 20.8701 11.6514 21.7351 12.5164C22.6001 13.3814 23.6727 14.0098 24.8502 14.3414"
        stroke="url(#paint2_linear_1054_1200)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1054_1200"
          x1="7.5102"
          y1="10.0585"
          x2="26.9006"
          y2="10.3893"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D19985" />
          <stop offset="1" stopColor="#FFBEA3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1054_1200"
          x1="12.4366"
          y1="9.13335"
          x2="27.1756"
          y2="9.38485"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D19985" />
          <stop offset="1" stopColor="#FFBEA3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1054_1200"
          x1="20.0362"
          y1="10.0947"
          x2="24.8256"
          y2="10.1764"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D19985" />
          <stop offset="1" stopColor="#FFBEA3" />
        </linearGradient>
      </defs>
    </svg>
  );
};
