const forgetPassword = {
  title: "Forgot",
  title2: "password",
  labels: {
    email: "Email",
  },
  placeholders: {
    email: "Enter your email",
  },
  actionBTN: "Send",
};

export default forgetPassword;
