const serviceProviders = {
  serviceProviderList: 'Service providers',
  list: {
    search: 'Search for a service provider',
    noDataSubTitle: 'There are no service provider to display yet',
    noDataDescription: 'Start now to add service provider',
    category: 'Category',
    branches: 'Branches',
    addBranch: 'Add branch',
    generatePassword: 'Generate password',
    CreateButton: 'Add a new service provider',
    editStatus: 'Edit status',
    filter: {
      filtersPlaceHolders: {
        filterByCategory: 'Category',
        filterByProviderStatus: 'status',
      },
    },
    suspendedByBlock: 'This provider will be blocked once all appointments are completed',
    suspendedByDeactivateProvider: 'This provider will be deactivated starting from',
    status: {
      active: 'Active',
      deactivated: 'Deactivated',
      blocked: 'Blocked',
      suspended: 'Suspended',
      unpublished: 'Unpublished',
      requestedPublishing: 'Requested publishing',
    },
    statusActions: {
      activate: 'Activate',
      block: 'Block',
      deactivateNow: 'Deactivate immediately',
      deacivateLater: 'Deactivate starting from a certain date',
      deacivateLaterTitle: 'Deactivate Service Provider',
      dateLabel: 'Starting from',
    },
  },
  addServiceProvider: {
    title: 'Add new service provider',
    labels: {
      email: 'Email',
    },
    placeholders: {
      email: 'Enter email',
      ArName: 'Enter arabic name',
      EnName: 'Enter english name',
      category: 'Choose category',
      uploadLogo: 'Logo',
      banner: 'Banner',
    },
  },
  editServiceProvider: {
    title: 'Edit service provider',
  },
  deleteServiceProvider: {
    title: 'Delete service provider',
    deleteConfirmationMsg: 'Are you sure you want to delete this Service Provider? ',
  },
  viewServiceProvider: {
    title: 'Service provider details',
    noProvider: 'no providers are added yet',
    showPasswordTitle: 'Password details',
    passwordIs: "Service provider's password is: ",
    generatePassword: 'Generate random passowrd',
    noBranches: 'No branches are added yet',
  },
};

export default serviceProviders;
