import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Menu as MuiMenu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import messages from "../../assets/locale/messages";

import "./NotificationsMenu.scss";

const NotificationsMenu = ({
  menuController,
  menuItems,
  handleSelectItem,
  menuFooter,
  menuPaperClass,
  menuListClass,
  menuFooterClass,
  emptyState,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];
  const [anchorEl, setAnchorEl] = useState(null);

  const handleControllerClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={`notifications-menu-wrapper`}>
      <div
        aria-controls="custom-menu"
        className={"menu-controller"}
        onClick={handleControllerClick}>
        {menuController}
      </div>
      <MuiMenu
        id="custom-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        classes={{
          paper: `notifications-menu py-2 px-3 bg-search-input-bg ${menuPaperClass}`,
          list: `notifications-list ${menuListClass}`,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
          <div className="pt-2 pb-2 fweight-500 fsize-22 text-secondary">
            {shared.notifications}
          </div>
        {menuItems?.length ? (
          menuItems.map(({ id, content, icon, className, adorment }, index) => (
            <MenuItem
              className={`bg-white rounded-4 p-2 mb-3 menu-item d-flex justify-content-between ${className}`}
              onClick={() => {
                handleSelectItem(id);
              }}
              key={index}>
              <div
                onClick={() => {
                  handleClose();
                }}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText>{content}</ListItemText>
              </div>
              {adorment && <>{adorment}</>}
            </MenuItem>
          ))
        ) : (
          <MenuItem>
            <ListItemText>{emptyState}</ListItemText>
          </MenuItem>
        )}
        {menuItems?.length > 0 && menuFooter && (
          <div
            className={`menu-title ${menuFooterClass}`}
            onClick={handleClose}>
            {menuFooter}
          </div>
        )}
      </MuiMenu>
    </div>
  );
};

NotificationsMenu.propTypes = {
  menuController: PropTypes.element.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      content: PropTypes.element,
      icon: PropTypes.element,
      adorment: PropTypes.element,
      className: PropTypes.string,
    })
  ),
  handleSelectItem: PropTypes.func,
  menuFooter: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  menuPaperClass: PropTypes.string,
  menuListClass: PropTypes.string,
  menuFooterClass: PropTypes.string,
  adorment: PropTypes.element,
  emptyState: PropTypes.string,
};

export default NotificationsMenu;
