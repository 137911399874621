import React from "react";

export const BranchDetailsTabIconDone = () => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="96" height="96" rx="46.1957" fill="#3F4752" />
    <g clipPath="url(#clip0_1_82)">
      <path
        d="M49.3 62.9C49.4 63.4 49.6 63.9 49.8 64.4C49 64.5 48.2 64.5 47.3 64.5H37C28.1 64.5 24.3 60.7 24.3 51.8V43.3C24.3 42.9 24.6 42.5 25.1 42.5C25.5 42.5 25.9 42.8 25.9 43.3V51.8C25.8 60 28.8 63 37 63H47.2C48 63 48.7 63 49.3 62.9Z"
        fill="white"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M60 43.3V49.2C59.5 49.2 59 49.2 58.5 49.3V43.3C58.5 42.9 58.8 42.5 59.3 42.5C59.8 42.5 60 42.9 60 43.3Z"
        fill="white"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M42.1 45.5C40.2 45.5 38.5 44.8 37.3 43.4C36.1 42 35.5 40.3 35.7 38.4L37 25.7C37 25.3 37.3 25 37.7 25H46.6C47 25 47.3 25.3 47.3 25.7L48.6 38.4C48.8 40.3 48.2 42.1 47 43.4C45.7 44.8 44 45.5 42.1 45.5ZM38.4 26.5L37.2 38.5C37.1 40 37.5 41.3 38.4 42.4C39.3 43.4 40.7 44 42.1 44C43.5 44 44.9 43.4 45.8 42.4C46.7 41.4 47.2 40 47 38.5L45.8 26.5H38.4Z"
        fill="white"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M46.9 64.5H37.4C37 64.5 36.6 64.2 36.6 63.7V59C36.6 55.4 38.5 53.5 42.1 53.5C45.7 53.5 47.6 55.4 47.6 59V63.8C47.6 64.2 47.3 64.5 46.9 64.5ZM38.1 63H46.1V59C46.1 56.2 44.9 55 42.1 55C39.3 55 38.1 56.2 38.1 59V63ZM54.1 45.5C50.6 45.5 47.4 42.6 47.1 39.1L45.8 25.8C45.8 25.6 45.8 25.4 46 25.2C46.1 25.1 46.3 25 46.5 25H52.3C57.5 25 59.9 27.1 60.6 32.5L61.1 37.8C61.3 39.9 60.7 41.8 59.4 43.3C58.1 44.7 56.2 45.5 54.1 45.5ZM47.3 26.5L48.6 39C48.9 41.8 51.4 44 54.1 44C55.7 44 57.2 43.4 58.3 42.2C59.3 41 59.8 39.5 59.7 37.9L59.2 32.7C58.6 28 56.9 26.5 52.4 26.5H47.3ZM30.1 45.5C28 45.5 26.2 44.7 24.8 43.2C23.5 41.7 22.8 39.8 23.1 37.7L23.6 32.5C24.3 27.1 26.6 25 31.9 25H37.7C37.9 25 38.1 25.1 38.3 25.2C38.4 25.4 38.5 25.6 38.5 25.8L37.2 39.1C36.7 42.6 33.6 45.5 30.1 45.5ZM31.9 26.5C27.3 26.5 25.7 28 25 32.7L24.5 37.9C24.3 39.5 24.8 41.1 25.9 42.2C26.9 43.4 28.4 44 30.1 44C32.9 44 35.4 41.8 35.6 39L36.8 26.5H31.9Z"
        fill="white"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M71.9 71.9C71.7 71.9 71.5 71.8 71.4 71.7L69.4 69.7C69.1 69.4 69.1 68.9 69.4 68.6C69.7 68.3 70.2 68.3 70.5 68.6L72.5 70.6C72.8 70.9 72.8 71.4 72.5 71.7C72.3 71.8 72.1 71.9 71.9 71.9ZM61.4 70.9C58.7 70.9 56.1 69.8 54.2 67.9C52.3 66 51.2 63.4 51.2 60.7C51.2 58 52.3 55.4 54.2 53.5C56.1 51.6 58.7 50.5 61.4 50.5C62.8 50.5 64.1 50.8 65.3 51.3C66.6 51.8 67.7 52.6 68.6 53.5C69.6 54.5 70.3 55.6 70.8 56.8C71.3 58 71.6 59.4 71.6 60.7C71.6 62.1 71.3 63.4 70.8 64.6C70.3 65.9 69.5 67 68.6 67.9C67.6 68.9 66.5 69.6 65.3 70.1C64.1 70.6 62.8 70.9 61.4 70.9ZM61.4 51.9C59.1 51.9 56.9 52.8 55.2 54.5C53.5 56.2 52.6 58.3 52.6 60.7C52.6 63 53.5 65.2 55.2 66.9C56.9 68.6 59.1 69.5 61.4 69.5C62.6 69.5 63.7 69.3 64.7 68.8C65.8 68.4 66.7 67.7 67.5 66.9C68.3 66.1 69 65.1 69.4 64.1C69.8 63 70.1 61.9 70.1 60.8C70.1 59.7 69.9 58.5 69.4 57.5C69 56.4 68.3 55.5 67.5 54.7C66.7 53.9 65.7 53.2 64.7 52.8C63.7 52.1 62.6 51.9 61.4 51.9Z"
        fill="white"
        stroke="white"
        strokeWidth="0.7"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_82">
        <rect
          width="49.7"
          height="46.9"
          fill="white"
          transform="translate(23 25)"
        />
      </clipPath>
    </defs>
  </svg>
);
