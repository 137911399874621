import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

import SideMenu from "./SideMenu";
import NavBar from "./NavBar";
import AuthLayout from "./AuthLayout";
import MainSection from "./MainSection";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { isAuth } from "../../utils/Auth";
import "./Layout.scss";

const drawerWidth = 240;
const collapsedDrawerWidth = 72;
const navbarHeight = 92;

const Layout = ({ children }) => {
  const location = useLocation();

  const [sideMenuOpen, setSideMenuOpen] = useState(true);
  const [mobileSideMenuOpen, setMobileSideMenuOpen] = useState(false);

  const detectSideMenuOpen = (isOpen) => {
    setSideMenuOpen(isOpen);
  };
  const detectMobileSideMenuOpen = (isOpen) => {
    setMobileSideMenuOpen(isOpen);
  };
  
  // Checking for changePassword as it's an authenticated page but needs the usual auth pages layout
  return isAuth() &&
    location.pathname !== ROUTE_PATHS.changePassword &&
    location.pathname !== ROUTE_PATHS.setupProfile ? (
    <Box sx={{ display: "flex", backgroundColor: "#f6f6f6" }}>
      <NavBar
        sideMenuOpen={sideMenuOpen}
        drawerWidth={drawerWidth}
        collapsedDrawerWidth={collapsedDrawerWidth}
        openMobileSideMenu={detectMobileSideMenuOpen}
      />
      <SideMenu
        detectSideMenuOpen={detectSideMenuOpen}
        closeMobileSideMenu={() => detectMobileSideMenuOpen(false)}
        drawerWidth={drawerWidth}
        mobileSideMenuOpen={mobileSideMenuOpen}
      />
      <MainSection
        sideMenuOpen={sideMenuOpen}
        drawerWidth={drawerWidth}
        collapsedDrawerWidth={collapsedDrawerWidth}
        navbarHeight={navbarHeight}>
        {children}
      </MainSection>
    </Box>
  ) : (
    <AuthLayout content={children} />
  );
};

Layout.propTypes = {
  // isAuth: PropTypes.bool,
  children: PropTypes.node,
};

export default Layout;
