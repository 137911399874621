import React from "react";
import { useSelector, useDispatch } from "react-redux";

import messages from "../../assets/locale/messages";
import { setCurrentLang } from "../../store/Lang/actions";
import "./LangSwitcher.scss";

const LangSwitcher = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  const dispatch = useDispatch();

  const handleSwitchLanguage = () => {
    dispatch(setCurrentLang(lang === "en" ? "ar" : "en"));
  };

  return (
    <div
      className="lang-switcher pointer d-flex align-items-center"
      onClick={handleSwitchLanguage}
    >
      <span className="lang-label fsize-16 border-radius-10">{shared.language}</span>
    </div>
  );
};

export default LangSwitcher;
