import { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { Menu as MenuList } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

import "./Menu.scss";

const Menu = ({ label, list, onChangeValue, ButtonClassName }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        className={ButtonClassName}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {label}
      </Button>
      <MenuList
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {list?.map((listItem) => (
          <MenuItem
            key={listItem.id}
            onClick={() => {
              onChangeValue(listItem);
              handleClose();
            }}
          >
            <span>{listItem.label}</span>
          </MenuItem>
        ))}
      </MenuList>
    </div>
  );
};

export default Menu;

Menu.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  list: PropTypes.array.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  ButtonClassName: PropTypes.string,
};

Menu.defaultProps = {
  list: [],
};
