import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from '@mui/material';
import { FiberManualRecord, Storefront, CalendarMonthTwoTone, LiveHelpOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import messages from "../../assets/locale/messages";
import { NotificationsBgIcon } from "../../utils/Icons";
import { formatDate, setPageMainTitle } from "../../utils/Helpers";
import {
  handleNotificationsClickActions,
  setNotificationSeen,
} from "../../firebase/helpers";
import EmptyState from "../../components/EmptyState";
import {
  getNotificationsRequest,
  getNotificationsResponse,
} from "../../store/Notifications/actions";
import Button from "../../components/Button";
import "./Notifications.scss";

const Notifications = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const notificationsList = useSelector((state) => state.notifications.list);
  const notificationsMeta = useSelector((state) => state.notifications.meta);
  const { notifications } = messages[lang];

  const [page, setPage] = useState(notificationsMeta?.currentPage || 1);

  const NotifcationTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="top-end" {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "transparent",
      color: "#448bca",
      textAlign: "center",
    },
  }));

console.log(notificationsList)

  useEffect(() => {
    setPageMainTitle("notificationsManagement");
 
  }, []);

  useEffect(() => {
    dispatch(
      getNotificationsRequest({
        items: 10,
        page,
      })
    );
  }, [page]);

  useEffect(() => {
    dispatch(
      getNotificationsRequest({
        items: 10,
        page: 1,
      })
    );
  }, [lang]);

  useEffect(() => {
    return () => {
      dispatch(
        getNotificationsResponse({
          list: [],
          meta: {},
          clear: true,
        })
      );
    };
  }, []);

  const handleNotificationSeen = (e, id) => {
    e.stopPropagation();
    setNotificationSeen(id);
  };

  return (
    <div className="notifications-page">
      <div className="page-title-margin mb-4">
        <Typography
            variant="h4"
            sx={{ flexGrow: 1 }}
            className={`page-title flex-grow-0 ${lang === 'en' ? 'me-3' : 'ms-3'}`}>
            {notifications.title}
        </Typography>
      </div>
      <div className="">
        {!notificationsList?.length ? (
          <EmptyState
            subTitle={notifications.noNotifications}
            showActionButton={false}
          />
        ) : (
          <>
            <div className="notifications-list">
              {notificationsList?.map(
                (
                  {
                    id,
                    title,
                    body,
                    created_at,
                    is_seen,
                    click_action,
                    action_id,
                  },
                  index
                ) => (
                  <div
                    className={`notification-item pointer p-4 bg-white rounded-4 mb-3 ${
                      index > 0 && "pt-4"
                    } pb-2 d-flex gap-2 ${
                      is_seen ? "seen-notification" : "unseen-notification"
                    }`}
                    key={index}
                    onClick={() => {
                      handleNotificationsClickActions({
                        id,
                        click_action,
                        action_id,
                        is_seen,
                      });
                    }}
                  >
                    <div className={`fsize-16 ${
                            is_seen ? "font-medium" : "font-bold"
                          }`}>
                      {click_action === "open_branch" 
                        ? (<Storefront className="text-secondary"/>)
                        : click_action === "view_appointment"
                        ? (<CalendarMonthTwoTone className="text-secondary"/>)
                        : (<LiveHelpOutlined className="text-secondary"/>)
                      }
                    </div>
                    <div className=" mt-1 ">
                      <p className={`notification-content mb-3`}>
                        <span
                          className={`d-block fsize-16 mb-2 ${
                            is_seen ? "font-medium" : "font-bold"
                          }`}
                        >
                          {title}
                        </span>
                        <span className="d-block fsize-14">{body}</span>
                        <span className="adorment-notifaction">
                          {!is_seen ? (
                            <span className="adorment-notifaction">
                              <NotifcationTooltip
                                title={notifications.markAsRead}
                              >
                                <FiberManualRecord
                                  className="seen-adorment"
                                  onClick={(e) => {
                                    handleNotificationSeen(e, id);
                                  }}
                                />
                              </NotifcationTooltip>
                            </span>
                          ) : null}
                        </span>
                      </p>
                      <p className="notification-date fsize-12">
                        {formatDate(created_at, "DD MMM YYYY",lang)}
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
              {(notificationsMeta?.count && notificationsMeta?.pages > 1  && page < notificationsMeta?.pages) && (
                <div className="d-flex justify-content-center mt-2 mx-3 fweight-500 fsize-16">
                  <u
                    className="text-primary pointer"
                    onClick={()=>{
                      setPage(page+1)
                    }}
                  >
                    {notifications.seeMore}
                  </u>
                    </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default Notifications;