import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker as TimePickerMUI } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

const TimePicker = ({ label, timeSlot, onChange, className }) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (timeSlot) {
      const formattedValue = dayjs(timeSlot, "HH:mm");
      setValue(formattedValue);
    }
  }, [timeSlot]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePickerMUI
        label={label}
        value={value || timeSlot}
        onChange={(newValue) => {
          let localeTimeFormat = new Date(newValue?.$d)?.toLocaleTimeString('en-GB'); 
          setValue(newValue);
          onChange(localeTimeFormat);
        }}
        className={className}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};
export default TimePicker;

TimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  timeSlot: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
