import {
    GET_PAYMENT_TRANSACTIONS_RESPONSE,
} from './types'

const INITIAL_STATE = {
    list: [],
    meta: {},
}

const paymentTransactions = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PAYMENT_TRANSACTIONS_RESPONSE:
            return {
                ...state,
                list: action.payload.list,
                meta: action.payload.meta,
            }
        default:
            return state;
    }
};

export default paymentTransactions;