export const acceptedImagesFilesTypes = ['image/jpeg', 'image/png', 'image/bmp', 'image/jpg'];

export const acceptedImagesFilesMaxSize = 2097152;

export const providerStatusList = {
  active: 'active',
  blocked: 'blocked',
  blockSuspended: 'block_suspended',
  deactivated: 'deactivated',
  deactivationScheduled: 'deactivation_scheduled',
  suspended: 'suspended',
};

export const providerStatusChangeActions = {
  activate: 'activate',
  block: 'block',
  deactivateImmediately: 'deactivate-immediately',
  deactivateLater: 'deactivate-later',
};

export const branchStatusList = {
  active: 'active',
  unpublished: 'unpublished',
  deactivated: 'deactivated',
  requestedPublishing: 'requested_publishing',
  deactivationScheduled:'deactivation_scheduled',
  suspended: 'suspended',
};

export const branchStatusChangeActions = {
  activate: 'activate',
  deactivateImmediately: 'deactivate-immediately',
  deactivateLater: 'deactivate-later',
};

export const Locations = {
  atHome: `At customer's place`,
  atBranch: 'At branch',
};

export const LocationsAr = {
  atHome: `في مكان العميل`,
  atBranch: 'في الفرع',
};

export const weekDays = {
  sat: 'Saturday',
  sun: 'Sunday',
  mon: 'Monday',
  tus: 'Tuesday',
  wed: 'Wednesday',
  thurs: 'Thursday',
  fri: 'Friday',
};

export const weekDaysAr = {
  sat: 'السبت',
  sun: 'الاحد',
  mon: 'الاثنين',
  tus: 'الثلاثاء',
  wed: 'الاربعاء',
  thurs: 'الخميس',
  fri: 'الجمعة',
};

export const genderList = {
  male: 'Male',
  female: 'Female',
  bothGender: 'Both genders',
};

export const genderListAr = {
  male: 'ذكر',
  female: 'أنثى',
  bothGender: 'كلا الجنسين',
};

export const paymentMethodsList = {
  online: 'online',
  cash: 'cash',
  both: 'both_methods',
};

export const paymentMethodsListAr = {
  online: 'الدفع الالكتروني',
  cash: 'الدفع نقدا',
  both: 'الدفع الالكتروني والدفع نقدا',
};

export const employeeStatusList = {
  active: 'active',
  deactivated: 'deactivated',
  suspended: 'deactivation_scheduled',
};

export const employeeStatusChangeActions = {
  activate: 'activate',
  deactivateImmediately: 'deactivate-immediately',
  deactivateLater: 'deactivate-later',
}

export const bookingsStatusList = {
  upcoming: 'Upcoming',
  ongoing: 'Ongoing',
  pendingPayment: 'Pending Payment',
  completed: 'Completed',
  canceled: 'Cancelled',
};

export const transactionsStatusList = {
  cash:'Cash',
  online:'Online',
  resolvedByAdmin:'Resolved by admin',
};
