const tempPassword = {
  title: "Temporary password",
  subTitle:
    "Please check your email inbox, we have sent you a temporary password",
  labels: {
    tempPassword: "Temporary password",
  },
  placeholders: {
    tempPassword: "Enter your temporary password",
  },
  actionBTN: "Send",
};

export default tempPassword;
