import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TableCell, TableRow, Typography } from '@mui/material';

import Table from '../../components/Table';
import Modal from '../../components/Modal';
import EmptyState from '../../components/EmptyState';
import Pagination from '../../components/Pagination';
import SearchInput from '../../components/SearchInput';
import FilterLabel from '../../components/Filter/FilterLabel';
import FilterInput from '../../components/Filter/FilterInput';
import Tooltip from '../../components/Tooltip';
import DatePicker from '../../components/DatePicker';
import RadioButtonsGroup from '../../components/RadioButton';
import { ROUTE_PATHS } from '../../utils/RoutesPaths';
import { DeleteBgIcon, EditBgIcon, ViewBgIcon, EditIcon } from '../../utils/Icons';
import { employeeStatusList, employeeStatusChangeActions } from '../../utils/Constants';
import { renderTextEllipsis, setPageMainTitle, textContainOnlySpaces } from '../../utils/Helpers';
import { renderFormatedDate } from '../../utils/DateHelpers';
import messages from '../../assets/locale/messages';
import { 
  deleteEmployeeRequest,
  getEmployeesRequest,
  getEmployeesResponse,
  getEditEmployeeStatusRequestStatus, 
  editEmployeeRequest
} from '../../store/Employees/actions';
import {
  getBranchesLookUpRequest,
  getBranchesLookUpResponse,
  getServicesLookUpRequest,
  getServicesLookUpResponse,
} from '../../store/LookUps/actions';
import './Employees.scss';

const Employees = () => {
  const lang = useSelector((state) => state.locale.lang);
  const isLoading = useSelector((state) => state.loader);
  const list = useSelector((state) => state.employees.list);
  const meta = useSelector((state) => state.employees.meta);
  const branchesList = useSelector((state) => state.lookUps.branches);
  const servicesList = useSelector((state) => state.lookUps.services);
  const editEmployeeStatusRequestStatus = useSelector((state) => state.employees.editEmployeeStatusRequestStatus);

  const history = useHistory();
  const dispatch = useDispatch();

  const { shared, branches, pagesTitles, employees, serviceProviders } = messages[lang];

  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [servicesOptions, setServicesOptions] = useState([]);
  const [filterServicesValue, setFilterServicesValue] = useState(null);
  const [filterEmployeeStatus, setFilterEmployeeStatus] = useState(null);
  const [statusChanged, setStatusChanged] = useState(false);
  const [filterBranchValue, setFilterBranchValue] = useState(null);
  const [branchOptions, setBranchOptions] = useState([]);
  const [filterLocationValue, setFilterLocationValue] = useState(null);
  const [deleteEmployeeId, setDeleteEmployeeId] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [changedStatusValue, setChangedStatusValue] = useState(null);
  const [susbendedAt, setSusbendedAt] = useState(null);
  const [openSusbendByTime, setOpenSusbendByTime] = useState(false);
  const [invalidDeactivatingDate, setInvalidDeactivatingDate] = useState(false);
  const [locationOptions, setLocationOptions] = useState([
    {
      id: 1,
      label: branches.list.filter.filtersPlaceHolders.AtHome,
      value: 'home',
    },
    {
      id: 2,
      label: branches.list.filter.filtersPlaceHolders.AtBranch,
      value: 'in_place',
    },
    {
      id: 3,
      label: branches.list.filter.filtersPlaceHolders.both,
      value: 'both',
    },
  ]);

  const editStatusActions = [
    {
      id: 0,
      value: employeeStatusChangeActions.activate,
      label: serviceProviders.list.statusActions.activate,
    },
    {
      id: 2,
      value: employeeStatusChangeActions.deactivateImmediately,
      label: serviceProviders.list.statusActions.deactivateNow,
    },
    {
      id: 3,
      value: employeeStatusChangeActions.deactivateLater,
      label: serviceProviders.list.statusActions.deacivateLater,
    }
  ];

  const employeesStatus = useMemo(
    () => ({
      active: 'active',
      deactivated: 'deactivated',
      suspended: 'deactivation_scheduled',
      blocked:'blocked'
    }),
    []
  );
  const providerStatusOptions = useMemo(() => {
    return [
      {
        id: employeeStatusList.active,
        label: employees.list.statusCases.active,
      },
      {
        id: employeeStatusList.deactivated,
        label: employees.list.statusCases.deactivated,
      },
      {
        id: employeeStatusList.suspended,
        label: employees.list.statusCases.suspended,
      }
    ];
  }, [lang]);

  useEffect(() => {
    if(editEmployeeStatusRequestStatus){
      getEmployees(
        page,
        searchValue,
        filterBranchValue?.id,
        filterEmployeeStatus?.id,
        filterLocationValue?.value,
        filterServicesValue?.id
      );
    }
  }, [editEmployeeStatusRequestStatus]);

  useEffect(() => {
    return () => {
      dispatch(
        getEmployeesResponse({
          list: [],
          meta: null,
        })
      );
      dispatch(getBranchesLookUpResponse([]));
      dispatch(getServicesLookUpResponse([]));
    };
  }, []);

  useEffect(() => {
    setPageMainTitle('employeesManagement');
    getBranchesLookup();
    getServicesLookup();
  }, []);

  useEffect(() => {
    getEmployees(
      page,
      searchValue,
      filterBranchValue?.id,
      filterEmployeeStatus?.id,
      filterLocationValue?.value,
      filterServicesValue?.id
    );
  }, [page]);

  useEffect(() => {
    if ((searchValue.length >= 3 || searchValue.length === 0) && !textContainOnlySpaces(searchValue)) {
      setPage(1);
      getEmployees(
        1,
        searchValue,
        filterBranchValue?.id,
        filterEmployeeStatus?.id,
        filterLocationValue?.value,
        filterServicesValue?.id
      );
    }
  }, [searchValue]);

  useEffect(() => {
    setLocationOptions([
      {
        id: 1,
        label: branches.list.filter.filtersPlaceHolders.AtHome,
        value: 'home',
      },
      {
        id: 2,
        label: branches.list.filter.filtersPlaceHolders.AtBranch,
        value: 'in_place',
      },
      {
        id: 3,
        label: branches.list.filter.filtersPlaceHolders.both,
        value: 'both',
      },
    ]);
  }, [lang]);

  useEffect(() => {
    setServicesOptions(
      servicesList?.map((option) => ({
        id: option.id,
        label: lang === 'en' ? option.title_en : option.title_ar,
      }))
    );
  }, [lang, servicesList]);

  useEffect(() => {
    if (branchesList && branchesList?.length) {
      setBranchOptions(
        branchesList?.map(({ id, name_en, name_ar }) => ({
          id: id,
          label: lang === 'en' ? name_en : name_ar,
        }))
      );
    }
  }, [lang, branchesList]);

  useEffect(() => {
    setPage(1);
    getEmployees(
      1,
      searchValue,
      filterBranchValue?.id,
      filterEmployeeStatus?.id,
      filterLocationValue?.value,
      filterServicesValue?.id
    );
  }, [filterBranchValue, filterEmployeeStatus, filterLocationValue, statusChanged, filterServicesValue]);

  useEffect(() => {
    if (filterServicesValue)
      setFilterServicesValue(servicesOptions.find((option) => option.id === filterServicesValue.id));
    if (filterLocationValue)
      setFilterLocationValue(locationOptions.find((option) => option.id === filterLocationValue.id));
    if (filterEmployeeStatus) {
      setFilterEmployeeStatus(providerStatusOptions.find((option) => option.id === filterEmployeeStatus.id));
      setStatusChanged(false);
    }
    if (filterBranchValue) {
      setFilterBranchValue(branchOptions.find((option) => option.id === filterBranchValue.id));
    }
  }, [lang, servicesOptions, locationOptions, providerStatusOptions, branchOptions]);

  const getEmployees = (pageNo, search, branchId, status, location, service) => {
    dispatch(
      getEmployeesRequest({
        page: pageNo,
        items: 10,
        query: search,
        branch_id: branchId,
        status: status,
        offered_location: location,
        service_ids: service,
      })
    );
  };

  const getBranchesLookup = () => {
    dispatch(
      getBranchesLookUpRequest({
        page: -1,
      })
    );
  };

  const getServicesLookup = () => {
    dispatch(
      getServicesLookUpRequest({
        page: -1,
      })
    );
  };

  const handleEditEmployee = (id) => {
    history.push(ROUTE_PATHS.editEmployees.replace(':id', id));
  };

  const handleViewEmployee = (id) => {
    history.push(ROUTE_PATHS.viewEmployee.replace(':id', id));
  };

  const handleDeleteClick = (id) => {
    setDeleteEmployeeId(id);
    setOpenDeleteConfirm(true);
  };

  const handleDeleteEmployee = () => {
    dispatch(
      deleteEmployeeRequest({
        id: deleteEmployeeId,
        currentPage: meta.currentPage,
      })
    );
    setOpenDeleteConfirm(false);
    setDeleteEmployeeId(null);
  };

  const handleCloseDeleteConfirmModal = () => {
    setOpenDeleteConfirm(false);
  };

  const renderStatusCase = (row) => {
    if (row.status === employeeStatusList.suspended && row.deactivation_date) {
      return (
        <Tooltip
          title={`${employees.list.suspendedByDeactivateEmployee} ${renderFormatedDate(
            row.deactivation_date
          )}`}
          content={<span>{RenderTitle(row.status)}</span>}
          hasArrow
        />
      );
    } else {
      return <span>{RenderTitle(row.status)}</span>;
    }
  };

  const RenderTitle = (title) => {
    switch (title) {
      case employeesStatus.active:
        return `${employees.list.statusCases.active}`;
      case employeesStatus.deactivated:
        return `${employees.list.statusCases.deactivated}`;
      case employeesStatus.suspended:
        return `${employees.list.statusCases.suspended}`;
      case employeesStatus.blocked:
        return `${employees.list.statusCases.blocked}`;
      default:
        return `-`;
    }
  };

  const handleStatusClick = (employee) => {
    setSelectedEmployee(employee);
    setOpenStatusModal(true);
  };

  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
    setSusbendedAt(null);
    setSelectedEmployee(null);
    setChangedStatusValue(null);
    setOpenSusbendByTime(false);
  };

  const handleStatusList = (employee) => {
    let list;
    if (employee.status === employeeStatusList.active) {
      list = editStatusActions.filter((action) => action.value !== employeeStatusChangeActions.activate);
    } else if (employee.status === employeeStatusList.deactivated) {
      list = editStatusActions.filter(
        (action) =>
          action.value !== employeeStatusChangeActions.deactivateImmediately &&
          action.value !== employeeStatusChangeActions.deactivateLater
      );
    } else if(employee.status === employeeStatusList.suspended){
      list = editStatusActions.filter((action) =>action.value !== employeeStatusChangeActions.deactivateLater);
    }
    return list;
  };

  const handleChangeStatus = () => {
    let employeeData;
    if (changedStatusValue) {
      switch (changedStatusValue) {
        case employeeStatusChangeActions.activate:
          employeeData = { status: employeeStatusList.active };
          break;
        case employeeStatusChangeActions.deactivateImmediately:
          employeeData = { status: employeeStatusList.deactivated };
          break;
        default:
          employeeData = { deactivation_date: susbendedAt };
          break;
      }
    }
    dispatch(editEmployeeRequest({
      params:{
        id: selectedEmployee?.id,
        data: employeeData,
      },
      from: 'list'
    }));
    dispatch(getEditEmployeeStatusRequestStatus(null));
    handleCloseStatusModal();
  };

  const renderHeaderSection = (showActions) => (
    <>
      <div className="employees-header d-flex justify-content-between mb-4">
        <div className="title-search d-flex flex-column flex-md-row">
          <Typography variant="h4" sx={{ flexGrow: 1 }} className={`page-title ${lang === 'en' ? 'me-5' : 'ms-5'}`}>
            {pagesTitles.employees}
          </Typography>
          {showActions && (
            <SearchInput
              name="search"
              value={searchValue}
              placeholder={employees.list.search}
              onChange={(value) => {
                setSearchValue(value);
              }}
            />
          )}
        </div>
      </div>
      {showActions && (
        <div className="filter-label-wrapper d-flex align-items-center mb-4 w-50 gap-2 flex-wrap flex-md-nowrap w-100">
          <FilterLabel />
          <FilterInput
            placeholder={employees.list.filter.filtersPlaceHolders.filterByService}
            options={servicesOptions}
            value={filterServicesValue}
            name="Service Filter"
            onChange={(value) => {
              setFilterServicesValue(value);
            }}
            filterWrapperClass="my-3 my-md-0 w-100"
          />
          <FilterInput
            placeholder={employees.list.filter.filtersPlaceHolders.filterByEmployeeStatus}
            options={providerStatusOptions}
            value={filterEmployeeStatus}
            name="provider status Filter"
            onChange={(value) => {
              setStatusChanged(true);
              setFilterEmployeeStatus(value);
            }}
            filterWrapperClass="my-3 my-md-0 w-100"
          />
          <FilterInput
            placeholder={employees.list.filter.filtersPlaceHolders.filterByLocations}
            options={locationOptions}
            value={filterLocationValue}
            name="offered locations Filter"
            onChange={(value) => {
              setFilterLocationValue(value);
            }}
            filterWrapperClass="my-0 w-100"
          />
          <FilterInput
            placeholder={employees.list.filter.filtersPlaceHolders.filterByBranchName}
            options={branchOptions}
            value={filterBranchValue}
            name="Branch Name Filter"
            onChange={(value) => {
              setFilterBranchValue(value);
            }}
            filterWrapperClass="my-0 w-100"
          />
        </div>
      )}
    </>
  );

  const employeesList = () => {
    if (
      !isLoading &&
      list?.length === 0 &&
      !searchValue &&
      filterBranchValue === null &&
      filterEmployeeStatus === null &&
      filterLocationValue === null &&
      filterServicesValue === null
    ) {
      return (
        <>
          {renderHeaderSection(false)}
          <div className="bg-white rounded-4 employees-container d-flex justify-content-center align-items-center flex-column">
            <EmptyState
              subTitle={shared.noData}
              description={employees.list.noDataDescription}
              showActionButton={false}
            />
          </div>
        </>
      );
    } else if (
      !isLoading &&
      (searchValue ||
        filterBranchValue !== null ||
        filterEmployeeStatus !== null ||
        filterLocationValue !== null ||
        filterServicesValue !== null) &&
      list?.length === 0
    ) {
      return (
        <>
          {renderHeaderSection(true)}
          <div className="bg-white rounded-4 employees-container d-flex justify-content-center align-items-center flex-column">
            <EmptyState subTitle={shared.noSearch} showActionButton={false} /> 
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            {renderHeaderSection(true)}
            <div className="bg-white rounded-4 employees-table-list">
              <Table
                className="rounded-4"
                headlines={[
                  employees.list.employeeId,
                  employees.list.name,
                  employees.list.phoneNumber,
                  employees.list.branch,
                  employees.list.status,
                ]}
                hasActions={true}
                rows={list?.map((row, i) => (
                  <TableRow key={row?.id || i}>
                    <TableCell className="font-regular text-dark-blue fsize-14 ">
                      {renderTextEllipsis(row.employee_number || '-')} 
                    </TableCell>
                    <TableCell className="font-regular text-dark-blue fsize-14 ">
                      {lang === 'en'
                        ? renderTextEllipsis(row.first_name_en + ' ' + row.last_name_en)
                        : renderTextEllipsis(row.first_name_ar + ' ' + row.last_name_ar)}
                    </TableCell>
                    <TableCell className="font-regular text-dark-blue fsize-14 ">{row.phone_number}</TableCell>
                    <TableCell className="font-regular text-dark-blue fsize-14 ">
                      {row.branch ? (lang === 'en' ? row.branch.name_en : row.branch.name_ar) : '-'}
                    </TableCell>
                    <TableCell className="font-regular text-dark-blue fsize-14 ">
                      <div
                        className={`status status-${row.status} px-3 py-2 border-radius-10 text-center position-relative`}>
                        <span className="d-inline-block me-2">{renderStatusCase(row)}</span>
                        {(row.status != null && row.status != 'blocked' )&& (
                          <EditIcon
                            className={`pointer position-absolute svg-${row.status}`}
                            onClick={() => {
                              handleStatusClick(row);
                            }}
                          />
                        )}
                      </div>
                    </TableCell>

                    <TableCell className="font-regular text-dark-blue fsize-14">
                      <div className="table-icons-container gap-4">
                        <EditBgIcon className="pointer" onClick={() => handleEditEmployee(row.id)} />
                        <ViewBgIcon className="pointer" onClick={() => handleViewEmployee(row.id)} />
                        <DeleteBgIcon className="pointer" onClick={() => handleDeleteClick(row.id)} />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              />
              <div className="d-flex justify-content-center py-3">
                {meta?.pages > 1 && (
                  <Pagination
                    count={meta?.pages}
                    page={meta?.currentPage || page}
                    handleChange={(event, page) => {
                      setPage(page);
                    }}
                    defaultPage={1}
                  />
                )}
              </div>
            </div>
          </div>
          <Modal
            open={openDeleteConfirm}
            handleClose={handleCloseDeleteConfirmModal}
            title={employees.deleteEmployee.title}
            contentClass="mb-5 pb-2 text-primary"
            content={<span>
                        {employees.deleteEmployee.deleteConfirmationMsg}
                        <div className="my-3">
                          <span className="text-secondary">{employees.deleteEmployee.note}: </span>
                          {employees.deleteEmployee.validationNote}
                        </div>
                     </span>
                    }
            confirmBtnTxt={shared.confirm}
            isContentText={false}
            maxWidth="xs"
            cancelBtnTxt={shared.cancel}
            handleConfirm={handleDeleteEmployee}
            handleCancel={handleCloseDeleteConfirmModal}
            modalClass="delete-employee-modal"
            actionsClass="container px-4"
            confirmBtnClass="btn-secondary fsize-14 text-white py-2 "
            cancelBtnClass="fsize-14 py-2"
          />
                    <Modal
            open={openStatusModal}
            handleClose={handleCloseStatusModal}
            title={serviceProviders.list.editStatus}
            contentClass="mb-3 text-primary"
            content={
              <>
                <RadioButtonsGroup
                  isLabel={false}
                  defaultValue
                  name="providerStatus"
                  list={handleStatusList(selectedEmployee ?? {})}
                  onChange={(e) => {
                    if(e.target.value==='deactivate-later'){
                      setOpenSusbendByTime(true)
                    }else{
                      setOpenSusbendByTime(false)
                    }
                    setChangedStatusValue(e.target.value)}}
                />
                {openSusbendByTime && (
                  <div className="mt-3">
                    <DatePicker
                      value={susbendedAt}
                      onChange={(dateValue) => {
                        setSusbendedAt(dateValue);
                      }}
                      name={'susbended at'}
                      disablePast={true}
                      label={serviceProviders.list.statusActions.dateLabel}
                      disableMinToday={true}
                      placement="right"
                      setInvalidDeactivatingDate={setInvalidDeactivatingDate}
                    />
                  </div>
                )}
              </>
            }
            isContentText={false}
            confirmBtnTxt={shared.save}
            maxWidth="xs"
            cancelBtnTxt={shared.cancel}
            handleConfirm={handleChangeStatus}
            handleCancel={handleCloseStatusModal}
            modalClass="status-modal"
            actionsClass="container px-4"
            confirmBtnClass="btn-secondary fsize-14 text-white py-2 "
            cancelBtnClass="fsize-14 py-2"
            disableActionBtn={changedStatusValue ==='deactivate-later' 
                                ? !(susbendedAt && changedStatusValue) || invalidDeactivatingDate 
                                : !changedStatusValue}
          />
        </>
      );
    }
  };

  return <div className="employees-page">{employeesList()}</div>;
};

export default Employees;
