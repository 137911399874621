import {
  GET_BRANCHES_LOOKUP_REQUEST,
  GET_BRANCHES_LOOKUP_RESPONSE,
  GET_CATEGORIES_LOOKUP_REQUEST,
  GET_CATEGORIES_LOOKUP_RESPONSE,
  GET_CURRANCIES_LOOKUP_REQUEST,
  GET_CURRANCIES_LOOKUP_RESPONSE,
  GET_EMPLOYEES_LOOKUP_REQUEST,
  GET_EMPLOYEES_LOOKUP_RESPONSE,
  GET_PROVIDERS_LOOKUP_REQUEST,
  GET_PROVIDERS_LOOKUP_RESPONSE,
  GET_SERVICES_LOOKUP_REQUEST,
  GET_SERVICES_LOOKUP_RESPONSE,
  GET_SPECIALTIES_LOOKUP_REQUEST,
  GET_SPECIALTIES_LOOKUP_RESPONSE,
  GET_BRANCH_SPECIALTIES_LOOKUP_REQUEST,
  GET_BRANCH_SPECIALTIES_LOOKUP_RESPONSE,
} from './types';

// list
export const getCurranciesLookUpRequest = (payload) => ({
  type: GET_CURRANCIES_LOOKUP_REQUEST,
  payload,
});

export const getCurranciesLookUpResponse = (payload) => ({
  type: GET_CURRANCIES_LOOKUP_RESPONSE,
  payload,
});
export const getProvidersLookUpRequest = (payload) => ({
  type: GET_PROVIDERS_LOOKUP_REQUEST,
  payload,
});

export const getProvidersLookUpResponse = (payload) => ({
  type: GET_PROVIDERS_LOOKUP_RESPONSE,
  payload,
});
export const getServicesLookUpRequest = (payload) => ({
  type: GET_SERVICES_LOOKUP_REQUEST,
  payload,
});

export const getServicesLookUpResponse = (payload) => ({
  type: GET_SERVICES_LOOKUP_RESPONSE,
  payload,
});
export const getSpecialtiesLookUpRequest = (payload) => ({
  type: GET_SPECIALTIES_LOOKUP_REQUEST,
  payload,
});

export const getSpecialtiesLookUpResponse = (payload) => ({
  type: GET_SPECIALTIES_LOOKUP_RESPONSE,
  payload,
});

export const getBranchSpecialtiesLookUpRequest = (payload) => ({
  type: GET_BRANCH_SPECIALTIES_LOOKUP_REQUEST,
  payload,
});

export const getBranchSpecialtiesLookUpResponse = (payload) => ({
  type: GET_BRANCH_SPECIALTIES_LOOKUP_RESPONSE,
  payload,
});

export const getEmployeesLookUpRequest = (payload) => ({
  type: GET_EMPLOYEES_LOOKUP_REQUEST,
  payload,
});

export const getEmployeesLookUpResponse = (payload) => ({
  type: GET_EMPLOYEES_LOOKUP_RESPONSE,
  payload,
});
export const getCategoriesLookUpRequest = (payload) => ({
  type: GET_CATEGORIES_LOOKUP_REQUEST,
  payload,
});

export const getCategoriesLookUpResponse = (payload) => ({
  type: GET_CATEGORIES_LOOKUP_RESPONSE,
  payload,
});
export const getBranchesLookUpRequest = (payload) => ({
  type: GET_BRANCHES_LOOKUP_REQUEST,
  payload,
});

export const getBranchesLookUpResponse = (payload) => ({
  type: GET_BRANCHES_LOOKUP_RESPONSE,
  payload,
});
