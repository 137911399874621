const login = {
  title: "Welcome",
  title2: "to Mimar’s service provider portal",
  subTitle: "Sign",
  subTitle2: "in",
  labels: {
    email: "Email",
    password: "Password",
    remember: "Remember me next time",
    forgetPassword: "Forgot password?",
    signin: "Sign in",
  },
  placeholders: {
    email: "Enter your email",
    password: "Enter your password",
  },
};

export default login;
