import { axiosInstance } from './';

const login = async (payload) => await axiosInstance.post('/sessions', payload);

const logout = async (payload) => await axiosInstance.delete('/sessions', { data: payload });

const changePassword = async (payload) =>
  await axiosInstance.post('/service_provider_managers/change_password', payload);

const forgetPassword = async (payload) =>
  await axiosInstance.post('/service_provider_managers/forget_password', payload);

export { login, logout, changePassword, forgetPassword };
