import { put, call, takeEvery } from 'redux-saga/effects';
import * as apis from '../../network/apis/employees';
import * as BranchApis from '../../network/apis/branches';
import * as types from './types';
import * as actions from './actions';
import { showErrorMsg, showSuccessMsg } from '../../utils/Helpers';
import { ROUTE_PATHS } from '../../utils/RoutesPaths';
import history from '../../routes/History';

function* getEmployeesListSaga({ payload }) {
  try {
    const response = yield call(apis.getEmployeesList, payload);
    yield put(
      actions.getEmployeesResponse({
        list: response.data.employees,
        meta: {
          currentPage: +response.headers['current-page'],
          count: +response.headers['total-count'],
          pages: +response.headers['total-pages'],
        },
      })
    );
    if (+response.headers['current-page'] > +response.headers['total-pages']) {
      yield put(
        actions.getEmployeesRequest({
          ...payload,
          page: +response.headers['total-pages'],
        })
      );
    }
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* editEmployeeSaga({ payload }) {
  try {
    yield call(apis.editEmployee, payload.params);
    yield showSuccessMsg('editedSuccessfully');
    if(payload.from === 'editFrom'){
      history.push(ROUTE_PATHS.viewEmployee.replace(':id', payload.params.id));
    }
    yield put(actions.getEditEmployeeStatusRequestStatus(true));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
    yield put(actions.getEditEmployeeStatusRequestStatus(error.response?.data?.success));
  }
}

function* getEmployeeSaga({ payload }) {
  try {
    const response = yield call(apis.getEmployeeDetails, payload);
    yield put(
      actions.getEmployeeDetailsResponse({
        employeeDetails: response.data.employee,
      })
    );
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* deleteEmployeeSaga({ payload }) {
  try {
    yield call(apis.deleteEmployee, payload);
    yield showSuccessMsg('deletedSuccessfully');
    yield put(
      actions.getEmployeesRequest({
        items: 10,
        page: payload.currentPage,
      })
    );
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

// helper
function* getBranchDetailsByEmpIdSaga({ payload }) {
  try {
    const response = yield call(BranchApis.getBranch, payload);
    yield put(
      actions.getBranchDetailsByEmpIdResponse({
        relatedBranchDetails: response.data.branch,
      })
    );
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* getBranchServicesByBranchIdSaga({ payload }) {
  try {
    const response = yield call(BranchApis.getBranchServicesList, payload);
    yield put(
      actions.getBranchservicesByBranchIdResponse({
        branch_services: response.data.branch_services,
      })
    );
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

export default function* EmployeesSagas() {
  yield takeEvery(types.GET_EMPLOYEES_REQUEST, getEmployeesListSaga);
  yield takeEvery(types.GET_EMPLOYEE_DETAILS_REQUEST, getEmployeeSaga);
  yield takeEvery(types.EDIT_EMPLOYEE_REQUEST, editEmployeeSaga);
  yield takeEvery(types.DELETE_EMPLOYEE_REQUEST, deleteEmployeeSaga);
  yield takeEvery(types.GET_BRANCH_DETAILS_BY_EMP_ID_REQUEST, getBranchDetailsByEmpIdSaga);
  yield takeEvery(types.GET_BRANCH_SERVICES_BY_BRANCH_ID_REQUEST, getBranchServicesByBranchIdSaga);
}
