import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  palette: {
    primary: {
      main: "#3F4752",
    },
    secondary: {
      main: "#D19985",
    },
    text: {
      primary: "#3F4752",
      secondary: "#ffffff",
      disabled:'#888D94'
    },
    background: {
      // default: "linear-gradient(to right,  #3F4752, #888D94)",
      // secondary: "linear-gradient(to right,#D19985, #FFBEA3)",
    },
    action: {
      disabledBackground: "#e95b25",
    },
    white: "#F8F9FC",
    black: {
      main: "#3F4752",
      light: "#888D94",
    },
    divider: "#888D94",
  },
  tooltip: {
    backgroundGrey: "#f2f2f3",
    backgroundBlue: "#003857",
    orangeColor: "#e95b25",
    colorBlack: "#171717",
  },
  typography: {
    fontFamily: "Poppins-Regular",
    h1: {
      fontFamily: "Poppins-Bold",
    },
    h2: {
      fontFamily: "Poppins-Bold",
    },
    h3: {
      fontFamily: "Poppins-Bold",
    },
    h4: {
      fontFamily: "Poppins-Bold",
    },
    h5: {
      fontFamily: "Poppins-Bold",
    },
    h6: {
      fontFamily: "Poppins-Bold",
    },
  },
});

export default Theme;
