import {
        GET_BOOKINGS_RESPONSE,
        GET_BOOKING_RESPONSE,
        GET_BOOKING_CANCELATION_REASONS_RESPONSE,
        GET_BOOKING_SERVICE_CANCELATION_REASONS_RESPONSE,
        GET_OTHER_SERVICES_RESPONSE,
        GET_REQUEST_STATUS,
        } from './types'

const INITIAL_STATE = {
    list: [],
    meta: {},
    bookingDetails:{},
    bookingCancelationReasons:[],
    bookingServiceCancelationReasons:[],
    otherServices:{
        list:[],
        meta:{}
    },
    requestStatus: null,
  };

const bookings = (state=INITIAL_STATE, action) => {
    switch(action.type){
        case GET_BOOKINGS_RESPONSE :
            return {
                ...state,
                list: action.payload.list,
                meta: action.payload.meta,
            };
        case GET_BOOKING_RESPONSE :
            return{
                ...state,
                bookingDetails: action.payload.bookingDetails
            };
        case GET_BOOKING_CANCELATION_REASONS_RESPONSE:
            return{
                ...state,
                bookingCancelationReasons:action.payload
            }
        case GET_BOOKING_SERVICE_CANCELATION_REASONS_RESPONSE:
            return {
                ...state,
                bookingServiceCancelationReasons:action.payload
            };
        case GET_OTHER_SERVICES_RESPONSE:{
            let newServices = action.payload.list.filter(service => {
                if(
                    !state.otherServices.list.find(
                        (oldItem) => oldItem.id == service.id
                    )
                ){
                    return service;
                }
            })
            return {
                ...state,
                otherServices:{
                    list: action.payload.meta.currentPage == 1
                        ? action.payload.list
                        : [...state.otherServices.list, ...newServices],
                    meta: action.payload.meta,
                }
            };
        }
        case GET_REQUEST_STATUS:
            return {
                ...state,
                requestStatus: action.payload === null ? null : action.payload,
            };
        default : 
            return state    
    }
}

export default bookings;