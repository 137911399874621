import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Add, DeleteOutline, EditOutlined, SendOutlined, FlagOutlined, MoreHoriz } from '@mui/icons-material';

import {
  BranchDetailsTabIcon,
  BranchDetailsTabIconDone,
  EmployeesTabIcon,
  EmployeesTabInprogress,
  EmployeesTabDone,
  BranchDetailsTabInprogress,
  SpecialtiesServicesTabIcon,
  SpecialtiesServicesTabInprogress,
  SpecialtiesServicesTabDone,
  BookingsTabIcon,
  BookingsTabInprogressIcon,
  BookingsTabDoneIcon,
} from '../../../utils/Icons';
import { setPageMainTitle } from '../../../utils/Helpers';
import { ROUTE_PATHS } from '../../../utils/RoutesPaths';
import Stepper from '../../../components/Stepper';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import messages from '../../../assets/locale/messages';
import ViewBranchDetails from './ViewBranchDetails';
import ViewServicesDetails from './ViewServicesDetails';
import ViewEmployeesDetails from './ViewEmployeesDetails';
import ViewBookingsDetails from './ViewBookingsDetails';
import {
  addBranchResponse,
  deleteBranchRequest,
  editBranchStatusRequest,
  editBranchRequest,
  getBranchRequest,
} from '../../../store/Branches/actions';
import { getGoogleMapsPolygon, saveBranchData } from '../../../store/GoogleMapCoords/actions';
import { ReviewsTab } from '../../../utils/Icons/ReviewsTab';
import { ReviewsTabInprogress } from '../../../utils/Icons/ReviewsTabInprogress';
import ViewReviewsDetails from './ViewReviewsDetails';
import './ViewBranch.scss';

const ViewBranch = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const lang = useSelector((state) => state.locale.lang);
  const { pagesTitles, branches, shared } = messages[lang];
  const createdBranch = useSelector((state) => state.branches.addEditViewBranch.addedBranch);
  const branchData = useSelector((state) => state.branches.addEditViewBranch.branchDetails); // get by id
  const editRequestStatus = useSelector((state) => state.branches.addEditViewBranch.requestStatus);

  const [tabIndex, setTabIndex] = useState(0);
  const [btnClicked, setBtnClicked] = useState(null);
  const [renderList, setRenderList] = useState(false);
  const [renderSubTitle, setRenderSubTitle] = useState(null);
  const [pageTitle, setPageTitle] = useState('');
  const [renderServiceTitle, setRenderServiceTitle] = useState(null);
  const [showDeleteBranchModal, setShowDeleteBranchModal] = useState(false);
  const [showDeleteBranchEmployeeModal, setShowDeleteBranchEmployeeModal] = useState(false);
  const [showRejectionReason, setShowRejectionReason] = useState(false);

  const stepperItems = [
    {
      label: branches.viewBranch.firstTab,
      icon: [<BranchDetailsTabIcon />, <BranchDetailsTabInprogress />, <BranchDetailsTabIconDone />],
    },
    {
      label: branches.viewBranch.secondTab,
      icon: [<SpecialtiesServicesTabIcon />, <SpecialtiesServicesTabInprogress />, <SpecialtiesServicesTabDone />],
    },
    {
      label: branches.viewBranch.thirdTab,
      icon: [<EmployeesTabIcon />, <EmployeesTabInprogress />, <EmployeesTabDone />],
    },
    {
      label: branches.viewBranch.fourthTab,
      icon: [<BookingsTabIcon />, <BookingsTabInprogressIcon />, <BookingsTabDoneIcon />],
    },
    {
      label: branches.viewBranch.fifthTab,
      icon: [<ReviewsTab />, <ReviewsTabInprogress />, <EmployeesTabDone />],
    },
  ];

  useEffect(() => {
    setPageMainTitle('branchesManagement');
    setPageTitle(lang === 'en' ? branchData?.name_en : branchData?.name_ar);
    dispatch(getBranchRequest(id));
    dispatch(getGoogleMapsPolygon([]));
    dispatch(saveBranchData({}))
  }, []);

  useEffect(() => {
    setPageTitle(lang === 'en' ? branchData?.name_en : branchData?.name_ar);
  }, [lang, branchData]);

  useEffect(() => {
    if (props?.location?.state?.setTabIndex) {
      setTabIndex(props?.location?.state?.setTabIndex);
    }
  }, [props]);

  useEffect(() => {
    if (tabIndex !== 2) {
      setRenderSubTitle(null);
    }
  }, [tabIndex]);

  useEffect(() => {
    if (props?.location?.state?.setTabIndex) {
      setTabIndex(props?.location?.state?.setTabIndex);
    }
  }, [props]);

  useEffect(() => {
    if (editRequestStatus !== null) {
      if (editRequestStatus) {
        dispatch(addBranchResponse(null));
        dispatch(editBranchStatusRequest(null));
        dispatch(getBranchRequest(id));
      }
    }
  }, [editRequestStatus]);

  const onSubTitleChange = (title) => {
    setRenderList(false);
    setRenderSubTitle(title);
  };

  const handleCloseDeleteBranchModal = () => {
    setShowDeleteBranchModal(false);
  };

  const handleConfirmDeleteBranch = () => {
    dispatch(
      deleteBranchRequest({
        id,
      })
    );
    setShowDeleteBranchModal(false);
    setTimeout(() => {
      history.push(ROUTE_PATHS.branches);
    }, 500);
  };

  const handleCloseDeleteBranchEmployeeModal = () => {
    setShowDeleteBranchEmployeeModal(false);
  };

  const handleConfirmDeleteBranchEmployee = () => {
    // history.push(ROUTE_PATHS.viewServiceProvider.replace(':id', id));
    setShowDeleteBranchEmployeeModal(false);
  };

  const handleEditBranchData = () => {
    // history.push(ROUTE_PATHS.editBranch.replace(':id', id));
    history.push({
      pathname: ROUTE_PATHS.editBranch.replace(':id', branchData?.id),
      state: {
        editMood: true,
        branchDataToEdit: branchData,
        setPageTitle: lang === 'en' ? branchData?.name_en : branchData?.name_ar,
        setPageTitleAr: branchData?.name_ar,
        setPageTitleEn: branchData?.name_en,
        noTranslationForTitle: true,
        setTabIndex: (tabIndex === 3 || tabIndex === 4) ? 0 : tabIndex,
        cameFrom: ROUTE_PATHS.viewBranch.replace(':id', id),
      },
    });
  };

  const handlepublishRequest = () => {
    dispatch(
      editBranchRequest({
        id: branchData?.id,
        data: {
          status: 'requested_publishing',
        },
        requestType: 'requestSentSuccessfully', 
      })
    );
  };

  const renderRejectionReasonText = () => {
    if (branchData?.rejection_reason?.length >= 225) {
      const trimmedString = branchData?.rejection_reason.slice(0, 224);
      return trimmedString;
    }
  };

  return (
    <>
      <div className="add-branch-page">
        <div className="header d-flex flex-column flex-md-row mb-3">
          <Typography variant="h4" sx={{ flexGrow: 1 }} className={`page-title mb-4`}>
            <div className="d-flex flex-column flex-md-row">
              <div
                className={`${renderSubTitle !== null && 'pointer'}`}
                onClick={() => {
                  setBtnClicked('list');
                  setRenderSubTitle(null);
                  setRenderList(true);
                }}>
                {pageTitle}
              </div>
              {renderSubTitle !== null && <div> / {branches.addBranch.labels[renderSubTitle]}</div>}
              {renderServiceTitle !== null && renderSubTitle !== null && <div> / {renderServiceTitle}</div>}
            </div>
          </Typography>
          <div className="d-flex gap-2 buttons-contanier align-self-start">
            <Button
              onClick={() => {
                // setBtnClicked('add');
                // handleAddServicesToBranch();
                setShowDeleteBranchModal(true);
              }}
              color="info"
              outlined
              label={
                <span className="d-flex align-items-center">
                  <DeleteOutline fontSize="small" />
                  <span>{shared.delete}</span>
                </span>
              }
              className="btn-secondary"
              labelClass="fsize-16 btn-info-generate-password"
            />
            <Button
              onClick={() => {
                handleEditBranchData();
              }}
              type="button"
              label={
                <span className="d-flex align-items-center">
                  <EditOutlined fontSize="small" />
                  <span className="ps-1">{shared.edit}</span>
                </span>
              }
              labelClass="fsize-16 text-white py-1"
            />
            {branchData?.status === 'unpublished' && (
              <Button
                onClick={() => {
                  handlepublishRequest();
                }}
                type="button"
                label={
                  <span className="d-flex align-items-center gap-2">
                    <span className="ps-1">{branches.viewBranch.sendPublishRequest}</span>
                    <SendOutlined fontSize="medium" />
                  </span>
                }
                labelClass="fsize-16 text-white py-1"
              />
            )}
            {branchData?.status === 'requested_publishing' && (
              <Button
                type="button"
                label={
                  <span className="d-flex align-items-center gap-2">
                    <span className="ps-1">{branches.viewBranch.requestPublishing}</span>
                    <SendOutlined fontSize="medium" />
                  </span>
                }
                labelClass="fsize-16 text-white py-1"
                disabled
              />
            )}
            {(branchData?.status != 'unpublished' && branchData?.status != 'requested_publishing') &&(
              <Button
                disabled
                type="button"
                label={
                  <span className="d-flex align-items-center gap-2">
                    <span className="ps-1">{branches.viewBranch.published}</span>
                    <SendOutlined fontSize="medium" />
                  </span>
                }
                labelClass="fsize-16 text-white py-1"
              />
            )}
          </div>
        </div>
        {branchData?.status === 'unpublished' && branchData?.rejection_reason != null && (
          <div className="reject-reason-container px-3 py-1 mb-5">
            <div className="d-flex gap-1 fsize-20 fweight-500">
              <div>
                <FlagOutlined fontSize="fsize-18 fweight-500" />
              </div>
              <div className="mt-1">{branches.viewBranch.rejectionFlag}</div>
            </div>
            <div className="px-1 fsize-18 fweight-400">
              {branchData?.rejection_reason?.length <= 225 ? (
                <div className="fsize-18 fweight-400">{branchData?.rejection_reason}</div>
              ) : (
                <div className="fsize-18 fweight-400">
                  <span className="me-2">{renderRejectionReasonText()}</span>
                  <span className="fsize-18">
                    <MoreHoriz fontSize="fweight-500" />
                  </span>
                  <button
                    className="see-more-btn fsize-18 fweight-500"
                    onClick={() => {
                      setShowRejectionReason(true);
                    }}>
                    {branches.viewBranch.seeMore}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="add-branch-stepper">
          <Stepper
            steps={stepperItems}
            handleSelectStep={(index) => setTabIndex(index)}
            currentStep={tabIndex}
            preventGoingBack={false}
            haveBranchId={true}
            haveServices={branchData?.specialties?.length > 0}
            allTabsIsClicable={true}
          />
        </div>
        <div className="page-container mt-5">{tabIndex === 0 && <ViewBranchDetails />}</div>
        <div className="page-container mt-5">{tabIndex === 1 && <ViewServicesDetails />}</div>
        <div className="page-container mt-5">{tabIndex === 2 && <ViewEmployeesDetails renderList={renderList} />}</div>
        <div className="page-container mt-5">{tabIndex === 3 && <ViewBookingsDetails renderList={renderList} />}</div>
        <div className="page-container mt-5">{tabIndex === 4 && <ViewReviewsDetails />}</div>
      </div>
      <Modal
        open={showDeleteBranchModal}
        handleClose={handleCloseDeleteBranchModal}
        title={branches.deleteBranch.title}
        contentClass="mb-5 pb-2 text-primary"
        content={<span>{branches.deleteBranch.deleteConfirmationMsg}</span>}
        confirmBtnTxt={shared.confirm}
        maxWidth="xs"
        cancelBtnTxt={shared.cancel}
        isContentText={false}
        handleConfirm={handleConfirmDeleteBranch}
        handleCancel={handleCloseDeleteBranchModal}
        modalClass="delete-services-modal"
        actionsClass="container px-4"
        confirmBtnClass="btn-secondary fsize-14 text-white py-2 "
        cancelBtnClass="fsize-14 py-2"
      />
      <Modal
        open={showDeleteBranchEmployeeModal}
        handleClose={handleCloseDeleteBranchEmployeeModal}
        title={branches.deleteBranch.titleEmployee}
        contentClass="mb-5 pb-2 text-primary"
        content={<span>{branches.deleteBranch.deleteEmployeeConfirmationMsg}</span>}
        confirmBtnTxt={shared.confirm}
        maxWidth="xs"
        cancelBtnTxt={shared.cancel}
        isContentText={false}
        handleConfirm={handleConfirmDeleteBranchEmployee}
        handleCancel={handleCloseDeleteBranchEmployeeModal}
        modalClass="delete-services-modal"
        actionsClass="container px-4"
        confirmBtnClass="btn-secondary fsize-14 text-white py-2 "
        cancelBtnClass="fsize-14 py-2"
      />
      <Modal
        open={showRejectionReason}
        handleClose={() => {
          setShowRejectionReason(false);
        }}
        title={branches.viewBranch.rejectionReason}
        contentClass="mb-5 pb-2 text-primary"
        content={<span>{branchData?.rejection_reason}</span>}
        maxWidth="xs"
        isContentText={false}
      />
    </>
  );
};

export default ViewBranch;
