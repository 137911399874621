import S3 from "react-aws-s3";

import store from "../store";
import { showHideLoader } from "../store/Loader/actions";
import { showErrorMsg } from "../utils/Helpers";
import messages from "../assets/locale/messages";

window.Buffer = window.Buffer || require("buffer").Buffer;

let CONFIG = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  dirName: "",
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  s3Url: process.env.REACT_APP_S3URL,
};

// generates unique file name to avoid conflicts and without spaces as it makes errors
export const generateUniqueFileName = (name) => {
  return `${new Date().getTime()}_${name
    .replace(/\s/g, "")
    .replace(/(\(|\))/g, "")}`;
};

// Add your S3 bucket directory name here
export const directories = {
  service_categories_logos: "service_categories_logos",
  providers_logos: "providers_logos",
  providers_banners: "providers_banners",
  branch_images: "branch_images",
  providers_logos_spp: "providers_logos_spp",
  providers_banners_spp: "providers_banners_spp",
};

export const uploadToS3 = async (file, fileName, directoryName) => {
  store.dispatch(showHideLoader(true));
  CONFIG.dirName = directoryName;
  const S3Client = new S3(CONFIG);
  if (file) {
    return await S3Client.uploadFile(file, fileName)
      .then((data) => {
        store.dispatch(showHideLoader(false));
        return { link: data.location };
      })
      .catch((err) => {
        const lang = store.getState().locale.lang;
        store.dispatch(showHideLoader(false));
        showErrorMsg(messages[lang].shared.generalErrMsg);
      });
  }
};
