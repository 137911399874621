import {
  GET_BRANCHES_LOOKUP_RESPONSE,
  GET_CATEGORIES_LOOKUP_RESPONSE,
  GET_CURRANCIES_LOOKUP_RESPONSE,
  GET_EMPLOYEES_LOOKUP_RESPONSE,
  GET_PROVIDERS_LOOKUP_RESPONSE,
  GET_SERVICES_LOOKUP_RESPONSE,
  GET_SPECIALTIES_LOOKUP_RESPONSE,
  GET_BRANCH_SPECIALTIES_LOOKUP_RESPONSE
} from './types';

const INITIAL_STATE = {
  currancies: null,
  services: null,
  providers: null,
  specialties: null,
  branchSpecialties:null,
  employees: null,
  categories: null,
  branches: null,
};

const lookUps = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CURRANCIES_LOOKUP_RESPONSE:
      return {
        ...state,
        currancies: action.payload,
      };
    case GET_SERVICES_LOOKUP_RESPONSE:
      return {
        ...state,
        services: action.payload,
      };
    case GET_PROVIDERS_LOOKUP_RESPONSE:
      return {
        ...state,
        providers: action.payload,
      };
    case GET_SPECIALTIES_LOOKUP_RESPONSE:
      return {
        ...state,
        specialties: action.payload,
      };
    case GET_BRANCH_SPECIALTIES_LOOKUP_RESPONSE:
      return {
        ...state,
        branchSpecialties: action.payload,
    };
    case GET_EMPLOYEES_LOOKUP_RESPONSE:
      return {
        ...state,
        employees: action.payload,
      };
    case GET_CATEGORIES_LOOKUP_RESPONSE:
      return {
        ...state,
        categories: action.payload,
      };
    case GET_BRANCHES_LOOKUP_RESPONSE:
      return {
        ...state,
        branches: action.payload,
      };
    default:
      return state;
  }
};

export default lookUps;
