import React from 'react';

export const StarIcon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 48 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 0L29.6129 17.2746H47.7764L33.0818 27.9508L38.6946 45.2254L24 34.5491L9.30537 45.2254L14.9182 27.9508L0.223587 17.2746H18.3871L24 0Z"
        fill="#FFBF00"
      />
    </svg>
  );
};
