import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../components/Button";
import Input from "../../../components/Input";
import messages from "../../../assets/locale/messages";
import { forgetPasswordRequest } from "../../../store/Auth/actions";
import "./ForgetPassword.scss";

const ForgetPassword = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { forgetPassword, shared } = messages[lang];
  const dispatch = useDispatch();

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("validEmailFormat").required("emailRequired"),
    }),
    onSubmit: ({ email }) => {
      dispatch(
        forgetPasswordRequest({
          email,
        })
      );
    },
  });
  return (
    <>
      <div className="w-100">
        <h3 className="title font-bold fsize-32 text-primary">
          {forgetPassword.title}{" "}
          <span className="text-secondary">{forgetPassword.title2}</span>
        </h3>
      </div>
      <form className="w-100" onSubmit={handleSubmit} noValidate>
        <Input
          id="email"
          label={forgetPassword.labels.email}
          name="email"
          type="email"
          placeholder={forgetPassword.placeholders.email}
          required
          onChange={(value) => {
            setFieldTouched("email");
            setFieldValue("email", value.trim());
          }}
          value={values["email"]}
          inputClass="mb-1"
          labelClassName="font-medium mb-2"
          inputWrapperClass="my-4"
          isInputHasErr={!!(touched["email"] && errors["email"])}
          errMsg={errors["email"]}
        />
        <Button
          label={forgetPassword.actionBTN}
          type="submit"
          labelClass="fsize-16 text-white py-1 fweight-600 border-radius-10"
          className="mb-2"
          block
          disabled={!dirty || !isValid}
        />
        {/* comment back btn for now */}
        {/* <div className="text-center">
          <Link
            to={ROUTE_PATHS.login}
            className="orange-label fsize-14 fweight-400">
            {shared.back}
          </Link>
        </div> */}
      </form>
    </>
  );
};

export default ForgetPassword;
