import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FilledInput, Button, FormHelperText } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  acceptedImagesFilesTypes,
  acceptedImagesFilesMaxSize,
} from "../../utils/Constants";
import messages from "../../assets/locale/messages";
import "./Upload.scss";

const Upload = ({
  accept,
  onChange,
  label,
  required,
  disabled,
  img,
  name,
  multiple,
  className,
  onChangeError,
  isBanner,
  bannerClassName,
  id,
  isInputHasErr,
  errMsg,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { inputsValidations } = messages[lang];
  const [error, setError] = useState(null);

  useEffect(() => {
    document.getElementById(id || "raised-button-file").accept = accept;
    if (multiple) {
      document.getElementById(id || "raised-button-file").multiple = true;
    }
  }, [accept]);

  const validateImagesFiles = (imagesFilesList) => {
    return Array.from(imagesFilesList).every((file) => {
      if (!acceptedImagesFilesTypes.includes(file.type)) {
        setError("uploadTypeError");
        onChangeError && onChangeError("uploadTypeError");
        return false;
      }
      if (file.size > acceptedImagesFilesMaxSize) {
        setError("uploadSizeError");
        onChangeError && onChangeError("uploadSizeError");
        return false;
      }
      setError(null);
      onChangeError && onChangeError(null);
      return true;
    });
  };

  return (
    <div className={`${className} ${!required && img? 'position-relative':''}`}>
      <FilledInput
        style={{ display: "none" }}
        id={id || "raised-button-file"}
        type="file"
        accept={accept}
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            if (!validateImagesFiles(e.target.files)) return;
            if (multiple) {
              const files = e.target.files;
              const filesAsArray = [...files];
              let filesSrc = [];
              filesAsArray.forEach((file) => {
                filesSrc.push(URL.createObjectURL(file));
              });
              onChange(filesSrc, files);
            } else {
              var reader = new FileReader();
              reader.onload = (event) => {
                onChange(event.target.result, e.target.files[0]);
              };
              reader.readAsDataURL(e.target.files[0]);
            }
          }
        }}
        required={required}
        disabled={disabled}
        name={name}
      />
      <label
        htmlFor={id || "raised-button-file"}
        className={`mt-2 ${
          isBanner
            ? "d-flex justify-content-start position-relative grow-child-panner"
            : ""
        }`}
      >
        {img ? (
          <>
            <div className={`${isBanner ? "position-relative w-100" : ""}`}>
              <div
                style={{ backgroundImage: `url(${img})` }}
                className={`logoInput pointer ${
                  isBanner ? bannerClassName : ""
                } ${!isBanner && lang === "ar" ? "image-postion-right" : ""}`}
              ></div>
            </div>
          </>
        ) : (
          <Button
            variant="contained"
            component="span"
            className={`btn-upload border-radius-14 ${
              isBanner ? "btn-banner" : ""
            }`}
            disabled={disabled}
          >
            {label}
          </Button>
        )}
      </label>
      {!required && img && (
        <div className="ovelay pointer">
          <HighlightOffIcon
            onClick={(event) => {
              onChange(null);
              event.stopImmediatePropagation();
              event.stopPropagation();
            }}
          />
        </div>
      )}
      <FormHelperText
        error={true}
        className={`font-medium fsize-12 ${lang === "ar" && "text-end"}`}
      >
        {isInputHasErr ? inputsValidations[errMsg] : inputsValidations[error]}
      </FormHelperText>
    </div>
  );
};

Upload.propTypes = {
  accept: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  img: PropTypes.any,
  name: PropTypes.string,
  id: PropTypes.string,
  multiple: PropTypes.bool,
  className: PropTypes.string,
  onChangeError: PropTypes.func,
  isBanner: PropTypes.bool,
  isInputHasErr: PropTypes.bool,
  errMsg: PropTypes.string,
  bannerClassName: PropTypes.string,
};

Upload.defaultProps = {
  accept: "image/*",
  label: "Upload",
  required: false,
  disabled: false,
};

export default Upload;
