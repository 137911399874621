import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar as MuiSnackbar } from "@mui/material";

import { showHideSnackbar } from "./../../store/Snackbar/actions";
import "./Snackbar.scss";

const Snackbar = () => {
  const dispatch = useDispatch();
  const showSnackbar = useSelector((state) => state.snackbar.showSnackbar);
  const message = useSelector((state) => state.snackbar.message);
  const type = useSelector((state) => state.snackbar.type);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      showHideSnackbar({
        isOpen: false,
        type,
        message,
      })
    );
  };

  return (
    <div className="snackbar-container">
      {message && (
        <MuiSnackbar
          open={showSnackbar}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          className={`snackbar ${type}`}
          message={<span className="fsize-16">{message}</span>}
        />
      )}
    </div>
  );
};

export default Snackbar;
