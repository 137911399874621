import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Map as MapComponent,
  GoogleApiWrapper,
  Marker,
  Polygon,
} from "google-maps-react";
import { Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";

import PolygonHint from "../../../assets/images/polygon_hint.png";
import messages from "../../../assets/locale/messages";
import SearchMap from "../SearchMap";
import Button from "../../Button";
import "./Map.scss";

const GoogleMapTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.tooltip.backgroundBlue,
  },
  arrow: {
    color: theme.tooltip.backgroundBlue,
    fontSize: "1rem",
  },
}))(Tooltip);

const Map = ({
  className,
  viewOnly,
  hasSearch,
  isMarker,
  getCoords,
  savedCoords,
  isPolygon,
  getPolygon,
  savedPolygon,
  previouslyCreatedPolygons,
  isTooltip,
  resetMap,
  resetButtonText,
  hideSearchBarAndBTNReset,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared, branches } = messages[lang];
  // the user positin in ordinary map
  const [position, setPosition] = useState({
    coords: { lng: 0, lat: 0 },
  });

  // the user initial positin and after search
  const [initialPolygonPath, setInitialPolygonPath] = useState([]);

  // after the user selects the area
  // this one can't be provided to the Polygon props, it gets the updated value from the click handler
  const [updatedPolygonPath, setUpdatedPolygonPath] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  // This is used to reset polygon cashed updated coords
  const [resetPolygon, setResetPolygon] = useState(false);

  useEffect(() => {
    if (resetPolygon) {
      setTimeout(() => {
        setResetPolygon(false);
      }, 1);
    }
  }, [resetPolygon]);

  useEffect(() => {
    if (isMarker && savedCoords?.coords) {
      // to set the user updated position in edit mode
      setPosition(savedCoords);
    } else if (isPolygon && savedPolygon && savedPolygon.length) {
      // to set user updated area in edit mode
      // and also set position with on of the polygon values
      // to set map center to show the selected area
      getCenterOfPolygon(savedPolygon);
      setInitialPolygonPath(savedPolygon);
    } else {
      // to set the initial user position
      navigator.geolocation.getCurrentPosition((userPosition) => {
        const {
          coords: { longitude, latitude },
        } = userPosition;
        setPosition({
          coords: {
            lng: longitude,
            lat: latitude,
          },
        });
        if (isPolygon) {
          const initialPolygonCoords = calculatePolygonCoordinates(
            latitude,
            longitude
          );
          setInitialPolygonPath([...initialPolygonCoords]);
          // to set user initial are in case of polygon
          // setInitialPolygonPath([
          //   {
          //     lat: latitude,
          //     lng: longitude,
          //   },
          //   {
          //     lat: latitude + 0.02,
          //     lng: longitude,
          //   },
          //   {
          //     lat: latitude + 0.02,
          //     lng: longitude + 0.02,
          //   },
          //   {
          //     lat: latitude,
          //     lng: longitude,
          //   },
          // ]);
        }
      });
    }
  }, []);

  // updating coords in parent in case of marker map
  useEffect(() => {
    if (!viewOnly && isMarker) {
      getCoords(position);
    }
  }, [position.coords]);

  // updating coords in parent in case of polygon map
  useEffect(() => {
    if (!viewOnly && isPolygon) {
      if (updatedPolygonPath.length) {
        getPolygon(updatedPolygonPath);
      } else {
        getPolygon(initialPolygonPath);
      }
    }
  }, [updatedPolygonPath, initialPolygonPath]);

  const onMoveMarker = (props, marker) => {
    // DO NOT remove the props param even if it's not used
    setPosition({
      coords: {
        lng: marker.position.lng(),
        lat: marker.position.lat(),
      },
    });
  };

  const onSearchMap = (SearchLat, SearchLng, address) => {
    setSearchValue(address);
    setPosition({
      coords: {
        lat: SearchLat,
        lng: SearchLng,
      },
    });
    if (isPolygon) {
      const newPolygonPath = calculatePolygonCoordinates(SearchLat, SearchLng);
      // const newPolygonPath = [
      //   {
      //     lng: SearchLng,
      //     lat: SearchLat,
      //   },
      //   {
      //     lng: SearchLng,
      //     lat: SearchLat + 0.02,
      //   },
      //   {
      //     lng: SearchLng + 0.02,
      //     lat: SearchLat + 0.02,
      //   },
      //   {
      //     lng: SearchLng,
      //     lat: SearchLat,
      //   },
      // ];
      setInitialPolygonPath([...newPolygonPath]);
      setResetPolygon(true);
      getPolygon(newPolygonPath);
    }
  };

  const onResetPolygon = () => {
    setSearchValue("");

    navigator.geolocation.getCurrentPosition((userPosition) => {
      const {
        coords: { longitude, latitude },
      } = userPosition;
      setPosition({
        coords: {
          lng: longitude,
          lat: latitude,
        },
      });
      const newPolygon = calculatePolygonCoordinates(latitude, longitude);
      // const newPolygon = [
      //   {
      //     lat: latitude,
      //     lng: longitude,
      //   },
      //   {
      //     lat: latitude + 0.02,
      //     lng: longitude,
      //   },
      //   {
      //     lat: latitude + 0.02,
      //     lng: longitude + 0.02,
      //   },
      //   {
      //     lat: latitude,
      //     lng: longitude,
      //   },
      // ];
      // to set user initial are in case of polygon
      setInitialPolygonPath([...newPolygon]);
      getPolygon(newPolygon);
    });

    setResetPolygon(true);
  };

  const handlePolygonChange = (props, polygon) => {
    // DO NOT remove the props param even if it's not used
    let geoJSON = {
      type: "Polygon",
      coordinates: [],
    };

    const paths = polygon.getPaths().getArray();
    for (let path of paths) {
      let pathArray = [];
      let points = path.getArray();
      let firstPoint = false;

      for (let point of points) {
        if (firstPoint === false) {
          firstPoint = point;
        }
        pathArray.push([point.lng(), point.lat()]);
      }

      pathArray.push([firstPoint.lng(), firstPoint.lat()]);
      geoJSON.coordinates.push(pathArray);
      getCenterOfPolygon(pathArray);
    }

    const newCoords = geoJSON.coordinates[0].map((elt) => ({
      lng: elt[0],
      lat: elt[1],
    }));
    setUpdatedPolygonPath(newCoords);

    return geoJSON;
  };

  const getCenterOfPolygon = (arrayOfCoords) => {
    const bounds = new window.google.maps.LatLngBounds();
    for (let i = 0; i < arrayOfCoords.length; i++) {
      let cord = arrayOfCoords[i];
      if (Object.keys(cord).length > 0) {
        // object
        bounds.extend(new window.google.maps.LatLng(cord.lat, cord.lng));
      } else {
        // array
        bounds.extend(new window.google.maps.LatLng(cord[1], cord[0]));
      }
    }
    setPosition({
      coords: {
        lng: bounds.getCenter().lng(),
        lat: bounds.getCenter().lat(),
      },
    });
  };
  const calculatePolygonCoordinates = (latitude, longitude) => {
    // Assuming you want to draw a polygon with a radius of 1 kilometer around the current location
    const radiusInKm = 1;
    const latitudeInDegrees = latitude * (Math.PI / 180);
    const longitudeInDegrees = longitude * (Math.PI / 180);
    const earthRadius = 6371; // Earth's radius in kilometers
    const coordinates = [];
    const numberOfPoints = 3; // Number of points to approximate the polygon
    for (let angle = 0; angle <= 360; angle += 360 / numberOfPoints) {
      const angleInRadians = angle * (Math.PI / 180);
      const latitudeOffset = Math.asin(
        Math.sin(latitudeInDegrees) * Math.cos(radiusInKm / earthRadius) +
          Math.cos(latitudeInDegrees) *
            Math.sin(radiusInKm / earthRadius) *
            Math.cos(angleInRadians)
      );
      const longitudeOffset =
        longitudeInDegrees +
        Math.atan2(
          Math.sin(angleInRadians) *
            Math.sin(radiusInKm / earthRadius) *
            Math.cos(latitudeInDegrees),
          Math.cos(radiusInKm / earthRadius) -
            Math.sin(latitudeInDegrees) * Math.sin(latitudeOffset)
        );
      const latitudeInDegreesOffset = latitudeOffset * (180 / Math.PI);
      const longitudeInDegreesOffset = longitudeOffset * (180 / Math.PI);
      coordinates.push([longitudeInDegreesOffset, latitudeInDegreesOffset]);
    }
    const newCoords = coordinates.map((elt) => ({
      lng: elt[0],
      lat: elt[1],
    }));
    return newCoords;
  };

  const renderToolTip = (children) => {
    if (isTooltip) {
      return (
        <GoogleMapTooltip
          className="MapToolTip"
          title={
            <div className=" d-flex  align-items-center p-1 ">
              <div className="p-2 bg-white polygon-image me-3">
                <img src={PolygonHint} alt="polygonHint" />
              </div>
              <p className="fsize-12">{shared.polygonHint}</p>
            </div>
          }
          placement="top"
          arrow>
          <div> {children}</div>
        </GoogleMapTooltip>
      );
    } else {
      return <div>{children}</div>;
    }
  };

  return (
    <div className="google-maps-container">
      {!hideSearchBarAndBTNReset && (
        <div
          className={
            "reset-map-wrapper d-flex flex-column flex-lg-row align-items-lg-center pb-4 gap-4 gap-md-2"
          }>
          {hasSearch && (
            <SearchMap
              onSearchMap={onSearchMap}
              searchValue={searchValue}
              placeholder={branches.addBranch.placeholders.searchForLocations}
            />
          )}
          {resetMap && (
            <Button
              onClick={onResetPolygon}
              label={resetButtonText}
              className="reset-map-btn text-white"
            />
          )}
        </div>
      )}
      <div className="bg-white rounded-4 p-4 handel-w-h">
        {renderToolTip(
          <MapComponent
            google={window.google}
            zoom={14}
            center={{
              lat: position.coords.lat,
              lng: position.coords.lng,
            }}
            className={`${className} map-dimension`}>
            {isMarker && (
              <Marker
                name="Current location"
                draggable={!viewOnly}
                onDragend={onMoveMarker}
                position={{
                  lat: position.coords.lat,
                  lng: position.coords.lng,
                }}
              />
            )}
            {isPolygon && !resetPolygon && (
              <Polygon
                draggable={!viewOnly}
                editable={!viewOnly}
                paths={initialPolygonPath}
                strokeColor="#e95b25"
                strokeOpacity={0.8}
                strokeWeight={4}
                fillColor="#e95b25"
                fillOpacity={0.35}
                onClick={handlePolygonChange}
              />
            )}

            {isPolygon &&
              previouslyCreatedPolygons &&
              previouslyCreatedPolygons.map((polygon, index) => (
                <Polygon
                  draggable={viewOnly}
                  editable={viewOnly}
                  paths={polygon}
                  strokeColor="#767676"
                  strokeOpacity={0.8}
                  strokeWeight={4}
                  fillColor="#767676"
                  fillOpacity={0.35}
                  key={`polygon-${index}`}
                />
              ))}
          </MapComponent>
        )}
      </div>
    </div>
  );
};
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(Map);

Map.propTypes = {
  className: PropTypes.string,
  viewOnly: PropTypes.bool,
  hasSearch: PropTypes.bool,
  isMarker: PropTypes.bool,
  getCoords: PropTypes.func,
  savedCoords: PropTypes.object,
  isPolygon: PropTypes.bool,
  getPolygon: PropTypes.func,
  hideSearchBarAndBTNReset: PropTypes.bool,
  savedPolygon: PropTypes.arrayOf(
    PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number })
  ),
  previouslyCreatedPolygons: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      })
    )
  ),
  isTooltip: PropTypes.bool,
  resetMap: PropTypes.bool,
  resetButtonText: PropTypes.object,
};
Map.defaultProps = {
  hasSearch: true,
  viewOnly: false,
  isTooltip: false,
  hideSearchBarAndBTNReset: false,
};
