import { put, call, takeEvery } from 'redux-saga/effects';
import * as apis from '../../network/apis/bookings';
import * as actions from './actions';
import * as types from './types';
import { showErrorMsg, showSuccessMsg } from '../../utils/Helpers';
import history from '../../routes/History';
import { ROUTE_PATHS } from '../../utils/RoutesPaths';

function* getBookingsListSaga({ payload }) {
    try {
      const response = yield call(apis.getBookingsList, payload);
      yield put(
        actions.getBookingsResponse({
          list: response.data.appointments,
          meta: {
            currentPage: +response.headers['current-page'],
            count: +response.headers['total-count'],
            pages: +response.headers['total-pages'],
          },
        })
      );
      if (+response.headers['current-page'] > +response.headers['total-pages']) {
        yield put(
          actions.getBookingsRequest({
            ...payload,
            page: +response.headers['total-pages'],
          })
        );
      }
    } catch (error) {
      yield showErrorMsg(error.response?.data?.message);
    }
};

function* getBookingSaga({payload}) {
  try {
    const response = yield call(apis.getBooking, payload);
    yield put(
      actions.getBookingResponse({
        bookingDetails: response.data.appointment,
      })
    );
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
};

function* editBookingServiceSaga({payload}) {
  try {
    const response = yield call(apis.editBookingService, payload);
    yield showSuccessMsg('editedSuccessfully');
    yield put(actions.getRequestStatus(response?.data?.success));
  } catch (error) {
    yield put(actions.getRequestStatus(error.response?.data?.success));
    yield showErrorMsg(error.response?.data?.message);
  }
};

function* getBookingServiceCancelationReasonsSaga() {
  try {
    const response = yield call(apis.listBookingServiceCancelationReasons);
    yield put(
      actions.getBookingServiceCancelationReasonsResponse(response.data.appointment_branch_service_cancellation_reasons)
    );
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
};

function* getBookingCancelationReasonsSaga() {
  try {
    const response = yield call(apis.listBookingCancelationReasons);
    yield put(
      actions.getBookingCancelationReasonsResponse(response.data.appointment_cancellation_reasons)
    );
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
};

function* updateBookingSaga({payload}) {
  try {
    const response = yield call(apis.updateBooking, payload.data);
    if(payload.requestType === "cancel"){
      yield showSuccessMsg('cancelledSuccessfully');
    }else if (payload.requestType === "add another service"){
      yield showSuccessMsg('addedSuccessfully');
      yield history.push(ROUTE_PATHS.viewBooking.replace(':id', payload.data.id));
    }else if(payload.requestType === 'edit'){
      yield showSuccessMsg('editedSuccessfully');
    }
    yield put(actions.getRequestStatus(response?.data?.success));
  } catch (error) {
    yield put(actions.getRequestStatus(error.response?.data?.success));
    yield showErrorMsg(error.response?.data?.message);
  }
};

function* getMoreServicesSaga ({payload}) {
  try {
    const response = yield call(apis.getMoreServices, payload);
    yield put(
      actions.getOtherServicesResponse({
        list: response.data.branch_services,
        meta: {
          currentPage: +response.headers['current-page'],
          count: +response.headers['total-count'],
          pages: +response.headers['total-pages'],
        },
      })
    );
    if (+response.headers['current-page'] > +response.headers['total-pages']) {
      yield put(
        actions.getOtherServicesRequest({
          ...payload,
          page: +response.headers['total-pages'],
        })
      );
    }
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
};

export default function* BookingsSaga() {
  yield takeEvery(types.GET_BOOKINGS_REQUEST, getBookingsListSaga);
  yield takeEvery(types.GET_BOOKING_REQUEST, getBookingSaga);
  yield takeEvery(types.EDIT_BOOKING_SERVICE_REQUEST, editBookingServiceSaga);
  yield takeEvery(types.GET_BOOKING_CANCELATION_REASONS_REQUEST, getBookingCancelationReasonsSaga);
  yield takeEvery(types.GET_BOOKING_SERVICE_CANCELATION_REASONS_REQUEST, getBookingServiceCancelationReasonsSaga);
  yield takeEvery(types.UPDATE_BOOKING_REQUEST, updateBookingSaga);
  yield takeEvery(types.GET_OTHER_SERVICES_REQUEST, getMoreServicesSaga);
}
