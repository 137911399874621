import { GET_PAYMENT_TRANSACTIONS_REQUEST,
         GET_PAYMENT_TRANSACTIONS_RESPONSE,
         EXPORT_PAYMENT_TRANSACTIONS
} from './types'

export const getPaymentTransactionRequest = (payload) => ({
type: GET_PAYMENT_TRANSACTIONS_REQUEST,
payload,
});

export const getPaymentTransactionResponse = (payload) => ({
type: GET_PAYMENT_TRANSACTIONS_RESPONSE,
payload,
});

export const getExportTransactionsRequest = (payload) => ({
    type: EXPORT_PAYMENT_TRANSACTIONS,
    payload,
 });
 