import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Breadcrumbs as BreadcrumbsMUI, Link } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

import "./BreadCrumbs.scss";

const Breadcrumbs = ({ list, className, BreadCrumbHasBackIcon }) => {
  const lang = useSelector((state) => state.locale.lang);
  const history = useHistory(); 

  return (
    <div className="bread-crumbs">
      <BreadcrumbsMUI className={className} maxItems={2}>
        {list?.length > 0 ? (
          list?.map((element, i) => {
            return (
              <Link
                key={element.label}
                underline="none"
                color={`${i === list?.length - 1 ? "#5E5E5E" : "#9E9E9E"}`}
                onClick={() => {
                  history.push(element.path);
                }}
                className={`pointer ${className} ${
                  i === list?.length - 1
                    ? "active-bread-crumb-link"
                    : "bread-crumb-link"
                }`}
              >
                <div
                  className={`${
                    BreadCrumbHasBackIcon
                      ? "d-flex justify-content-center align-items-center"
                      : ""
                  }`}
                >
                  {i === 0 ? (
                    lang === "en" ? (
                      <ChevronLeft className="bread-crumb-icon me-3" />
                    ) : (
                      <ChevronRight className="bread-crumb-icon ms-3" />
                    )
                  ) : (
                    <></>
                  )}
                  {element.label}
                </div>
              </Link>
            );
          })
        ) : (
          <></>
        )}
      </BreadcrumbsMUI>
    </div>
  );
};

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  list: PropTypes.array.isRequired,
  className: PropTypes.string,
  BreadCrumbHasBackIcon: PropTypes.bool,
};
