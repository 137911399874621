export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_RESPONSE = "GET_NOTIFICATIONS_RESPONSE";

export const SET_NOTIFICATION_SEEN = "SET_NOTIFICATION_SEEN";

export const ADD_PUSH_NOTIFICATION = "ADD_PUSH_NOTIFICATION";

export const SET_UNSEEN_NOTIFICATIONS_COUNT = "SET_UNSEEN_NOTIFICATIONS_COUNT";

export const GET_DROPDOWN_NOTIFICATIONS_REQUEST = "GET_DROPDOWN_NOTIFICATIONS_REQUEST";
export const GET_DROPDOWN_NOTIFICATIONS_RESPONSE = "GET_DROPDOWN_NOTIFICATIONS_RESPONSE";