const login = {
  title: "مرحبا بك",
  title2: "في بوابة مقدمي خدمة ميمار",
  subTitle: "سجل",
  subTitle2: "الدخول",
  labels: {
    email: "البريد الإلكتروني",
    password: "كلمة المرور",
    remember: "تذكرني المرة القادمة",
    forgetPassword: "نسيت كلمة المرور؟",
    signin: "تسجيل الدخول",
  },
  placeholders: {
    email: "أدخل بريدك الإلكتروني",
    password: "أدخل كلمة المرور",
  },
};

export default login;
