import React from "react";
import PropTypes from "prop-types";

import Button from "../Button";
import noDataVector from "../../assets/images/vectors/noData.png";
import noSearchResultVector from "../../assets/images/vectors/noSearchResult.png";
import "./EmptyState.scss";

const EmptyState = ({
  subTitle,
  description,
  ButtonLabel,
  handleBtnAction,
  showActionButton,
  NoDataListWithNoActionBtn,
}) => (
  <div className="d-flex py-5 justify-content-center align-items-center flex-column ">
    <p className="text-secondary fsize-16 font-medium">{description}</p>
    <p className="fsize-24 font-medium subTitle text-secondary fweight-700">{subTitle}</p>
    <img
      src={showActionButton || NoDataListWithNoActionBtn ? noDataVector : noSearchResultVector}
      alt="Mimar-logo"
      className="mt-1 mb-3 w-55"
    />
    {showActionButton && (
      <Button
        label={ButtonLabel}
        type="button"
        labelClass="fsize-16 text-white"
        onClick={handleBtnAction}
        block={true}
      />
    )}
  </div>
);

export default EmptyState;

EmptyState.propTypes = {
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.string,
  ButtonLabel: PropTypes.string,
  handleBtnAction: PropTypes.func,
  showActionButton: PropTypes.bool,
  NoDataListWithNoActionBtn: PropTypes.bool,
};
EmptyState.defaultProps = {
  showActionButton: true,
  NoDataListWithNoActionBtn: false,
};
