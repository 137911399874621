import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { EditLocationAltOutlined } from '@mui/icons-material';

import messages from '../../../../assets/locale/messages';
import banner from '../../../../assets/images/bg-pic-placeholder.svg';
import { addBranchDataInStore, getBranchRequest } from '../../../../store/Branches/actions';
import { ROUTE_PATHS } from '../../../../utils/RoutesPaths';
import { weekDays, weekDaysAr, paymentMethodsList } from '../../../../utils/Constants'; 
import { StarIcon } from '../../../../utils/Icons';
import { timeConverter } from '../../../../utils/Helpers';
import EmptyState from '../../../../components/EmptyState';
import Modal from '../../../../components/Modal';
import Rate from '../../../../components/Rate';
import CoverageAreas from '../../CoverageAreas';
import SelectLocation from '../../SelectLocation';
import './ViewBranchDetails.scss';

const ViewBranchDetails = ({ setShowLeaveWarningModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const lang = useSelector((state) => state.locale.lang);
  const branchData = useSelector((state) => state.branches.addEditViewBranch.branchDetails);
  const { branches, shared } = messages[lang];

  const [branchDetails, setBranchDetails] = useState(null);
  const [homePolygon, setHomePolygon] = useState(null);
  const [openMap, setOpenMap] = useState(false);
  const [AtHome, setAtHome] = useState(null);
  const [mappedWorkingDays, setMappedWorkingDays] = useState(() => {});

  useEffect(() => {
    dispatch(getBranchRequest(id));
    dispatch(addBranchDataInStore(null));
  }, []);
  useEffect(() => {
    if (branchData) {
      setBranchDetails(branchData);
    }
  }, [branchData]);

  useEffect(() => {
    if (branchDetails?.branch_working_hours?.length > 0) {
      mapWorkingHours();
    }
  }, [branchDetails]);

  const mapWorkingHours = () => {
    let workingDays = [
      {
        id: 0,
        label: shared.days.sat,
        value: weekDays.sat,
        timeSlots: [],
      },
      {
        id: 1,
        label: shared.days.sun,
        value: weekDays.sun,
        timeSlots: [],
      },
      {
        id: 2,
        label: shared.days.mon,
        value: weekDays.mon,
        timeSlots: [],
      },
      {
        id: 3,
        label: shared.days.tus,
        value: weekDays.tus,
        timeSlots: [],
      },
      {
        id: 4,
        label: shared.days.wed,
        value: weekDays.wed,
        timeSlots: [],
      },
      {
        id: 5,
        label: shared.days.thurs,
        value: weekDays.thurs,
        timeSlots: [],
      },
      {
        id: 6,
        label: shared.days.fri,
        value: weekDays.fri,
        timeSlots: [],
      },
    ];
    workingDays.map((day) => {
      branchDetails.branch_working_hours.map((workingDay) => {
        if (lang === 'en') {
          if (day.label.toLowerCase() === workingDay.week_day) {
            if (workingDay.intervals.length > 0) {
              workingDay?.intervals?.map((interval, i) => {
                day.timeSlots.push({
                  id: i,
                  startTime: interval.starts_at,
                  endTime: interval.ends_at,
                });
                return interval;
              });
            }
          }
        } else {
          if (day.value.toLowerCase() === workingDay.week_day) {
            if (workingDay.intervals.length > 0) {
              workingDay?.intervals?.map((interval, i) => {
                day.timeSlots.push({
                  id: i,
                  startTime: interval.starts_at,
                  endTime: interval.ends_at,
                });
                return interval;
              });
            }
          }
        }
        return workingDay;
      });
      return day;
    });
    setMappedWorkingDays(workingDays);
  };

  const renderDayLabel = (day) => {
    switch (day.id) {
      case 0:
        return lang === 'en' ? weekDays.sat : weekDaysAr.sat;
      case 1:
        return lang === 'en' ? weekDays.sun : weekDaysAr.sun;
      case 2:
        return lang === 'en' ? weekDays.mon : weekDaysAr.mon;
      case 3:
        return lang === 'en' ? weekDays.tus : weekDaysAr.tus;
      case 4:
        return lang === 'en' ? weekDays.wed : weekDaysAr.wed;
      case 5:
        return lang === 'en' ? weekDays.thurs : weekDaysAr.thurs;
      case 6:
        return lang === 'en' ? weekDays.fri : weekDaysAr.fri;

      default:
        return '-';
    }
  };

  const renderWorkingDaysView = () => {
    return (
      <>
        <div className="view-working-day">
          {mappedWorkingDays?.map((day) => {
            return (
              <div
                className="d-flex gap-3 align-items-lg-center align-items-start flex-column flex-lg-row mb-3"
                key={day.id}>
                <div className="day flex-basis-15 text-secondary fsize-18">
                  {lang === 'en' ? renderDayLabel(day) : renderDayLabel(day)}
                </div>
                <div className="d-flex gap-3 align-items-md-center align-items-start flex-column flex-lg-row">
                  {day.timeSlots.length === 0 ? (
                    <div className="text-red font-medium">{shared.closed}</div>
                  ) : (
                    day.timeSlots?.map((slot) => {
                      return (
                        <div className="d-flex bg-section p-2 px-3 gap-2" key={slot.id}>
                          <div className="view-working-day-item-time-slot-start">
                            {branches.addBranch.specialtiesAndServices.from}: {timeConverter(slot.startTime, lang)}
                          </div>
                          <div> - </div>
                          <div className="view-working-day-item-time-slot-end">
                            {branches.addBranch.specialtiesAndServices.to}: {timeConverter(slot.endTime, lang)}
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const getClickedPolygon = () => {
    let element = {
      id: null,
      name_ar: null,
      name_en: null,
      latlng: [],
    };
    if (homePolygon !== null) {
      element.id = homePolygon.id;
      element.name_ar = homePolygon.name_ar;
      element.name_en = homePolygon.name_en;
      element.latlng = homePolygon.polygon;
    }
    return element;
  };

  const viewBranchPointerOnMap = () => {
    if (branchDetails?.lat && branchDetails?.lng) {
      return { coords: { lng: +branchDetails.lng, lat: +branchDetails.lat } };
    }
  };
  const handleCloseMapModal = () => {
    setOpenMap(false);
    setHomePolygon(null);
    setAtHome(null);
  };

  const renderPaymentLabel = (location) => {
    switch (location) {
      case paymentMethodsList.both:
        return branches.addBranch.labels.both;
      case paymentMethodsList.cash:
        return branches.addBranch.labels.cash;

      default:
        return branches.addBranch.labels.online;
    }
  };

  return (
    <>
      <div className={`branch-details ${!branchData && 'mx-auto w-50'}`}>
        {branchDetails ? (
          <>
            <div className="d-flex justify-content-between gap-2 flex-column-reverse flex-md-row">
              {/* Branch info */}
              <div className="bg-white rounded-4 me-2 p-4 branch-info">
                <div className="mb-4 mt-2">
                  <div className="text-dark text-primary fsize-16">{branches.viewBranch.labels.enTitle}</div>
                  <div className="text-gray  fsize-18">{branchDetails.name_en}</div>
                </div>
                <div className="mb-4">
                  <div className="text-dark text-primary fsize-16">{branches.viewBranch.labels.arTitle}</div>
                  <div className="text-gray  fsize-18">{branchDetails.name_ar}</div>
                </div>
                <div className="mb-4">
                  <div className="text-dark text-primary fsize-16">{branches.viewBranch.labels.phone}</div>
                  <div className="text-gray  fsize-18">{branchDetails.phone_number || '-'}</div>
                </div>
                <div className="mb-4">
                  <div className="text-dark text-primary fsize-16">{branches.viewBranch.labels.category}</div>
                  <div className="text-gray  fsize-18">
                    {branchDetails.category
                      ? lang === 'en'
                        ? branchDetails.category.name_en
                        : branchDetails.category.name_ar
                      : branches.viewBranch.labels.noCategory}
                  </div>
                </div>
                {/* <div className="mb-4">
                  <div className="text-dark text-primary fsize-16">{branches.viewBranch.labels.serviceProvider}</div>
                  <div className="text-gray fsize-18">
                    {branchDetails.service_provider ? (
                      lang === 'en' ? (
                        <Link
                          to={ROUTE_PATHS.viewServiceProvider.replace(':id', branchDetails?.service_provider?.id)}
                          className="pointer">
                          {branchDetails.service_provider.name_en}
                        </Link>
                      ) : (
                        <Link
                          to={ROUTE_PATHS.viewServiceProvider.replace(':id', branchDetails?.service_provider?.id)}
                          className="pointer">
                          {branchDetails.service_provider.name_ar}
                        </Link>
                      )
                    ) : (
                      <span>{branches.viewBranch.labels.noProvider}</span>
                    )}
                  </div>
                </div> */}
              </div>
              {/* Branch image */}
              <div className="bg-white rounded-4 p-4 branch-image">
                <div
                  className="banner d-flex align-items-end"
                  style={{
                    backgroundImage: `url(${branchDetails.image ?? banner})`,
                  }}></div>
                <div>
                  <div className="text-gray fsize-18">
                    {branchDetails.rating !== 0 ? (
                      <div className="d-flex justify-content-center align-items-center">
                        <Rate name={'rating Branch'} id={'rating Branch'} rateValue={branchDetails.rating} />
                      </div>
                    ) : (
                      <div className="text-gray fsize-18 d-flex justify-content-center align-items-center gap-2">
                        <StarIcon />
                        <div>{branches.viewBranch.labels.noRating}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Working hours */}
            <div className="bg-white rounded-4 mt-3 p-4">
              <div className="mb-4 mt-2">
                <div className="text-dark text-primary fsize-16 mb-3">{branches.viewBranch.labels.workingHours}</div>
                <div className="text-gray  fsize-18"> {renderWorkingDaysView()}</div>
              </div>
            </div>
            {/* Branch location */}
            <div className="bg-white rounded-4 mt-3 p-4">
              <div className="mb-4 mt-2">
                <div className="text-dark text-primary fsize-16 mb-3">{branches.viewBranch.labels.location}</div>
                <div className="text-gray  fsize-18">
                  {(branchDetails?.offered_location == 'home' ||
                    branchDetails?.offered_location == 'both' ||
                    branchDetails?.branch_covered_zones?.length > 0) && (
                    <>
                      <div className="text-dark text-primary fsize-16 mb-1 text-secondary">
                        {branches.viewBranch.labels.home}
                      </div>
                      <div className="text-dark text-primary fsize-16 mb-3 text-gray fweight-600">
                        {branches.viewBranch.labels.supportedRegions}
                      </div>
                      <div className="text-gray  fsize-18 d-flex flex-column gap-2">
                        {branchDetails?.branch_covered_zones?.length > 0 &&
                          branchDetails?.branch_covered_zones.map((zone, i) => {
                            return (
                              <div
                                className={`d-flex justify-content-between align-items-center ${
                                  i !== branchDetails?.branch_covered_zones.length - 1 && 'mb-3'
                                }`}
                                key={zone.id || i}>
                                <div>
                                  {zone.name_en || zone.name_ar ? (lang === 'en' ? zone.name_en : zone.name_ar) : '-'}
                                </div>
                                <div
                                  className="fsize-16 text-secondary d-flex gap-1 align-items-center pointer  text-underline"
                                  onClick={() => {
                                    // home polygon
                                    setAtHome(true);
                                    setHomePolygon(zone);
                                    setOpenMap(true);
                                  }}>
                                  <EditLocationAltOutlined />
                                  <div>{branches.viewBranch.labels.viewOnMap}</div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      {(branchDetails?.offered_location == 'both' ||
                        (branchDetails?.branch_covered_zones?.length > 0 &&
                          branchDetails?.lat &&
                          branchDetails?.lng)) && <div className="line-separator"></div>}
                    </>
                  )}
                  {(branchDetails?.offered_location == 'in_place' || branchDetails?.offered_location == 'both') && (
                    <>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-dark text-primary fsize-16 mb-1 text-secondary">
                          {branches.viewBranch.labels.branch}
                        </div>
                        <div
                          className="fsize-16 text-secondary d-flex gap-1 align-items-center pointer text-underline"
                          onClick={() => {
                            setAtHome(false);
                            // setHomePolygon(zone);
                            setOpenMap(true);
                          }}>
                          <EditLocationAltOutlined />
                          <div>{branches.viewBranch.labels.viewOnMap}</div>
                        </div>
                      </div>
                      <div className="mb-4 mt-2">
                        <div className="text-dark text-primary fsize-16 mb-2 text-gray fweight-600">
                          {branches.addBranch.labels.contryNameEn}
                        </div>
                        <div className="text-gray  fsize-18"> {branchDetails?.country ?? '-'}</div>
                      </div>
                      <div className="mb-4 mt-2">
                        <div className="text-dark text-primary fsize-16 mb-2 text-gray fweight-600">
                          {branches.addBranch.labels.contryNameAr}
                        </div>
                        <div className="text-gray  fsize-18"> {branchDetails?.country ?? '-'}</div>
                      </div>
                      <div className="mb-4 mt-2">
                        <div className="text-dark text-primary fsize-16 mb-2 text-gray fweight-600">
                          {branches.addBranch.labels.city}
                        </div>
                        <div className="text-gray  fsize-18"> {branchDetails?.city ?? '-'}</div>
                      </div>
                      <div className="mb-4 mt-2">
                        <div className="text-dark text-primary fsize-16 mb-2 text-gray fweight-600">
                          {branches.addBranch.labels.region}
                        </div>
                        <div className="text-gray  fsize-18"> {branchDetails?.region ?? '-'}</div>
                      </div>
                      <div className="mb-4 mt-2">
                        <div className="text-dark text-primary fsize-16 mb-2 text-gray fweight-600">
                          {branches.addBranch.labels.streetName}
                        </div>
                        <div className="text-gray  fsize-18"> {branchDetails?.street ?? '-'}</div>
                      </div>
                      <div className="mb-4 mt-2">
                        <div className="text-dark text-primary fsize-16 mb-2 text-gray fweight-600">
                          {branches.addBranch.labels.buildingNumber}
                        </div>
                        <div className="text-gray  fsize-18"> {branchDetails?.building_number ?? '-'}</div>
                      </div>
                      <div className="mb-4 mt-2">
                        <div className="text-dark text-primary fsize-16 mb-2 text-gray fweight-600">
                          {branches.addBranch.labels.secondaryNumber}
                        </div>
                        <div className="text-gray  fsize-18"> {branchDetails?.secondary_number ?? '-'}</div>
                      </div>
                      <div className="mb-4 mt-2">
                        <div className="text-dark text-primary fsize-16 mb-2 text-gray fweight-600">
                          {branches.addBranch.labels.postalNumber}
                        </div>
                        <div className="text-gray  fsize-18"> {branchDetails?.postal_code ?? '-'}</div>
                      </div>
                      <div className="mb-4 mt-2">
                        <div className="text-dark text-primary fsize-16 mb-2 text-gray fweight-600">
                          {branches.addBranch.labels.landmark}
                        </div>
                        <div className="text-gray  fsize-18"> {branchDetails?.land_mark ?? '-'}</div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* gender */}
            <div className="bg-white rounded-4 mt-3 p-4">
              <div className="mt-2">
                <div className="text-dark text-primary fsize-16 mb-3">{branches.viewBranch.labels.servicedGender}</div>
                <div className="text-gray  fsize-18">
                  {' '}
                  {branchDetails.serviced_gender === 'both_genders'
                    ? branches.addBranch.labels.bothGender
                    : branchDetails.serviced_gender === 'male'
                    ? branches.addBranch.labels.male
                    : branches.addBranch.labels.female}
                </div>
              </div>
            </div>
            {/* payment method */}
            <div className="bg-white rounded-4 mt-3 p-4">
              <div className="mt-2">
                <div className="text-dark text-primary fsize-16 mb-3">{branches.viewBranch.labels.paymenMethods}</div>
                <div className="text-gray  fsize-18">
                  {renderPaymentLabel(branchDetails.payment_methods) ?? branches.viewBranch.labels.none}
                </div>
              </div>
            </div>
          </>
        ) : (
          <EmptyState
            subTitle={shared.noDataToShow}
            ButtonLabel={shared.back}
            handleBtnAction={() => {
              history.push(ROUTE_PATHS.branches);
            }}
          />
        )}
      </div>
      <Modal
        open={openMap}
        handleClose={handleCloseMapModal}
        title={<h2>{branches.viewBranch.labels.supportedRegions}</h2>}
        contentClass="mb-5 pb-2 text-primary"
        content={
          AtHome ? (
            <CoverageAreas viewMapInModelOnly={true} viewOnlyPolygon={getClickedPolygon()} />
          ) : (
            <SelectLocation viewMapInModelOnly={true} showCoords={viewBranchPointerOnMap()} />
          )
        }
        confirmBtnTxt={shared.confirm}
        isContentText={false}
        maxWidth="lg"
        cancelBtnTxt={shared.cancel}
        handleCancel={handleCloseMapModal}
        modalClass="map-modal"
        actionsClass="container px-4"
        confirmBtnClass="btn-secondary fsize-14 text-white py-2 "
        cancelBtnClass="fsize-14 py-2"
        hasActions={false}
        disableActionBtn={false}
      />
    </>
  );
};

export default ViewBranchDetails;
