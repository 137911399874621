import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { Tooltip } from '@mui/material';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Upload from '../../../components/Upload';
import Select from '../../../components/Select';
import { ARABIC_REGEX, ENGLISH_REGEX, NAME_REGEX, ONE_SPACE_ONLY } from '../../../utils/Patterns';
import { directories, generateUniqueFileName, uploadToS3 } from '../../../utils/Aws-S3';
import { getProfileDetailsRequest, setupProfileRequest, setupProfileResponse } from '../../../store/ProfileDetails/actions';
import { getCategoriesLookUpRequest } from '../../../store/LookUps/actions';
import messages from '../../../assets/locale/messages';
import './SetupProfile.scss';

const SetupProfile = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { setupProfile, shared } = messages[lang];
  const dispatch = useDispatch();

  const [imgBanner, setImgBanner] = useState();
  const [imgBannerChanged, setImgBannerChanged] = useState(false);
  const [imgLogo, setImgLogo] = useState();
  const [imgLogoChanged, setImgLogoChanged] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const categoryList = useSelector((state) => state.lookUps.categories);
  const profileDetails = useSelector((state) => state.profileDetails.myProfile);

  useEffect(() => {
    return () => {
      dispatch(setupProfileResponse({}));
    }
  }, [])

  useEffect(() => {
    getCategoriesDropDownList();
    getProfileDetails();
  }, []);

  useEffect(() => {
    setCategoryOptions(
      categoryList?.map((option) => ({
        id: option.id,
        label: lang === 'en' ? option.name_en : option.name_ar,
      }))
    );
  }, [categoryList, lang]);

  useEffect(() => {
    if (values['category']) {
      setFieldValue(
        'category',
        categoryOptions?.find((option) => option.id === values['category'].id)
      );
    }
  }, [lang, categoryOptions]);

  useEffect(() => {
    if (Object.keys(profileDetails).length > 0) {
      setTouched({
        name_en: !!profileDetails?.name_en ?? false,
        name_ar: !!profileDetails?.name_ar ?? false,
        email: !!profileDetails?.email ?? false,
        category: !!categoryOptions?.find((option) => option?.id === profileDetails?.category?.id ?? false),
        banner_image: !!profileDetails?.banner_image ?? false,
        logo_image: !!profileDetails?.logo_image ?? false,
      });
      setValues({
        name_en: profileDetails?.name_en,
        name_ar: profileDetails?.name_ar,
        email: profileDetails?.email,
        banner_image: profileDetails?.banner_image,
        logo_image: profileDetails?.logo_image,
        category: categoryOptions?.find((option) => option?.id === profileDetails?.category?.id),
      });
    }
  }, [profileDetails]);

  const getCategoriesDropDownList = () => {
    dispatch(
      getCategoriesLookUpRequest({
        page: -1,
      })
    );
  };

  const getProfileDetails = () => {
    dispatch(getProfileDetailsRequest());
  };

  const {
    values,
    setFieldValue,
    handleSubmit,
    setValues,
    setTouched,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      name_en: '',
      name_ar: '',
      email: '',
      category: null,
      banner_image: null,
      logo_image: null,
    },
    validationSchema: Yup.object({
      name_en: Yup.string()
        .required('enNameRequired')
        .matches(NAME_REGEX, 'nameNotFollowREGEX')
        .matches(ENGLISH_REGEX, 'englishLettersOnly'),
      name_ar: Yup.string()
        .required('arNameRequired')
        .matches(NAME_REGEX, 'nameNotFollowREGEX')
        .matches(ARABIC_REGEX, 'arabicLettersOnly'),
      category: Yup.object().nullable().required('categoryRequired'),
      banner_image: Yup.mixed().required('bannerRequired'),
      logo_image: Yup.mixed().required('logoRequired'),
      email: Yup.string(),
    }),
    onSubmit: ({ name_en, name_ar, category, banner_image, logo_image }) => {
      let providerData = { name_en, name_ar, category_id: category.id };
      CheckImageValidationAndUpload(providerData, banner_image, logo_image).then((NewproviderData) => {
        dispatch(setupProfileRequest({ 
          data:{...NewproviderData},
          from:'Auth'
        }));
      });
    },
  });

  const CheckImageValidationAndUpload = async (providerData, banner_image, logo_image) => {
    if (banner_image) {
      if (imgBannerChanged) {
        const data = await uploadToS3(
          banner_image,
          generateUniqueFileName(banner_image.name),
          directories.providers_banners_spp
        );
        providerData.banner_image = data?.link;
      } else {
        providerData.banner_image = banner_image;
      }
    }
    if (logo_image) {
      if (imgLogoChanged) {
        const data = await uploadToS3(
          logo_image,
          generateUniqueFileName(logo_image.name),
          directories.providers_logos_spp
        );
        providerData.logo_image = data?.link;
      } else {
        providerData.logo_image = logo_image;
      }
    }
    return providerData;
  };

  return (
    <div className="setup-profile-page w-100">
      <h3 className="title font-bold fsize-32 mb-2 text-primary">
        {setupProfile.title} <span className="text-secondary">{setupProfile.subTitle}</span>
      </h3>
      <form className="w-100" onSubmit={handleSubmit} noValidate>
        <div className="row mt-4 flex-column-reverse flex-md-row">
          <div className="col-12 my-4 mt-md-0">
            <span className="text-dark font-medium">
              {setupProfile.placeholders.uploadLogo}
              <span className="err-asterisk">*</span>
            </span>
            <Tooltip title={<span className="fsize-12">{shared.filesAccepted} </span>}>
              <span className="text-bg-primary-mid fsize-14 font-reguler ms-2">{shared.acceptedFormatLabel}</span>
            </Tooltip>
            <Upload
              id="logo_image"
              label={
                <div className="mt-2 pt-1 d-flex flex-column align-items-center">
                  <ImageOutlinedIcon fontSize="large" />
                  <span>{setupProfile.placeholders.uploadLogo}</span>
                </div>
              }
              onChange={(img, value) => {
                setImgLogo(img);
                setImgLogoChanged(true);
                setFieldValue('logo_image', value);
              }}
              img={imgLogo || values['logo_image']}
              name="logo_image"
              required={true}
              isInputHasErr={!!(touched['logo_image'] && errors['logo_image'])}
              errMsg={errors['logo_image']}
            />
          </div>

          <div className="col-12">
            <span className="text-dark font-medium">
              {setupProfile.placeholders.banner}
              <span className="err-asterisk">*</span>
            </span>
            <Tooltip title={<span className="fsize-12">{shared.filesAccepted} </span>}>
              <span className="text-bg-primary-mid fsize-14 font-reguler ms-2">{shared.acceptedFormatLabel}</span>
            </Tooltip>
            <Upload
              label={
                <div className="pt-1 d-flex flex-column align-items-center justify-content-center banner-image-upload-initial-container px-5">
                  <ImageOutlinedIcon fontSize="large" />
                  <span>{setupProfile.placeholders.banner}</span>
                </div>
              }
              onChange={(img, value) => {
                setImgBanner(img);
                setImgBannerChanged(true);
                setFieldValue('banner_image', value);
              }}
              img={imgBanner || values['banner_image']}
              name="image"
              isBanner={true}
              className="mt-3 min-height-image"
              bannerClassName="banner-image-upload-container"
              required={true}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12">
            <Input
              id="name_en"
              label={shared.lists.enName}
              placeholder={setupProfile.placeholders.EnName}
              name="name_en"
              type="text"
              required
              onChange={(value) => {
                if (ONE_SPACE_ONLY.test(value) || !value) {
                  setFieldTouched('name_en');
                  setFieldValue('name_en', value);
                }
              }}
              value={values['name_en']}
              inputWrapperClass="mb-4"
              labelClassName="text-dark font-medium mb-2"
              isInputHasErr={!!(touched['name_en'] && errors['name_en'])}
              errMsg={errors['name_en']}
            />
          </div>
          <div className="col-12">
            <Input
              id="name_ar"
              label={shared.lists.arName}
              name="name_ar"
              type="text"
              placeholder={setupProfile.placeholders.ArName}
              required
              onChange={(value) => {
                if (ONE_SPACE_ONLY.test(value) || !value) {
                  setFieldTouched('name_ar');
                  setFieldValue('name_ar', value);
                }
              }}
              value={values['name_ar']}
              inputWrapperClass="mb-4"
              labelClassName="text-dark font-medium mb-2"
              isInputHasErr={!!(touched['name_ar'] && errors['name_ar'])}
              errMsg={errors['name_ar']}
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12">
            <Input
              id="email"
              label={shared.lists.email}
              placeholder={setupProfile.placeholders.email}
              name="email"
              type="text"
              required
              onChange={(value) => {
                setFieldTouched('email');
                setFieldValue('email', value);
              }}
              value={values['email']}
              inputWrapperClass="mb-4"
              labelClassName="text-dark font-medium mb-2"
              isInputHasErr={!!(touched['email'] && errors['email'])}
              errMsg={errors['email']}
              disabled={true}
            />
          </div>

          <div className="col-12">
            <Select
              name="category"
              labelClassName="text-dark font-medium mb-2"
              label={shared.lists.categories}
              options={categoryOptions}
              inputClassName="input-wrapper"
              placeholder={setupProfile.placeholders.category}
              required
              value={values['category']}
              onChange={(value) => {
                setFieldTouched('category');
                setFieldValue('category', value);
              }}
              hasError={!!(touched['category'] && errors['category'])}
              errMsg={errors['category']}
            />
          </div>
        </div>

        <Button
          label={setupProfile.actionBTN}
          type="submit"
          labelClass="fsize-16 text-white py-1 fweight-600 border-radius-10"
          className="my-4"
          block
          disabled={!dirty || !isValid}
        />
      </form>
    </div>
  );
};
export default SetupProfile;
