import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

import messages from '../../../assets/locale/messages';
import pagesTitles from '../../../assets/locale/En/pagesTitles';
import { StarIcon } from '../../../utils/Icons';
import { setPageMainTitle, timeConverter } from '../../../utils/Helpers';
import { weekDays, weekDaysAr } from '../../../utils/Constants';
import { ROUTE_PATHS } from '../../../utils/RoutesPaths';
import EmptyState from '../../../components/EmptyState';
import Rate from '../../../components/Rate';
import Button from '../../../components/Button';
import {
  deleteEmployeeRequest,
  getEmployeeDetailsRequest,
  getEmployeeDetailsResponse,
} from '../../../store/Employees/actions';
import Modal from '../../../components/Modal';

const ViewEmployeesDetails = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { branches, shared, employees } = messages[lang];
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const employeeDetails = useSelector((state) => state.employees.employeeDetails);

  const [selectedEmployeeDetails, setSelectedEmployeeDetails] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  useEffect(() => {
    setPageMainTitle('employeesManagement');
    return () => {
      dispatch(
        getEmployeeDetailsResponse({
          employeeDetails: {},
        })
      );
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getEmployeeDetailsRequest({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (employeeDetails) {
      let selectedEmployeeMappedData = {};
      selectedEmployeeMappedData = { ...employeeDetails };
      let workingDays = [
        {
          id: 0,
          label: shared.days.sat,
          value: weekDays.sat,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 1,
          label: shared.days.sun,
          value: weekDays.sun,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 2,
          label: shared.days.mon,
          value: weekDays.mon,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 3,
          label: shared.days.tus,
          value: weekDays.tus,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 4,
          label: shared.days.wed,
          value: weekDays.wed,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 5,
          label: shared.days.thurs,
          value: weekDays.thurs,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 6,
          label: shared.days.fri,
          value: weekDays.fri,
          isChosen: false,
          timeSlots: [],
        },
      ];
      selectedEmployeeMappedData.employee_working_hours?.forEach((workingDay) => {
        const dayIndex = workingDays.findIndex((day) => day.value.toLowerCase() === workingDay.week_day);
        if (dayIndex !== -1) {
          workingDays[dayIndex].isChosen = true;
          workingDays[dayIndex].timeSlots = workingDay.intervals.map((interval) => {
            return {
              startTime: interval.starts_at,
              endTime: interval.ends_at,
            };
          });
        }
      });
      selectedEmployeeMappedData.workingDays = workingDays;
      setSelectedEmployeeDetails(selectedEmployeeMappedData);
    }
  }, [employeeDetails]);

  const renderDayLabel = (day) => {
    switch (day.id) {
      case 0:
        return lang === 'en' ? weekDays.sat : weekDaysAr.sat;
      case 1:
        return lang === 'en' ? weekDays.sun : weekDaysAr.sun;
      case 2:
        return lang === 'en' ? weekDays.mon : weekDaysAr.mon;
      case 3:
        return lang === 'en' ? weekDays.tus : weekDaysAr.tus;
      case 4:
        return lang === 'en' ? weekDays.wed : weekDaysAr.wed;
      case 5:
        return lang === 'en' ? weekDays.thurs : weekDaysAr.thurs;
      case 6:
        return lang === 'en' ? weekDays.fri : weekDaysAr.fri;

      default:
        return '-';
    }
  };

  const handleDeleteEmployee = () => {
    dispatch(
      deleteEmployeeRequest({
        id,
      })
    );
    setOpenDeleteConfirm(false);
    history.push(ROUTE_PATHS.employees);
  };

  const handleCloseDeleteConfirmModal = () => {
    setOpenDeleteConfirm(false);
  };

  const renderBranchEmployeeDetails = () => {
    return (
      <div className="employeeDetails">
        <div className="d-flex mb-4 flex-column flex-md-row">
          <Typography variant="h4" sx={{ flexGrow: 1 }} className={`page-title ${lang === 'en' ? 'me-5' : 'ms-5'}`}>
            {selectedEmployeeDetails
              ? (selectedEmployeeDetails?.first_name_ar && selectedEmployeeDetails?.last_name_ar) ||
                (selectedEmployeeDetails?.first_name_en && selectedEmployeeDetails?.last_name_en)
                ? lang === 'en'
                  ? selectedEmployeeDetails?.first_name_en + ' ' + selectedEmployeeDetails?.last_name_en
                  : selectedEmployeeDetails?.first_name_ar + ' ' + selectedEmployeeDetails?.last_name_ar
                : pagesTitles.employeeView
              : pagesTitles.employeeView}
          </Typography>
          {Object.keys(employeeDetails).length > 0 && (
            <div className="actions-container d-flex justify-content-end mb-4 mt-4 mt-md-0">
              <Link to={ROUTE_PATHS.editEmployees.replace(':id', id)}>
                <Button
                  label={
                    <>
                      <Edit />
                      <span className="mx-2">{shared.edit}</span>
                    </>
                  }
                  className={`fsize-14 text-white btn-secondary ${lang === 'en' ? 'me-3' : 'ms-3'}`}
                />
              </Link>
              <Button
                label={
                  <>
                    <Delete />
                    <span className="mx-2">{shared.delete}</span>
                  </>
                }
                outlined
                className="fsize-14"
                onClick={() => setOpenDeleteConfirm(true)}
              />
            </div>
          )}
        </div>
        {Object.keys(employeeDetails).length > 0 ? (
          <>
            <div>
              {/* name and phone number */}
              <div className={`bg-white rounded-4 p-4 ${!selectedEmployeeDetails && 'd-flex justify-content-center'}`}>
                <div className="mb-4">
                  {lang === 'en' && (
                    <>
                      <div className="text-dark font-medium mb-2">
                        {branches.addBranch.specialtiesAndServices.employeeFirstNameEn}
                      </div>
                      <div className="text-gray font-medium">{selectedEmployeeDetails?.first_name_en}</div>
                      <div className="text-dark font-medium mb-2">
                        {branches.addBranch.specialtiesAndServices.employeeLastNameEn}
                      </div>
                      <div className="text-gray font-medium">{selectedEmployeeDetails?.last_name_en}</div>
                    </>
                  )}
                  {lang === 'ar' && (
                    <>
                      <div className="text-dark font-medium mb-2">
                        {branches.addBranch.specialtiesAndServices.employeeFirstNameAr}
                      </div>
                      <div className="text-gray font-medium">{selectedEmployeeDetails?.first_name_ar}</div>
                      <div className="text-dark font-medium mb-2">
                        {branches.addBranch.specialtiesAndServices.employeeLastNameAr}
                      </div>
                      <div className="text-gray font-medium">{selectedEmployeeDetails?.last_name_ar}</div>
                    </>
                  )}
                </div>
                <div className="mb-4">
                  <div className="text-dark font-medium mb-2">{branches.addBranch.labels.phone}</div>
                  <div className="text-gray font-medium">{selectedEmployeeDetails?.phone_number || ''}</div>
                </div>
                <div className="mb-2">
                  <div className="text-dark font-medium mb-2">{branches.viewBranch.labels.rating}</div>

                  {selectedEmployeeDetails?.average_rating !== 0 ? (
                    <div className="d-flex align-items-center">
                      <Rate
                        name={'rating Branch'}
                        id={'rating Branch'}
                        rateValue={selectedEmployeeDetails?.average_rating || 0}
                      />
                    </div>
                  ) : (
                    <div className="text-gray fsize-18 d-flex align-items-center gap-2">
                      <StarIcon />
                      <div>{branches.viewBranch.labels.noRating}</div>
                    </div>
                  )}
                </div>
                <div className="mt-4">
                  <div className="text-dark font-medium mb-2">
                    {branches.addBranch.specialtiesAndServices.branchName}
                  </div>
                  <div className="text-gray font-medium">
                    {lang === 'en'
                      ? selectedEmployeeDetails?.branch?.name_en
                      : selectedEmployeeDetails?.branch?.name_ar}
                  </div>
                </div>
              </div>
              {/* working hours */}
              <div className="bg-white rounded-4 p-4 mt-4">
                <div className="text-dark font-medium mb-2">{branches.addBranch.labels.workingHours}</div>
                <div className="days text-gray">
                  {selectedEmployeeDetails?.workingDays?.map((day) => {
                    return (
                      <div
                        className="d-flex gap-3 align-items-md-center align-items-start flex-column flex-md-row mb-3"
                        key={day.id}>
                        <div className="day flex-basis-15 text-secondary fsize-18">
                          {lang === 'en' ? renderDayLabel(day) : renderDayLabel(day)}
                        </div>
                        {day.isChosen ? (
                          <>
                            {day.timeSlots.map((slot, i) => {
                              return (
                                <div className="slot-one bg-section p-2 px-3" key={i}>
                                  {branches.addBranch.specialtiesAndServices.from} :{' '}
                                  {timeConverter(slot.startTime, lang)} -{branches.addBranch.specialtiesAndServices.to}{' '}
                                  : {timeConverter(slot.endTime, lang)}
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className="text-red font-medium">{shared.closed}</div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* location */}
              <div className="bg-white rounded-4 p-4 mt-4">
                <div className="text-dark font-medium mb-2">{branches.addBranch.specialtiesAndServices.location}</div>
                <div className="text-gray">
                  <div>
                    {(selectedEmployeeDetails?.offered_location === 'home' ||
                      selectedEmployeeDetails?.offered_location === 'both') && (
                      <div className="fweight-600 fsize-16"> {branches.addBranch.placeholders.atHome}</div>
                    )}
                  </div>
                  {selectedEmployeeDetails?.offered_location === 'both' && <div className="divider my-3"></div>}
                  <div>
                    {(selectedEmployeeDetails?.offered_location === 'in_place' ||
                      selectedEmployeeDetails?.offered_location === 'both') && (
                      <div className="fweight-600 fsize-16">{branches.addBranch.placeholders.atBranch} </div>
                    )}
                  </div>
                </div>
              </div>
              {/* services */}
              <div className="bg-white rounded-4 p-4 mt-4">
                <div className="text-dark font-medium mb-2">
                  {branches.addBranch.specialtiesAndServices.assignedServices}
                </div>
                <div>
                  {selectedEmployeeDetails?.services?.length > 0 ? (
                    <div>
                      {selectedEmployeeDetails?.services?.map((service, i) => {
                        return (
                          <div key={service.id} className="px-3 py-2  bg-section mb-3 font-medium text-dark fsize-16">
                            {lang === 'en' ? service.title_en : service.title_ar}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="text-red fweight-600 fsize-16">{shared.noServicesAddedyet}</div>
                  )}
                </div>
              </div>
            </div>
            <Modal
              open={openDeleteConfirm}
              handleClose={handleCloseDeleteConfirmModal}
              title={employees.deleteEmployee.title}
              contentClass="mb-5 pb-2 text-primary"
              content={<span>
                        {employees.deleteEmployee.deleteConfirmationMsg}
                        <div className="my-3">
                          <span className="text-secondary">{employees.deleteEmployee.note}: </span>
                          {employees.deleteEmployee.validationNote}
                        </div>
                       </span>
                      }
              confirmBtnTxt={shared.confirm}
              isContentText={false}
              maxWidth="xs"
              cancelBtnTxt={shared.cancel}
              handleConfirm={handleDeleteEmployee}
              handleCancel={handleCloseDeleteConfirmModal}
              modalClass="delete-employee-modal"
              actionsClass="container px-4"
              confirmBtnClass="btn-secondary fsize-14 text-white py-2 "
              cancelBtnClass="fsize-14 py-2"
            />
          </>
        ) : (
          <div className="d-flex justify-content-center px-2 px-md-5 py-5 bg-white rounded-4">
            <EmptyState
              subTitle={shared.noDataToShow}
              ButtonLabel={shared.back}
              handleBtnAction={() => {
                history.push(ROUTE_PATHS.employees);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  return <>{employeeDetails && renderBranchEmployeeDetails()}</>;
};

export default ViewEmployeesDetails;
