import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AccessTimeOutlined, CreditCardOutlined, ReceiptLongOutlined } from '@mui/icons-material';

import messages from '../../../../assets/locale/messages';
import Accordion from '../../../../components/Accordion';
import Switch from '../../../../components/Switch';
import EmptyState from '../../../../components/EmptyState';
import { getBranchServicesRequest } from '../../../../store/Branches/actions';
import './ViewServicesDetails.scss';

const ViewServicesDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const lang = useSelector((state) => state.locale.lang);
  const branchData = useSelector((state) => state.branches.addEditViewBranch.branchDetails);
  const branchServicesList = useSelector((state) => state.branches.addEditViewBranch.helpers.branchServices);
  const { branches, shared } = messages[lang];
  const [branchDetails, setBranchDetails] = useState(null);

  useEffect(() => {
    if (branchData?.specialties?.length > 0) {
      setBranchDetails(branchData);

      branchDetails?.specialties?.map((specialty) => {
        specialty.isExpanded = false;
        specialty.services = [];
        return specialty;
      });

      dispatch(
        getBranchServicesRequest({
          page: -1,
          branch_id: branchData?.id,
        })
      );
    }
  }, [branchData]);

  useEffect(() => {
    if (branchServicesList?.length > 0) {
      branchDetails?.specialties?.map((specialty) => {
        specialty.services = branchServicesList.filter((service) => service.branch_specialty_id === specialty.id);
        return specialty;
      });
    }
  }, [branchServicesList]);

  const arrowRotateAction = (value, specialty) => {
    handleExpandFuntion(value);
    branchDetails.specialties.map((spec) => {
      if (spec.id === specialty.id) {
        specialty.isExpanded = value;
      }
      return specialty;
    });
  };

  const handleExpandFuntion = (isExpanded) => {
    if (isExpanded) {
      dispatch(
        getBranchServicesRequest({
          page: -1,
          branch_id: branchData?.id,
        })
      );
    } else {
      dispatch(
        getBranchServicesRequest({
          page: -1,
          branch_id: branchData?.id,
        })
      );
    }
  };

  return (
    <div className="view-services-details">
      <div className="bg-white rounded-4 p-4">
        {branchDetails?.specialties?.length > 0 ? (
          <>
            <div>
              {branchDetails?.specialties?.map((specialty) => {
                return (
                  <Accordion
                    key={specialty.id}
                    wrapperClassName={'service'}
                    label={
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="fsize-16 fweight-600 text-primary">
                          {lang === 'en' ? specialty.specialty_title_en : specialty.specialty_title_ar}
                        </div>
                      </div>
                    }
                    labelClassName={'text-primary opacity05'}
                    body={
                      <>
                        {specialty?.services?.map((service) => {
                          return (
                            <div
                              className="d-flex justify-content-between align-items-start ps-5 pe-3 mt-3"
                              key={service.id}>
                              <div className="service-content d-flex flex-column">
                                <div className="fsize-18">{lang === 'en' ? service.title_en : service.title_ar}</div>
                                <div className="text-gray fsize-16">
                                  <div className="d-flex text-gray align-items-center flex-wrap gap-2">
                                    <CreditCardOutlined className="text-gray" />
                                    <div className="text-gray fsize-16 mb-0">
                                      {branches.addBranch.specialtiesAndServices.feesRange}:
                                    </div>
                                    <div className="d-flex gap-1">
                                      <span>
                                        {service.fees_to && service.fees_from
                                          ? branches.addBranch.specialtiesAndServices.from
                                          : ''}
                                      </span>
                                      <span>{service.fees_from ? service.fees_from : ' ... '}</span>
                                      <span>
                                        {lang === 'en' ? service.currency?.name_en : service.currency?.name_ar}
                                      </span>
                                    </div>
                                    {service.fees_to && (
                                      <>
                                        <div>
                                          <span>-</span>
                                        </div>
                                        <div className="d-flex gap-1">
                                          <span>{branches.addBranch.specialtiesAndServices.to}</span>
                                          <span>{service.fees_to ?? '...'}</span>
                                          <span>
                                            {lang === 'en' ? service.currency?.name_en : service.currency?.name_ar}
                                          </span>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div className="d-flex my-1 align-items-center gap-2">
                                    <AccessTimeOutlined className="text-gray" />
                                    <div className="text-gray fsize-16 mb-0 d-flex gap-2">
                                      <span>{branches.addBranch.specialtiesAndServices.duration}:</span>
                                      <span>{service.duration_mins ?? ' ... '}</span>
                                      <span> {branches.addBranch.specialtiesAndServices.minutes}</span>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center gap-2">
                                    <ReceiptLongOutlined className="text-gray" />
                                    <div className="text-gray fsize-16 mb-0 d-flex gap-2">
                                      <span>{branches.addBranch.specialtiesAndServices.description}:</span>
                                      <span>
                                        {service.description_en || service.description_ar
                                          ? lang === 'en'
                                            ? service.description_en
                                            : service.description_ar
                                          : '-'}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="service-icons d-flex flex-column-reverse flex-md-row gap-2 align-items-center">
                                <div>
                                  <Switch
                                    checked={service.status === 'published' ? true : false}
                                    name="published"
                                    onChange={() => {}}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    }
                    isExpanded={specialty.isExpanded}
                    arrowRotate={specialty.isExpanded}
                    setArrowRotateValue={(value) => {
                      arrowRotateAction(value, specialty);
                    }}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <div className={` ${!branchDetails?.specialties?.length > 0 && 'mx-auto w-50'}`}>
            <EmptyState
              subTitle={shared.noServicesAddedyet}
              showActionButton={false}
              NoDataListWithNoActionBtn={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewServicesDetails;
