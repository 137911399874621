export const GET_CURRANCIES_LOOKUP_REQUEST = 'GET_CURRANCIES_LOOKUP_REQUEST';
export const GET_CURRANCIES_LOOKUP_RESPONSE = 'GET_CURRANCIES_LOOKUP_RESPONSE';

export const GET_PROVIDERS_LOOKUP_REQUEST = 'GET_PROVIDERS_LOOKUP_REQUEST';
export const GET_PROVIDERS_LOOKUP_RESPONSE = 'GET_PROVIDERS_LOOKUP_RESPONSE';

export const GET_SERVICES_LOOKUP_REQUEST = 'GET_SERVICES_LOOKUP_REQUEST';
export const GET_SERVICES_LOOKUP_RESPONSE = 'GET_SERVICES_LOOKUP_RESPONSE';

export const GET_SPECIALTIES_LOOKUP_REQUEST = 'GET_SPECIALTIES_LOOKUP_REQUEST';
export const GET_SPECIALTIES_LOOKUP_RESPONSE = 'GET_SPECIALTIES_LOOKUP_RESPONSE';

export const GET_BRANCH_SPECIALTIES_LOOKUP_REQUEST = 'GET_BRANCH_SPECIALTIES_LOOKUP_REQUEST';
export const GET_BRANCH_SPECIALTIES_LOOKUP_RESPONSE = 'GET_BRANCH_SPECIALTIES_LOOKUP_RESPONSE';

export const GET_EMPLOYEES_LOOKUP_REQUEST = 'GET_EMPLOYEES_LOOKUP_REQUEST';
export const GET_EMPLOYEES_LOOKUP_RESPONSE = 'GET_EMPLOYEES_LOOKUP_RESPONSE';

export const GET_CATEGORIES_LOOKUP_REQUEST = 'GET_CATEGORIES_LOOKUP_REQUEST';
export const GET_CATEGORIES_LOOKUP_RESPONSE = 'GET_CATEGORIES_LOOKUP_RESPONSE';

export const GET_BRANCHES_LOOKUP_REQUEST = 'GET_BRANCHES_LOOKUP_REQUEST';
export const GET_BRANCHES_LOOKUP_RESPONSE = 'GET_BRANCHES_LOOKUP_RESPONSE';


