import React from "react";

export const EmployeesTabInprogress = () => (
  <svg
    width="122"
    height="123"
    viewBox="0 0 122 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1_102)">
      <rect
        x="4"
        y="4"
        width="114"
        height="114"
        rx="57"
        fill="url(#paint0_linear_1_102)"
        fillOpacity="0.1"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter1_d_1_102)">
      <rect
        x="14"
        y="13"
        width="96"
        height="96"
        rx="46.1957"
        fill="url(#paint1_linear_1_102)"
      />
    </g>
    <g clipPath="url(#clip0_1_102)">
      <path
        d="M81.8062 69.4977C79.2462 69.4977 76.8831 70.0837 74.7169 71.2558L76.4892 73.6C78.0646 72.8186 79.8369 72.4279 81.6092 72.4279C87.32 72.4279 92.0462 76.1395 92.0462 80.6326C92.0462 81.414 92.6369 82.1954 93.6216 82.1954C94.4092 82.1954 95.1969 81.6093 95.1969 80.6326C95 74.3814 89.0923 69.4977 81.8062 69.4977ZM87.7139 54.2605C86.1385 52.5023 83.9723 51.7209 81.8062 51.7209C79.64 51.7209 77.4739 52.5023 75.8985 54.2605C74.3231 55.8233 73.5354 57.9721 73.5354 60.1209C73.5354 62.2698 74.3231 64.4186 75.8985 65.9814C77.4739 67.5442 79.64 68.5209 81.8062 68.5209C83.9723 68.5209 86.1385 67.5442 87.7139 65.9814C89.2892 64.4186 90.0769 62.2698 90.0769 60.1209C90.0769 57.7767 89.2892 55.6279 87.7139 54.2605ZM85.5477 63.8326C83.5785 65.786 80.0339 65.786 77.8677 63.8326C76.8831 62.8558 76.2923 61.4884 76.2923 60.1209C76.2923 58.7535 76.8831 57.386 77.8677 56.214C78.8523 55.2372 80.2308 54.6512 81.6092 54.6512C82.9877 54.6512 84.3662 55.2372 85.3508 56.214C86.3354 57.1907 86.9262 58.5581 86.9262 60.1209C87.1231 61.4884 86.5323 62.8558 85.5477 63.8326Z"
        fill="white"
      />
      <path
        d="M51.2831 71.0605C50.6923 70.6698 50.1015 70.4744 49.5108 70.2791C47.9354 69.693 46.1631 69.3023 44.3908 69.3023C36.9077 69.4977 31 74.3814 31 80.6326C31 81.414 31.5908 82.1954 32.5754 82.1954C33.3631 82.1954 34.1508 81.6093 34.1508 80.6326C34.1508 76.1395 38.8769 72.4279 44.5877 72.4279C45.5723 72.4279 46.7538 72.6233 47.7385 72.8186C48.3292 73.014 49.1169 73.2093 49.7077 73.4047C49.3138 73.2093 50.1015 73.6 49.7077 73.4047C50.2985 72.6233 51.2123 71.5863 52 71.0002C51.2123 70.8049 51.6769 71.2558 51.2831 71.0605ZM50.2985 54.2605C48.7231 52.5023 46.5569 51.7209 44.3908 51.7209C42.2246 51.7209 40.0585 52.5023 38.4831 54.2605C36.9077 55.6279 35.9231 57.7767 35.9231 60.1209C35.9231 62.2698 36.7108 64.4186 38.2862 65.9814C39.8615 67.5442 42.0277 68.5209 44.1938 68.5209C46.36 68.5209 48.5262 67.5442 50.1015 65.9814C51.6769 64.4186 52.4646 62.2698 52.4646 60.1209C52.6615 57.7767 51.8738 55.6279 50.2985 54.2605ZM48.1323 63.8326C46.1631 65.786 42.6185 65.786 40.4523 63.8326C39.4677 62.8558 38.8769 61.4884 38.8769 60.1209C38.8769 58.7535 39.4677 57.386 40.4523 56.214C41.4369 55.2372 42.8154 54.6512 44.3908 54.6512C45.7692 54.6512 47.1477 55.2372 48.1323 56.214C49.1169 57.1907 49.7077 58.5581 49.7077 60.1209C49.7077 61.4884 49.1169 62.8558 48.1323 63.8326Z"
        fill="white"
      />
      <path
        d="M63.0985 65.3953C57.7815 65.3953 52.8585 67.3488 49.5108 70.2791C48.7231 71.0605 47.9354 71.8419 47.3446 72.6233C45.7692 74.9674 44.7846 77.507 44.7846 80.4372C44.7846 81.2186 45.3754 82 46.36 82C47.1477 82 47.9354 81.414 47.9354 80.4372C47.9354 79.4605 48.1323 78.4837 48.3292 77.507C48.7231 76.1395 49.5108 74.7721 50.2985 73.7953C50.8892 73.014 51.6769 72.2326 52.4646 71.6465C53.0554 71.2558 53.4492 70.8651 54.04 70.6698C56.6 69.107 59.7508 68.3256 63.0985 68.3256C71.5661 68.3256 78.4585 73.7953 78.4585 80.6326C78.4585 81.4139 79.0492 82.1953 80.0338 82.1953C80.8215 82.1953 81.6092 81.6093 81.6092 80.6326C81.2154 72.2326 73.1415 65.3953 63.0985 65.3953ZM70.9754 43.3209C68.8092 41.1721 66.0523 40 63.0985 40C60.1446 40 57.3877 41.1721 55.2215 43.3209C53.0554 45.4698 51.8738 48.2047 51.8738 51.3302C51.8738 54.2605 53.0554 57.1907 55.2215 59.3395C57.3877 61.4884 60.1446 62.6605 63.0985 62.6605C66.0523 62.6605 69.0061 61.4884 70.9754 59.3395C73.1415 56.9953 74.3231 54.2605 74.3231 51.3302C74.3231 48.2047 73.1415 45.4698 70.9754 43.3209ZM68.8092 57.1907C67.2338 58.7535 65.2646 59.5349 62.9015 59.5349C60.7354 59.5349 58.5692 58.7535 56.9938 57.1907C55.4185 55.6279 54.6308 53.4791 54.6308 51.3302C54.6308 49.1814 55.4185 47.0326 56.9938 45.4698C58.5692 43.907 60.5385 43.1256 62.9015 43.1256C65.0677 43.1256 67.2338 43.907 68.8092 45.4698C70.3846 47.0326 71.1723 49.1814 71.1723 51.3302C71.3692 53.4791 70.3846 55.4326 68.8092 57.1907Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1_102"
        x="0"
        y="0.8"
        width="122"
        height="122"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.8" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.247059 0 0 0 0 0.278431 0 0 0 0 0.321569 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1_102"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1_102"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_1_102"
        x="10"
        y="9.8"
        width="104"
        height="104"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.8" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.247059 0 0 0 0 0.278431 0 0 0 0 0.321569 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1_102"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1_102"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1_102"
        x1="4"
        y1="118"
        x2="139.621"
        y2="118"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3F4752" />
        <stop offset="0.375" stopColor="#3F4752" />
        <stop offset="1" stopColor="#888D94" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_102"
        x1="14"
        y1="109"
        x2="128.207"
        y2="109"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3F4752" />
        <stop offset="0.375" stopColor="#3F4752" />
        <stop offset="1" stopColor="#888D94" />
      </linearGradient>
      <clipPath id="clip0_1_102">
        <rect
          width="64"
          height="42"
          fill="white"
          transform="translate(31 40)"
        />
      </clipPath>
    </defs>
  </svg>
);
