const changePassword = {
  title: "Change",
  subTitle: "password",
  labels: {
    oldPassword: "Current password",
    newPassword: "New password",
    confirmNewPassword: "Confirm new password",
  },
  placeholders: {
    oldPassword: "Enter your current password",
    newPassword: "Enter your new password",
    confirmNewPassword: "Confirm new password",
  },
  actionBTN: "Save",
};

export default changePassword;
