import React from "react";

export const EmployeesTabDone = () => (
<svg width="104" height="105" viewBox="0 0 104 105" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1_114)">
<rect x="4" y="4" width="96" height="96" rx="46.1957" fill="#3F4752"/>
</g>
<g clipPath="url(#clip0_1_114)">
<path d="M71.8062 60.4977C69.2462 60.4977 66.8831 61.0837 64.7169 62.2558L66.4892 64.6C68.0646 63.8186 69.8369 63.4279 71.6092 63.4279C77.32 63.4279 82.0462 67.1395 82.0462 71.6326C82.0462 72.414 82.6369 73.1954 83.6216 73.1954C84.4092 73.1954 85.1969 72.6093 85.1969 71.6326C85 65.3814 79.0923 60.4977 71.8062 60.4977ZM77.7139 45.2605C76.1385 43.5023 73.9723 42.7209 71.8062 42.7209C69.64 42.7209 67.4739 43.5023 65.8985 45.2605C64.3231 46.8233 63.5354 48.9721 63.5354 51.1209C63.5354 53.2698 64.3231 55.4186 65.8985 56.9814C67.4739 58.5442 69.64 59.5209 71.8062 59.5209C73.9723 59.5209 76.1385 58.5442 77.7139 56.9814C79.2892 55.4186 80.0769 53.2698 80.0769 51.1209C80.0769 48.7767 79.2892 46.6279 77.7139 45.2605ZM75.5477 54.8326C73.5785 56.786 70.0339 56.786 67.8677 54.8326C66.8831 53.8558 66.2923 52.4884 66.2923 51.1209C66.2923 49.7535 66.8831 48.386 67.8677 47.214C68.8523 46.2372 70.2308 45.6512 71.6092 45.6512C72.9877 45.6512 74.3662 46.2372 75.3508 47.214C76.3354 48.1907 76.9262 49.5581 76.9262 51.1209C77.1231 52.4884 76.5323 53.8558 75.5477 54.8326Z" fill="white"/>
<path d="M41.2831 62.0605C40.6923 61.6698 40.1015 61.4744 39.5108 61.2791C37.9354 60.693 36.1631 60.3023 34.3908 60.3023C26.9077 60.4977 21 65.3814 21 71.6326C21 72.414 21.5908 73.1954 22.5754 73.1954C23.3631 73.1954 24.1508 72.6093 24.1508 71.6326C24.1508 67.1395 28.8769 63.4279 34.5877 63.4279C35.5723 63.4279 36.7538 63.6233 37.7385 63.8186C38.3292 64.014 39.1169 64.2093 39.7077 64.4047C39.3138 64.2093 40.1015 64.6 39.7077 64.4047C40.2985 63.6233 41.2123 62.5863 42 62.0002C41.2123 61.8049 41.6769 62.2558 41.2831 62.0605ZM40.2985 45.2605C38.7231 43.5023 36.5569 42.7209 34.3908 42.7209C32.2246 42.7209 30.0585 43.5023 28.4831 45.2605C26.9077 46.6279 25.9231 48.7767 25.9231 51.1209C25.9231 53.2698 26.7108 55.4186 28.2862 56.9814C29.8615 58.5442 32.0277 59.5209 34.1938 59.5209C36.36 59.5209 38.5262 58.5442 40.1015 56.9814C41.6769 55.4186 42.4646 53.2698 42.4646 51.1209C42.6615 48.7767 41.8738 46.6279 40.2985 45.2605ZM38.1323 54.8326C36.1631 56.786 32.6185 56.786 30.4523 54.8326C29.4677 53.8558 28.8769 52.4884 28.8769 51.1209C28.8769 49.7535 29.4677 48.386 30.4523 47.214C31.4369 46.2372 32.8154 45.6512 34.3908 45.6512C35.7692 45.6512 37.1477 46.2372 38.1323 47.214C39.1169 48.1907 39.7077 49.5581 39.7077 51.1209C39.7077 52.4884 39.1169 53.8558 38.1323 54.8326Z" fill="white"/>
<path d="M53.0985 56.3953C47.7815 56.3953 42.8585 58.3488 39.5108 61.2791C38.7231 62.0605 37.9354 62.8419 37.3446 63.6233C35.7692 65.9674 34.7846 68.507 34.7846 71.4372C34.7846 72.2186 35.3754 73 36.36 73C37.1477 73 37.9354 72.414 37.9354 71.4372C37.9354 70.4605 38.1323 69.4837 38.3292 68.507C38.7231 67.1395 39.5108 65.7721 40.2985 64.7953C40.8892 64.014 41.6769 63.2326 42.4646 62.6465C43.0554 62.2558 43.4492 61.8651 44.04 61.6698C46.6 60.107 49.7508 59.3256 53.0985 59.3256C61.5661 59.3256 68.4585 64.7953 68.4585 71.6326C68.4585 72.4139 69.0492 73.1953 70.0338 73.1953C70.8215 73.1953 71.6092 72.6093 71.6092 71.6326C71.2154 63.2326 63.1415 56.3953 53.0985 56.3953ZM60.9754 34.3209C58.8092 32.1721 56.0523 31 53.0985 31C50.1446 31 47.3877 32.1721 45.2215 34.3209C43.0554 36.4698 41.8738 39.2047 41.8738 42.3302C41.8738 45.2605 43.0554 48.1907 45.2215 50.3395C47.3877 52.4884 50.1446 53.6605 53.0985 53.6605C56.0523 53.6605 59.0061 52.4884 60.9754 50.3395C63.1415 47.9953 64.3231 45.2605 64.3231 42.3302C64.3231 39.2047 63.1415 36.4698 60.9754 34.3209ZM58.8092 48.1907C57.2338 49.7535 55.2646 50.5349 52.9015 50.5349C50.7354 50.5349 48.5692 49.7535 46.9938 48.1907C45.4185 46.6279 44.6308 44.4791 44.6308 42.3302C44.6308 40.1814 45.4185 38.0326 46.9938 36.4698C48.5692 34.907 50.5385 34.1256 52.9015 34.1256C55.0677 34.1256 57.2338 34.907 58.8092 36.4698C60.3846 38.0326 61.1723 40.1814 61.1723 42.3302C61.3692 44.4791 60.3846 46.4326 58.8092 48.1907Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_1_114" x="0" y="0.8" width="104" height="104" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="0.8"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.247059 0 0 0 0 0.278431 0 0 0 0 0.321569 0 0 0 1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_114"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_114" result="shape"/>
</filter>
<clipPath id="clip0_1_114">
<rect width="64" height="42" fill="white" transform="translate(21 31)"/>
</clipPath>
</defs>
</svg>

);
