const services = {
  servicesList: "الخدمات",
  list: {
    enTitle: "الاسم بالانجليزية",
    arTitle: "الاسم بالعربية",
    specialty: "التخصصات",
    category: "الفئات",
    search: "ابحث عن خدمة",
    noDataSubTitle: "لا توجد خدمات لعرضها حتى الآن",
    noDataDescription: "ابدأ الآن بإضافة خدمة",
    CreateServicesButton: "إضافة خدمة جديدة",
    filter: {
      filtersPlaceHolders: {
        filterBySpecialty: "التخصص",
        filterByCategory: "الفئة",
      },
    },
  },
  addService: {
    title: "أضف خدمة جديدة",
    labels: {
      enTitle: "الاسم بالانجليزية",
      arTitle: "الاسم بالعربية",
      specialty: "التخصص",
    },
    placeholders: {
      enTitle: "أدخل اسم الخدمة بالانجليزية",
      arTitle: "أدخل اسم الخدمة بالعربية",
      specialty: "اختر التخصص",
    },
  },
  editService: {
    title: "تعديل الخدمة",
    labels: {
      edit: "حفظ",
    },
  },
  deleteService: {
    title: "حذف الخدمة",
    deleteConfirmationMsg: "هل أنت متأكد من أنك تريد حذف هذه الخدمة ؟ ",
  },
};

export default services;
