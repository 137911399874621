import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateBookingRequest, getRequestStatus } from '../../../../store/Bookings/actions'
import messages from '../../../../assets/locale/messages';
import Modal from '../../../../components/Modal'
const CustomerHasPaidConfirmationPopup = ({
    open,
    setOpen,
    bookingId
}) => {
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.locale.lang);
    const { bookings, shared} = messages[lang];
    
    const handleClosePopup = () => {
        setOpen(false);
    }
    const handleCustomerHasPaid = () => {
        dispatch(updateBookingRequest({
            data:{
                id: bookingId,
                params:{
                    payment_status: "paid",
                }
            },
            requestType:"edit"
        }))
        dispatch(getRequestStatus(false))
        setOpen(false);
    }
    return (
        <Modal
                open={open}
                handleClose={handleClosePopup}
                title={<span className="fsize-26 fweight-400">{bookings.details.customerHasPaid}</span>}
                contentClass="mb-3 pb-2 text-primary"
                content={<span className="fsize-20 fweight-400">{bookings.details.customerHasPaidPopupContent}</span>}
                confirmBtnTxt={shared.confirm}
                cancelBtnTxt={shared.cancel}
                isContentText={false}
                handleConfirm={()=>{handleCustomerHasPaid()}}
                handleCancel={handleClosePopup}
                maxWidth="sm"
                actionsClass=" px-4"
                confirmBtnClass="btn-secondary fsize-14 text-white py-2 px-5"
                cancelBtnClass="fsize-14 py-2 text-secondary px-5 "
            />
    )
};
export default CustomerHasPaidConfirmationPopup;