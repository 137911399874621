const services = {
  servicesList: "Services",
  list: {
    enTitle: "Title (En)",
    arTitle: "Title (Ar)",
    specialty: "Specialty",
    category: "Category",
    search: "Search for a service",
    noDataSubTitle: "There are no services to display yet",
    noDataDescription: "Start now to add services",
    CreateServicesButton: "Add a new service",
    filter: {
      filtersPlaceHolders: {
        filterByCategory: "Category",
        filterBySpecialty: "Specialty",
      },
    },
  },
  addService: {
    title: "Add new service",
    labels: {
      enTitle: "English title",
      arTitle: "Arabic title",
      specialty: "Specialty",
    },
    placeholders: {
      enTitle: "Enter service english title",
      arTitle: "Enter service arabic title",
      specialty: "select a specialty",
    },
  },
  editService: {
    title: "Edit service",
    labels: {
      edit: "Save",
    },
  },
  deleteService: {
    title: "Delete service",
    deleteConfirmationMsg: "Are you sure you want to delete this service? ",
  },
};

export default services;
