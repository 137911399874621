import React from "react";
import PropTypes from "prop-types";
import { Tooltip as MuiTooltip } from "@mui/material";
import "./Tooltip.scss";

const Tooltip = ({ title, content, placement, className, hasArrow }) => (
  <MuiTooltip
    title={title}
    placement={placement}
    classes={{ popper: className }}
    arrow={hasArrow}>
    {content}
  </MuiTooltip>
);

Tooltip.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired,
  ]).isRequired,
  content: PropTypes.element.isRequired,
  placement: PropTypes.oneOf(["top", "left", "right", "bottom"]),
  className: PropTypes.string,
};

Tooltip.defaultProps = {
  placement: "bottom",
  hasArrow: true,
  className: "default-tooltip",
};

export default Tooltip;
