import React from "react";

export const EditIcon = (props) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 1.25146H8C3 1.25146 1 3.25146 1 8.25146V14.2515C1 19.2515 3 21.2515 8 21.2515H14C19 21.2515 21 19.2515 21 14.2515V12.2515"
        stroke="url(#paint0_linear_1202_1038)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0399 2.27123L7.15988 10.1512C6.85988 10.4512 6.55988 11.0412 6.49988 11.4712L6.06988 14.4812C5.90988 15.5712 6.67988 16.3312 7.76988 16.1812L10.7799 15.7512C11.1999 15.6912 11.7899 15.3912 12.0999 15.0912L19.9799 7.21123C21.3399 5.85123 21.9799 4.27123 19.9799 2.27123C17.9799 0.271229 16.3999 0.911229 15.0399 2.27123V2.27123Z"
        stroke="url(#paint1_linear_1202_1038)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9102 3.40137C14.2417 4.57883 14.8701 5.65143 15.7351 6.51641C16.6001 7.38139 17.6727 8.00978 18.8502 8.34137"
        stroke="url(#paint2_linear_1202_1038)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1202_1038"
          x1="1.5102"
          y1="4.05848"
          x2="20.9006"
          y2="4.38931"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D19985" />
          <stop offset="1" stopColor="#FFBEA3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1202_1038"
          x1="6.43664"
          y1="3.13335"
          x2="21.1756"
          y2="3.38485"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D19985" />
          <stop offset="1" stopColor="#FFBEA3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1202_1038"
          x1="14.0362"
          y1="4.0947"
          x2="18.8256"
          y2="4.17641"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D19985" />
          <stop offset="1" stopColor="#FFBEA3" />
        </linearGradient>
      </defs>
    </svg>
  );
};
