import React from "react";

export const BookingsTabDoneIcon = () => (
  <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="48.5" cy="48.5" r="48.5" fill="#3F4752" />
    <path
      d="M40.2778 29V35.1667M56.7222 29V35.1667M31.0278 43.5739H65.9722M67 42.3611V59.8333C67 66 63.9167 70.1111 56.7222 70.1111H40.2778C33.0833 70.1111 30 66 30 59.8333V42.3611C30 36.1944 33.0833 32.0833 40.2778 32.0833H56.7222C63.9167 32.0833 67 36.1944 67 42.3611Z"
      stroke="white"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.0987 53.0502H56.1172M56.0987 59.2169H56.1172M48.4931 53.0502H48.5137M48.4931 59.2169H48.5137M40.8855 53.0502H40.906M40.8855 59.2169H40.906"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
