import React from "react";

export const DeleteBgIcon = (props) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="35" height="35" rx="11" fill="#F8F9FC" />
      <path
        d="M27 11.98C23.67 11.65 20.32 11.48 16.98 11.48C15 11.48 13.02 11.58 11.04 11.78L9 11.98M14.5 10.97L14.72 9.66C14.88 8.71 15 8 16.69 8H19.31C21 8 21.13 8.75 21.28 9.67L21.5 10.97M24.85 15.14L24.2 25.21C24.09 26.78 24 28 21.21 28H14.79C12 28 11.91 26.78 11.8 25.21L11.15 15.14M16.33 22.5H19.66M15.5 18.5H20.5"
        stroke="url(#paint0_linear_1050_1023)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1050_1023"
          x1="9.45918"
          y1="10.807"
          x2="26.9115"
          y2="11.075"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D19985" />
          <stop offset="1" stopColor="#FFBEA3" />
        </linearGradient>
      </defs>
    </svg>
  );
};
