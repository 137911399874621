import React from "react";
import PropTypes from "prop-types";
import { Avatar as MuiAvatar } from "@mui/material";

import { getNamesInitials } from "../../utils/Helpers";
import "./Avatar.scss";

const Avatar = ({
  imgSrc,
  imgSrcAlt,
  name,
  containerClass,
  size,
  ...props
}) => (
  <div
    className={`avatar-container ${containerClass} ${
      size && size < 40 && "small-avatar"
    } ${size && size > 40 && "large-avatar"}`}>
    {imgSrc ? (
      <MuiAvatar
        alt={imgSrcAlt}
        src={imgSrc}
        sx={{
          width: size,
          height: size,
        }}
        {...props}
      />
    ) : (
      <MuiAvatar
        sx={{
          width: size,
          height: size,
        }}
        {...props}>
        {getNamesInitials(name)}
      </MuiAvatar>
    )}
  </div>
);

Avatar.propTypes = {
  imgSrc: PropTypes.string,
  imgSrcAlt: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  containerClass: PropTypes.string,
};

Avatar.defaultProps = {
  imgSrcAlt: "Avatar",
};

export default Avatar;
