import firebase from "firebase/app";
import "firebase/messaging";
import { notificationsService } from "../utils/NotificationsRxjs";

import config from "./firebaseConfig";

let messaging = null;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app();
}

if (typeof window !== "undefined") {
  messaging = firebase.messaging();
}

export const requestFirbaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((firbaseToken) => {
        resolve(firbaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const onMessageListener = () =>
  messaging.onMessage((payload) => {
    notificationsService.sendNotification(payload);
  });