import {
  MY_PROFILE_DETAILS_REQUEST,
  MY_PROFILE_DETAILS_RESPONSE,
  SETUP_PROFILE_REQUEST,
  SETUP_PROFILE_RESPONSE,
} from './types';

export const getProfileDetailsRequest = () => ({
  type: MY_PROFILE_DETAILS_REQUEST,
});

export const getProfileDetailsResponse = (payload) => ({
  type: MY_PROFILE_DETAILS_RESPONSE,
  payload,
});

export const setupProfileRequest = (payload) => ({
  type: SETUP_PROFILE_REQUEST,
  payload,
});

export const setupProfileResponse = (payload) => ({
  type: SETUP_PROFILE_RESPONSE,
  payload,
});
