import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { TableCell, TableRow } from '@mui/material';

import EmptyState from '../../../../components/EmptyState';
import Table from '../../../../components/Table';
import Pagination from '../../../../components/Pagination';
import Rate from '../../../../components/Rate';
import Button from '../../../../components/Button';
import {
  branchReviewRequestStatusRequest,
  getBranchReviewsRequest,
  getBranchReviewsResponse,
  branchReviewDetailsRequest,
  branchReviewDetailsResponse,
} from '../../../../store/Branches/actions';
import messages from '../../../../assets/locale/messages';
import { ViewBgIcon } from '../../../../utils/Icons';
import { ROUTE_PATHS } from '../../../../utils/RoutesPaths';
import { renderTextEllipsis } from '../../../../utils/Helpers';
import './ViewReviewsDetails.scss';

function ViewReviewsDetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const lang = useSelector((state) => state.locale.lang);
  const isLoading = useSelector((state) => state.loader);
  const branchReviewsList = useSelector((state) => state.branches.addEditViewBranch.reviews.reviewsList.list);
  const meta = useSelector((state) => state.branches.addEditViewBranch.reviews.reviewsList.meta);
  const branchReviewDetails = useSelector(
    (state) => state.branches.addEditViewBranch.reviews.addEditViewReview.reviewDetails
  );
  const branchReviewRequestStatus = useSelector(
    (state) => state.branches.addEditViewBranch.reviews.addEditViewReview.requestStatus
  );

  const { branches, shared } = messages[lang];

  const [page, setPage] = useState(0);
  const [showEmpatyState, setShowEmpatyState] = useState(false);
  const [showReviewsList, setShowReviewsList] = useState(false);
  const [showReviewDetails, setShowReviewDetails] = useState(false);

  const LIST_SECTION = useMemo(() => {
    return 'LIST_SECTION';
  }, []);
  const DETAILS_SECTION = useMemo(() => {
    return 'DETAILS_SECTION';
  }, []);

  useEffect(() => {
    renderSection();
    getBranchReviewsList();
    return () => {
      dispatch(
        getBranchReviewsResponse({
          list: [],
          meta: {},
        })
      );
      dispatch(branchReviewRequestStatusRequest(null));
      dispatch(branchReviewDetailsResponse({}));
    };
  }, []);

  useEffect(() => {
    if (branchReviewsList.length > 0) {
      renderSection(LIST_SECTION);
    }
  }, [branchReviewsList]);

  useEffect(() => {
    if (Object.keys(branchReviewDetails).length > 0) {
      renderSection(DETAILS_SECTION);
    }
  }, [branchReviewDetails]);

  useEffect(() => {
    if (branchReviewRequestStatus) {
      getBranchReviewsList();
      dispatch(branchReviewRequestStatusRequest(null));
    }
  }, [branchReviewRequestStatus]);

  const renderSection = (section) => {
    switch (section) {
      case LIST_SECTION:
        setShowReviewsList(true);
        setShowReviewDetails(false);
        setShowEmpatyState(false);
        break;
      case DETAILS_SECTION:
        setShowReviewDetails(true);
        setShowReviewsList(false);
        setShowEmpatyState(false);
        break;

      default:
        setShowEmpatyState(true);
        setShowReviewsList(false);
        setShowReviewDetails(false);
        break;
    }
  };

  const getBranchReviewsList = () => {
    dispatch(
      getBranchReviewsRequest({
        page: -1,
        branch_id: id,
      })
    );
  };

  const handleViewReviewDetailsClick = (id) => {
    dispatch(
      branchReviewDetailsRequest({
        id,
      })
    );
  };

  const viewEmployeeClick = (id) => {
    history.push(ROUTE_PATHS.viewEmployee.replace(':id', id));
  };

  const showEmpatyStateFunc = () => {
    return (
      <div className="bg-white rounded-4 p-4">
        <div className="mx-auto w-50">
          <EmptyState subTitle={shared.noReviewsAdded} showActionButton={false} NoDataListWithNoActionBtn={true} />
        </div>
      </div>
    );
  };

  const showReviewsListFunc = () => {
    return (
      <div className="reviews-table-list rounded-4">
        <Table
          headlines={[
            branches.viewBranch.branchReviews.customerName,
            branches.viewBranch.branchReviews.appointmentId,
            branches.viewBranch.branchReviews.reviewsContent,
          ]}
          hasActions={true}
          rows={branchReviewsList?.map((row, i) => (
            <TableRow key={row.id || i}>
              <TableCell className="start font-regular text-dark-blue fsize-14 ">
                {renderTextEllipsis(row.customer_name) ?? '-'}
              </TableCell>
              <TableCell className="font-regular text-dark-blue fsize-14 ">
                <div className="d-flex align-items-center justify-content-center gap-2">
                  <p className="m-0">{renderTextEllipsis(row.appointment_id) ?? '-'}</p>
                </div>
              </TableCell>
              <TableCell className="font-regular text-dark-blue fsize-14 ">
                {renderTextEllipsis(row.feedback) ?? '-'}
              </TableCell>
              <TableCell className="font-regular text-dark-blue fsize-14 ">
                <ViewBgIcon
                  className="pointer"
                  onClick={() => {
                    handleViewReviewDetailsClick(row.id);
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        />
        <div className="d-flex justify-content-center py-3">
          {meta?.pages > 1 && (
            <Pagination
              count={meta?.pages}
              page={meta?.currentPage || page}
              handleChange={(event, page) => {
                setPage(page);
              }}
              defaultPage={1}
            />
          )}
        </div>
      </div>
    );
  };

  const showReviewDetailsFunc = () => {
    return (
      <>
        <div className="show-review-details bg-white rounded-4 p-4 mb-4">
          <div className="text-dark font-medium mb-1">{branches.viewBranch.branchReviews.customerName}</div>
          <div className="text-gray font-medium mb-3">{branchReviewDetails?.customer_name ?? '-'}</div>
          <div className="text-dark font-medium mb-1">{branches.viewBranch.branchReviews.appointmentId}</div>
          <div className="text-gray font-medium mb-3">{branchReviewDetails?.appointment_id}</div>
        </div>
        <div className="show-review-details bg-white rounded-4 p-4 mb-4">
          <div className="text-dark font-medium mb-1">{branches.viewBranch.branchReviews.submittedBranchRating}</div>
          <div className="text-gray font-medium mb-3">
            {branchReviewDetails?.rating ? (
              <Rate name={'rating Branch'} id={branchReviewDetails?.id} rateValue={branchReviewDetails.rating} />
            ) : (
              <Rate name={'rating Branch'} id={branchReviewDetails?.id} rateValue={0} />
            )}
          </div>
          <div className="text-dark font-medium mb-1">{branches.viewBranch.branchReviews.reviewsContent}</div>
          <div className="text-gray font-medium mb-3">{branchReviewDetails?.feedback}</div>
        </div>
        <div className="show-review-details bg-white rounded-4 p-4 mb-4">
          <div className="text-dark font-medium mb-1">{branches.viewBranch.branchReviews.submittedEmployeeRating}</div>
          <div className="text-gray font-medium mb-3">
            {branchReviewDetails?.employee_ratings?.length > 0 ? (
              branchReviewDetails?.employee_ratings?.map((emp, i) => {
                return (
                  <div key={emp.id || i} className="mb-2">
                    <div
                      className="pointer"
                      onClick={() => {
                        viewEmployeeClick(emp.id);
                      }}>
                      {emp?.employee_name}
                    </div>
                    <div>
                      {emp?.rating ? (
                        <Rate name={'emp rating'} id={emp?.id} rateValue={emp.rating} />
                      ) : (
                        <Rate name={'emp rating'} id={emp?.id} rateValue={0} />
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div>{branches.viewBranch.branchReviews.noSubmittedEmployeeRating}</div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end">
            <Button
              onClick={() => {
                renderSection(LIST_SECTION);
              }}
              color="info"
              outlined
              label={<span>{shared.back}</span>}
              className="btn-secondary"
              labelClass="fsize-14 px-3 py-1"
            />
          </div>
      </>
    );
  };

  return (
    <>
      <div className="view-reviews-details-page">
        {!isLoading && (
          <div>
            {showEmpatyState && showEmpatyStateFunc()}
            {showReviewsList && showReviewsListFunc()}
            {showReviewDetails && showReviewDetailsFunc()}
          </div>
        )}
      </div>
    </>
  );
}

export default ViewReviewsDetails;
