import { SET_BRANCH_LOCATION } from "./types";

let INITIAL_STATE = {
  branch: {
    location: {
      coords: null,
    },
  },
};

const googleMaps = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BRANCH_LOCATION:
      return {
        ...state,
        branch: {
          ...state.branch,
          location: {
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export default googleMaps;
