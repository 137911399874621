import { notificationsService } from "../utils/NotificationsRxjs";
import { requestFirbaseNotificationPermission } from "./firebase";

import store from "../store";
// import { showHideToast } from "../store/ToastMessage/actions";
import { ROUTE_PATHS } from "../utils/RoutesPaths";
import history from "../routes/History";
import {
  getNotificationsResponse,
  setNotificationSeenRequest,
  addPushNotification,
  getDropdownNotificationsResponse,
} from "../store/Notifications/actions";

export const setFirebaseToken = (token) => {
  localStorage.setItem("fcmToken", token);
};

export const getFirebaseToken = () => {
  return localStorage.getItem("fcmToken");
};

export const clearFirebaseToken = () => {
  localStorage.removeItem("fcmToken");
};

export const generateFirebaseToken = () => {
  requestFirbaseNotificationPermission()
    .then((firebaseToken) => {
      console.log("fcm token", firebaseToken);
      setFirebaseToken(firebaseToken);
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const subscribeOnPushNotifications = () => {
  const subscribtion = notificationsService
    .getNotification()
    .subscribe((notification) => {
      if (notification) {
        console.log("notification", notification);
        const {
          data,
          fcmMessageId,
          notification: { body, title },
        } = notification;
        const created_at = new Date();
        // store.dispatch(
        //   showHideToast({
        //     id: lastNotificationId + 1 || null,
        //     isOpen: true,
        //     title,
        //     body,
        //     action_id: data?.action_id,
        //     click_action: data?.click_action,
        //     is_seen: false,
        //   })
        // );

        store.dispatch(
          addPushNotification({
            badge: data?.badge || 1,
            data: {
              id: fcmMessageId,
              title,
              body,
              click_action: data?.click_action || null,
              action_id: data?.action_id || null,
              created_at,
              is_seen: false,
            },
          })
        );
      }
    });
  return subscribtion;
};

export const notificationsActionsList = [
  {
    action: "open_branch",
    route: ROUTE_PATHS.viewBranch,
  },
  {
    action: "view_appointment",
    route: ROUTE_PATHS.viewBooking,
  },
  {
    action:"publish_request",
    route: ROUTE_PATHS.viewPublishRequest,
  }
];

export const handleNotificationsClickActions = ({
  id,
  click_action,
  action_id,
  is_seen,
}) => {
  const notificationAction = notificationsActionsList.find(
    (elt) => elt.action === click_action
  );
  !is_seen && setNotificationSeen(id);
  click_action &&
    notificationAction &&
    history.push(notificationAction.route?.replace(":id", action_id));
};

export const setNotificationSeen = (id) => {
    //waiting integration
  store.dispatch(setNotificationSeenRequest(id));
};


export const replaceSeenNotificationInList = (notification) => {
  // For main list
  const notificationsList = store.getState().notifications.list;
  const notificationsMeta = store.getState().notifications.meta;
  const notificationIndex = notificationsList.findIndex(
    (elt) => elt.id === notification.id
  );
  notificationsList.splice(notificationIndex, 1, notification);
  store.dispatch(
    getNotificationsResponse({
      list: notificationsList,
      meta: notificationsMeta,
    })
  );
  // For dropdown list
  const notificationsListDP =
    store.getState().notifications.dropdownNotifications;
  const notificationIndexDP = notificationsListDP.findIndex(
    (elt) => elt.id === notification.id
  );
  notificationsListDP.splice(notificationIndexDP, 1, notification);
  store.dispatch(getDropdownNotificationsResponse(notificationsListDP));
};