import { axiosInstance } from '.';

const getBranchesList = async (params) => await axiosInstance.get('/branches', { params });

const addBranch = async (payload) => await axiosInstance.post('/branches', payload);

const editBranch = async ({ id, data }) => await axiosInstance.put(`/branches/${id}`, data);

const deleteBranch = async ({ id }) => await axiosInstance.delete(`/branches/${id}`);

const getBranch = async (id) => await axiosInstance.get(`/branches/${id}`);

const getBranchServicesList = async (params) => await axiosInstance.get('/branch_services', { params });

const editBranchServices = async ({ id, data }) => await axiosInstance.put(`/branch_services/${id}`, data);

const deleteBranchSpecialty = async ({ id }) => await axiosInstance.delete(`/branch_specialties/${id}`);

const getAddressFromCoords = async (data) =>
  await axiosInstance.get(`/countries/data?lat=${data?.lat}&lng=${data?.lng}`);

const deleteBranchService = async ({ id }) => await axiosInstance.delete(`/branch_services/${id}`);

const getServicesList = async (params) => await axiosInstance.get('/services', { params });

const getSpecialtyList = async (params) => await axiosInstance.get('/specialties', { params });

export {
  addBranch,
  getAddressFromCoords,
  getBranch,
  editBranch,
  getBranchServicesList,
  deleteBranchSpecialty,
  editBranchServices,
  deleteBranchService,
  getBranchesList,
  deleteBranch,
  getServicesList,
  getSpecialtyList,
};
