const pagesTitles = {
  adminsManagement: 'Admins management',
  admins: 'List admins',
  adminsAdd: 'Add new admin',
  adminsEdit: 'Edit admin',
  employeesManagement: 'Employees management',
  employees: 'List employees',
  employeesAdd: 'Add new employee',
  employeesEdit: 'Edit employee',
  categoriesManagement: 'Service providers categories management',
  serviceProviderCategories: 'List categories',
  serviceProviderCategoriesAdd: 'Add new category',
  serviceProviderCategoriesEdit: 'Edit category',
  serviceProviderCategoriesView: 'View category',
  employeeView: 'View employee',
  specialtiesManagement: 'Specialties management',
  specialties: 'List specialties',
  specialtiesAdd: 'Add new specialty',
  specialtiesView: 'View specialty',
  specialtiesEdit: 'Edit specialty',
  servicesManagement: 'Services management',
  services: 'List services',
  servicesAdd: 'Add new service',
  servicesEdit: 'Edit service',
  serviceProvidersManagement: 'Service providers management',
  branchesManagement: 'Branches management',
  serviceProviders: 'List service providers',
  serviceProvidersAdd: 'Add new service provider',
  serviceProvidersEdit: 'Edit service provider',
  serviceProvidersView: 'View service provider',
  addProviderBranchEmployees: 'Branch employees',
  branchManagement: 'Branches management',
  branches: 'My Branches',
  addProviderBranch: 'Add new branch',
  editProviderBranch: 'Edit branch details',
  editProviderBranchServicesAndSpecialties: 'Edit branch services & specialties',
  editProviderBranchEmployee: 'Edit branch employee',
  AddBranch: 'Add branch',
  viewBranch: 'View branch',
  branchTabOne: 'View branch details',
  branchTabTwo: 'View branch specialties & services',
  branchTabThree: 'View branch employees',
  branchTabFour: 'Bookings list',
  SupportedRegions: 'Supported districts',
  SelectLocationFromMap: 'Select location from map',
  addProviderBranchSpecialties: 'Specialties & Services',
  bookings: 'List bookings',
  bookingsManagement: 'Bookings management',
  SettingsManagment: 'Settings management',
  paymentTransactionsManagement: 'Payment transactions management',
  paymentTransactions:'Payment transactions list',
};

export default pagesTitles;
