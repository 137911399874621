import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AccordionSummary, FormControlLabel, AccordionDetails, Accordion as MaterialAccordion } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import './Accordion.scss';

const Accordion = ({ label, body, wrapperClassName, isExpanded, labelClassName, setArrowRotateValue }) => {
  const [expanded, setExpanded] = useState(false);
  const [arrowRotate, setArrowRotate] = useState(false);

  useEffect(() => {
    if (isExpanded !== undefined) {
      setExpanded(isExpanded);
    }
  }, [isExpanded]);
  return (
    <div className={`${wrapperClassName}`}>
      <MaterialAccordion expanded={expanded}>
        <AccordionSummary
          onClick={() => {
            setExpanded(!expanded);
            setArrowRotate(!arrowRotate);
            setArrowRotateValue(!arrowRotate);
          }}
          className="pointer"
          expandIcon={<span>{arrowRotate ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</span>}
          aria-label="Expand"
          aria-controls={`additional-actions1-content`}
          id={`additional-actions1-header`}>
          <FormControlLabel
            aria-label="Acknowledge"
            onFocus={(event) => event.stopPropagation()}
            control={<span />}
            // className={`ms-1 ${labelClassName}`}
            className={`ms-1 flex-grow-1 d-block ${labelClassName}`}
            label={label}
          />
        </AccordionSummary>
        <AccordionDetails>{body}</AccordionDetails>
      </MaterialAccordion>
    </div>
  );
};
export default Accordion;

Accordion.propTypes = {
  label: PropTypes.element,
  body: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  wrapperClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  setArrowRotate: PropTypes.func,
  SetArrowRotateValue: PropTypes.func,
  arrowRotate: PropTypes.bool,
  isExpanded: PropTypes.bool,
};
