import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import messages from "../../assets/locale/messages";
import "./Table.scss";

const Table = ({
  headlines,
  rows,
  hasActions,
  hasSizeLimitation,
  className,
}) => {
  const lang = useSelector((state) => state.locale.lang);

  const { actions } = messages[lang].shared;

  return (
    <TableContainer
      component={Paper}
      className={`table-container ${className}`}>
      <MuiTable>
        <TableHead>
          <TableRow>
            {headlines.map((head, i) => (
              <TableCell
                className={`text-gray font-regular fsize-14 ${
                  hasSizeLimitation ? "w-25" : ""
                } ${lang === "ar" && "text-end"}`}
                key={i}>
                {head}
              </TableCell>
            ))}
            {hasActions && (
              <TableCell
                className={`actions-cell text-gray font-regular fsize-14 ${
                  lang === "ar" && "text-end"
                }`}></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </MuiTable>
    </TableContainer>
  );
};

Table.propTypes = {
  headlines: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.node,
  hasActions: PropTypes.bool,
  hasSizeLimitation: PropTypes.bool,
  className: PropTypes.string,
};

Table.defaultProps = {
  headlines: [],
  hasActions: false,
};

export default Table;
