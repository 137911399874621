import { MY_PROFILE_DETAILS_RESPONSE, SETUP_PROFILE_RESPONSE } from './types';

const INITIAL_STATE = {
  myProfile: {},
};

const profileDetails = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MY_PROFILE_DETAILS_RESPONSE:
      return { ...state, myProfile: action.payload };
    case SETUP_PROFILE_RESPONSE:
      return { ...state, myProfile: action.payload };
    default:
      return state;
  }
};

export default profileDetails;
