import { SET_PAGE_TITLE } from "./types";

const INITIAL_STATE = {
  pageTitle: "Mimar",
};

const navigation = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PAGE_TITLE:
      return { ...state, pageTitle: action.payload };
    default:
      return state;
  }
};

export default navigation;
