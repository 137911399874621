import React from "react";

export const ViewBgIcon = (props) => {
  return (
    <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="35" height="35" rx="11" fill="#F8F9FC" />
    <path
      d="M21.3651 17.2797C21.3651 19.2597 19.7651 20.8597 17.7851 20.8597C15.8051 20.8597 14.2051 19.2597 14.2051 17.2797C14.2051 15.2997 15.8051 13.6997 17.7851 13.6997C19.7651 13.6997 21.3651 15.2997 21.3651 17.2797Z"
      stroke="url(#paint0_linear_1054_1201)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.785 25.55C21.315 25.55 24.605 23.47 26.895 19.87C27.795 18.46 27.795 16.09 26.895 14.68C24.605 11.08 21.315 9 17.785 9C14.255 9 10.965 11.08 8.675 14.68C7.775 16.09 7.775 18.46 8.675 19.87C10.965 23.47 14.255 25.55 17.785 25.55Z"
      stroke="url(#paint1_linear_1054_1201)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1054_1201"
        x1="14.3877"
        y1="14.7046"
        x2="21.3295"
        y2="14.8231"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D19985" />
        <stop offset="1" stopColor="#FFBEA3" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1054_1201"
        x1="8.49923"
        y1="11.3228"
        x2="27.4706"
        y2="11.7055"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D19985" />
        <stop offset="1" stopColor="#FFBEA3" />
      </linearGradient>
    </defs>
  </svg>
  );
};
