const shared = {
  home: 'Home',
  language: 'Ar',
  admins: 'Admins',
  lookups: 'Lookups',
  BranchesManagement: 'Branches management',
  Branches: 'Branches',
  BranchesManagement: 'Branches management',
  bookings: 'Bookings',
  employees: 'Employees',
  settings: 'Settings',
  notifications: 'Notifications',
  serviceProviderCategories: 'Service provider categories',
  serviceProviders: 'Service providers',
  noReviewsAdded: 'No Reviews added yet',
  specialties: 'Specialties',
  services: 'Services',
  logout: 'Logout',
  lang: 'العربية',
  back: 'Back',
  noSearch: 'Sorry, No search results found!',
  noServicesAddedyet: 'Sorry, No Services add yet!',
  noSpecialtiesOnCategoryyet: 'Sorry, Category does not contain any specialties yet!',
  noEmployeesAddedyet: 'Sorry, No Employees add yet!',
  noData: 'There is no data yet',
  noDataToShow: 'There is no data',
  actions: 'Actions',
  edit: 'Edit',
  add: 'Add',
  view: 'View',
  delete: 'Delete',
  save: 'Save',
  procced: 'Proceed',
  submit: 'Submit',
  saveAndProcced: 'Save & proceed',
  saveAndPublish: 'Save & request publish',
  cancel: 'Cancel',
  previous: 'Previous',
  filter: 'Filter',
  confirm: 'Confirm',
  copy: 'Copy',
  copied: 'Copied',
  lists: {
    enTitle: 'Title (En)',
    arTitle: 'Title (Ar)',
    enName: 'Name (En)',
    arName: 'Name (Ar)',
    name: 'Name',
    email: 'Email',
    phone: 'Phone Number',
    image: 'Image',
    categories: 'Categories',
    status: 'Status',
    category: 'Category',
    branchName: 'Branch Name',
    location: 'Location',
    rating: 'Rating',
    providerName: 'Provider’s name',
    appointmentId: 'Appointment id',
    customerName: 'Customer name',
    date: 'Date',
    transactionType:'Transaction type',
    paidAmount:'Paid amount',
  },
  filesAccepted: 'Max size: 2MB, accepted formats: jpg, jpeg, png, and bmp',
  acceptedFormatLabel: 'Accepted format',
  noOptions: 'No options',
  generalErrMsg: 'Something went wrong!',
  days: {
    sat: 'Saturday',
    sun: 'Sunday',
    mon: 'Monday',
    tus: 'Tuesday',
    wed: 'Wednesday',
    thurs: 'Thursday',
    fri: 'Friday',
  },
  closed: 'Closed',
  polygonHint: 'To confirm your zone, click inside your polygon after finishing',
  ResetPolygon: 'Reset polygon',
  serviceProvider:'Service provider',
  transactions: 'Payment transactions',
};

export default shared;
