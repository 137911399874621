import {
  ADD_BRANCH_DATA_REQUEST,
  ADD_BRANCH_REQUEST,
  EDIT_BRANCH_REQUEST,
  GET_BRANCH_REQUEST,
  GET_BRANCH_RESPONSE,
  ADD_BRANCH_RESPONSE,
  GET_ADDRESS_FROM_COORDS_REQUEST,
  GET_ADDRESS_FROM_COORDS_RESPONSE,
  GET_BRANCH_SERVICES_REQUEST,
  GET_BRANCH_SERVICES_RESPONSE,
  DELETE_BRANCH_SPECIALTY_REQUEST,
  EDIT_BRANCH_SERVICES_REQUEST,
  DELETE_BRANCH_SERVICE_REQUEST,
  DELETE_BRANCH_REQUEST,
  GET_BRANCHES_REQUEST,
  GET_BRANCHES_RESPONSE,
  GET_REQUEST_STATUS,
  ADD_BRANCH_EMPLOYEE_REQUEST,
  DELETE_BRANCH_EMPLOYEE_REQUEST,
  GET_BRANCH_EMPLOYEE_REQUEST_STATUS,
  GET_SERVICES_REQUEST,
  GET_SERVICES_RESPONSE,
  GET_SPECIALTIES_REQUEST,
  GET_SPECIALTIES_RESPONSE,
  EDIT_BRANCH_STATUS_REQUEST,
  GET_BRANCH_REVIEWS_REQUEST,
  GET_BRANCH_REVIEWS_RESPONSE,
  DELETE_BRANCH_REVIEWS_REQUEST,
  BRANCH_REVIEW_REQUEST_STATUS,
  GET_BRANCH_REVIEW_DETAILS_REQUEST,
  GET_BRANCH_REVIEW_DETAILS_RESPONSE,
  GET_PUBLISH_BRANCH_SERVICE_REQUEST_STATUS,
  GET_DELETE_BRANCH_SERVICE_REQUEST_STATUS,
  GET_DELETE_BRANCH_SPECIALITY_REQUEST_STATUS
} from './types';

// get all
export const getBranchesRequest = (payload) => ({
  type: GET_BRANCHES_REQUEST,
  payload,
});

export const getBranchesResponse = (payload) => ({
  type: GET_BRANCHES_RESPONSE,
  payload,
});

// add
export const addBranchRequest = (payload) => ({
  type: ADD_BRANCH_REQUEST,
  payload,
});

export const addBranchResponse = (payload) => ({
  type: ADD_BRANCH_RESPONSE,
  payload,
});

// edit
export const editBranchRequest = (payload) => ({
  type: EDIT_BRANCH_REQUEST,
  payload,
});

// edit Status
export const editBranchStatusRequest = (payload) => ({
  type: EDIT_BRANCH_STATUS_REQUEST,
  payload,
});

export const addBranchDataInStore = (payload) => ({
  type: ADD_BRANCH_DATA_REQUEST,
  payload,
});

// delete
export const deleteBranchRequest = (payload) => ({
  type: DELETE_BRANCH_REQUEST,
  payload,
});

// get by id (view)
export const getBranchRequest = (payload) => ({
  type: GET_BRANCH_REQUEST,
  payload,
});

export const getBranchResponse = (payload) => ({
  type: GET_BRANCH_RESPONSE,
  payload,
});

// *****************************************************************************************

// list Brnach Services
export const getBranchServicesRequest = (payload) => ({
  type: GET_BRANCH_SERVICES_REQUEST,
  payload,
});

export const getBranchServicesResponse = (payload) => ({
  type: GET_BRANCH_SERVICES_RESPONSE,
  payload,
});

// edit branch service
export const editBranchServicesRequest = (payload) => ({
  type: EDIT_BRANCH_SERVICES_REQUEST,
  payload,
});

// delete branch service
export const deleteBranchServiceRequest = (payload) => ({
  type: DELETE_BRANCH_SERVICE_REQUEST,
  payload,
});

// *****************************************************************************************

// request status
export const getRequestStatus = (payload) => ({
  type: GET_REQUEST_STATUS,
  payload,
});

// coords
export const getAddressFromCoordsRequest = (payload) => ({
  type: GET_ADDRESS_FROM_COORDS_REQUEST,
  payload,
});

export const getAddressFromCoordsResponse = (payload) => ({
  type: GET_ADDRESS_FROM_COORDS_RESPONSE,
  payload,
});

// delete branch specialty
export const deleteBranchSpecialtyRequest = (payload) => ({
  type: DELETE_BRANCH_SPECIALTY_REQUEST,
  payload,
});

// *****************************************************************************************
// *****************************************************************************************
// employee actions
// add
export const addBranchEmployeeRequest = (payload) => ({
  type: ADD_BRANCH_EMPLOYEE_REQUEST,
  payload,
});

export const deleteBranchEmployeeRequest = (payload) => ({
  type: DELETE_BRANCH_EMPLOYEE_REQUEST,
  payload,
});

export const getBranchEmployeeRequestStatus = (payload) => ({
  type: GET_BRANCH_EMPLOYEE_REQUEST_STATUS,
  payload,
});

// *****************************************************************************************
// *****************************************************************************************
// services
// list
export const getServicesRequest = (payload) => ({
  type: GET_SERVICES_REQUEST,
  payload,
});

export const getServicesResponse = (payload) => ({
  type: GET_SERVICES_RESPONSE,
  payload,
});

// *****************************************************************************************
// *****************************************************************************************
// specialities
// list
export const getSpecialtiesRequest = (payload) => ({
  type: GET_SPECIALTIES_REQUEST,
  payload,
});

export const getSpecialtiesResponse = (payload) => ({
  type: GET_SPECIALTIES_RESPONSE,
  payload,
});
// *****************************************************************************************
// *****************************************************************************************
// reviews

export const getBranchReviewsRequest = (payload) => ({
  type: GET_BRANCH_REVIEWS_REQUEST,
  payload,
});

export const getBranchReviewsResponse = (payload) => ({
  type: GET_BRANCH_REVIEWS_RESPONSE,
  payload,
});

export const deleteBranchReviewsRequest = (payload) => ({
  type: DELETE_BRANCH_REVIEWS_REQUEST,
  payload,
});

export const branchReviewRequestStatusRequest = (payload) => ({
  type: BRANCH_REVIEW_REQUEST_STATUS,
  payload,
});

export const branchReviewDetailsRequest = (payload) => ({
  type: GET_BRANCH_REVIEW_DETAILS_REQUEST,
  payload,
});

export const branchReviewDetailsResponse = (payload) => ({
  type: GET_BRANCH_REVIEW_DETAILS_RESPONSE,
  payload,
});

export const getPublishBranchServiceRequestStatus = (payload) => ({
  type: GET_PUBLISH_BRANCH_SERVICE_REQUEST_STATUS,
  payload,
});

export const getDeleteBranchServiceStatusRequest = (payload) => ({
  type: GET_DELETE_BRANCH_SERVICE_REQUEST_STATUS,
  payload,
});
export const getDeleteBranchSpecialityStatusRequest = (payload) => ({
  type: GET_DELETE_BRANCH_SPECIALITY_REQUEST_STATUS,
  payload,
});