import { put, call, takeEvery } from 'redux-saga/effects';
import * as apis from '../../network/apis/paymentTransactions';
import * as actions from './actions';
import * as types from './types';
import { showErrorMsg, showSuccessMsg } from '../../utils/Helpers';

function* getpaymentTransactionsListSaga({ payload }) {
    try {
      const response = yield call(apis.getPaymentTransactionsList, payload);
      yield put(
        actions.getPaymentTransactionResponse({
          list: response.data.payment_transactions,
          meta: {
            currentPage: +response.headers['current-page'],
            count: +response.headers['total-count'],
            pages: +response.headers['total-pages'],
          },
        })
      );
      if (+response.headers['current-page'] > +response.headers['total-pages']) {
        yield put(
          actions.getPaymentTransactionRequest({
            ...payload,
            page: +response.headers['total-pages'],
          })
        );
      }
    } catch (error) {
      yield showErrorMsg(error.response?.data?.message);
    }
};

function* exportPaymentTransactionsSaga({ payload }) {
  try {
    const response = yield call(apis.ExportPaymentTransactions, payload);
    yield showSuccessMsg('exportTransactions');
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
};

export default function* PaymentTransactionsSagas() {
    yield takeEvery(types.GET_PAYMENT_TRANSACTIONS_REQUEST, getpaymentTransactionsListSaga);
    yield takeEvery(types.EXPORT_PAYMENT_TRANSACTIONS, exportPaymentTransactionsSaga);
  }