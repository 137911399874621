import { put, call, takeEvery } from 'redux-saga/effects';
import * as apis from '../../network/apis/branches';
import * as employeesApis from '../../network/apis/employees';
import * as reviewssApis from '../../network/apis/reviews';
import * as types from './types';
import * as actions from './actions';
import { showErrorMsg, showSuccessMsg } from '../../utils/Helpers';
import { ROUTE_PATHS } from '../../utils/RoutesPaths';
import history from '../../routes/History';

// get all
function* getBranchesListSaga({ payload }) {
  try {
    const response = yield call(apis.getBranchesList, payload);
    yield put(
      actions.getBranchesResponse({
        list: response.data.branches,
        meta: {
          currentPage: +response.headers['current-page'],
          count: +response.headers['total-count'],
          pages: +response.headers['total-pages'],
        },
      })
    );
    if (+response.headers['current-page'] > +response.headers['total-pages']) {
      yield put(
        actions.getBranchesRequest({
          ...payload,
          page: +response.headers['total-pages'],
        })
      );
    }
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

// add
function* addBranchSaga({ payload }) {
  try {
    const response = yield call(apis.addBranch, payload);
    yield showSuccessMsg('addedSuccessfully');
    yield put(actions.addBranchResponse(response?.data?.branch));
    yield put(actions.getRequestStatus(true));
    // yield history.push(ROUTE_PATHS.serviceBranches);
  } catch (error) {
    yield put(actions.getRequestStatus(error.response?.data?.success));
    yield showErrorMsg(error.response?.data?.message);
  }
}

// edit
function* editBranchSaga({ payload }) {
  try {
    const response = yield call(apis.editBranch, payload);
    if (payload.requestType === 'requestSentSuccessfully') {
      yield showSuccessMsg('requestSentSuccessfully');
    } else {
      yield showSuccessMsg('editedSuccessfully');
    }
    yield put(actions.addBranchResponse(response?.data?.branch));
    yield put(actions.editBranchStatusRequest(response?.data?.success));
  } catch (error) {
    yield put(actions.editBranchStatusRequest(error.response?.data?.success));
    yield showErrorMsg(error.response?.data?.message);
  }
}

// delete
function* deleteBranchSaga({ payload }) {
  try {
    yield call(apis.deleteBranch, payload);
    yield showSuccessMsg('deletedSuccessfully');
    if (payload.currentPage) {
      yield put(
        actions.getBranchesRequest({
          items: 10,
          page: payload.currentPage,
        })
      );
    } else {
      yield history.push(ROUTE_PATHS.branches);
    }
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

// get by id (view)

function* getBranchSaga({ payload }) {
  try {
    const response = yield call(apis.getBranch, payload);
    yield put(
      actions.getBranchResponse({
        branchDetails: response.data.branch,
      })
    );
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

// *****************************************************************************************
// get branch services
function* getBranchServicesListSaga({ payload }) {
  try {
    const response = yield call(apis.getBranchServicesList, payload);
    yield put(
      actions.getBranchServicesResponse({
        branch_services: response.data.branch_services,
        meta: {
          currentPage: +response.headers['current-page'],
          count: +response.headers['total-count'],
          pages: +response.headers['total-pages'],
        },
      })
    );
    if (+response.headers['current-page'] > +response.headers['total-pages']) {
      yield put(
        actions.getBranchServicesRequest({
          ...payload,
          page: +response.headers['total-pages'],
        })
      );
    }
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

// edit branch services
function* editBranchServicesSaga({ payload }) {
  try {
    const response = yield call(apis.editBranchServices, payload);
    yield showSuccessMsg('editedSuccessfully');
    yield put(actions.getPublishBranchServiceRequestStatus(response?.data?.success))
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
    yield put(actions.getPublishBranchServiceRequestStatus(error.response?.data?.success))
  }
}

// delete branch services
function* deleteBranchServiceSaga({ payload }) {
  try {
    const response = yield call(apis.deleteBranchService, payload);
    yield showSuccessMsg('deletedSuccessfully');
    yield put(actions.getDeleteBranchServiceStatusRequest(response?.data?.success));
    // if (payload.currentPage) {
    //   yield put(
    //     actions.getCategoriesRequest({
    //       items: 10,
    //       page: payload.currentPage,
    //     })
    //   );
    // } else {
    //   yield history.push(ROUTE_PATHS.serviceProviderCategories);
    // }
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
    yield put(actions.getDeleteBranchServiceStatusRequest(error.response?.data?.success));
  }
}

// *****************************************************************************************
// helpers
function* getAddressFromCoordsSaga({ payload }) {
  try {
    const response = yield call(apis.getAddressFromCoords, payload);
    yield put(actions.getAddressFromCoordsResponse(response.data.data));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* deleteBranchSpecialtySaga({ payload }) {
  try {
    const response = yield call(apis.deleteBranchSpecialty, payload);
    yield showSuccessMsg('deletedSuccessfully');
    yield put(actions.getDeleteBranchSpecialityStatusRequest(response?.data?.success));
    // if (payload.currentPage) {
    //   yield put(
    //     actions.getCategoriesRequest({
    //       items: 10,
    //       page: payload.currentPage,
    //     })
    //   );
    // } else {
    //   yield history.push(ROUTE_PATHS.serviceProviderCategories);
    // }
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
    yield put(actions.getDeleteBranchSpecialityStatusRequest(error.response?.data?.success));
  }
}

//  *****************************************************************************************
//  *****************************************************************************************
// employees
// add
function* addBranchEmployeeSaga({ payload }) {
  try {
    yield call(employeesApis.addEmployee, payload);
    yield showSuccessMsg('addedSuccessfully');
    yield put(actions.getBranchEmployeeRequestStatus(true));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
    yield put(actions.getBranchEmployeeRequestStatus(error.response?.data?.success));
  }
}

// delete
function* deleteBranchEmployeeSaga({ payload }) {
  try {
    yield call(employeesApis.deleteEmployee, payload);
    yield showSuccessMsg('deletedSuccessfully');
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

//  *****************************************************************************************
//  *****************************************************************************************
// services
function* getServicesListSaga({ payload }) {
  try {
    const response = yield call(apis.getServicesList, payload);
    yield put(
      actions.getServicesResponse({
        list: response.data.services,
        meta: {
          currentPage: +response.headers['current-page'],
          count: +response.headers['total-count'],
          pages: +response.headers['total-pages'],
        },
      })
    );
    if (+response.headers['current-page'] > +response.headers['total-pages']) {
      yield put(
        actions.getServicesRequest({
          ...payload,
          page: +response.headers['total-pages'],
        })
      );
    }
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

//  *****************************************************************************************
//  *****************************************************************************************
// specialties
function* getSpecialtiesListSaga({ payload }) {
  try {
    const response = yield call(apis.getSpecialtyList, payload);
    yield put(
      actions.getSpecialtiesResponse({
        list: response.data.specialties,
        meta: {
          currentPage: +response.headers['current-page'],
          count: +response.headers['total-count'],
          pages: +response.headers['total-pages'],
        },
      })
    );
    if (+response.headers['current-page'] > +response.headers['total-pages']) {
      yield put(
        actions.getSpecialtiesRequest({
          ...payload,
          page: +response.headers['total-pages'],
        })
      );
    }
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

//  *****************************************************************************************
//  *****************************************************************************************
// reviews
function* getBranchReviewsList({ payload }) {
  try {
    const response = yield call(reviewssApis.getBranchReviewsList, payload);
    yield put(
      actions.getBranchReviewsResponse({
        list: response.data.branch_reviews,
        meta: {
          currentPage: +response.headers['current-page'],
          count: +response.headers['total-count'],
          pages: +response.headers['total-pages'],
        },
      })
    );
    if (+response.headers['current-page'] > +response.headers['total-pages']) {
      yield put(
        actions.getBranchReviewsResponse({
          ...payload,
          page: +response.headers['total-pages'],
        })
      );
    }
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* deleteBranchReviewSaga({ payload }) {
  try {
    yield call(reviewssApis.deleteBranchReview, payload);
    yield showSuccessMsg('deletedSuccessfully');
    yield put(actions.branchReviewRequestStatusRequest(true));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
    yield put(actions.branchReviewRequestStatusRequest(error.response?.data?.success));
  }
}

function* getBranchReviewDetailsSage({ payload }) {
  try {
    const response = yield call(reviewssApis.getBranchReviewDetails, payload);
    yield put(actions.branchReviewDetailsResponse(response.data.branch_review));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

export default function* BranchesSagas() {
  yield takeEvery(types.GET_BRANCHES_REQUEST, getBranchesListSaga);
  yield takeEvery(types.ADD_BRANCH_REQUEST, addBranchSaga);
  yield takeEvery(types.EDIT_BRANCH_REQUEST, editBranchSaga);
  yield takeEvery(types.DELETE_BRANCH_REQUEST, deleteBranchSaga);
  yield takeEvery(types.GET_BRANCH_REQUEST, getBranchSaga);
  yield takeEvery(types.GET_BRANCH_SERVICES_REQUEST, getBranchServicesListSaga);
  yield takeEvery(types.EDIT_BRANCH_SERVICES_REQUEST, editBranchServicesSaga);
  yield takeEvery(types.DELETE_BRANCH_SERVICE_REQUEST, deleteBranchServiceSaga);
  yield takeEvery(types.GET_ADDRESS_FROM_COORDS_REQUEST, getAddressFromCoordsSaga);
  yield takeEvery(types.DELETE_BRANCH_SPECIALTY_REQUEST, deleteBranchSpecialtySaga);
  yield takeEvery(types.ADD_BRANCH_EMPLOYEE_REQUEST, addBranchEmployeeSaga);
  yield takeEvery(types.DELETE_BRANCH_EMPLOYEE_REQUEST, deleteBranchEmployeeSaga);
  yield takeEvery(types.GET_SERVICES_REQUEST, getServicesListSaga);
  yield takeEvery(types.GET_SPECIALTIES_REQUEST, getSpecialtiesListSaga);
  yield takeEvery(types.GET_BRANCH_REVIEWS_REQUEST, getBranchReviewsList);
  yield takeEvery(types.DELETE_BRANCH_REVIEWS_REQUEST, deleteBranchReviewSaga);
  yield takeEvery(types.GET_BRANCH_REVIEW_DETAILS_REQUEST, getBranchReviewDetailsSage);
}
