import { put, call, takeEvery } from 'redux-saga/effects';
import * as apis from '../../network/apis/lookUps';
import * as types from './types';
import * as actions from './actions';
import { showErrorMsg } from '../../utils/Helpers';

function* getCurranciesListLookUpSaga({ payload }) {
  try {
    const response = yield call(apis.getCurranciesListLookUp, payload);
    yield put(actions.getCurranciesLookUpResponse(response?.data?.currencies));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* getProvidersListLookUpSaga({ payload }) {
  try {
    const response = yield call(apis.getProvidersListLookUp, payload);
    yield put(actions.getProvidersLookUpResponse(response?.data?.service_providers));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* getServicesListLookUpSaga({ payload }) {
  try {
    const response = yield call(apis.getServicesListLookUp, payload);
    yield put(actions.getServicesLookUpResponse(response?.data?.services));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* getSpecialtyListLookUpSaga({ payload }) {
  try {
    const response = yield call(apis.getSpecialtyListLookUp, payload);
    yield put(actions.getSpecialtiesLookUpResponse(response?.data?.specialties));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* getBranchSpecialtyListLookUpSaga({ payload }) {
  try {
    const response = yield call(apis.getBranchSpecialtyListLookUp, payload);
    yield put(actions.getBranchSpecialtiesLookUpResponse(response?.data?.branch_specialties));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* getEmployeesListLookUpSaga({ payload }) {
  try {
    const response = yield call(apis.getEmployeesListLookUp, payload);
    yield put(actions.getEmployeesLookUpResponse(response?.data?.employees));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* getCategoriesListLookUpSaga({ payload }) {
  try {
    const response = yield call(apis.getCategoriesListLookUp, payload);
    yield put(actions.getCategoriesLookUpResponse(response?.data?.categories));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* getBranchesListLookUpSaga({ payload }) {
  try {
    const response = yield call(apis.getBranchesListLookUp, payload);
    yield put(actions.getBranchesLookUpResponse(response?.data?.branches));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

export default function* LookUpsSagas() {
  yield takeEvery(types.GET_CURRANCIES_LOOKUP_REQUEST, getCurranciesListLookUpSaga);
  yield takeEvery(types.GET_PROVIDERS_LOOKUP_REQUEST, getProvidersListLookUpSaga);
  yield takeEvery(types.GET_SERVICES_LOOKUP_REQUEST, getServicesListLookUpSaga);
  yield takeEvery(types.GET_SPECIALTIES_LOOKUP_REQUEST, getSpecialtyListLookUpSaga);
  yield takeEvery(types.GET_BRANCH_SPECIALTIES_LOOKUP_REQUEST, getBranchSpecialtyListLookUpSaga);
  yield takeEvery(types.GET_EMPLOYEES_LOOKUP_REQUEST, getEmployeesListLookUpSaga);
  yield takeEvery(types.GET_CATEGORIES_LOOKUP_REQUEST, getCategoriesListLookUpSaga);
  yield takeEvery(types.GET_BRANCHES_LOOKUP_REQUEST, getBranchesListLookUpSaga);
}
