const serviceProviders = {
  serviceProviderList: 'مقدمي الخدمات',
  list: {
    search: 'ابحث عن مقدم خدمة',
    noDataSubTitle: 'لا يوجد مقدمي خدمات لعرضهم حتى الآن',
    noDataDescription: 'ابدأ الآن بإضافة مقدمي خدمات',
    CreateButton: 'إضافة مقدم خدمة جديد',
    category: 'الفئة',
    branches: 'الفروع',
    addBranch: 'اضافة فرع',
    generatePassword: 'انشاء كلمة مرور',
    editStatus: 'تعديل الحالة',
    filter: {
      filtersPlaceHolders: {
        filterByCategory: 'الفئة',
        filterByProviderStatus: 'الحالة',
      },
    },
    suspendedByBlock: 'سيتم حظر مقدم الخدمة بعد انتهاء جميع حجوزاته',
    suspendedByDeactivateProvider: 'سيتم ايقاف مقدم الخدمة بداية من',
    status: {
      active: 'نشط',
      deactivated: 'معطل',
      blocked: 'محظور',
      suspended: 'معلق',
      unpublished: 'غير منشور',
      requestedPublishing: 'طلب نشر',
    },
    statusActions: {
      activate: 'تنشيط',
      block: 'حظر',
      deactivateNow: 'تعطيل فورى',
      deacivateLater: 'تعطيل بداية من تاريخ محدد',
      deacivateLaterTitle: 'تعطيل مقدم الخدمة',
      dateLabel: 'بداية من',
    },
  },
  addServiceProvider: {
    title: 'أضف مقدم خدمة جديد',
    labels: {
      email: 'بريدك الإلكتروني',
    },
    placeholders: {
      email: 'أدخل بريدك الإلكتروني',
      ArName: 'أدخل الاسم بالغة العربية',
      EnName: 'أدخل الاسم بالغة الانجليزية',
      category: 'اختر الفئة',
      uploadLogo: 'شعار',
      banner: 'الغلاف',
    },
  },
  editServiceProvider: {
    title: 'تعديل مقدم الخدمة',
  },
  deleteServiceProvider: {
    title: 'حذف مقدم الخدمة',
    deleteConfirmationMsg: 'هل أنت متأكد من حذف مقدم الخدمة ؟ ',
  },
  viewServiceProvider: {
    title: 'تفاصيل مقدم الخدمة',
    noProvider: 'لم تتم إضافة أي مقدمين خدمة حتى الآن.',
    showPasswordTitle: 'تفاصيل كلمة المرور',
    passwordIs: 'كلمة مرور مقدم الخدمة هي: ',
    generatePassword: 'انشاء كلمة مرور',
    noBranches: 'لم يتم إضافة فروع بعد',
  },
};

export default serviceProviders;
