const forgetPassword = {
  title: "نسيت",
  title2: "كلمة المرور",
  labels: {
    email: "البريد الإلكتروني",
  },
  placeholders: {
    email: "ادخل بريدك الالكتروني",
  },
  actionBTN: "ارسال",
};

export default forgetPassword;
