import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { TableCell, TableRow, Typography } from '@mui/material';

import Rate from '../../components/Rate';
import Modal from '../../components/Modal';
import Table from '../../components/Table';
import Tooltip from '../../components/Tooltip';
import Pagination from '../../components/Pagination';
import DatePicker from '../../components/DatePicker';
import EmptyState from '../../components/EmptyState';
import { ROUTE_PATHS } from '../../utils/RoutesPaths';
import SearchInput from '../../components/SearchInput';
import RadioButtonsGroup from '../../components/RadioButton';
import { renderFormatedDate } from '../../utils/DateHelpers';
import FilterInput from '../../components/Filter/FilterInput';
import FilterLabel from '../../components/Filter/FilterLabel';
import Button from '../../components/Button';
import { DeleteBgIcon, EditBgIcon, StarIcon, ViewBgIcon, EditIcon } from '../../utils/Icons';
import { branchStatusChangeActions, branchStatusList } from '../../utils/Constants';
import { renderTextEllipsis, setPageMainTitle, textContainOnlySpaces } from '../../utils/Helpers';
import {
  getCategoriesLookUpRequest,
  getSpecialtiesLookUpResponse,
  getSpecialtiesLookUpRequest,
  getServicesLookUpRequest,
} from '../../store/LookUps/actions';
import {
  addBranchDataInStore,
  addBranchResponse,
  deleteBranchRequest,
  editBranchRequest,
  editBranchStatusRequest,
  getAddressFromCoordsResponse,
  getBranchesRequest,
  getBranchResponse,
} from '../../store/Branches/actions';
import { getGoogleMapsCoords, getGoogleMapsPolygon, saveBranchData } from '../../store/GoogleMapCoords/actions';
import messages from '../../assets/locale/messages';
import './Branches.scss';

const Branches = () => {
  const lang = useSelector((state) => state.locale.lang);
  const history = useHistory();
  const dispatch = useDispatch();
  const { shared, serviceProviders, pagesTitles, branches, serviceProviderCategories } = messages[lang];

  const isLoading = useSelector((state) => state.loader);
  const list = useSelector((state) => state.branches.listBranches.list);
  const meta = useSelector((state) => state.branches.listBranches.meta);
  const categoriesList = useSelector((state) => state.lookUps.categories);
  const specialtiesList = useSelector((state) => state.lookUps.specialties);
  const servicesList = useSelector((state) => state.lookUps.services);
  const requestStatus = useSelector((state) => state.branches.addEditViewBranch.requestStatus);

  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [filterStatusValue, setFilterfilterStatusValue] = useState(null);
  const [statusChanged, setStatusChanged] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [filterRatingValue, setFilterRatingValue] = useState(null);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [specialtiesOptions, setSpecialtiesOptions] = useState([]);
  const [filterLocationValue, setFilterLocationValue] = useState(null);
  const [openSusbendByTime, setOpenSusbendByTime] = useState(false);
  const [susbendedAt, setSusbendedAt] = useState(null);
  const [providerIdWithSusbendedAt, setProviderIdWithSusbendedAt] = useState(null);
  const [deleteBranchId, setdeleteBranchId] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [filterSpecialtyValue, setFilterSpecialtyValue] = useState([]);
  const [filterServiceValue, setFilterServiceValue] = useState([]);
  const [servicesOptions, setServicesOptions] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [changedStatusValue, setChangedStatusValue] = useState(null);
  const [invalidDeactivatingDate, setInvalidDeactivatingDate] = useState(false);
  const [ratingOptions, setRatingOptions] = useState([
    {
      id: 1,
      label: `1`,
      value: 1,
    },
    {
      id: 2,
      label: '2',
      value: 2,
    },
    {
      id: 3,
      label: '3',
      value: 3,
    },
    {
      id: 4,
      label: '4',
      value: 4,
    },
    {
      id: 5,
      label: '5',
      value: 5,
    },
  ]);
  const [locationOptions, setLocationOptions] = useState([
    {
      id: 1,
      label: branches.list.filter.filtersPlaceHolders.AtHome,
      value: 'home',
    },
    {
      id: 2,
      label: branches.list.filter.filtersPlaceHolders.AtBranch,
      value: 'in_place',
    },
    {
      id: 3,
      label: branches.list.filter.filtersPlaceHolders.both,
      value: 'both',
    },
  ]);
  const statusOptions = [
    {
      id: branchStatusList.active,
      label: serviceProviders.list.status.active,
    },
    {
      id: branchStatusList.deactivated,
      label: serviceProviders.list.status.deactivated,
    },
    {
      id: branchStatusList.deactivationScheduled,
      label: serviceProviders.list.status.suspended,
    },
    {
      id: branchStatusList.unpublished,
      label: serviceProviders.list.status.unpublished,
    },
    {
      id: branchStatusList.requestedPublishing,
      label: serviceProviders.list.status.requestedPublishing,
    },
  ];
  const editStatusActions = [
    {
      id: 0,
      value: branchStatusChangeActions.activate,
      label: serviceProviders.list.statusActions.activate,
    },
    {
      id: 1,
      value: branchStatusChangeActions.deactivateImmediately,
      label: serviceProviders.list.statusActions.deactivateNow,
    },
    {
      id: 2,
      value: branchStatusChangeActions.deactivateLater,
      label: serviceProviders.list.statusActions.deacivateLater,
    },
  ];

  useEffect(() => {
    if(requestStatus){
      getBranches(
        page,
        searchValue,
        filterSpecialtyValue,
        filterLocationValue,
        filterRatingValue,
        filterStatusValue,
        filterServiceValue
      );
    }
  }, [requestStatus])

  useEffect(() => {
    getBranches(
      page,
      searchValue,
      filterSpecialtyValue,
      filterLocationValue,
      filterRatingValue,
      filterStatusValue,
      filterServiceValue
    );
  }, [page]);

  useEffect(() => {
    return () => {
      dispatch(getSpecialtiesLookUpResponse([]));
    };
  }, []);

  useEffect(() => {
    if ((searchValue.length >= 3 || searchValue.length === 0) && !textContainOnlySpaces(searchValue)) {
      setPage(1);
      getBranches(
        1,
        searchValue,
        filterSpecialtyValue,
        filterLocationValue,
        filterRatingValue,
        filterStatusValue,
        filterServiceValue
      );
    }
  }, [searchValue]);

  useEffect(() => {
    setPage(1);
    getBranches(
      1,
      searchValue,
      filterSpecialtyValue,
      filterLocationValue,
      filterRatingValue,
      filterStatusValue,
      filterServiceValue
    );
  }, [filterLocationValue, filterRatingValue, filterServiceValue, filterSpecialtyValue, statusChanged]);

  useEffect(() => {
    setPageMainTitle('branchManagement');
    getSpecialtiesLookup();
    getServicesLookup();
    getCategoriesLookup();
    clearAddBranchSavedDataInStore();
  }, []);

  useEffect(() => {
    if (statusChanged) {
      setPage(1);
      setStatusChanged(false);
    }
  }, []);

  useEffect(() => {
    if (statusChanged) {
      if (filterStatusValue) {
        setFilterfilterStatusValue(statusOptions.find((option) => option.id === filterStatusValue.id));
        setStatusChanged(false);
      }
    }
    if (filterLocationValue)
      setFilterLocationValue(locationOptions.find((option) => option.id === filterLocationValue.id));
  }, [statusOptions, lang, locationOptions]);

  useEffect(() => {
    setSpecialtiesOptions(
      specialtiesList?.map((option) => ({
        id: option.id,
        label: lang === 'en' ? option.name_en : option.name_ar,
      }))
    );
    setServicesOptions(
      servicesList?.map((option) => ({
        id: option.id,
        label: lang === 'en' ? option.title_en : option.title_ar,
      }))
    );
    setStatusChanged(true);
  }, [servicesList, specialtiesList, lang]);

  useEffect(() => {
    setCategoriesOptions(
      categoriesList?.map((option) => ({
        id: option.id,
        label: lang === 'en' ? option.name_en : option.name_ar,
      }))
    );
  }, [categoriesList, lang]);

  useEffect(() => {
    setLocationOptions([
      {
        id: 1,
        label: branches.list.filter.filtersPlaceHolders.AtHome,
        value: 'home',
      },
      {
        id: 2,
        label: branches.list.filter.filtersPlaceHolders.AtBranch,
        value: 'in_place',
      },
      {
        id: 3,
        label: branches.list.filter.filtersPlaceHolders.both,
        value: 'both',
      },
    ]);
  }, [lang]);

  const clearAddBranchSavedDataInStore = () => {
    dispatch(getBranchResponse({}));
    dispatch(addBranchDataInStore({}));
    dispatch(addBranchResponse({}));
    dispatch(getAddressFromCoordsResponse({}));
    dispatch(saveBranchData({}));
    dispatch(getGoogleMapsPolygon([]));
    dispatch(getGoogleMapsCoords({}));
    dispatch(addBranchResponse(null));
  };

  const getBranches = (
    pageNo,
    search,
    arrayOfSpecialties,
    locationValue,
    ratingValue,
    filterStatusValue,
    arrayOfServices
  ) => {
    dispatch(
      getBranchesRequest({
        page: pageNo,
        items: 10,
        query: search,
        specialty_ids: arrayOfSpecialties.join(','),
        service_ids: arrayOfServices?.join(','),
        offered_location: locationValue?.value,
        rating: ratingValue?.value,
        status: filterStatusValue?.id,
      })
    );
  };

  // filters function
  const getCategoriesLookup = () => {
    dispatch(
      getCategoriesLookUpRequest({
        page: -1,
      })
    );
  };

  const getSpecialtiesLookup = () => {
    dispatch(
      getSpecialtiesLookUpRequest({
        page: -1,
      })
    );
  };

  const getServicesLookup = () => {
    dispatch(
      getServicesLookUpRequest({
        page: -1,
      })
    );
  };

  const getFilterServicesValues = () => {
    const services = filterServiceValue
      ?.map((serv) => servicesOptions?.find((elt) => elt?.id === serv))
      ?.map((serv) => ({
        id: serv?.id,
        label: serv?.label,
      }));
    return services;
  };

  const getFilterSpecilatiesValues = () => {
    const specialties = filterSpecialtyValue
      .map((spec) => specialtiesOptions?.find((elt) => elt.id === spec))
      .map((spec) => ({
        id: spec.id,
        label: spec.label,
      }));
    return specialties;
  };

  // delete branch functions
  const handleDeleteClick = (id) => {
    setdeleteBranchId(id);
    setOpenDeleteConfirm(true);
  };

  const handleCloseDeleteConfirmModal = () => {
    setOpenDeleteConfirm(false);
  };

  const handleDeleteBranch = () => {
    dispatch(
      deleteBranchRequest({
        id: deleteBranchId,
        currentPage: meta.currentPage,
      })
    );
    setOpenDeleteConfirm(false);
  };

  const handleStatusClick = (branch) => {
    setSelectedBranch(branch);
    setOpenStatusModal(true);
  };

  // status section
  const renderStatusCase = (row) => {
    if (row.status === branchStatusList.deactivationScheduled && row.deactivation_date) {
      return (
        <Tooltip
          title={`${branches.list.suspendedByDeactivateBranch} ${renderFormatedDate(
            row.deactivation_date
          )}`}
          content={<span>{RenderTitle(row.status)}</span>}
          hasArrow
        />
      );
    } else {
      return <span>{RenderTitle(row.status)}</span>;
    }
  };

  const RenderTitle = (title) => {
    switch (title) {
      case branchStatusList.active:
        return `${serviceProviders.list.status.active}`;
      case branchStatusList.deactivated:
        return `${serviceProviders.list.status.deactivated}`;
      case branchStatusList.blockSuspended:
        return `${serviceProviders.list.status.suspended}`;
      case branchStatusList.unpublished:
        return `${serviceProviders.list.status.unpublished}`;
      case branchStatusList.requestedPublishing:
        return `${serviceProviders.list.status.requestedPublishing}`;
      case branchStatusList.deactivationScheduled:
        return `${serviceProviders.list.status.suspended}`;
      default:
        return `-`;
    }
  };
  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
    setOpenSusbendByTime(false);
    setSusbendedAt(null);
    setSelectedBranch(null);
    setChangedStatusValue(null)
  };

  const handleStatusList = (branch) => {
    let list;
    // active case
    if (branch.status === branchStatusList.active) {
      list = editStatusActions.filter((action) => action.value !== branchStatusChangeActions.activate);
    } else if (
      branch.status === branchStatusList.deactivated) {
        list = editStatusActions.filter(
          (action) =>
            action.value !== branchStatusChangeActions.deactivateImmediately &&
            action.value !== branchStatusChangeActions.deactivateLater
        );
    } else if (branch.status === branchStatusList.deactivationScheduled) { 
      list = editStatusActions.filter((action) => action.value !== branchStatusChangeActions.deactivateLater);
    }
    return list;
  };

  const handleChangeStatus = () => {
    let branchData;
    if (changedStatusValue) {
      switch (changedStatusValue) {
        case branchStatusChangeActions.activate:
          branchData = { status: branchStatusList.active };
          break;
        case branchStatusChangeActions.deactivateImmediately:
          branchData = { status: branchStatusList.deactivated };
          break;
        default:
          branchData = { deactivation_date: susbendedAt };
          break;
      }
    }
    dispatch(editBranchRequest({
      id: selectedBranch.id,
      data: branchData
    }));
    dispatch(editBranchStatusRequest(null));
    handleCloseStatusModal();
  };

  const renderHeaderSection = (showActions) => (
    <>
      <div className="services-header mb-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="title-search d-flex gap-2">
            <Typography
              variant="h4"
              sx={{ flexGrow: 1 }}
              className={`page-title flex-grow-0 ${lang === 'en' ? 'me-3' : 'ms-3'}`}>
              {pagesTitles.branches}
            </Typography>
            {showActions && (
              <SearchInput
                className="filter-label-wrapper"
                placeholder={branches.list.search}
                name="search"
                value={searchValue}
                onChange={(value) => {
                  setSearchValue(value);
                }}
              />
            )}
          </div>
          <Button
            label={
              <span className="d-flex align-items-center">
                <Add fontSize="large" />
                <span className="ps-1 adjust-font-size-small-screen">{branches.addBranch.addBranch}</span>
              </span>
            }
            labelClass="fsize-16 text-white pe-2 adjust-CTA"
            onClick={() => {
              history.push(ROUTE_PATHS.addBranch);
            }}
          />
        </div>
      </div>
      {showActions && (
        <>
          <div className="d-flex gap-1 align-items-start">
            <div className="mt-2">
              <FilterLabel />
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <div className="filter-label-wrapper d-flex flex-column flex-lg-row align-items-center mb-4 gap-2 flex-wrap flex-md-nowrap">
                {/* rating */}
                <FilterInput
                  placeholder={branches.list.filter.filtersPlaceHolders.filterByRating}
                  options={ratingOptions}
                  value={filterRatingValue}
                  renderIcon={true}
                  icon={<StarIcon />}
                  repeateIconBy={ratingOptions?.length}
                  renderIconOnly={true}
                  name="Rating Filter"
                  onChange={(value) => {
                    setFilterRatingValue(value);
                  }}
                  filterWrapperClass="my-0 w-100"
                />
                {/* offered locations ( home / at branch /both) */}
                <FilterInput
                  placeholder={branches.list.filter.filtersPlaceHolders.filterByLocations}
                  options={locationOptions}
                  value={filterLocationValue}
                  name="offered locations Filter"
                  onChange={(value) => {
                    setFilterLocationValue(value);
                  }}
                  filterWrapperClass="my-0 w-100"
                />
                {/* branch status */}
                <FilterInput
                  placeholder={serviceProviders.list.filter.filtersPlaceHolders.filterByProviderStatus}
                  options={statusOptions}
                  value={filterStatusValue}
                  name="branch status Filter"
                  onChange={(value) => {
                    setStatusChanged(true);
                    setFilterfilterStatusValue(value);
                  }}
                  filterWrapperClass="my-0 w-100"
                />
              </div>
              <div className="d-flex gap-2 flex-column flex-lg-row align-items-center w-75">
                {/* specialties → Multi-select */}
                <FilterInput
                  placeholder={serviceProviderCategories.list.filter.filtersPlaceHolders.filterBySpecialty}
                  options={specialtiesOptions || []}
                  value={getFilterSpecilatiesValues()}
                  name="specialty Filter"
                  onChange={(value) => {
                    setFilterSpecialtyValue(value.map((spec) => spec.id));
                  }}
                  inputClassName=""
                  filterWrapperClass="my-0 w-100 max-height-100"
                  isMultiple={true}
                />
                {/* services→ Multi-select */}
                <FilterInput
                  placeholder={branches.list.filter.filtersPlaceHolders.filterByServices}
                  options={servicesOptions || []}
                  value={getFilterServicesValues()}
                  name="services Filter"
                  onChange={(value) => {
                    setFilterServiceValue(value.map((spec) => spec.id));
                  }}
                  inputClassName=""
                  filterWrapperClass="my-0 w-100 max-height-100"
                  isMultiple={true}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );

  const handleViewbranch = (branchId) => {
    history.push(ROUTE_PATHS.viewBranch.replace(':id', branchId));
  };

  const handleEditBranchClick = (branchData) => {
    history.push({
      pathname: ROUTE_PATHS.editBranch.replace(':id', branchData.id),
      state: {
        editMood: true,
        branchDataToEdit: branchData,
        setPageTitle: lang === 'en' ? branchData?.name_en : branchData?.name_ar,
        setPageTitleAr: branchData?.name_ar,
        setPageTitleEn: branchData?.name_en,
        noTranslationForTitle: true,
        setTabIndex: 0,
        cameFrom: ROUTE_PATHS.branches,
      },
    });
  };

  const renderBranchesList = () => {
    if (
      !isLoading &&
      list?.length === 0 &&
      !searchValue &&
      filterSpecialtyValue?.length === 0 &&
      filterServiceValue?.length === 0 &&
      filterLocationValue === null &&
      filterRatingValue === null &&
      filterStatusValue === null
    ) {
      return (
        <>
          {renderHeaderSection(false)}
          <div className="bg-white rounded-4 categoriess-page d-flex justify-content-center align-items-center flex-column">
            <EmptyState
              subTitle={shared.noData}
              description={branches.list.noDataDescription}
              NoDataListWithNoActionBtn={true}
              showActionButton={false}
            />
          </div>
        </>
      );
    } else if (
      !isLoading &&
      (searchValue ||
        filterSpecialtyValue?.length > 0 ||
        filterServiceValue?.length > 0 ||
        filterStatusValue != null ||
        filterRatingValue != null ||
        filterLocationValue != null) &&
      list?.length === 0
    ) {
      return (
        <>
          {renderHeaderSection(true)}
          <div className="bg-white rounded-4 categoriess-page d-flex justify-content-center align-items-center flex-column  mt-3">
            <EmptyState subTitle={shared.noSearch} showActionButton={false} />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="branches-page">
            {renderHeaderSection(true)}
            <div className="bg-white rounded-4 my-3 branches-table-list">
              <Table
                headlines={[shared.lists.branchName, shared.lists.location, shared.lists.rating, shared.lists.status]}
                hasActions={true}
                rows={list?.map((row, i) => (
                  <TableRow key={i}>
                    {/* branchName */}
                    <TableCell
                      className="start font-regular text-dark-blue fsize-14 pointer "
                      onClick={() => {
                        handleViewbranch(row.id);
                      }}>
                      {row.name_en || row.name_ar
                        ? lang === 'en'
                          ? renderTextEllipsis(row.name_en)
                          : renderTextEllipsis(row.name_ar)
                        : '-'}
                    </TableCell>
                    {/* location */}
                    <TableCell className="font-regular text-dark-blue fsize-14 ">
                      {row.offered_location ? (
                        row.offered_location === 'in_place' ? (
                          renderTextEllipsis(row.city) + ', ' + renderTextEllipsis(row.region ?? '-')
                        ) : row.offered_location === 'home' ? (
                          branches.list.atHomeService
                        ) : (
                          <>
                            {/* both case */}
                            {renderTextEllipsis(row.city) + ', ' + renderTextEllipsis(row.region ?? '-')}
                          </>
                        )
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    {/* rating */}
                    <TableCell className="font-regular text-dark-blue fsize-14 ">
                      {row.rating ? (
                        <Rate name={'rating Branch'} id={i} rateValue={row.rating} />
                      ) : (
                        <Rate name={'rating Branch'} id={i} rateValue={0} />
                      )}
                    </TableCell>
                    {/* status */}
                    <TableCell className="font-regular text-dark-blue fsize-14 ">
                      <div className="d-flex flex-column ">
                        <div
                          className={`status status-${row.status} px-3 py-2 border-radius-10 text-center position-relative`}>
                          <span className="d-inline-block me-2">{renderStatusCase(row)}</span>
                          {(row.status==='deactivated' || row.status==='active' || row.status==='deactivation_scheduled') && (
                            <EditIcon
                              className={`pointer position-absolute svg-${row.status}`}
                              onClick={() => {
                                handleStatusClick(row);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className="font-regular text-dark-blue fsize-14 ">
                      <div className="table-icons-container gap-4">
                        {
                          <EditBgIcon
                            className="pointer"
                            onClick={() => {
                              handleEditBranchClick(row);
                            }}
                          />
                        }
                        <ViewBgIcon
                          className="pointer"
                          onClick={() => {
                            handleViewbranch(row.id);
                          }}
                        />

                        <DeleteBgIcon className="pointer" onClick={() => handleDeleteClick(row.id)} />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              />
              <div className="d-flex justify-content-center py-3">
                {meta?.pages > 1 && (
                  <Pagination
                    count={meta?.pages}
                    page={meta?.currentPage || page}
                    handleChange={(event, page) => {
                      setPage(page);
                    }}
                    defaultPage={1}
                  />
                )}
              </div>
            </div>
          </div>
          <Modal
            open={openStatusModal}
            handleClose={handleCloseStatusModal}
            title={serviceProviders.list.editStatus}
            contentClass="mb-3 text-primary"
            content={
              <>
                <RadioButtonsGroup
                  isLabel={false}
                  defaultValue
                  name="providerStatus"
                  list={handleStatusList(selectedBranch ?? {})}
                  onChange={(e) => {
                    if(e.target.value==='deactivate-later'){
                      setOpenSusbendByTime(true)
                    }else{
                      setOpenSusbendByTime(false)
                    }
                    setChangedStatusValue(e.target.value)}}
                />
                {openSusbendByTime && (
                  <div className="mt-3">
                    <DatePicker
                      value={susbendedAt}
                      onChange={(dateValue) => {
                        setSusbendedAt(dateValue);
                      }}
                      name={'susbended at'}
                      disablePast={true}
                      label={serviceProviders.list.statusActions.dateLabel}
                      disableMinToday={true}
                      placement="right"
                      setInvalidDeactivatingDate={setInvalidDeactivatingDate}
                    />
                  </div>
                )}
              </>
            }
            isContentText={false}
            confirmBtnTxt={shared.save}
            maxWidth="xs"
            cancelBtnTxt={shared.cancel}
            handleConfirm={handleChangeStatus}
            handleCancel={handleCloseStatusModal}
            modalClass="status-modal"
            actionsClass="container px-4"
            confirmBtnClass="btn-secondary fsize-14 text-white py-2 "
            cancelBtnClass="fsize-14 py-2"
            disableActionBtn={changedStatusValue ==='deactivate-later' 
                                ? !(susbendedAt && changedStatusValue) || invalidDeactivatingDate 
                                : !changedStatusValue}
          />
          <Modal
            open={openDeleteConfirm}
            handleClose={handleCloseDeleteConfirmModal}
            title={branches.deleteBranch.title}
            contentClass="mb-3 text-primary"
            content={<span>{branches.deleteBranch.deleteConfirmationMsg}</span>}
            isContentText={false}
            confirmBtnTxt={shared.delete}
            maxWidth="xs"
            cancelBtnTxt={shared.cancel}
            handleConfirm={handleDeleteBranch}
            handleCancel={handleCloseDeleteConfirmModal}
            modalClass="delete-categories-modal"
            actionsClass="container px-4"
            confirmBtnClass="btn-secondary fsize-14 text-white py-2 "
            cancelBtnClass="fsize-14 py-2"
          />
        </>
      );
    }
  };

  return <>{renderBranchesList()}</>;
};

export default Branches;
