import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ar from 'react-phone-input-2/lang/ar.json';
import PhoneInput from 'react-phone-input-2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Add,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Close,
  Edit,
  ContentCopy,
  KeyboardArrowDown,
  KeyboardArrowUp,
  SendOutlined,
} from '@mui/icons-material';

import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import Switch from '../../../../components/Switch';
import Checkbox from '../../../../components/Checkbox';
import TimePicker from '../../../../components/TimePicker';
import EmptyState from '../../../../components/EmptyState';
import RadioButtonsGroup from '../../../../components/RadioButton';
import DatePicker from '../../../../components/DatePicker';
import Tooltip from '../../../../components/Tooltip'
import messages from '../../../../assets/locale/messages';
import { NAME_REGEX } from '../../../../utils/Patterns';
import { Locations, LocationsAr, weekDays, weekDaysAr, employeeStatusList, employeeStatusChangeActions } from '../../../../utils/Constants';
import { DeleteBgIcon, EditBgIcon, StarIcon, ViewBgIcon } from '../../../../utils/Icons';
import { ROUTE_PATHS } from '../../../../utils/RoutesPaths';
import { timeConverter } from '../../../../utils/Helpers';
import { renderFormatedDate } from '../../../../utils/DateHelpers';
import {
  addBranchEmployeeRequest,
  addBranchResponse,
  deleteBranchEmployeeRequest,
  editBranchEmployeeRequest,
  editBranchRequest,
  editBranchStatusRequest,
  getBranchEmployeeRequestStatus,
  getBranchRequest,
  getBranchServicesRequest,
} from '../../../../store/Branches/actions';
import { getServicesRequest } from '../../../../store/Branches/actions';
import { editEmployeeRequest, getEditEmployeeStatusRequestStatus } from '../../../../store/Employees/actions'
import './AddEditEmployeesDetails.scss';
import { ARABIC_REGEX } from '../../../../utils/Patterns';
import { ENGLISH_REGEX } from '../../../../utils/Patterns';
import Rate from '../../../../components/Rate';

const AddEditEmployeesDetails = ({
  btnClicked,
  changeSubTitle,
  renderList,
  refreshCurrentTab,
  backToPrevStep,
  parentProps,
  removeMainAddBtn,
  ressetBtnClicked,
}) => {
  const { id } = useParams();
  const lang = useSelector((state) => state.locale.lang);
  const { branches, shared, employees, serviceProviders  } = messages[lang];
  const history = useHistory();
  const dispatch = useDispatch();

  const viewBranchDetails = useSelector((state) => state.branches.addEditViewBranch.branchDetails);
  const branchServicesList = useSelector((state) => state.branches.addEditViewBranch.helpers.branchServices);

  const requestStatus = useSelector(
    (state) => state.branches.addEditViewBranch.employees.addEditViewEmployee.requestStatus
  );
  const editRequestStatus = useSelector((state) => state.branches.addEditViewBranch.requestStatus);
  const editEmployeeStatusRequestStatus = useSelector((state) => state.employees.editEmployeeStatusRequestStatus);

  const [renderAddEditEmployeeSection, setRenderAddEditEmployeeSection] = useState(false);
  const [renderEmployeesListSection, setRenderEmployeesListSection] = useState(false);
  const [renderEmpatyStateSection, setRenderEmpatyStateSection] = useState(false);
  const [openAssignServicesModal, setOpenAssignServicesModal] = useState(false);
  const [showLeaveWarningModal, setShowLeaveWarningModal] = useState(false);
  const [inputLocalization, setInputLocalization] = useState(lang === 'en' ? {} : ar);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [assignedServices, setAssignedServices] = useState([]);
  const [employeesList, setEmployeesList] = useState(null);
  const [specialties, setSpecialties] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [renderServices, setRenderServices] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [formHasLocation, setFormHasLocation] = useState(false);
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
  const [changedStatusValue, setChangedStatusValue] = useState(null);
  const [susbendedAt, setSusbendedAt] = useState(null);
  const [openSusbendByTime, setOpenSusbendByTime] = useState(false);
  const [invalidDeactivatingDate, setInvalidDeactivatingDate] = useState(false);
  const editStatusActions = [
    {
      id: 0,
      value: employeeStatusChangeActions.activate,
      label: serviceProviders.list.statusActions.activate,
    },
    {
      id: 2,
      value: employeeStatusChangeActions.deactivateImmediately,
      label: serviceProviders.list.statusActions.deactivateNow,
    },
    {
      id: 3,
      value: employeeStatusChangeActions.deactivateLater,
      label: serviceProviders.list.statusActions.deacivateLater,
    }
  ];

  useEffect(() => {
    if(editEmployeeStatusRequestStatus){
      dispatch(getBranchRequest(viewBranchDetails?.id));
    }
  }, [editEmployeeStatusRequestStatus])

  useEffect(() => {
    if (requestStatus !== null) {
      if (requestStatus === true) {
        if (employeeId !== null) {
          refreshCurrentTab();
        } else {
          dispatch(getBranchRequest(viewBranchDetails?.id));
          refreshCurrentTab();
          setRenderAddEditEmployeeSection(false);
          setRenderEmployeesListSection(true);
        }
      }
      dispatch(getBranchEmployeeRequestStatus(null));
    }
    if (editRequestStatus !== null) {
      if (editRequestStatus) {
        if (parentProps?.location?.state?.cameFrom) {
          history.push({
            pathname: ROUTE_PATHS.viewBranch.replace(':id', id),
            state: {
              setTabIndex: 2,
            },
          });
        } else {
          history.push(ROUTE_PATHS.branches);
        }
        dispatch(addBranchResponse(null));
      }
      dispatch(editBranchStatusRequest(null));
    }
  }, [requestStatus, editRequestStatus]);

  useEffect(() => {
    if (renderServices && branchServicesList?.length > 0) {
      let servicesList = [];
      branchServicesList?.forEach((service) => {
        let services = {};
        services.id = service.id;
        services.service_id = service.service_id;
        services.branch_specialty_id = service.branch_specialty_id;
        services.name = lang === 'en' ? service.title_en : service.title_ar;
        services.isChecked = false;
        if (assignedServices?.length > 0) {
          assignedServices?.forEach((assignedService) => {
            if (assignedService.id === service.id && assignedService.isChecked) {
              services.isChecked = true;
            }
          });
        }
        servicesList.push(services);
      });
      // setAssignedServices(servicesList);
      setSpecialties(
        specialties?.map((specialty) => {
          servicesList?.forEach((service) => {
            if (specialty.id === service.branch_specialty_id) {
              if (specialty.services?.length > 0) {
                let isExsists = specialty.services?.find((item) => item.id === service.id);
                if (!isExsists) {
                  specialty.services.push(service);
                }
              } else {
                specialty.services.push(service);
              }
            }
          });
          return specialty;
        })
      );
    }
  }, [branchServicesList, renderServices, openAssignServicesModal]);

  useEffect(() => {
    dispatch(getBranchRequest(viewBranchDetails?.id));
  }, []);

  useEffect(() => {
    if (viewBranchDetails?.employees?.length > 0) {
      let employeesList = [];
      viewBranchDetails?.employees?.forEach((employee) => {
        let employees = {};
        employees.id = employee.id;
        employees.name = employee.first_name_en + ' ' + employee.last_name_en;
        employees.nameAr = employee.first_name_ar + ' ' + employee.last_name_ar;
        employees.phone = employee.phone_number;
        employees.status = employee.status;
        employees.deactivationDate = employee.deactivation_date;
        employeesList.push(employees);
      });
      setEmployeesList(employeesList);
    } else {
      // will be activared when we get the real id when brnach is created
      setEmployeesList([]);
    }
  }, [lang,viewBranchDetails?.employees]);

  useEffect(() => {
    if (employeesList?.length === 0) {
      // render empty state
      setRenderEmpatyStateSection(true);
      setRenderEmployeesListSection(false);
      setRenderAddEditEmployeeSection(false);
      changeSubTitle(null);
    } else {
      setRenderEmployeesListSection(true);
      setRenderEmpatyStateSection(false);
      setRenderAddEditEmployeeSection(false);
      changeSubTitle(null);
    }
  }, [employeesList]);

  useEffect(() => {
    if (btnClicked == null || btnClicked == 'list' || renderList) {
      setRenderEmployeesListSection(true);
      changeSubTitle(null);
      setRenderAddEditEmployeeSection(false);
      setRenderEmpatyStateSection(false);
      ressetBtnClicked();
    } else if (btnClicked == 'add') {
      setRenderAddEditEmployeeSection(true);
      changeSubTitle('addEmployee');
      setAssignedServices(null);
      setEmployeeId(null);
      renderEmptyForm();
      setRenderEmployeesListSection(false);
      setRenderEmpatyStateSection(false);
    } else if (btnClicked == 'delete') {
      changeSubTitle('employessDetails');
      handleDeleteClick(selectedEmployee?.id);
      setRenderAddEditEmployeeSection(false);
      setRenderEmployeesListSection(false);
      setRenderEmpatyStateSection(false);
    }
    // refreshCurrentTab();
  }, [btnClicked, renderList]);

  useEffect(() => {
    if (renderServices && branchServicesList?.length > 0) {
      let servicesList = [];
      branchServicesList?.forEach((service) => {
        let services = {};
        services.id = service.id;
        services.service_id = service.service_id;
        services.branch_specialty_id = service.branch_specialty_id;
        services.name = lang === 'en' ? service.title_en : service.title_ar;
        services.isChecked = false;
        if (assignedServices?.length > 0) {
          assignedServices?.forEach((assignedService) => {
            if (assignedService.id === service.id) {
              services.isChecked = true;
            }
          });
        }
        servicesList.push(services);
      });
      // setAssignedServices(servicesList);
      setSpecialties(
        specialties?.map((specialty) => {
          servicesList?.forEach((service) => {
            if (specialty.id === service.branch_specialty_id) {
              if (specialty.services?.length > 0) {
                let isExsists = specialty.services?.find((item) => item.id === service.id);
                if (!isExsists) {
                  specialty.services.push(service);
                }
              } else {
                specialty.services.push(service);
              }
            }
          });
          return specialty;
        })
      );
      setRenderServices(false);
    }
  }, [branchServicesList, renderServices, openAssignServicesModal]);

  useEffect(() => {
    if (openAssignServicesModal) {
      let listOfSpecialties = [];
      if (viewBranchDetails && viewBranchDetails?.specialties?.length > 0) {
        viewBranchDetails?.specialties?.forEach((specialty) => {
          let specialtyWithServices = {};
          specialtyWithServices.id = specialty.id;
          specialtyWithServices.specialty_id = specialty.specialty_id;
          specialtyWithServices.name = specialty.specialty_title_en;
          specialtyWithServices.nameAr = specialty.specialty_title_ar;
          specialtyWithServices.services = [];
          specialtyWithServices.isOpen = false;
          listOfSpecialties.push(specialtyWithServices);
        });
        setSpecialties(listOfSpecialties);
      }
    }
  }, [openAssignServicesModal]);

  useEffect(() => {
    if (renderAddEditEmployeeSection) {
      let locattions = [
        {
          id: 0,
          label: branches.list.filter.filtersPlaceHolders.AtHome,
          value: Locations.atHome,
          isChecked: !!viewBranchDetails?.branch_covered_zones?.length,
          isEditable:
            !!viewBranchDetails?.branch_covered_zones?.length && !!(viewBranchDetails?.lat || viewBranchDetails?.lng),
        },
        {
          id: 1,
          label: branches.list.filter.filtersPlaceHolders.AtBranch,
          value: Locations.atBranch,
          isChecked: viewBranchDetails?.lat || viewBranchDetails?.lng,
          isEditable:
            !!viewBranchDetails?.branch_covered_zones?.length && !!(viewBranchDetails?.lat || viewBranchDetails?.lng),
        },
      ];
      setFieldValue('location', locattions);
      checkFormHasOneChoosenLocation();
    }
  }, [renderAddEditEmployeeSection]);

  useEffect(() => {
    if (lang === 'en') {
      setInputLocalization({});
    } else {
      setInputLocalization(ar);
    }
  }, [lang]);

  useEffect(() => {
    removeMainAddBtn(renderEmpatyStateSection);
  }, [renderEmpatyStateSection]);
  // start useformik
  const {
    values,
    setFieldValue,
    setValues,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      first_name_en: '',
      last_name_en: '',
      first_name_ar: '',
      last_name_ar: '',
      phone: '',
      dial_code: '',
      employee_working_hours: [
        {
          id: 0,
          label: shared.days.sat,
          value: weekDays.sat,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 1,
          label: shared.days.sun,
          value: weekDays.sun,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 2,
          label: shared.days.mon,
          value: weekDays.mon,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 3,
          label: shared.days.tus,
          value: weekDays.tus,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 4,
          label: shared.days.wed,
          value: weekDays.wed,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 5,
          label: shared.days.thurs,
          value: weekDays.thurs,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: 6,
          label: shared.days.fri,
          value: weekDays.fri,
          isChosen: false,
          timeSlots: [],
        },
      ],
      location: [
        {
          id: 0,
          value: Locations.atHome,
          label: branches.list.filter.filtersPlaceHolders.AtHome,
          isChecked: !!viewBranchDetails?.branch_covered_zones?.length,
          isEditable:
            !!viewBranchDetails?.branch_covered_zones?.length && !!(viewBranchDetails?.lat || viewBranchDetails?.lng),
        },
        {
          id: 1,
          value: Locations.atBranch,
          label: branches.list.filter.filtersPlaceHolders.AtBranch,
          isChecked: !!(viewBranchDetails?.lat || viewBranchDetails?.lng),
          isEditable:
            !!viewBranchDetails?.branch_covered_zones?.length && !!(viewBranchDetails?.lat || viewBranchDetails?.lng),
        },
      ],
    },
    validationSchema: Yup.object({
      first_name_en: Yup.string().required('enNameRequired').matches(ENGLISH_REGEX, 'englishLettersOnly'),
      last_name_en: Yup.string().required('enNameRequired').matches(ENGLISH_REGEX, 'englishLettersOnly'),
      first_name_ar: Yup.string().required('arNameRequired').matches(ARABIC_REGEX, 'arabicLettersOnly'),
      last_name_ar: Yup.string().required('arNameRequired').matches(ARABIC_REGEX, 'arabicLettersOnly'),
      phone: Yup.string(),
      employee_working_hours: Yup.array().min(1, 'pickOneWorkingDayAtLeast'),
      location: Yup.array().min(1, 'pickOneCoverageZoneAtLeast'),
    }),
    onSubmit: ({}) => {},
  });

  // end useformik

  const renderEmptyForm = () => {
    resetForm();
  };

  const checkFormHasOneChoosenLocation = () => {
    let validation = values?.location?.some((loc) => loc.isChecked);
    setFormHasLocation(validation);
  };
  const addEditEmployeeAction = async () => {
    let empData = {
      first_name_en: values.first_name_en ?? null,
      last_name_en: values.last_name_en ?? null,
      first_name_ar: values.first_name_ar ?? null,
      last_name_ar: values.last_name_ar ?? null,
      phone_number: values.phone.replace(values.dial_code, '') ?? null,
      dial_code: '+' + values.dial_code ?? null,
      locations: values.location.filter((loc) => loc.isChecked),
      branch_service_ids: [],
      employee_working_hours_attributes: null,
      branch_id: viewBranchDetails?.id,
    };

    let workingDays = values?.employee_working_hours?.filter((day) => {
      if (day.isChosen) {
        return day;
      }
    });

    let workingDaysFilteredSlots = workingDays.map((day) => {
      let filteredSlots = day.timeSlots.filter((slot) => {
        if (slot.starts_at !== null && slot.ends_at !== null) {
          return day;
        }
      });
      day.timeSlots = filteredSlots;
      return day;
    });

    let mappedWorkingDays = workingDaysFilteredSlots.map((day) => {
      let mappedSlots = day.timeSlots.map((slot) => {
        return {
          starts_at: slot.starts_at || '00:00:00',
          ends_at: slot.ends_at || '00:00:00',
        };
      });
      let finalObject = {
        intervals: mappedSlots,
        _destroy: !day.isChosen,
        week_day: day.value.toLowerCase(),
      };

      return finalObject;
    });

    empData.employee_working_hours_attributes = mappedWorkingDays;

    if (assignedServices?.length > 0) {
      empData.branch_service_ids = assignedServices
        .filter((service) => service.isChecked === true)
        ?.map((service) => {
          return service.id;
        });
    } else {
      empData.branch_service_ids = [];
    }
    if (empData.locations?.length > 0) {
      if (empData.locations.length > 1) {
        empData.offered_location = 'both';
      } else {
        if (empData.locations[0].label.toLowerCase() === Locations.atHome.toLowerCase()) {
          empData.offered_location = 'home';
        } else if (empData.locations[0].label.toLowerCase() === Locations.atBranch.toLowerCase()) {
          empData.offered_location = 'in_place';
        }
      }
    }

    dispatch(addBranchEmployeeRequest(empData));
  };

  const handleEditEmployeeStatus = (employee) => {
    setSelectedEmployee(employee)
    setOpenChangeStatusModal(true)
  };
  const handleCloseChangeStatusModal = () => {
    setSelectedEmployee(null);
    setOpenChangeStatusModal(false);
    setChangedStatusValue(null);
    setOpenSusbendByTime(false);
    setSusbendedAt(null);
  };
  const handleStatusList = (employee) => {
    let list;
    if (employee.status === employeeStatusList.active) {
      list = editStatusActions.filter((action) => action.value !== employeeStatusChangeActions.activate);
    } else if (employee.status === employeeStatusList.deactivated) {
      list = editStatusActions.filter(
        (action) =>
          action.value !== employeeStatusChangeActions.deactivateImmediately &&
          action.value !== employeeStatusChangeActions.deactivateLater
      );
    } else if(employee.status === employeeStatusList.suspended){
      list = editStatusActions.filter((action) =>action.value !== employeeStatusChangeActions.deactivateLater);
    }
    return list;
  };
  const RenderTitle = (title) => {
    switch (title) {
      case employeesStatus.active:
        return `${employees.list.statusCases.active}`;
      case employeesStatus.deactivated:
        return `${employees.list.statusCases.deactivated}`;
      case employeesStatus.suspended:
        return `${employees.list.statusCases.suspended}`;
      default:
        return `-`;
    }
  };
  
  const renderStatusCase = (employee) => {
    if (employee.status === employeeStatusList.suspended && employee.deactivationDate) {
      return (
        <Tooltip
          title={`${employees.list.suspendedByDeactivateEmployee} ${renderFormatedDate(
            employee.deactivationDate
          )}`}
          content={<span>{RenderTitle(employee.status)}</span>}
          hasArrow
        />
      );
    } else {
      return <span 
                className={`${employee.status === "deactivated"
                                ? `status-container`
                                :  ``
                          }`}
              >
                {RenderTitle(employee.status)}
              </span>;
    }
  };
  const employeesStatus = useMemo(
    () => ({
      active: 'active',
      deactivated: 'deactivated',
      suspended: 'deactivation_scheduled',
    }),
    []
  );
  const handleChangeStatus = () => {
    let employeeData;
    if (changedStatusValue) {
      switch (changedStatusValue) {
        case employeeStatusChangeActions.activate:
          employeeData = { status: employeeStatusList.active };
          break;
        case employeeStatusChangeActions.deactivateImmediately:
          employeeData = { status: employeeStatusList.deactivated };
          break;
        default:
          employeeData = { deactivation_date: susbendedAt };
          break;
      }
    }
    dispatch(editEmployeeRequest({
      params:{
        id: selectedEmployee?.id,
        data: employeeData,
      },
      from: 'listBranchEmployee'
    }));
    dispatch(getEditEmployeeStatusRequestStatus(null));
    handleCloseChangeStatusModal();
  };

  const renderEmptyState = () => {
    return (
      <div className="bg-white px-2 px-md-5 py-4 rounded-4 services-page d-flex justify-content-center align-items-center flex-column m-4 mt-0">
        <EmptyState
          subTitle={shared.noEmployeesAddedyet}
          ButtonLabel={branches.addBranch.labels.addEmployee}
          handleBtnAction={() => {
            resetForm();
            setAssignedServices([]);
            setEmployeeId(null);
            setRenderEmpatyStateSection(false);
            setRenderAddEditEmployeeSection(true);
            changeSubTitle('addEmployee');
          }}
        />
      </div>
    );
  };

  //   start working days functions
  const changeDayToIsChosen = (dayIsCheck, i) => {
    let oldDataOfWeekDays = JSON.parse(JSON.stringify(values['employee_working_hours']));
    oldDataOfWeekDays[i].isChosen = dayIsCheck;
    if (!dayIsCheck) {
      if (oldDataOfWeekDays[i]?.timeSlots?.length > 0) {
        oldDataOfWeekDays[i].isTimeEnter = false;
      }
    }
    setFieldTouched('employee_working_hours');
    setFieldValue('employee_working_hours', oldDataOfWeekDays);
  };

  const renderFisrtTimeSlot = (day) => {
    if (day) {
      day.timeSlots.push({
        id: 1,
        starts_at: null,
        ends_at: null,
      });
    }
    return;
  };

  const copyToAllWorkingDays = (activeDays, slotToCopy) => {
    let modifyWeekDays = values['employee_working_hours'];
    modifyWeekDays.forEach((day) => {
      if (day.id !== activeDays.id) {
        day.isChosen = true;
        day.timeSlots = [];
        day.isTimeEnter = false;
        activeDays.timeSlots.forEach((slot) => {
          day.timeSlots.push({ ...slot });
        });
      }
    });
    activeDays.isTimeEnter = false;
    setFieldTouched('employee_working_hours');
    setFieldValue('employee_working_hours', modifyWeekDays);
  };

  const addNewSlot = (day) => {
    let modifyWeekDays = values['employee_working_hours'];
    let targetedDay = modifyWeekDays.find((oldDay) => {
      return oldDay.id === day.id;
    });
    targetedDay.timeSlots.push({
      id: targetedDay.timeSlots[targetedDay.timeSlots.length - 1].id++,
      starts_at: '',
      ends_at: '',
    });
    setFieldTouched('employee_working_hours');
    setFieldValue('employee_working_hours', modifyWeekDays);
  };

  const removeSlot = (day, indexOfRemovedSlot) => {
    let modifyWeekDays = values['employee_working_hours'];
    let targetedDay = modifyWeekDays.find((oldDay) => {
      return oldDay.id === day.id;
    });
    targetedDay.timeSlots.splice(indexOfRemovedSlot, 1);
    targetedDay.isTimeEnter = true;
    setFieldTouched('employee_working_hours');
    setFieldValue('employee_working_hours', modifyWeekDays);
  };

  const changeSlotTime = (day, time, indexOfSlot, fromOrTo) => {
    let modifyWeekDays = values['employee_working_hours'];
    let targetedDay = modifyWeekDays.find((oldDay) => {
      return oldDay.id === day.id;
    });
    let targetedSlot = targetedDay.timeSlots.find((slot, i) => i === indexOfSlot);
    if (fromOrTo === 'starts_at') {
      targetedSlot.starts_at = time;
    } else {
      targetedSlot.ends_at = time;
    }
    if (targetedSlot.starts_at && targetedSlot.ends_at) {
      targetedDay.isTimeEnter = true;
    }
    setFieldTouched('employee_working_hours');
    setFieldValue('employee_working_hours', modifyWeekDays);
  };

  const formTimeToFullDate = (time) => {
    let day = new Date().toLocaleDateString();
    let fullDate = `${day} ${time}`;
    return fullDate;
  };

  const renderWorkingHoursBody = () => {
    return (
      <>
        {values['employee_working_hours']?.map((day, i) => {
          return (
            <div className="d-flex flex-column justify-content-between py-1" key={i}>
              <div className="woring-hours-day ">
                <div className="d-flex gap-3 align-items-center">
                  <div className="flex-basis-25">
                    <Checkbox
                      color="secondary"
                      label={lang === 'en' ? renderDayLabel(day) : renderDayLabel(day)}
                      labelClass="font-reguler text-dark fsize-16"
                      name={day.label}
                      id={day.label}
                      checked={day.isChosen}
                      onChange={(elementIsChecked) => {
                        setFieldTouched('employee_working_hours');
                        changeDayToIsChosen(elementIsChecked, i);
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-column gap-3">
                      {day.isChosen ? (
                        <span className="time-slots">{renderTimeSlots(day)}</span>
                      ) : (
                        <span className="time-slots text-red fweight-600">{shared.closed}</span>
                      )}
                    </div>
                    {day.isTimeEnter && (
                      <div
                        className="copy-style copy-working-hours d-flex align-items-center gap-2 text-primary pointer mt-2"
                        id={day.id}
                        onClick={(e) => {
                          copyToAllWorkingDays(day);
                        }}>
                        <ContentCopy />
                        {branches.addBranch.labels.applyToAllWokringDays}
                        <span className="text-secondary text-underline">
                          {branches.addBranch.labels.applyToAllWokringDaysAction}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const renderDayLabel = (day) => {
    switch (day.id) {
      case 0:
        return lang === 'en' ? weekDays.sat : weekDaysAr.sat;
      case 1:
        return lang === 'en' ? weekDays.sun : weekDaysAr.sun;
      case 2:
        return lang === 'en' ? weekDays.mon : weekDaysAr.mon;
      case 3:
        return lang === 'en' ? weekDays.tus : weekDaysAr.tus;
      case 4:
        return lang === 'en' ? weekDays.wed : weekDaysAr.wed;
      case 5:
        return lang === 'en' ? weekDays.thurs : weekDaysAr.thurs;
      case 6:
        return lang === 'en' ? weekDays.fri : weekDaysAr.fri;

      default:
        return '-';
    }
  };

  const renderLocationLabel = (location) => {
    switch (location?.value?.toLowerCase()) {
      case Locations.atHome.toLowerCase():
        return lang === 'en' ? Locations.atHome : LocationsAr.atHome;
      case Locations.atBranch.toLowerCase():
        return lang === 'en' ? Locations.atBranch : LocationsAr.atBranch;

      default:
        return '-';
    }
  };

  const renderTimeSlots = (day) => {
    if (day) {
      return day.timeSlots?.length > 0 ? (
        <div className="d-flex flex-column gap-3">
          {day.timeSlots.map((slot, i) => {
            return (
              <div key={slot.id}>
                <div className={`d-flex gap-2 align-items-center adjust-small-screens`} key={slot.id}>
                  <TimePicker
                    label={branches.addBranch.placeholders.from}
                    timeSlot={formTimeToFullDate(slot.starts_at)}
                    onChange={(time) => {
                      changeSlotTime(day, time, i, 'starts_at');
                    }}
                    className={`${lang === 'en' ? 'timePicker' : 'timePicker reverse-style-ar'}`}
                  />
                  <div className="fsize-22"> - </div>
                  <TimePicker
                    label={branches.addBranch.placeholders.to}
                    timeSlot={formTimeToFullDate(slot.ends_at)}
                    onChange={(time) => {
                      changeSlotTime(day, time, i, 'ends_at');
                    }}
                    className={`${lang === 'en' ? 'timePicker' : 'timePicker reverse-style-ar'}`}
                  />
                  {i > 0 && (
                    <div
                      className="pointer"
                      onClick={() => {
                        removeSlot(day, i);
                      }}>
                      <Close fontSize="large" />
                    </div>
                  )}
                  {day.timeSlots?.length >= 1 && day.timeSlots?.length < 3 && !i > 0 && (
                    <div className="add-btn-wrapper">
                      <Button
                        label={
                          <span className="d-flex align-items-center">
                            <Add fontSize="large" />
                          </span>
                        }
                        labelClass="fsize-16 text-white"
                        onClick={() => {
                          addNewSlot(day);
                        }}
                        btnStyle="gray"
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <>{renderFisrtTimeSlot(day)}</>
      );
    } else {
      return;
    }
  };
  //   end working days functions

  const handleCloseAssignServicesModal = () => {
    setOpenAssignServicesModal(false);
  };

  const handleAssignServices = () => {
    // reflect the changes in the services
    let old = JSON.parse(JSON.stringify(assignedServices)) || [];
    setAssignedServices(null);
    specialties.map((specialty) => {
      specialty.services?.map((service) => {
        if (service.isChecked === true) {
          let found = false;
          if (assignedServices?.length > 0) {
            assignedServices.map((assignedService) => {
              if (assignedService.id === service.id) {
                found = true;
              }
              return assignedService;
            });
          }
          if (!found) {
            let copy = JSON.parse(JSON.stringify(service));
            old.push(copy);
          } else {
            old.map((ser) => {
              if (ser.id === service.id) {
                ser.isChecked = true;
              }
              return ser;
            });
          }
        } else {
          let foundd = false;
          if (assignedServices?.length > 0) {
            assignedServices.map((assignedService) => {
              if (assignedService.id === service.id) {
                foundd = true;
              }
              return assignedService;
            });
          }
          if (foundd) {
            old = old.filter((assignedService) => {
              return assignedService.id !== service.id;
            });
          }
        }
        return service;
      });
      return specialty;
    });
    setAssignedServices(old);

    setOpenAssignServicesModal(false);
  };

  const checkPreviousClicked = () => {
    if (viewBranchDetails?.employees?.length > 0) {
      setRenderEmployeesListSection(true);
      setRenderEmpatyStateSection(false);
      ressetBtnClicked();
    } else {
      backToPrevStep();
    }
    setEmployeeId(null);
    setRenderAddEditEmployeeSection(false);
    changeSubTitle(null);
    setShowLeaveWarningModal(false);
    ressetBtnClicked();
  };

  const handleCloseShowLeaveWarningModal = () => {
    setShowLeaveWarningModal(false);
  };

  const handleConfirmShowLeaveWarning = () => {
    checkPreviousClicked();
  };

  const checkIfShowWarning = () => {
    let touched = false;
    values.location.map((loc) => {
      if (loc.isChecked === true) {
        touched = true;
      }
      return loc;
    });
    values.employee_working_hours.map((day) => {
      if (day.isChosen === true) {
        touched = true;
      }
      return day;
    });
    if (
      values.first_name_en != null ||
      values.first_name_en !== '' ||
      values.first_name_ar != null ||
      values.first_name_ar !== '' ||
      values.last_name_ar !== '' ||
      values.last_name_ar != null ||
      values.last_name_en != null ||
      values.last_name_en !== '' ||
      values.phone != null ||
      touched
    ) {
      setShowLeaveWarningModal(true);
    } else {
      if (viewBranchDetails?.employees?.length > 0) {
        setRenderEmployeesListSection(true);
        setRenderEmpatyStateSection(false);
      } else {
        setRenderEmpatyStateSection(true);
        setRenderEmployeesListSection(false);
      }
      setEmployeeId(null);
      setRenderAddEditEmployeeSection(false);
      changeSubTitle(null);
    }
  };

  const renderAddEditEmployee = () => {
    return (
      <>
        <Input
          id="first_name_en"
          label={branches.addBranch.specialtiesAndServices.first_name}
          placeholder={branches.addBranch.specialtiesAndServices.first_name}
          name="first_name_en"
          type="text"
          required
          onChange={(value) => {
            setFieldTouched('first_name_en');
            setFieldValue('first_name_en', value);
          }}
          value={values['first_name_en']}
          inputWrapperClass="mb-4"
          labelClassName="text-dark font-medium mb-2"
          isInputHasErr={!!(touched['first_name_en'] && errors['first_name_en'])}
          errMsg={errors['first_name_en']}
        />
        <Input
          id="last_name_en"
          label={branches.addBranch.specialtiesAndServices.last_name}
          placeholder={branches.addBranch.specialtiesAndServices.last_name}
          name="last_name_en"
          type="text"
          required
          onChange={(value) => {
            setFieldTouched('last_name_en');
            setFieldValue('last_name_en', value);
          }}
          value={values['last_name_en']}
          inputWrapperClass="mb-4"
          labelClassName="text-dark font-medium mb-2"
          isInputHasErr={!!(touched['last_name_en'] && errors['last_name_en'])}
          errMsg={errors['last_name_en']}
        />
        <Input
          id="first_name_ar"
          label={branches.addBranch.specialtiesAndServices.first_name_ar}
          placeholder={branches.addBranch.specialtiesAndServices.first_name_ar}
          name="first_name_ar"
          type="text"
          required
          onChange={(value) => {
            setFieldTouched('first_name_ar');
            setFieldValue('first_name_ar', value);
          }}
          value={values['first_name_ar']}
          inputWrapperClass="mb-4"
          labelClassName="text-dark font-medium mb-2"
          isInputHasErr={!!(touched['first_name_ar'] && errors['first_name_ar'])}
          errMsg={errors['first_name_ar']}
        />
        <Input
          id="last_name_ar"
          label={branches.addBranch.specialtiesAndServices.last_name_ar}
          placeholder={branches.addBranch.specialtiesAndServices.last_name_ar}
          name="last_name_ar"
          type="text"
          required
          onChange={(value) => {
            setFieldTouched('last_name_ar');
            setFieldValue('last_name_ar', value);
          }}
          value={values['last_name_ar']}
          inputWrapperClass="mb-4"
          labelClassName="text-dark font-medium mb-2"
          isInputHasErr={!!(touched['last_name_ar'] && errors['last_name_ar'])}
          errMsg={errors['last_name_ar']}
        />
        <div className="my-4">
          <div className="text-dark font-medium mb-2">
            {branches.addBranch.labels.phone} <span className="err-asterisk"> *</span>
          </div>
          {Object.keys(inputLocalization).length === 0 ? (
            <PhoneInput
              name="phone"
              type="text"
              className={`${lang === 'ar' && 'phone-arabic-dir'}`}
              value={values['phone']}
              country={'sa'}
              localization={inputLocalization}
              onChange={(phone, countryData) => {
                setFieldTouched('phone');
                setFieldValue('phone', phone);
                setFieldValue('dial_code', countryData.dialCode);
              }}
              isInputHasErr={!!(touched['phone'] && errors['phone'])}
              errMsg={errors['phone']}
            />
          ) : (
            <>
              <PhoneInput
                name="phone"
                type="text"
                className={`${lang === 'ar' && 'phone-arabic-dir'}`}
                value={values['phone']}
                country={'sa'}
                localization={inputLocalization}
                onChange={(phone, countryData) => {
                  setFieldTouched('phone');
                  setFieldValue('phone', phone);
                  setFieldValue('dial_code', countryData.dialCode);
                }}
                isInputHasErr={!!(touched['phone'] && errors['phone'])}
                errMsg={errors['phone']}
              />
            </>
          )}
        </div>
        {/* Working Hours */}
        <div className="col-12 mt-4">
          <span className="text-dark font-medium">{branches.addBranch.labels.workingHours}</span>
          <div className="fsize-12 text-error font-medium">
            {touched['employee_working_hours'] && errors['employee_working_hours']}
          </div>
          <div className="working-hours-section text-primary">{renderWorkingHoursBody()}</div>
        </div>
        {/* Location */}
        <div className="text-dark font-medium mt-4">
          {branches.addBranch.specialtiesAndServices.location}
          <span className="err-asterisk"> * </span>
        </div>
        <div className="bg-section p-4 my-2">
          {values['location'].map((location, i) => {
            return (
              <div key={location.id}>
                <Checkbox
                  key={i}
                  color="secondary"
                  label={lang === 'en' ? renderLocationLabel(location) : renderLocationLabel(location)}
                  labelClass="font-reguler text-dark fsize-16"
                  name={location.label}
                  id={location.label}
                  checked={location.isChecked}
                  onChange={(IsChecked, e) => {
                    setFieldValue(
                      'location',
                      values['location'].map((loc) => {
                        if (loc.label === e.target.id) {
                          loc.isChecked = IsChecked;
                        }
                        return loc;
                      })
                    );
                    checkFormHasOneChoosenLocation();
                  }}
                  disabled={!location.isEditable}
                />
                {i !== values['location'].length - 1 && <div className="divider my-3"></div>}
              </div>
            );
          })}
        </div>
        <div className="text-dark font-medium mt-4">
          {branches.addBranch.specialtiesAndServices.assignServices} 
        </div>
        <div className=" my-2">
          {assignedServices?.length === 0 ? (
            <Button
              label={
                <span className="d-flex align-items-center">
                  <Add fontSize="medium" />
                  <span>{branches.addBranch.specialtiesAndServices.assignServices}</span>
                </span>
              }
              labelClass="fsize-16 text-white"
              onClick={() => {
                setOpenAssignServicesModal(true);
              }}
            />
          ) : (
            <div className="bg-section p-4 my-2">
              {assignedServices?.map((service, i) => {
                return (
                  <div key={service.id}>
                    <Checkbox
                      key={i}
                      color="secondary"
                      label={service.name}
                      labelClass="font-reguler text-dark fsize-16"
                      name={service.name}
                      id={service.name}
                      checked={service.isChecked}
                      onChange={(IsChecked, e) => {
                        setAssignedServices(
                          assignedServices?.map((service) => {
                            if (service.name == e.target.id) {
                              service.isChecked = IsChecked;
                            }
                            return service;
                          })
                        );
                      }}
                      checkboxClass="mb-3"
                    />
                    {i !== assignedServices.length - 1 && <div className="divider mb-3"></div>}
                  </div>
                );
              })}
              <Button
                label={
                  <span className="d-flex align-items-center">
                    <Add fontSize="medium" />
                    {branches.addBranch.specialtiesAndServices.assignServices}
                  </span>
                }
                labelClass="fsize-16 text-white"
                onClick={() => {
                  setOpenAssignServicesModal(true);
                }}
              />
            </div>
          )}
        </div>
        {/* 2 action btns */}
        <div className="d-flex justify-content-end align-items-center mt-5">
          <div className="d-flex align-items-center gap-2">
            <div>
              <Button
                onClick={() => {
                  checkIfShowWarning();
                }}
                outlined
                type="button"
                color={'info'}
                label={
                  <div className="d-flex gap-1 align-items-center">
                    {lang === 'en' ? (
                      <ArrowBackIosOutlined fontSize="small" />
                    ) : (
                      <ArrowForwardIosOutlined fontSize="small" />
                    )}
                    {shared.previous}
                  </div>
                }
                labelClass="fsize-16"
              />
            </div>
            <div>
              <Button
                label={
                  <span className="d-flex align-items-center">
                    <span>{shared.save}</span>
                  </span>
                }
                labelClass="fsize-16 text-white"
                onClick={() => {
                  addEditEmployeeAction();
                }}
                disabled={!dirty || !isValid || !values.phone || !formHasLocation}
              />
            </div>
          </div>
        </div>
        <Modal
          open={openAssignServicesModal}
          handleClose={handleCloseAssignServicesModal}
          title={branches.addBranch.specialtiesAndServices.assignServices}
          contentClass="mb-2 pb-2 text-primary"
          content={
            <>
              {specialties?.map((specialty) => {
                return (
                  <div className="d-flex justify-content-between align-items-top mb-4 custmo-color" key={specialty.id}>
                    <div className="flex-grow-1">
                      <div className="text-secondary fsize-16">
                        {lang === 'en' ? specialty.name : specialty.nameAr} 
                      </div>
                      {specialty.isOpen && (
                        <div>
                          {specialty?.services?.map((service, i) => {
                            return (
                              <div className="ms-2 ps-1 my-2" key={service.id || i}>
                                <Checkbox
                                  key={i}
                                  color="secondary"
                                  label={service?.name}
                                  labelClass="font-reguler text-dark fsize-16"
                                  name={service?.name}
                                  id={service?.name}
                                  checked={service?.isChecked}
                                  onChange={(IsChecked, e) => {
                                    setSpecialties(
                                      specialties.map((specialty) => {
                                        specialty.services.map((service, index) => {
                                          if (service.name == e.target.id) {
                                            service.isChecked = IsChecked;
                                          }
                                          return service;
                                        });
                                        return specialty;
                                      })
                                    );
                                  }}
                                />
                                {i !== specialty?.services.length - 1 && <div className="divider my-3"></div>}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div>
                      {specialty.isOpen ? (
                        <div
                          className="pointer"
                          onClick={() => {
                            setRenderServices(false);
                            setSpecialties(
                              specialties.map((spec) => {
                                if (spec.id === specialty.id) {
                                  spec.isOpen = false;
                                }
                                return spec;
                              })
                            );
                          }}>
                          <KeyboardArrowUp />
                        </div>
                      ) : (
                        <div
                          className="pointer"
                          onClick={() => {
                            getServicesBySpecialtyId(specialty.specialty_id);
                            dispatch(
                              getBranchServicesRequest({
                                page: -1,
                                branch_id: viewBranchDetails.id,
                              })
                            );
                            setTimeout(() => {
                              setRenderServices(true);
                            }, 700);
                            setSpecialties(
                              specialties.map((spec) => {
                                if (spec.id === specialty.id) {
                                  spec.isOpen = true;
                                }
                                return spec;
                              })
                            );
                          }}>
                          <KeyboardArrowDown />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          }
          isContentText={false}
          confirmBtnTxt={shared.save}
          maxWidth="xs"
          cancelBtnTxt={shared.cancel}
          handleConfirm={handleAssignServices}
          handleCancel={handleCloseAssignServicesModal}
          modalClass="delete-categories-modal"
          actionsClass="container px-4"
          confirmBtnClass="btn-secondary fsize-14 text-white py-2"
          cancelBtnClass="fsize-14 py-2"
        />
        <Modal
          open={showLeaveWarningModal}
          handleClose={handleCloseShowLeaveWarningModal}
          title={branches.addBranch.labels.confirmCancel}
          contentClass="mb-5 pb-2 text-primary"
          content={<span>{branches.addBranch.labels.confirmCancelMessage}</span>}
          confirmBtnTxt={shared.confirm}
          maxWidth="xs"
          cancelBtnTxt={shared.cancel}
          isContentText={false}
          handleConfirm={handleConfirmShowLeaveWarning}
          handleCancel={handleCloseShowLeaveWarningModal}
          modalClass="delete-services-modal"
          actionsClass="container px-4"
          confirmBtnClass="btn-secondary fsize-14 text-white py-2 "
          cancelBtnClass="fsize-14 py-2"
        />
      </>
    );
  };

  const getServicesBySpecialtyId = (specialtyId) => {
    dispatch(
      getServicesRequest({
        page: -1,
        specialty_id: specialtyId,
      })
    );
  };

  const navigateMeBack = () => {
    history.push({
      pathname: parentProps?.location?.state?.cameFrom,
      state: {
        setTabIndex: parentProps?.location?.state?.setTabIndex,
      },
    });
  };

  const renderEmployeesList = () => {
    return (
      <div>
        <div>
          {employeesList?.length > 0 && (
            <div className="d-flex align-items-center justify-content-between p-2 px-4 mb-4">
              <div className="flex-grow-1 d-flex flex-column flex-md-row align-items-md-center align-items-start gap-2 text-gray">
                <div className="fsize-16 fweight-600 flex-basis-34">
                  {branches.addBranch.specialtiesAndServices.employeeName}
                </div>
                <div className="fsize-16 fweight-600">{branches.addBranch.labels.phone}</div>
              </div>
            </div>
          )}
          {employeesList?.map((employee, i) => {
            return (
              <div
                className="bg-section d-flex align-items-center justify-content-between p-2 px-4 mb-4"
                key={employee.id}>
                <div className="flex-grow-1 d-flex flex-column flex-md-row align-items-md-center align-items-start gap-2">
                  <div className="fsize-16 fweight-600 flex-basis-40">
                    {lang === 'en' ? employee.name : employee.nameAr}
                  </div>
                  <div className="fsize-16 fweight-600 ">{employee.phone}</div>
                </div>
                <div className="d-flex justify-content-around align-items-center flex-basis-15 gap-2">
                  {employee.status != null && (
                      <div className={`${employee.status}-container py-1 px-3 rounded-4 d-flex gap-1`}>
                        {renderStatusCase(employee)}
                        <div 
                          className="pointer"
                          onClick={()=>{handleEditEmployeeStatus(employee)}}
                        >
                          <Edit/>
                        </div> 
                      </div>
                  )}
                  <div
                    className="pointer"
                    onClick={() => {
                      handleDeleteClick(employee.id);
                    }}>
                    <DeleteBgIcon />
                  </div>
                  <div
                    className="pointer"
                    onClick={() => {
                      history.push(ROUTE_PATHS.editEmployees.replace(':id', employee.id));
                    }}>
                    <EditBgIcon />
                  </div>
                  <div
                    className="pointer"
                    onClick={() => {
                      history.push(ROUTE_PATHS.viewEmployee.replace(':id', employee.id));
                    }}>
                    <ViewBgIcon />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {employeesList?.length > 0 && (
          <div className={`${parentProps?.location?.state?.cameFrom && 'd-flex justify-content-end gap-2'}`}>
            {parentProps?.location?.state?.cameFrom && (
              <Button
                label={
                  <span className="d-flex align-items-center gap-1">
                    <span>{shared.save}</span>
                  </span>
                }
                labelClass={`fsize-16 text-white`}
                onClick={() => {
                  navigateMeBack();
                }}
              />
            )}
            {viewBranchDetails.status !== 'active' && (
              <Button
                label={
                  <span className="d-flex align-items-center gap-1">
                    {!parentProps?.location?.state?.cameFrom && <SendOutlined fontSize="medium" />}
                    <span>
                      {parentProps?.location?.state?.cameFrom
                        ? shared.saveAndPublish
                        : branches.addBranch.saveAndSendPublishRequest}
                    </span>
                  </span>
                }
                labelClass={`fsize-16 text-white`}
                onClick={() => {
                  // publish request
                  publishBranch();
                }}
              />
            )}
          </div>
        )}
        <Modal
          open={openDeleteConfirm}
          handleClose={handleCloseDeleteConfirmModal}
          title={branches.addBranch.labels.deleteEmployee}
          contentClass="mb-5 pb-2 text-primary"
          content={<span>
                      {branches.addBranch.labels.deleteEmployeeMessage}
                      <div className="my-3">
                        <span className="text-secondary">{employees.deleteEmployee.note}: </span>
                        {employees.deleteEmployee.validationNote}
                      </div>
                   </span>
                  }
          confirmBtnTxt={shared.delete}
          maxWidth="xs"
          cancelBtnTxt={shared.cancel}
          isContentText={false}
          handleConfirm={handleDeleteEmployee}
          handleCancel={handleCloseDeleteConfirmModal}
          modalClass="delete-services-modal"
          actionsClass="container px-4"
          confirmBtnClass="btn-secondary fsize-14 text-white py-2 "
          cancelBtnClass="fsize-14 py-2"
        />
        <Modal
              open={openChangeStatusModal}
              handleClose={handleCloseChangeStatusModal}
              title={serviceProviders.list.editStatus}
              contentClass="mb-3 text-primary"
              content={
              <>
                <RadioButtonsGroup
                  isLabel={false}
                  defaultValue
                  name="employeeStatus"
                  list={handleStatusList(selectedEmployee ?? {})}
                  onChange={(e) => {
                    if(e.target.value==='deactivate-later'){
                      setOpenSusbendByTime(true)
                    }else{
                      setOpenSusbendByTime(false)
                    }
                    setChangedStatusValue(e.target.value)}}
                />
                {openSusbendByTime && (
                  <div className="mt-3">
                    <DatePicker
                      value={susbendedAt}
                      onChange={(dateValue) => {
                        setSusbendedAt(dateValue);
                      }}
                      name={'susbended at'}
                      disablePast={true}
                      label={serviceProviders.list.statusActions.dateLabel}
                      disableMinToday={true}
                      placement="right"
                      setInvalidDeactivatingDate={setInvalidDeactivatingDate}
                    />
                  </div>
                )}
              </>
            }
            isContentText={false}
            confirmBtnTxt={shared.save}
            maxWidth="xs"
            cancelBtnTxt={shared.cancel}
            handleConfirm={handleChangeStatus}
            handleCancel={handleCloseChangeStatusModal}
            modalClass="status-modal"
            actionsClass="container px-4"
            confirmBtnClass="btn-secondary fsize-14 text-white py-2 "
            cancelBtnClass="fsize-14 py-2"
            disableActionBtn={changedStatusValue ==='deactivate-later' 
            ? !(susbendedAt && changedStatusValue) || invalidDeactivatingDate 
            : !changedStatusValue}
          />
      </div>
    );
  };

  const publishBranch = () => {
    dispatch(
      editBranchRequest({
        id: viewBranchDetails?.id,
        data: {
          status: 'requested_publishing',
        },
        requestType: 'requestSentSuccessfully',
      })
    );
  };

  const handleDeleteClick = (id) => {
    setEmployeeId(id);
    setOpenDeleteConfirm(true);
  };

  const handleDeleteEmployee = () => {
    dispatch(
      deleteBranchEmployeeRequest({
        id: employeeId,
      })
    );
    setOpenDeleteConfirm(false);
    setEmployeeId(null);
    setTimeout(() => {
      refreshCurrentTab();
    }, 200);
  };

  const handleCloseDeleteConfirmModal = () => {
    setOpenDeleteConfirm(false);
  };

  return (
    <form noValidate onSubmit={handleSubmit} className="add-specialties-services-section">
      <div className="bg-white px-2 px-md-5 py-4 rounded-4">
        <div className="row mt-4">
          <div className="col-12">
            {renderEmpatyStateSection && renderEmptyState()}
            {renderAddEditEmployeeSection && renderAddEditEmployee()}
            {renderEmployeesListSection && renderEmployeesList()}
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddEditEmployeesDetails;
