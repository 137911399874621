export const GET_BOOKINGS_REQUEST = "GET_BOOKINGS_REQUEST";
export const GET_BOOKINGS_RESPONSE = "GET_BOOKINGS_RESPONSE";

export const GET_BOOKING_REQUEST = "GET_BOOKING_REQUEST";
export const GET_BOOKING_RESPONSE = "GET_BOOKING_RESPONSE";

export const EDIT_BOOKING_SERVICE_REQUEST = "EDIT_BOOKING_SERVICE_REQUEST";

export const GET_BOOKING_CANCELATION_REASONS_REQUEST = "GET_BOOKING_CANCELATION_REASONS_REQUEST";
export const GET_BOOKING_CANCELATION_REASONS_RESPONSE = "GET_BOOKING_CANCELATION_REASONS_RESPONSE";

export const GET_BOOKING_SERVICE_CANCELATION_REASONS_REQUEST = "GET_BOOKING_SERVICE_CANCELATION_REASONS_REQUEST";
export const GET_BOOKING_SERVICE_CANCELATION_REASONS_RESPONSE = "GET_BOOKING_SERVICE_CANCELATION_REASONS_RESPONSE";

export const UPDATE_BOOKING_REQUEST = "UPDATE_BOOKING_REQUEST";

export const GET_OTHER_SERVICES_REQUEST = "GET_OTHER_SERVICES_REQUEST";
export const GET_OTHER_SERVICES_RESPONSE = "GET_OTHER_SERVICES_RESPONSE";

export const GET_REQUEST_STATUS = "GET_REQUEST_STATUS";



