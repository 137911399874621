import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Redirect, Route, useLocation } from "react-router-dom";

import { isAuth } from "../utils/Auth";
import { ROUTE_PATHS } from "../utils/RoutesPaths";
import { isSuperManger } from "../utils/Permissions";

const PrivateRoute = ({ component: Component, permission, ...rest }) => {
  const location = useLocation();

  const mustChangePassword = useSelector(
    (state) => state.auth?.login?.personal_data?.must_change_password
  );
  const isProfileCompleted = useSelector(
    (state) => state.auth?.login?.is_profile_completed
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth() ? (
          // To force the user to change the temp password before routing to other locations
          mustChangePassword ? (
            location.pathname === ROUTE_PATHS.changePassword ? (
              <Component {...props} />
            ) : (
              <Redirect to={{ pathname: ROUTE_PATHS.changePassword }} />
            )
          ) : isProfileCompleted === false ? (
            location.pathname === ROUTE_PATHS.setupProfile ? (
              <Component {...props} />
            ) : (
              <Redirect to={{ pathname: ROUTE_PATHS.setupProfile }} />
            )
          ) : permission || isSuperManger() ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: ROUTE_PATHS.branches }} />
          )
        ) : (
          <Redirect to={{ pathname: ROUTE_PATHS.login }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
  permission: PropTypes.bool,
};

export default PrivateRoute;
