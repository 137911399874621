import { GET_BOOKINGS_REQUEST,
         GET_BOOKINGS_RESPONSE,
         GET_BOOKING_REQUEST,
         GET_BOOKING_RESPONSE,
         EDIT_BOOKING_SERVICE_REQUEST,
         GET_BOOKING_CANCELATION_REASONS_REQUEST,
         GET_BOOKING_CANCELATION_REASONS_RESPONSE,
         GET_BOOKING_SERVICE_CANCELATION_REASONS_REQUEST,
         GET_BOOKING_SERVICE_CANCELATION_REASONS_RESPONSE,
         UPDATE_BOOKING_REQUEST,
         GET_OTHER_SERVICES_REQUEST,
         GET_OTHER_SERVICES_RESPONSE,
         GET_REQUEST_STATUS,
        } from './types'

export const getBookingsRequest = (payload) => ({
    type: GET_BOOKINGS_REQUEST,
    payload,
});

export const getBookingsResponse = (payload) => ({
    type: GET_BOOKINGS_RESPONSE,
    payload,
});

export const getBookingRequest = (payload) => ({
    type: GET_BOOKING_REQUEST,
    payload,
});

export const getBookingResponse = (payload) => ({
    type: GET_BOOKING_RESPONSE,
    payload,
});

export const editBookingServiceRequest = (payload) => ({
    type: EDIT_BOOKING_SERVICE_REQUEST,
    payload,
});

export const getBookingCancelationReasonsRequest = (payload) => ({
    type: GET_BOOKING_CANCELATION_REASONS_REQUEST,
    payload,
});

export const getBookingCancelationReasonsResponse = (payload) => ({
    type: GET_BOOKING_CANCELATION_REASONS_RESPONSE,
    payload,
});

export const getBookingServiceCancelationReasonsRequest = (payload) => ({
    type: GET_BOOKING_SERVICE_CANCELATION_REASONS_REQUEST,
    payload,
});

export const getBookingServiceCancelationReasonsResponse = (payload) => ({
    type: GET_BOOKING_SERVICE_CANCELATION_REASONS_RESPONSE,
    payload,
})

export const updateBookingRequest = (payload) => ({
    type: UPDATE_BOOKING_REQUEST,
    payload,
});

export const getOtherServicesRequest = (payload) => ({
    type: GET_OTHER_SERVICES_REQUEST,
    payload,
});

export const getOtherServicesResponse = (payload) => ({
    type: GET_OTHER_SERVICES_RESPONSE,
    payload,
});

export const getRequestStatus = (payload) => ({
    type: GET_REQUEST_STATUS,
    payload,
});


