import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Input from "../../../components/Input";
import Button from "../../../components/Button";
import messages from "../../../assets/locale/messages";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import "./TempPassword.scss";

const TempPassword = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { tempPassword, shared } = messages[lang];

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      tempPassword: "",
    },
    validationSchema: Yup.object({
      tempPassword: Yup.string().required("tempPasswordRequired"),
    }),
    onSubmit: ({ tempPassword }) => {},
  });
  return (
    <>
      <h1 className="title font-bold fsize-32">{tempPassword.title}</h1>
      <p className="my-2 fsize-14 font-medium text-center">{tempPassword.subTitle}</p>
      <form className="w-100" onSubmit={handleSubmit} noValidate>
        <Input
          id="tempPassword"
          label={tempPassword.labels.tempPassword}
          name="tempPassword"
          type="password"
          placeholder={tempPassword.placeholders.tempPassword}
          required={true}
          onChange={(value) => {
            setFieldTouched("tempPassword");
            setFieldValue("tempPassword", value.trim());
          }}
          value={values["tempPassword"]}
          inputClass="mb-1"
          labelClassName="font-medium mb-2"
          inputWrapperClass="mt-4 mb-5"
          isInputHasErr={!!(touched["tempPassword"] && errors["tempPassword"])}
          errMsg={errors["tempPassword"]}
        />
        <Button
          label={tempPassword.actionBTN}
          type="submit"
          labelClass="fsize-16 text-white py-1"
          className="mb-2"
          block
          disabled={!dirty || !isValid}
        />
        <div className="text-center">
          <Link
            to={ROUTE_PATHS.login}
            className="orange-label fsize-14 fweight-400">
            {shared.back}
          </Link>
        </div>
      </form>
    </>
  );
};
export default TempPassword;
