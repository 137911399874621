const successMsgs = {
  changePassword: 'تم تغيير كلمة المرور بنجاح',
  forgetPassword: 'تم ارسال كلمة المرور جديدة',
  deletedSuccessfully: 'تم الحذف',
  addedSuccessfully: 'تم الاضافة بنجاح',
  editedSuccessfully: 'تم التعديل بنجاح',
  generatedSuccessfully: 'تم التعيين بنجاح',
  copiedSuccessfully: 'تم النسخ بنجاح',
  cancelledSuccessfully: 'تم الإلغاء بنجاح',
  requestSentSuccessfully: 'تم ارسال الطلب بنجاح',
  exportTransactions: "تم ارسال ايميل تقرير معاملات الدفع",
};

export default successMsgs;
