import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Stepper as MuiStepper, Step, StepLabel, StepButton, StepConnector } from '@mui/material';

import './Stepper.scss';

const Stepper = ({
  steps,
  preventSkippingSteps,
  preventGoingBack,
  handleSelectStep,
  currentStep,
  dynamicChange,
  haveBranchId,
  haveServices,
  allTabsIsClicable,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (currentStep != null) {
      setActiveStep(currentStep);
    }
  }, [currentStep]);

  const handleStepClick = (stepIndex) => {
    // To allow steps clicks
    if (dynamicChange) {
      setActiveStep(stepIndex);
      handleSelectStep(stepIndex);
    }
  };

  // To prevent skipping steps and prevent going back to completed steps
  const handleStepDisabling = (disabled, index) => {
    if (index === activeStep) {
      return true;
    } else if (disabled) {
      return disabled;
    } else {
      if (allTabsIsClicable) {
        return false;
      }
      if (preventSkippingSteps && !preventGoingBack) {
        if (haveBranchId === true && haveServices === false && activeStep === 0) {
          return index > activeStep + 1;
        } else if (haveBranchId === true && haveServices === true) {
          return index > activeStep + 2;
        } else {
          return index > activeStep;
        }
      }
      if (preventGoingBack && !preventSkippingSteps) {
        return index < activeStep;
      }
      if (preventSkippingSteps && preventGoingBack) {
        return index > activeStep + 1 || index < activeStep;
      }
    }
  };
  return (
    <div className="stepper-container">
      <MuiStepper activeStep={activeStep} alternativeLabel nonLinear className="custom-stepper">
        {steps.map(({ label, disabled, icon }, index) => (
          <Step
            key={index}
            disabled={handleStepDisabling(disabled, index)}
            className={`step ${activeStep === index ? 'active-step' : 'inactive-step'}`}>
            {(lang === 'en' ? index !== 0 : index < steps.length - 1) && <StepConnector className="custom-stepper" />}
            <StepButton
              onClick={() => handleStepClick(index)}
              className={`step-button ${activeStep === index && 'step-button-inprogress'}`}
              icon={activeStep === index ? icon[1] : icon[0]}>
              <StepLabel className="adjust-font-size-label">{label}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </MuiStepper>
    </div>
  );
};

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]),
      disabled: PropTypes.bool,
      icon: PropTypes.arrayOf(PropTypes.element.isRequired),
    })
  ),
  handleSelectStep: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  preventSkippingSteps: PropTypes.bool,
  preventGoingBack: PropTypes.bool,
  dynamicChange: PropTypes.bool,
  haveBranchId: PropTypes.bool,
  haveServices: PropTypes.bool,
  allTabsIsClicable: PropTypes.bool,
};
Stepper.defaultProps = {
  preventSkippingSteps: true,
  preventGoingBack: true,
  dynamicChange: true,
  haveBranchId: false,
  haveServices: false,
  allTabsIsClicable: false,
};

export default Stepper;
