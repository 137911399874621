const employees = {
  list: {
    search: 'Search for employee name or phone number',
    CreateEmployeeButton: 'Add new employee',
    noDataDescription: 'Start by adding a new employee',
    employeeId: 'Employee ID',
    name: 'Name',
    phoneNumber: 'Phone Number',
    branch: 'Branch',
    status: 'Status',
    filter: {
      filtersPlaceHolders: {
        filterByService: 'Filter by service',
        filterByEmployeeStatus: 'Filter by status',
        filterByBranchName: 'Filter by branch name',
        filterByLocations: 'Filter by locations',
      },
    },
    statusCases: {
      active: 'Active',
      deactivated: 'Deactivated',
      suspended: 'Suspended',
      blocked: 'Blocked'
    },
    suspendedByDeactivateEmployee: 'This employee will be deactivated starting from',
  },
  deleteEmployee: {
    title: 'Delete employee',
    deleteConfirmationMsg: 'Are you sure you want to delete the employee?',
    note:'Note that',
    validationNote:'Some services will be deactivated if this was the last assigned employee.'
  },
  errorMessages: {
    phoneRequired: 'Phone number is required',
  },
};

export default employees;
