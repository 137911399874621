import dayjs from "dayjs";
import 'dayjs/locale/ar';

import messages from '../assets/locale/messages';
import store from '../store';
import { showHideSnackbar } from '../store/Snackbar/actions';
import { setPageTitle } from '../store/Navigation/actions';
import Tooltip from '../components/Tooltip';
import { useSelector } from 'react-redux';

export const showSuccessMsg = (message) => {
  if (message) {
    const lang = store.getState().locale.lang;
    const { successMsgs } = messages[lang];
    store.dispatch(
      showHideSnackbar({
        isOpen: true,
        type: 'success',
        message: successMsgs[message],
      })
    );
  }
};

export const showErrorMsg = (message) => {
  if (message) {
    store.dispatch(
      showHideSnackbar({
        isOpen: true,
        type: 'error',
        message,
      })
    );
  }
};

export const renderTextEllipsis = (text, length = 50) => {
  if (text?.length > length) {
    const trimmedText = text.slice(0, length);
    return <Tooltip title={text} content={<span>{trimmedText}...</span>} />;
  } else {
    return text;
  }
};

export const textContainOnlySpaces = (text) => text.length && /^\s*$/.test(text);

export const setPageMainTitle = (title) => {
  store.dispatch(setPageTitle(title));
};

export const timeConverter = (time, lang) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) {
    time = time.slice(1);
    if ((time[5] = +time[0] < 12)) {
      if (lang === 'en') {
        time[5] = ' AM';
      } else {
        time[5] = ' ص';
      }
    } else {
      if (lang === 'en') {
        time[5] = ' PM';
      } else {
        time[5] = ' م';
      }
    }
    time[0] = +time[0] % 12 || 12;
  }
  return time.join('');
};

export const getNamesInitials = (fullName) => {
  return fullName
    ? fullName
        .split(" ")
        .filter((_, i) => i < 2)
        .map((name) => name[0]?.toUpperCase())
        .join("")
    : "";
};

export const formatDate = (date, format = "DD-MM-YYYY", lang) =>
  dayjs(date).locale(lang).format(format)
