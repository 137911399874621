import { put, call, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import * as actions from './actions';
import * as apis from '../../network/apis/profileDetails';
import { ROUTE_PATHS } from '../../utils/RoutesPaths';
import { setServiceProvidar } from '../../utils/Auth';
import { showErrorMsg, showSuccessMsg } from '../../utils/Helpers';
import * as loginActions from '../Auth/actions';
import history from '../../routes/History';

function* profileDetailsSaga() {
  try {
    const response = yield call(apis.getProfileDetails);
    yield put(actions.getProfileDetailsResponse(response.data.service_provider));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* setupProfileSaga({ payload }) {
  try {
    const response = yield call(apis.setupProfile, payload.data);
    yield put(actions.setupProfileResponse(response?.data?.service_provider));
    yield put(loginActions.loginResponse(response?.data?.service_provider));
    const isRemeberMeEnabled = JSON.parse(localStorage.getItem('serviceProvider'));
    yield setServiceProvidar(response?.data?.service_provider, isRemeberMeEnabled ? 'local' : 'session');
    // redirect to main page
    yield showSuccessMsg('editedSuccessfully');
    if(payload.from === 'settings'){
      yield history.push(ROUTE_PATHS.settings);
    }else{
      yield history.push(ROUTE_PATHS.branches);
    }
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

export default function* ProfileDetailsSagas() {
  yield takeEvery(types.MY_PROFILE_DETAILS_REQUEST, profileDetailsSaga);
  yield takeEvery(types.SETUP_PROFILE_REQUEST, setupProfileSaga);
}
