import { all } from 'redux-saga/effects';
import AuthSagas from './Auth/sagas';
import ProfileDetails from './ProfileDetails/sagas';
import BranchesSagas from './Branches/sagas';
import LookUps from './LookUps/sagas';
import Employees from './Employees/sagas';
import Bookings from './Bookings/sagas';
import PaymentTransactionsSagas from './PaymentTransactions/sagas';
import NotificationsSagas from './Notifications/sagas';

export default function* rootSaga() {
  yield all([AuthSagas(), ProfileDetails(), BranchesSagas(), LookUps(), Employees(), Bookings(), PaymentTransactionsSagas(), NotificationsSagas()]);
}
