import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Collapse,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  Close,
  Logout,
  ExpandLess,
  ExpandMore,
  Storefront,
  Badge,
  CalendarMonthTwoTone,
  SettingsOutlined,
  CreditScoreOutlined,
  NotificationsOutlined,
} from '@mui/icons-material';
import { styled } from "@mui/material/styles";

import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { getDeviceId } from "../../../utils/Auth";
import messages from "../../../assets/locale/messages";
import { logoutRequest } from "../../../store/Auth/actions";
import logo from "../../../assets/logo/mimar.png";
import "./SideMenu.scss";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const SideMenu = ({
  detectSideMenuOpen,
  closeMobileSideMenu,
  drawerWidth,
  mobileSideMenuOpen,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  const [open, setOpen] = useState(true);
  const [path, setPath] = useState("");
  const [openItemsIds, setOpenItemsIds] = useState([2]);

  const handleItemClick = (itemId) => {
    if (openItemsIds.includes(itemId)) {
      setOpenItemsIds(openItemsIds.filter((item) => item !== itemId));
    } else {
      setOpenItemsIds([...openItemsIds, itemId]);
    }
  };

  useEffect(() => {
    setPath(location.pathname);
  }, [location, setPath]);

  useEffect(() => {
    detectSideMenuOpen(open);
  }, [detectSideMenuOpen, open]);

  const handleSideMenuOpen = () => {
    setOpen(true);
  };

  const handleSideMenuClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(
      logoutRequest({
        device_id: getDeviceId(),
      })
    );
  };

  const activeRoute = (activekeys) => {
    return activekeys.some((key) => path.includes(key));
  };

  const sideMenuItems = [
    {
      id: 1,
      title: shared.Branches,
      icon: <Storefront />,
      slug: ROUTE_PATHS.branches,
      activekeys: ['branches'],
      hasChildren: false,
      children: [],
    },
    {
      id: 2,
      title: shared.employees,
      icon: <Badge />,
      slug: ROUTE_PATHS.employees,
      activekeys: ['employees'],
      hasChildren: false,
      children: [],
    },
    {
      id: 3,
      title: shared.bookings,
      icon: <CalendarMonthTwoTone />,
      activekeys: ['bookings'],
      slug: ROUTE_PATHS.bookings,
      hasChildren: false,
      children: [],
    },
    {id: 4,
      title: shared.transactions,
      icon: <CreditScoreOutlined/>,
      slug: ROUTE_PATHS.transactions,
      activekeys: ['payment-transactions'],
      hasChildren: false,
      children: [],
    },
    {
      id: 5,
      title: shared.notifications,
      icon: <NotificationsOutlined />,
      slug: ROUTE_PATHS.notifications,
      activekeys: ['notifications'],
      hasChildren: false,
      children: [],
    },
    {
      id: 6,
      title: shared.settings,
      icon: <SettingsOutlined />,
      slug: ROUTE_PATHS.settings,
      activekeys: ['settings'],
      hasChildren: false,
      children: [],
    },
  ];

  const renderSideMenuItems = (device) => (
    <div className="side-menu-container">
      <DrawerHeader className="justify-content-center mt-3 mb-2">
        {device === "mobile" ? (
          <>
            <img src={logo} alt="Mimar-logo" className="w-55 m-auto" />
            <IconButton
              onClick={closeMobileSideMenu}
              className="close-side-menu-icon">
              <Close />
            </IconButton>
          </>
        ) : (
          <>
            {open && (
              <>
                <img src={logo} alt="Mimar-logo" className="w-70 m-auto" />
              </>
            )}
            <IconButton
              onClick={open ? handleSideMenuClose : handleSideMenuOpen}
              className="side-menu-toggle-icon">
              {lang === "en" ? (
                open ? (
                  <ChevronLeft />
                ) : (
                  <ChevronRight />
                )
              ) : open ? (
                <ChevronRight />
              ) : (
                <ChevronLeft />
              )}
            </IconButton>
          </>
        )}
      </DrawerHeader>
      <List className="side-menu-list">
        {sideMenuItems.map(
          ({
            id,
            title,
            icon,
            slug,
            activekeys,
            endAdorment,
            hasChildren,
            children,
          }) =>
            hasChildren ? (
              <div key={id}>
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  className="list-item"
                  onClick={() => handleItemClick(id)}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    className="list-item-wrapper">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: lang === "en" && open ? 3 : "auto",
                        ml: lang === "ar" && open ? 3 : "auto",
                        justifyContent: "center",
                      }}>
                      {icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={title}
                      sx={{
                        display: open ? "block" : "none",
                        textAlign: "start",
                      }}
                      className="list-item-label"
                    />
                    {openItemsIds.includes(id) ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                </ListItem>
                <Collapse
                  in={openItemsIds.includes(id)}
                  timeout="auto"
                  unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    className={`child-sub-list ${!open ? "closed-menu" : ""}`}>
                    {children.map((child, index) => (
                      <Link to={child.slug} key={index}>
                        <ListItem
                          selected={activeRoute(child.activekeys)}
                          disablePadding
                          sx={{ display: "block" }}
                          className="list-item">
                          <ListItemButton
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? "initial" : "center",
                              px: 2.5,
                            }}
                            className={`list-item-wrapper ${
                              !open ? "closed-menu" : ""
                            }`}>
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: lang === "en" && open ? 1 : "auto",
                                ml: lang === "ar" && open ? 1 : "auto",
                                justifyContent: "center",
                              }}>
                              {child.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={child.title}
                              sx={{
                                display: open ? "block" : "none",
                                textAlign: "start",
                              }}
                              className="list-item-label"
                            />
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </Collapse>
              </div>
            ) : (
              <Link to={slug} key={id}>
                <ListItem
                  selected={activeRoute(activekeys)}
                  disablePadding
                  sx={{ display: "block" }}
                  className="list-item">
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    className={`list-item-wrapper ${
                      !open ? "closed-menu" : ""
                    }`}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: lang === "en" && open ? 3 : "auto",
                        ml: lang === "ar" && open ? 3 : "auto",
                        justifyContent: "center",
                      }}>
                      {icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={title}
                      sx={{
                        display: open ? "block" : "none",
                        textAlign: "start",
                      }}
                      className="list-item-label"
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            )
        )}
      </List>
      <div className="side-menu-footer w-100">
        <ListItem
          disablePadding
          sx={{ display: "block" }}
          className={`list-item ${!open ? "closed-menu" : ""}`}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            className={`list-item-wrapper ${!open ? "closed-menu" : ""}`}
            onClick={handleLogout}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: lang === "en" && open ? 3 : "auto",
                ml: lang === "ar" && open ? 3 : "auto",
                justifyContent: "center",
              }}>
              <Logout />
            </ListItemIcon>
            <ListItemText
              primary={shared.logout}
              sx={{ display: open ? "block" : "none", textAlign: "start" }}
              className="list-item-label"
            />
          </ListItemButton>
        </ListItem>
      </div>
    </div>
  );

  const desktopMenuCustomStyle = {
    display: { xs: "none", sm: "block" },
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: (theme) =>
        theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        width: (theme) => ({
          xs: theme.spacing(7),
          sm: theme.spacing(9),
        }),
      }),
    },
  };

  const mobileMenuCustomStyle = {
    display: { xs: "block", sm: "none" },
    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
  };

  return (
    <Box
      component="nav"
      aria-label="mailbox folders"
      className="side-menu position-fixed">
      <Drawer
        variant="temporary"
        open={mobileSideMenuOpen}
        onClose={closeMobileSideMenu}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={mobileMenuCustomStyle}
        className="mobile-side-menu"
        anchor={lang === "en" ? "left" : "right"}>
        {renderSideMenuItems("mobile")}
      </Drawer>
      <Drawer
        variant="permanent"
        open={open}
        sx={desktopMenuCustomStyle}
        className="desktop-side-menu">
        {renderSideMenuItems("desktop")}
      </Drawer>
    </Box>
  );
};

SideMenu.propTypes = {
  detectSideMenuOpen: PropTypes.func.isRequired,
  closeMobileSideMenu: PropTypes.func.isRequired,
  drawerWidth: PropTypes.number.isRequired,
  mobileSideMenuOpen: PropTypes.bool.isRequired,
};

export default SideMenu;
