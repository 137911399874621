const inputsValidations = {
  emailRequired: 'البريد الإلكتروني مطلوب',
  nameRequired: 'الاسم مطلوب',
  locationNameRequired: 'الاسم المكان باللغة الانجليزية مطلوب',
  locationNameArRequired: 'الاسم المكان باللغة العربية مطلوب',
  enNameRequired: 'الاسم باللغة الانجليزية مطلوب',
  arNameRequired: 'الاسم باللغة العربية مطلوب',
  pickOneCategoryAtLeast: 'يجب اختيار فئة واحدة علي الأقل',
  pickOneWorkingDayAtLeast: 'يجب اختيار يوم عمل واحد علي الأقل',
  pickOneCoverageZoneAtLeast: 'يجب اختيار منطقة واحدة علي الأقل',
  genderRequired: 'اختيار نوع الفئة المسهدفة مطلوب',
  paymentMenthodRequired: 'اختيار طرق الدفع مطلوبة',
  specialtyRequired: 'اختيار التخصص مطلوب',
  logoRequired: 'اختيار الشعار مطلوب',
  bannerRequired: 'اختيار صورة الغلاف مطلوب',
  categoryRequired: 'اختيار الفئة مطلوبة',
  roleRequired: 'الوظيفة مطلوبة',
  passwordRequired: 'كلمة المرور مطلوبة',
  tempPasswordRequired: 'كلمة المرور المؤقتة مطلوبة ',
  oldPasswordRequired: 'كلمة المرور الحالية مطلوبة ',
  streetNumberRequired: 'رقم الشارع مطلوب ',
  streetNameRequired: 'اسم الشارع مطلوب ',
  buildingNumberRequired: 'رقم المبني مطلوب ',
  newPasswordRequired: 'كلمة مرور الجديدة مطلوبة',
  postalCodeNumberOnly: 'الرمز البريدي يقبل 5 ارقام فقط',
  validEmailFormat: 'يجب أن يكون البريد الإلكتروني عنوان بريد إلكتروني صالحًا',
  uniqeEmailRequired: 'يتم استخدام هذا البريد الإلكتروني من قبل، ويجب أن يكون البريد الإلكتروني موحدًا',
  confirmNewPasswordRequired: 'تأكيد كلمة المرور الجديدة مطلوب',
  passwordIsNotMatched: 'كلمات المرور يجب أن تتطابق',
  passwordNotFollowREGEX:
    'يجب أن تحتوي كلمات المرور على 8 أحرف على الأقل ؛ مزيج من الأحرف (الكبيرة والصغيرة) والأرقام والرموز',
  nameNotFollowREGEX: 'الاسم لا يجب أن يحتوى علي رموز',
  englishLettersOnly: 'هذا الحقل يقبل اللغة الانجليزية فقط',
  arabicLettersOnly: 'هذا الحقل يقبل اللغة العربية فقط',
  numbersOnly: 'هذا الحقل يقبل الارقام فقط',
  floatTwoDecimalOnly: 'هذا الحقل يقبل 2 منزلة عشرية فقط',
  invalidDate: 'التاريخ غير صالح',
  uploadTypeError: 'الصيغات المقبولة هي jpg و jpeg و png و bmp فقط',
  uploadSizeError: 'اقصي حجم 2MB',
  regionRequired: 'المنطقة مطلوبة',
  cityRequired: 'المدينة مطلوبة',
  countryRequired: 'الدولة مطلوبة',
  required: 'هذا الحقل مطلوب'
};

export default inputsValidations;
