const bookings ={
    list: {
        bookingList:'قائمة الحجوزات',
        search: 'ابحث باستخدام رقم الحجز او بأسم العميل',
        filter:{
            filtersPlaceHolders:{
                filterByBranchName: 'اسم الفرع',
                filterByServiceProvider:'مقدم الخدمة',
                filterByStartDate:'تاريخ من',
                filterByEndDate:'تاريخ إلى',
                filterByBookingsStatus:'حاله',
                dateLabel:'تاريخ'
            }
        },
        noData: 'لا توجد حجوزات حتى اللآن' 
    },
    status:{
        upcoming: 'قادمه', 
        ongoing: 'حاليه', 
        pending_payment: 'في انتظار الدفع', 
        completed: 'مكتملة', 
        canceled: 'أُلغيت',
    },
    details: {
        booking: 'حجز',
        appointmentId: 'رقم الحجز',
        date: 'تاريخ',
        status: 'الحالة',
        paymentMethod: 'طريقة الدفع',
        cash: 'نقدي',
        online:'دفع إلكتروني',
        location: 'المكان',
        in_place:'في الفرع',
        home: 'في منزل العميل',
        providerName: 'مقدم الخدمة',
        branchName: 'اسم الفرع',
        customerName: 'اسم العميل',
        customerPhoneNumber:'رقم تليفون العميل',
        customerNote:'ملاحظات العميل',
        noNotes:'لا توجد ملاحظات',
        deletedBranch:'فرع ممسوح',
        services:'الخدمات',
        emptyState:' لا يوجد خدمات حتى الآن !',
        feesRange:'مدى الرسوم',
        fees: 'الرسوم',
        sar:' ريال سعودي ',
        duration: 'المدة',
        from:' من',
        to:' إلى ',
        description: 'الوصف',
        employeeName: 'الموظف',
        customerReported: '"العميل أبلغ أن"الموظف لم يحضر',
        employeeReporeted: '"الموظف أبلغ أن"العميل لم يحضر',
        reported: 'أُبلغ',
        employeeDidNotShowUp: 'الموظف لم يحضر',
        customerDidiNotShowUP: 'العميل لم يحضر',
        remining:'متبقية',
        markAsDone: 'تم اكتمال الخدمة ',
        resolve: 'العميل حضر',
        editFees: 'تعديل الرسوم',
        feesInputLabel:' ادخل قيمة الرسوم المطلوبة لخدمة ',
        service: 'خدمة',
        feesHint: 'يجب أن تبدأ قيمة الرسوم من ',
        feesInputPlaceholder: 'الرسوم مطلوبة',
        cancelUpcomingPopupContent: 'هل متأكد من إلغاء هذا الحجز ؟',
        cancelBooking: 'إلغاء الحجز',
        cancelService:'إلغاء الخدمة',
        refuseToCancelPopupContent: 'هذا الحجز لا يمكن إلغائه لإن لديه خدمة مكتملة.',
        bookingCancelationReasonLabel:'اختار سبب إلغاء هذا الحجز',
        serviceCancelationReasonLabel:'اختار سبب إلغاء هذه الخدمة',
        cancellationReason: 'سبب الإلغاء',
        cancelledBy: 'أٌلغي من',
        Admin:'المسؤول',
        ServiceProviderManager:'مقدم لخدمة',
        Employee:'الموظف',
        Customer:'العميل',
        addAnotherService: 'أضف خدمة أخرى',
        customerHasPaid:'لقد دفع العميل',
        customerHasPaidPopupContent: 'هل أنت متأكد أن العميل قد دفع ؟',
        totalAmountWithoutVAT:' المبلغ الإجمالي بدون ضريبة القيمة المضافة',
        VAT:'القيمة المضافة',
        totalAmountWithVAT: 'المبلغ الإجمالي بضريبة القيمة المضافة',
        paymentConfirmedBy: 'تم تأكيد الدفع بواسطة',
        problemLabel: 'لقد أُبلغ عن مشكلة',
        
    },
    addAnotherService:{
        searchPlaceholder: 'ابحث باستخدام اسم الخدمة',
        specialtyFilterPlaceholder: 'التخصص',
        noData: 'لا يوجد خدمات آخرى',
        mins: 'دقيقة',
        employeePlaceHolder: 'لم يٌختار موظف حتى الآن',
        employeeLabel: 'اختار موظف',
        employeeList: 'قائمة الموظفين',
        employeeSearchPlaceolder:'ابجث باستخدام اسم الموظف أو رقم الموظف',
        counterLabel: 'خدمات مختارة',
        seeMore:'مشاهدة المزيد',
        noEmployeePlaceholder: ' لا يمكن اختيار هذه الخدمة لأن غير معين موظفين عليها '
    }

};

export default bookings;