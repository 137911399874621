import { GET_COORDS, GET_POLYON, SAVE_BRANCH_DATA } from './types';

const INITIAL_STATE = {
  coords: {},
  polygon: [],
  savedBranchData: {},
};
const googleMapCoords = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COORDS:
      return {
        ...state,
        coords: action.payload,
      };
    case GET_POLYON:
      return {
        ...state,
        polygon: action.payload,
      };
    case SAVE_BRANCH_DATA:
      return {
        ...state,
        savedBranchData: action.payload,
      };
    default:
      return state;
  }
};

export default googleMapCoords;
