import { put, call, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/auth";
import * as types from "./types";
import * as actions from "./actions";
import history from "../../routes/History";
import {
  clearserviceProviderData,
  setDeviceId,
  setServiceProvidar,
  setServiceProvidarToken,
} from "../../utils/Auth";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";
import store from "../index";
import { setUnseenNotificationsCount } from "../Notifications/actions";

function* loginSaga({ payload }) {
  try {
    const response = yield call(apis.login, payload.data);
    const device_id = payload.data.device_id;
    yield put(actions.loginResponse(response.data.service_provider));
    yield put(
      setUnseenNotificationsCount(
        response.data.service_provider.personal_data.unseen_notifications_count
      )
    );
    yield localStorage.setItem(
      "unseenNotificationsCount",
      response.data.service_provider.personal_data.unseen_notifications_count
    );
    yield setServiceProvidar(
      response.data.service_provider,
      payload.rememberMe ? "local" : "session"
    );
    yield setServiceProvidarToken(
      response.data.service_provider?.personal_data?.auth_token,
      payload.rememberMe ? "local" : "session"
    );
    yield setDeviceId(device_id);

    yield history.push(
      response.data.service_provider.personal_data.must_change_password
        ? ROUTE_PATHS.changePassword
        : !response.data.service_provider.is_profile_completed
        ? ROUTE_PATHS.setupProfile
        : ROUTE_PATHS.branches
    );
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* logoutSaga({ payload }) {
  try {
    yield call(apis.logout, payload);
    yield put(actions.loginResponse({}));
    yield clearserviceProviderData();
    yield history.push(ROUTE_PATHS.login);
  } catch (error) {
    console.log("Logout error", error);
  }
}

function* changePasswordSaga({ payload }) {
  try {
    const response = yield call(apis.changePassword, payload.data);
    const serviceProviderData = store.getState().auth.login;
    yield put(
      actions.loginResponse({
        ...serviceProviderData,
        personal_data: {
          ...serviceProviderData.personal_data,
          must_change_password: !response?.data?.success,
        },
      })
    );
    const isRemeberMeEnabled = JSON.parse(
      localStorage.getItem("serviceProvider")
    );
    yield setServiceProvidar(
      {
        ...serviceProviderData,
        personal_data: {
          ...serviceProviderData.personal_data,
          must_change_password: !response?.data?.success,
        },
      },
      isRemeberMeEnabled ? "local" : "session"
    );
    yield showSuccessMsg("changePassword");
    if(payload.from === 'settings'){
      yield history.push(ROUTE_PATHS.settings);
    }else{
      yield history.push(
        !serviceProviderData.is_profile_completed
          ? ROUTE_PATHS.setupProfile
          : ROUTE_PATHS.branches
      );
    };
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* forgetPasswordSaga({ payload }) {
  try {
    yield call(apis.forgetPassword, payload);
    yield showSuccessMsg("forgetPassword");
    yield history.push(ROUTE_PATHS.login);
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}
export default function* AuthSagas() {
  yield takeEvery(types.LOGIN_REQUEST, loginSaga);
  yield takeEvery(types.LOGOUT_REQUEST, logoutSaga);
  yield takeEvery(types.CHANGE_PASSWORD_REQUEST, changePasswordSaga);
  yield takeEvery(types.FORGET_PASSWORD_REQUEST, forgetPasswordSaga);
}
