const pagesTitles = {
  adminsManagement: 'إدارة المسؤولين',
  admins: 'قائمة المسؤولين',
  adminsAdd: 'إضافة مسؤول جديد',
  adminsEdit: 'تعديل مسؤول',
  employeesManagement: 'إدارة الموظفين',
  employees: 'قائمة الموظفين',
  employeesAdd: 'إضافة موظف جديد',
  employeesEdit: 'تعديل موظف',
  categoriesManagement: 'إدارة فئات مقدمى الخدمات',
  serviceProviderCategories: 'قائمة الفئات',
  serviceProviderCategoriesAdd: 'إضافة فئة جديدة',
  serviceProviderCategoriesEdit: 'تعديل فئة',
  serviceProviderCategoriesView: 'تفاصيل الفئة',
  employeeView: 'تفاصيل الموظف',
  specialtiesManagement: 'إدارة التخصصات',
  specialties: 'التخصصات',
  specialtiesAdd: 'إضافة تخصص جديد',
  specialtiesEdit: 'تعديل تخصص',
  specialtiesView: 'تفاصيل التخصص',
  servicesManagement: 'إدارة الخدمات',
  services: 'الخدمات',
  servicesAdd: 'إضافة خدمة جديدة',
  servicesEdit: 'تعديل خدمة',
  serviceProvidersManagement: 'إدارة مقدمي الخدمات',
  branchesManagement: 'إدارة الفروع',
  serviceProviders: 'مقدمي الخدمات',
  serviceProvidersAdd: 'إضافة مقدم خدمة',
  serviceProvidersEdit: 'تعديل مقدم خدمة',
  serviceProvidersView: 'تفاصيل مقدم خدمة',
  branchManagement: 'إدارة الفروع ',
  branches: 'فروعي',
  addProviderBranch: 'إضافة فرع جديد',
  editProviderBranch: 'تعديل تفاصيل الفرع',
  editProviderBranchServicesAndSpecialties: 'تعديل خدمات وتخصصات الفرع',
  editProviderBranchEmployee: 'تعديل موظفي الفرع',
  AddBranch: 'إضافة فرع جديد',
  viewBranch: 'تفاصيل الفرع',
  branchTabOne: 'تفاصيل الفرع',
  branchTabTwo: 'تخصصات وخدمات الفرع',
  branchTabThree: 'موظفي الفرع',
  branchTabFour: 'قائمة الحجوزات',
  SupportedRegions: 'مناطق التغطية',
  SelectLocationFromMap: 'حدد الموقع من الخريطة',
  addProviderBranchSpecialties: 'التخصصات والخدمات',
  addProviderBranchEmployees: 'الموظفين',
  bookings: 'قائمة الحجوزات',
  bookingsManagement: 'إدارة الحجوزات',
  SettingsManagment:'إدارة الإعدادات',
  paymentTransactionsManagement:'إدارة معاملات الدفع',
  paymentTransactions:'قائمة معاملات الدفع',
};

export default pagesTitles;
