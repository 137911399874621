import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import Button from '../../../components/Button';
import Map from '../../../components/GoogleMaps/Map';
import Breadcrumbs from '../../../components/BreadCrumbs';
import messages from '../../../assets/locale/messages';
import { setPageMainTitle } from '../../../utils/Helpers';
import { ROUTE_PATHS } from '../../../utils/RoutesPaths';
import './SelectLocation.scss';
import { getGoogleMapsCoords } from '../../../store/GoogleMapCoords/actions';

const SelectLocation = (props) => {
  const lang = useSelector((state) => state.locale.lang);
  const history = useHistory();
  const { providerid } = useParams();
  const dispatch = useDispatch();

  const { branches, pagesTitles, shared } = messages[lang];
  const [coords, setCoords] = useState(null);
  const [viewOnlyMood, setViewOnlyMood] = useState(false);
  const [removeDataFromViewExceptMap, setRemoveDataFromViewExceptMap] = useState(false);
  //   perviousCoords should be retruned later on to use select
  // const [previousCoords, setpreviousCoords] = useState(null);

  useEffect(() => {
    if (props.location?.state?.viewOnly) {
      setViewOnlyMood(true);
    }
  }, [props.location?.state]);

  useEffect(() => {
    if (props?.viewMapInModelOnly === true) {
      setViewOnlyMood(true);
      setRemoveDataFromViewExceptMap(true);
    }
  }, [props]);

  const getCurrentCoords = () => {
    if (!coords) {
      navigator.geolocation.getCurrentPosition((result) => {
        let currentCoords = {
          lat: result?.coords.latitude,
          lng: result?.coords.longitude,
        };
        setCoords(currentCoords);
      });
    }
    return coords;
  };

  const getCoords = (position) => {
    setCoords(position.coords);
  };

  useEffect(() => {
    setPageMainTitle('serviceProvidersManagement');
  }, []);

  useEffect(() => {
    // if (!previousCoords) {
    //   getCurrentCoords();
    // }
    if( props?.location?.state?.coordsData?.coords?.lat && 
      props?.location?.state?.coordsData?.coords?.lng){
       setCoords(props.location?.state?.coordsData?.coords)
    }else{
       getCurrentCoords();  
    }
  }, []);

  return (
    <div className="coverage-areas-page">
      <div className="page-container">
        <div className="header mb-4">
          {!removeDataFromViewExceptMap && (
            <Breadcrumbs
              list={[
                {
                  label: pagesTitles['AddBranch'],
                  path: ROUTE_PATHS.addBranch,
                },
                {
                  label: pagesTitles['SupportedRegions'],
                  path: ROUTE_PATHS.addBranchSupportedRegions.replace(':id', providerid),
                },
              ]}
              BreadCrumbHasBackIcon={true}
            />
          )}
        </div>
        <div className="google-map-section">
          <Map
            isMarker={true}
            getCoords={getCoords}
            // savedCoords={(props.location?.state?.coords && {coords:{...props.location?.state?.coords}}) || props?.showCoords || coords}
            savedCoords={props.location?.state?.coordsData || props?.showCoords || coords}
            className="h-50 w-75"
            hasSearch={true}
            isTooltip={true}
            resetMap={false}
            hasBreadCurmb={true}
            viewOnly={viewOnlyMood}
            breadcrumbsList={[
              {
                label: pagesTitles['AddBranch'],
                path: ROUTE_PATHS.addBranch,
              },
              {
                label: pagesTitles['SelectLocationFromMap'],
                path: ROUTE_PATHS.addBranchSupportedRegions.replace(':id', providerid),
              },
            ]}
            BreadCrumbHasBackIcon={true}
            resetButtonText={
              <div>
                <span>
                  <SettingsBackupRestoreIcon />
                </span>
                <span>{shared.ResetPolygon}</span>
              </div>
            }
            hideSearchBarAndBTNReset={removeDataFromViewExceptMap}
          />
        </div>
        {!removeDataFromViewExceptMap && (
          <div className="SaveChanges text-end mt-5">
            {!viewOnlyMood ? (
              <Button
                label={
                  <span className=" w-25">
                    <span className="ps-1">{shared.saveAndProcced}</span>
                  </span>
                }
                labelClass="fsize-16 text-white"
                onClick={() => {
                  dispatch(getGoogleMapsCoords({ ...coords }));
                  history.goBack();
                }}
              />
            ) : (
              <Button
                label={
                  <span className=" w-25">
                    <span className="ps-1">{shared.back}</span>
                  </span>
                }
                labelClass="fsize-16 text-white"
                onClick={() => {
                  history.goBack();
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectLocation;
