import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  AppBar as MuiAppBar,
  Typography,
  Toolbar,
  IconButton,
} from "@mui/material";
import { Menu, FiberManualRecord, Storefront, CalendarMonthTwoTone, LiveHelpOutlined } from "@mui/icons-material";

import { getDropdownNotificationsRequest } from "../../../store/Notifications/actions";
import {
  handleNotificationsClickActions,
  setNotificationSeen,
} from "../../../firebase/helpers";
import messages from "../../../assets/locale/messages";
import LangSwitcher from "../../LangSwitcher";
import Avatar from "../../Avatar"
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { formatDate } from "../../../utils/Helpers";
import {
  NotificationsBgIcon,
  SeenNotificationIcon,
} from "../../../utils/Icons";
import NotificationsMenu from "../../NotificationsMenu";
import "./NavBar.scss";

const NavBar = ({
  sideMenuOpen,
  drawerWidth,
  collapsedDrawerWidth,
  openMobileSideMenu,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector((state) => state.locale.lang);
  const title = useSelector((state) => state.navigation.pageTitle);
  const user = useSelector((state) => state.auth.login);
  const notificationsList = useSelector(
    (state) => state?.notifications?.dropdownNotifications
  );
  const unseenNotificationsCount = useSelector(
    (state) => state?.notifications?.unseenNotificationsCount
  );
  const { pagesTitles, shared, notifications } = messages[lang];

  const customStyle = {
    backgroundColor: "white",
    transition: (theme) =>
      theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    width: { sm: `calc(100% - ${collapsedDrawerWidth}px)` },
    ...(sideMenuOpen && {
      ml: { sm: `${drawerWidth}px` },
      width: { sm: `calc(100% - ${drawerWidth}px)` },
      transition: (theme) =>
        theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
    }),
  };

  const handleGetNotifications = () => {
    dispatch(
      getDropdownNotificationsRequest({
        items: 5,
        page: 1,
      })
    );
  };

  const renderSingleNotification = (title, body, created_at, click_action) => (
    <div className="d-flex">
      <span>
        {click_action === "open_branch" 
          ? (<Storefront className="text-secondary"/>)
          : click_action === "view_appointment"
          ? (<CalendarMonthTwoTone className="text-secondary"/>)
          : (<LiveHelpOutlined className="text-secondary"/>)
        }
      </span>
      <div style={{ paddingLeft: "0.5rem" }} className="me-3">
        <span className="mb-1 d-block notification-title font-bold fsize-15">
          {title}
        </span>
        <span className="mb-2 d-block notification-body">
          {body.length > 50 ? <>{body.substring(0, 50)} ...</> : body}
        </span>
        <span className="notification-date d-block fsize-12 font-regular">
          {formatDate(created_at, "DD MMM YYYY",lang)}
        </span>
      </div>
    </div>
  );
  const getMenuItems = () =>
    notificationsList?.map(({ id, title, body, created_at, is_seen, click_action }) => ({
      id,
      content: renderSingleNotification(title, body, created_at, click_action),
      className: is_seen ? "seen-notification" : "unseen-notification",
      adorment: !is_seen ? (
        <span className="adorment-notifaction fsize-16">
          {/* <NotifcationTooltip title={<p>{notifications.markAsRead}</p>}> */}
          <FiberManualRecord
            className="seen-adorment text-secondary"
            onClick={(e) => handleNotificationSeen(e, id)}
          />
          {/* </NotifcationTooltip> */}
        </span>
      ) : null,
    }));

  const renderMenuController = () => (
    <div
      className="d-flex flex-column align-items-center ms-3 pointer"
      onClick={handleGetNotifications}>
      <div className="position-relative bg-gray-mid hei-40 wid-40 rounded-circle notification-icon-wrapper mx-2 mx-sm-3">
        {unseenNotificationsCount ? (
          <div
            className={`notifications-count position-absolute text-center`}></div>
        ) : null}
        <SeenNotificationIcon size="medium" sx={{ ml: 2 }} className="" />
      </div>
    </div>
  );
  const renderMenuFooter = () => (
    <div className="py-1 text-secondary fsize-14 pointer mb-0 text-center">
      <u
        onClick={()=>{
          history.push(ROUTE_PATHS.notifications);
        }}
      >
          {notifications.seeAllNotifications}
      </u>
    </div>
  );

  const handleSelectNotification = (notificationId) => {
    const selectedNotification = notificationsList.find(
      (item) => item.id === notificationId
    );

    handleNotificationsClickActions(selectedNotification);
  };

  const handleNotificationSeen = (e, id) => {
    e.stopPropagation();
    setNotificationSeen(id);
  };

  return (
    <MuiAppBar position="fixed" sx={customStyle} className="nav-bar">
      <Toolbar>
        <IconButton
          color="primary"
          aria-label="open drawer"
          edge="start"
          onClick={() => openMobileSideMenu(true)}
          sx={{ display: { sm: "none" } }}
          className="mobile-toggle-icon">
          <Menu />
        </IconButton>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <Typography variant="h4" sx={{ flexGrow: 1 }} className="fweight-700 page-title">
            {pagesTitles[title]}
          </Typography>
          <NotificationsMenu
            menuController={renderMenuController()}
            menuItems={getMenuItems()}
            handleSelectItem={handleSelectNotification}
            menuFooter={renderMenuFooter()}
            emptyState={notifications.noNotifications}
          />
          <div className="mx-2">
            <LangSwitcher />
          </div>
          <Link
            className="d-flex gap-2"
            to={ROUTE_PATHS.editProfile} 
          >
            <Avatar
              imgSrc= {user.logo_image}
              imgSrcAlt="user-profile"
              name= {lang === 'en'
                      ? user.name_en
                      : user.name_ar
                    } 
              size={45}
            />
            <div className="text-primary fsize-16 fweight-500">
              <div>
                {lang === 'en'
                ? user.name_en
                : user.name_ar
                }
              </div>
              <div className="text-secondary fsize-14 fweight-400">
                {shared.serviceProvider}
              </div>
            </div>
          </Link>
        </div>
      </Toolbar>
    </MuiAppBar>
  );
};

NavBar.propTypes = {
  sideMenuOpen: PropTypes.bool.isRequired,
  drawerWidth: PropTypes.number.isRequired,
  collapsedDrawerWidth: PropTypes.number.isRequired,
  openMobileSideMenu: PropTypes.func.isRequired,
};

export default NavBar;
