export const GET_EMPLOYEES_REQUEST = 'GET_EMPLOYEES_REQUEST';
export const GET_EMPLOYEES_RESPONSE = 'GET_EMPLOYEES_RESPONSE';

export const EDIT_EMPLOYEE_REQUEST = 'EDIT_EMPLOYEE_REQUEST';

export const GET_EMPLOYEE_DETAILS_REQUEST = 'GET_EMPLOYEE_DETAILS_REQUEST';
export const GET_EMPLOYEE_DETAILS_RESPONSE = 'GET_EMPLOYEE_DETAILS_RESPONSE';

export const DELETE_EMPLOYEE_REQUEST = 'DELETE_EMPLOYEE_REQUEST';

// helpers
// Usage: to get branch details by employee id
export const GET_BRANCH_DETAILS_BY_EMP_ID_REQUEST = 'GET_BRANCH_DETAILS_BY_EMP_ID_REQUEST';
export const GET_BRANCH_DETAILS_BY_EMP_ID_RESPONSE = 'GET_BRANCH_DETAILS_BY_EMP_ID_RESPONSE';

// Usage: to get branch services by branch id after came from employee id
export const GET_BRANCH_SERVICES_BY_BRANCH_ID_REQUEST = 'GET_BRANCH_SERVICES_BY_BRANCH_ID_REQUEST';
export const GET_BRANCH_SERVICES_BY_BRANCH_ID_RESPONSE = 'GET_BRANCH_SERVICES_BY_BRANCH_ID_RESPONSE';

export const GET_EDIT_EMPLOYEE_STATUS_REQUEST_STATUS = 'GET_EDIT_EMPLOYEE_STATUS_REQUEST_STATUS';
