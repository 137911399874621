const employees = {
  list: {
    search: 'ابحث عن اسم موظف او رقم الهاتف',
    CreateEmployeeButton: 'إضافة موظف جديد',
    noDataDescription: 'ابدأ بإضافة موظف جديد',
    employeeId: 'رقم الموظف',
    name: 'الاسم',
    phoneNumber: 'رقم الهاتف',
    branch: 'الفرع',
    status: 'الحالة',
    filter: {
      filtersPlaceHolders: {
        filterByService: 'الخدمة',
        filterByEmployeeStatus: 'الحالة',
        filterByBranchName: 'الفرع',
        filterByLocations: 'الموقع',
      },
    },
    statusCases: {
      active: 'مفعل',
      deactivated: 'غير مفعل',
      suspended: 'معلق',
      blocked:'محظور'
    },
    suspendedByDeactivateEmployee: 'سيتم ايقاف الموظف  بداية من',
  },
  deleteEmployee: {
    title: 'حذف موظف',
    deleteConfirmationMsg: 'هل انت متأكد من حذف الموظف؟',
    note:'لاحظ أن',
    validationNote:'قد يتم تعطيل بعض الخدمات لو كان آخر موظف مٌعين لها.'
  },
  errorMessages: {
    phoneRequired: 'رقم الهاتف مطلوب',
  },
};

export default employees;
