import React from "react";

export const BranchDetailsTabInprogress = () => (
<svg width="122" height="123" viewBox="0 0 122 123" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1_84)">
<rect x="4" y="4" width="114" height="114" rx="57" fill="url(#paint0_linear_1_84)" fillOpacity="0.1" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter1_d_1_84)">
<rect x="14" y="13" width="96" height="96" rx="46.1957" fill="url(#paint1_linear_1_84)"/>
</g>
<g clipPath="url(#clip0_1_84)">
<path d="M62.3 75.9C62.4 76.4 62.6 76.9 62.8 77.4C62 77.5 61.2 77.5 60.3 77.5H50C41.1 77.5 37.3 73.7 37.3 64.8V56.3C37.3 55.9 37.6 55.5 38.1 55.5C38.5 55.5 38.9 55.8 38.9 56.3V64.8C38.8 73 41.8 76 50 76H60.2C61 76 61.7 76 62.3 75.9Z" fill="white" stroke="white" strokeWidth="0.7"/>
<path d="M73 56.3V62.2C72.5 62.2 72 62.2 71.5 62.3V56.3C71.5 55.9 71.8 55.5 72.3 55.5C72.8 55.5 73 55.9 73 56.3Z" fill="white" stroke="white" strokeWidth="0.7"/>
<path d="M55.1 58.5C53.2 58.5 51.5 57.8 50.3 56.4C49.1 55 48.5 53.3 48.7 51.4L50 38.7C50 38.3 50.3 38 50.7 38H59.6C60 38 60.3 38.3 60.3 38.7L61.6 51.4C61.8 53.3 61.2 55.1 60 56.4C58.7 57.8 57 58.5 55.1 58.5ZM51.4 39.5L50.2 51.5C50.1 53 50.5 54.3 51.4 55.4C52.3 56.4 53.7 57 55.1 57C56.5 57 57.9 56.4 58.8 55.4C59.7 54.4 60.2 53 60 51.5L58.8 39.5H51.4Z" fill="white" stroke="white" strokeWidth="0.7"/>
<path d="M59.9 77.5H50.4C50 77.5 49.6 77.2 49.6 76.7V72C49.6 68.4 51.5 66.5 55.1 66.5C58.7 66.5 60.6 68.4 60.6 72V76.8C60.6 77.2 60.3 77.5 59.9 77.5ZM51.1 76H59.1V72C59.1 69.2 57.9 68 55.1 68C52.3 68 51.1 69.2 51.1 72V76ZM67.1 58.5C63.6 58.5 60.4 55.6 60.1 52.1L58.8 38.8C58.8 38.6 58.8 38.4 59 38.2C59.1 38.1 59.3 38 59.5 38H65.3C70.5 38 72.9 40.1 73.6 45.5L74.1 50.8C74.3 52.9 73.7 54.8 72.4 56.3C71.1 57.7 69.2 58.5 67.1 58.5ZM60.3 39.5L61.6 52C61.9 54.8 64.4 57 67.1 57C68.7 57 70.2 56.4 71.3 55.2C72.3 54 72.8 52.5 72.7 50.9L72.2 45.7C71.6 41 69.9 39.5 65.4 39.5H60.3ZM43.1 58.5C41 58.5 39.2 57.7 37.8 56.2C36.5 54.7 35.8 52.8 36.1 50.7L36.6 45.5C37.3 40.1 39.6 38 44.9 38H50.7C50.9 38 51.1 38.1 51.3 38.2C51.4 38.4 51.5 38.6 51.5 38.8L50.2 52.1C49.7 55.6 46.6 58.5 43.1 58.5ZM44.9 39.5C40.3 39.5 38.7 41 38 45.7L37.5 50.9C37.3 52.5 37.8 54.1 38.9 55.2C39.9 56.4 41.4 57 43.1 57C45.9 57 48.4 54.8 48.6 52L49.8 39.5H44.9Z" fill="white" stroke="white" strokeWidth="0.7"/>
<path d="M84.9 84.9C84.7 84.9 84.5 84.8 84.4 84.7L82.4 82.7C82.1 82.4 82.1 81.9 82.4 81.6C82.7 81.3 83.2 81.3 83.5 81.6L85.5 83.6C85.8 83.9 85.8 84.4 85.5 84.7C85.3 84.8 85.1 84.9 84.9 84.9ZM74.4 83.9C71.7 83.9 69.1 82.8 67.2 80.9C65.3 79 64.2 76.4 64.2 73.7C64.2 71 65.3 68.4 67.2 66.5C69.1 64.6 71.7 63.5 74.4 63.5C75.8 63.5 77.1 63.8 78.3 64.3C79.6 64.8 80.7 65.6 81.6 66.5C82.6 67.5 83.3 68.6 83.8 69.8C84.3 71 84.6 72.4 84.6 73.7C84.6 75.1 84.3 76.4 83.8 77.6C83.3 78.9 82.5 80 81.6 80.9C80.6 81.9 79.5 82.6 78.3 83.1C77.1 83.6 75.8 83.9 74.4 83.9ZM74.4 64.9C72.1 64.9 69.9 65.8 68.2 67.5C66.5 69.2 65.6 71.3 65.6 73.7C65.6 76 66.5 78.2 68.2 79.9C69.9 81.6 72.1 82.5 74.4 82.5C75.6 82.5 76.7 82.3 77.7 81.8C78.8 81.4 79.7 80.7 80.5 79.9C81.3 79.1 82 78.1 82.4 77.1C82.8 76 83.1 74.9 83.1 73.8C83.1 72.7 82.9 71.5 82.4 70.5C82 69.4 81.3 68.5 80.5 67.7C79.7 66.9 78.7 66.2 77.7 65.8C76.7 65.1 75.6 64.9 74.4 64.9Z" fill="white" stroke="white" strokeWidth="0.7"/>
</g>
<defs>
<filter id="filter0_d_1_84" x="0" y="0.8" width="122" height="122" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="0.8"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.247059 0 0 0 0 0.278431 0 0 0 0 0.321569 0 0 0 0.5 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_84"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_84" result="shape"/>
</filter>
<filter id="filter1_d_1_84" x="10" y="9.8" width="104" height="104" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="0.8"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.247059 0 0 0 0 0.278431 0 0 0 0 0.321569 0 0 0 1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_84"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_84" result="shape"/>
</filter>
<linearGradient id="paint0_linear_1_84" x1="4" y1="118" x2="139.621" y2="118" gradientUnits="userSpaceOnUse">
<stop stopColor="#3F4752"/>
<stop offset="0.375" stopColor="#3F4752"/>
<stop offset="1" stopColor="#888D94"/>
</linearGradient>
<linearGradient id="paint1_linear_1_84" x1="14" y1="109" x2="128.207" y2="109" gradientUnits="userSpaceOnUse">
<stop stopColor="#3F4752"/>
<stop offset="0.375" stopColor="#3F4752"/>
<stop offset="1" stopColor="#888D94"/>
</linearGradient>
<clipPath id="clip0_1_84">
<rect width="49.7" height="46.9" fill="white" transform="translate(36 38)"/>
</clipPath>
</defs>
</svg>

);
