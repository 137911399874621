import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { TextField, InputLabel, FormHelperText } from "@mui/material";
// For localization
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ar-sa";

import messages from "../../assets/locale/messages";
import "./DatePicker.scss";

const DatePicker = ({
  value,
  onChange,
  name,
  id,
  label,
  required,
  placeholder,
  calendarViews,
  openView,
  wrapperClass,
  labelClass,
  inputClass,
  helperTextClass,
  closeOnSelect,
  disabled,
  disableFuture,
  disablePast,
  disableMinToday,
  disableMaxToday,
  maxDate,
  minDate,
  inputDateFormat,
  shouldDisableDate,
  shouldDisableMonth,
  shouldDisableYear,
  readOnly,
  isInputHasErr,
  errMsg,
  helperText,
  placement,
  setInvalidDeactivatingDate,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { inputsValidations } = messages[lang];

  const [date, setDate] = useState(null);
  const [invalidDate, setInvalidDate] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const today = new Date();

  // To handle any required formatting on date
  useEffect(() => {
    setDate(dayjs(value));
  }, [value]);

  const checkEnteredDayBeforeToday = (keyboardinput) => {
    let formattedToday = new Date().toJSON().slice(0, 10);
    return dayjs(formattedToday).format("DD/MM/YYYY") >= keyboardinput
  };

  const handleDateChange = (selectedDate, keyboardinput) => {
    if (dayjs(selectedDate).format() === "Invalid Date" || checkEnteredDayBeforeToday(keyboardinput)) {
      setInvalidDate(true);
      setDate(selectedDate);
      setInvalidDeactivatingDate(true)
    } else {
      setInvalidDate(false);
      onChange(dayjs(selectedDate).format());
      setInvalidDeactivatingDate(false)
    }
  };
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClick = (event) => {
    setIsOpen((isOpen) => !isOpen);
    setAnchorEl(event.currentTarget);
  };
  return (
    <div className={`date-picker-wrapper ${wrapperClass}`}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={lang === "en" ? "en" : "ar-sa"}
      >
        <InputLabel
          htmlFor={id || name}
          className={`date-picker-label ${labelClass}`}
        >
          <>
            {label}
            {required ? <span className="err"> *</span> : ""}
          </>
        </InputLabel>
        <MuiDatePicker
          value={date}
          PopperProps={{
            placement: placement ? placement : "right",
            anchorEl: anchorEl,
          }}
          onChange={handleDateChange}
          onclick={(e) => setAnchorEl(e.currentTarget)}
          required={required}
          className={`custom-date-picker ${inputClass} ${
            invalidDate || isInputHasErr ? "error-border" : ""
          }`}
          views={calendarViews}
          openTo={openView}
          closeOnSelect={closeOnSelect}
          disabled={disabled}
          disableFuture={disableFuture}
          disablePast={disablePast}
          maxDate={
            disableMaxToday ? today.setDate(today.getDate() - 1) : maxDate
          }
          minDate={
            disableMinToday ? today.setDate(today.getDate() + 1) : minDate
          }
          inputFormat={inputDateFormat}
          readOnly={readOnly}
          shouldDisableDate={shouldDisableDate}
          shouldDisableMonth={shouldDisableMonth}
          shouldDisableYear={shouldDisableYear}
          onClick={handleClick}
          renderInput={(params) => (
            <TextField
              {...params}
              onClick={handleClick}
              placeholder={placeholder}
              error={invalidDate || isInputHasErr}
            />
          )}
        />
      </LocalizationProvider>
      <FormHelperText
        error={invalidDate || isInputHasErr}
        className={`${helperTextClass || "font-medium fsize-12"} ${
          lang === "en" ? "" : "text-end"
        }`}
      >
        {invalidDate
          ? inputsValidations["invalidDate"]
          : isInputHasErr
          ? inputsValidations[errMsg]
          : helperText}
      </FormHelperText>
    </div>
  );
};

DatePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  calendarViews: PropTypes.arrayOf(PropTypes.string),
  openView: PropTypes.oneOf(["day", "month", "year"]),
  wrapperClass: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  helperTextClass: PropTypes.string,
  closeOnSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableMinToday: PropTypes.bool,
  disableMaxToday: PropTypes.bool,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  inputDateFormat: PropTypes.string,
  shouldDisableDate: PropTypes.func,
  shouldDisableMonth: PropTypes.func,
  shouldDisableYear: PropTypes.func,
  readOnly: PropTypes.bool,
  isInputHasErr: PropTypes.bool,
  errMsg: PropTypes.string,
  helperText: PropTypes.string,
  placement: PropTypes.string,
  setInvalidDeactivatingDate: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  calendarViews: ["year", "month", "day"],
  placement: PropTypes.oneOf([
    "bottom",
    "top",
    "right",
    "left",
    "bottom-end",
    "bottom-start",
    "top-end",
    "top-start",
    "right-end",
    "right-start",
    "left-end",
    "left-start",
  ]),
  openView: "day",
  closeOnSelect: true,
  inputDateFormat: "DD/MM/YYYY",
};

export default DatePicker;
