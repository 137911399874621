import { put, call, takeEvery } from "redux-saga/effects";
import * as types from "./types";
import * as apis from "../../network/apis/notifications";
import {
  getDropdownNotificationsResponse,
  getNotificationsResponse,
  setUnseenNotificationsCount,
} from "./actions";
import { replaceSeenNotificationInList } from "../../firebase/helpers";
import { showErrorMsg } from "../../utils/Helpers";

function* getNotificationsSaga({ payload }) {
  try {
    const response = yield call(apis.getNotifications, payload);
    yield put(
      getNotificationsResponse({
        list: response.data.notifications,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"],
        },
      })
    );
    yield put(setUnseenNotificationsCount(0));
    yield localStorage.setItem("unseenNotificationsCount", 0);
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* setNotificationSeen({ payload }) {
  try {
    const response = yield call(apis.setNotificationSeen, payload);
    yield replaceSeenNotificationInList(response.data.notification);
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* getDropdownNotificationsSaga({ payload }) {
  try {
    const response = yield call(apis.getNotifications, payload);
    console.log(response)
    yield put(getDropdownNotificationsResponse(response.data.notifications));
    yield put(setUnseenNotificationsCount(0));
    yield localStorage.setItem("unseenNotificationsCount", 0);
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

export default function* NotificationsSagas() {
  yield takeEvery(types.GET_NOTIFICATIONS_REQUEST, getNotificationsSaga);
  yield takeEvery(types.SET_NOTIFICATION_SEEN, setNotificationSeen);
  yield takeEvery(
    types.GET_DROPDOWN_NOTIFICATIONS_REQUEST,
    getDropdownNotificationsSaga
  );
}