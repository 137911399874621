import { axiosInstance } from '.';

const getCurranciesListLookUp = async (params) => await axiosInstance.get('/currencies', { params });

const getProvidersListLookUp = async (params) => await axiosInstance.get('/service_providers', { params });

const getServicesListLookUp = async (params) => await axiosInstance.get('/services', { params });

const getSpecialtyListLookUp = async (params) => await axiosInstance.get('/specialties', { params });

const getBranchSpecialtyListLookUp = async (params) => await axiosInstance.get('/branch_specialties', { params });

const getEmployeesListLookUp = async (params) => await axiosInstance.get('/employees', { params });

const getCategoriesListLookUp = async (params) => await axiosInstance.get('/categories', { params });

const getBranchesListLookUp = async (params) => await axiosInstance.get('/branches', { params });

export {
  getCurranciesListLookUp,
  getProvidersListLookUp,
  getServicesListLookUp,
  getSpecialtyListLookUp,
  getBranchSpecialtyListLookUp,
  getEmployeesListLookUp,
  getCategoriesListLookUp,
  getBranchesListLookUp,
};
