const serviceProviderCategories = {
  serviceProviderCategoriesList: "Service provider categories",
  list: {
    addedSpecialties: "Added specialties",
    addedServiceProviders: "Added service providers",
    search: "Search for a category",
    noDataSubTitle: "There are no categories to display yet",
    noDataDescription: "Start now to add categories",
    CreateCategoriesButton: "Add a new category",
    filter: {
      filtersPlaceHolders: {
        filterBySpecialty: "Specialty",
      },
    },
  },
  addCategory: {
    title: "Add new category",
    labels: {
      enTitle: "Title (En)",
      arTitle: "Title (Ar)",
      uploadLogo: "Logo",
    },
    placeholders: {
      enTitle: "Enter category english title",
      arTitle: "Enter category arabic title",
    },
  },
  editCategory: {
    title: "Edit Category",
    labels: {
      edit: "Save",
    },
  },
  deleteCategory: {
    title: "Delete category",
    deleteConfirmationMsg: "Are you sure you want to delete this category? ",
  },
  viewCategory: {
    title: "Category details",
    noProvider: "No providers are added yet",
    noSpecialty: "No specialties are added yet",
    noCategory: "No category is added yet",
  },
};

export default serviceProviderCategories;
