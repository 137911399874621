import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ROUTE_PATHS } from '../../../utils/RoutesPaths';
import { generateDeviceId } from '../../../utils/Auth';
import messages from '../../../assets/locale/messages';
import { getFirebaseToken } from "../../../firebase/helpers";
import { loginRequest } from '../../../store/Auth/actions';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import './Login.scss';

const Login = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { login } = messages[lang];

  const dispatch = useDispatch();

  const { values, setFieldValue, handleSubmit, touched, errors, setFieldTouched, dirty, isValid } = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().email('validEmailFormat').required('emailRequired'),
      password: Yup.string().required('passwordRequired'),
    }),
    onSubmit: ({ email, password, rememberMe }) => {
      dispatch(
        loginRequest({
          data: {
            email,
            password,
            device_id: generateDeviceId(),
            fcm_token: getFirebaseToken(),
          },
          rememberMe,
        })
      );
    },
  });

  return (
    <div className="login-page w-100">
      <h3 className="title fsize-16 font-medium text-secondary">
        {login.title} <span className="text-secondary">{login.title2}</span>
      </h3>
      <p className="my-2 fsize-30 font-bold text-primary">
        {login.subTitle}
        <span className="text-secondary"> {login.subTitle2}</span>
      </p>
      <form className="form-login" onSubmit={handleSubmit} noValidate>
        <Input
          id="email"
          label={login.labels.email}
          name="email"
          type="email"
          placeholder={login.placeholders.email}
          required
          onChange={(value) => {
            setFieldTouched('email');
            setFieldValue('email', value.trim());
          }}
          value={values['email']}
          inputClass="mb-1"
          labelClassName="font-medium mb-2 text-primary"
          inputWrapperClass="my-4"
          isInputHasErr={!!(touched['email'] && errors['email'])}
          errMsg={errors['email']}
        />
        <Input
          id="password"
          label={login.labels.password}
          name="password"
          type="password"
          placeholder={login.placeholders.password}
          required={true}
          onChange={(value) => {
            setFieldTouched('password');
            setFieldValue('password', value.trim());
          }}
          value={values['password']}
          inputClass="mb-1"
          labelClassName="font-medium mb-2 text-primary"
          inputWrapperClass="mt-4"
          isInputHasErr={!!(touched['password'] && errors['password'])}
          errMsg={errors['password']}
        />
        <Checkbox
          id="rememberMe"
          label={login.labels.remember}
          labelClass="text-secondary fsize-14"
          name="rememberMe"
          checked={values['rememberMe']}
          onChange={(checked) => {
            setFieldTouched('rememberMe');
            setFieldValue('rememberMe', checked);
          }}
          checkboxClass="text-secondary"
        />
        <Button
          label={login.labels.signin}
          type="submit"
          labelClass="fsize-16 text-white py-1 fweight-600"
          className="my-2"
          block
          disabled={!dirty || !isValid}
        />
        <div className="text-end">
          <Link to={ROUTE_PATHS.forgetPassword} className="text-gray fsize-14 fweight-400 text-underline">
            {login.labels.forgetPassword}
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
