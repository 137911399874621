import React, {useEffect} from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';

import messages from "../../assets/locale/messages";
import { setPageMainTitle } from '../../utils/Helpers';
import { ROUTE_PATHS } from '../../utils/RoutesPaths';


const Settings = () => {

    const lang = useSelector((state) => state.locale.lang);
    const { settings } = messages[lang];


    useEffect(() => {
        setPageMainTitle('SettingsManagment');
    }, []);

    return(
        <>
            <div className="bg-white rounded-4 px-3 pb-3 mb-4 d-flex gap-2">
                <div className="text-secondary fsize-22"><HttpsOutlinedIcon fontSize="fweight-500"/></div>
                <Link
                    className="mt-2 fsize-18 fweight-500"
                    to={ROUTE_PATHS.changePasswordFromSettings}   
                >
                    {settings.viewSettings.changePassword}
                </Link>
            </div>
            <div className="bg-white rounded-4 px-3 pb-3 d-flex gap-2">
                <div className="text-secondary fsize-22"><PortraitOutlinedIcon fontSize="fweight-500"/></div>
                <Link
                    className="mt-2 fsize-18 fweight-500"
                    to={ROUTE_PATHS.editProfile} 
                >
                    {settings.viewSettings.editPofile}
                </Link>
            </div>
        </>
    );
};

export default Settings;