import React, { useState, useEffect }  from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { TableCell, TableRow, Typography } from '@mui/material';
import { ReportProblemOutlined } from '@mui/icons-material'

import {
  getBranchesLookUpRequest,
  getBranchesLookUpResponse,
} from "../../store/LookUps/actions";
import { getBookingsRequest } from '../../store/Bookings/actions'
import messages from '../../assets/locale/messages';
import { renderTextEllipsis, setPageMainTitle, textContainOnlySpaces } from "../../utils/Helpers";
import { bookingsStatusList } from '../../utils/Constants';
import { ROUTE_PATHS } from '../../utils/RoutesPaths';
import { ViewBgIcon } from '../../utils/Icons';
import SearchInput from '../../components/SearchInput';
import FilterLabel from '../../components/Filter/FilterLabel';
import FilterInput from '../../components/Filter/FilterInput';
import FilterDatePicker from '../../components/Filter/FilterDatePicker';
import EmptyState from '../../components/EmptyState';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import './Bookings.scss'

const Bookings = () => {
    
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader);
  const lang = useSelector((state) => state.locale.lang);
  const branchList = useSelector((state) => state.lookUps.branches);
  const bookingsList = useSelector((state) => state.bookings.list);
  const meta = useSelector((state) => state.bookings.meta);
  const { shared, pagesTitles, bookings } = messages[lang];
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [filterBranchValue, setFilterBranchValue] = useState(null);
  const [filterStartDateValue, setFilterStartDateValue] = useState(null);
  const [filterEndDateValue, setFilterEndDateValue] = useState(null);
  const [filterStatusValue, setFilterStatusValue] = useState(null);
  const [branchOptions, setBranchOptions] = useState([]);
  const statusOptions = [
    {
      id: bookingsStatusList.upcoming,
      label: bookings.status.upcoming,
      value: 'upcoming'
    },
    {
      id: bookingsStatusList.ongoing,
      label: bookings.status.ongoing,
      value: 'ongoing'
    },
    {
      id: bookingsStatusList.pendingPayment,
      label: bookings.status.pending_payment,
      value: 'pending_payment'
    },
    {
      id: bookingsStatusList.completed,
      label: bookings.status.completed,
      value: 'completed'
    },
    {
      id: bookingsStatusList.canceled,
      label: bookings.status.canceled,
      value: 'canceled'
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(getBranchesLookUpResponse([]));
    };
  }, []);

  useEffect(() => {
    setPageMainTitle("bookingsManagement");
    getBranchesLookUp();
  }, []);

  useEffect(()=>{
    getBookingsList(
      page,
      searchValue,
      filterBranchValue?.id,
      filterStartDateValue,
      filterEndDateValue,
      filterStatusValue?.value
    );
  },[page]);  

  useEffect(()=>{
    setPage(1);
    getBookingsList(
      1,
      searchValue,
      filterBranchValue?.id,
      filterStartDateValue,
      filterEndDateValue,
      filterStatusValue?.value
    );
  },[
      filterBranchValue,
      filterStartDateValue,
      filterEndDateValue,
      filterStatusValue,
      branchOptions
    ]);  

  useEffect(()=>{
    if ((searchValue.length >= 3 || searchValue.length === 0) && !textContainOnlySpaces(searchValue)){
      setPage(1);
      getBookingsList(
        1,
        searchValue,
        filterBranchValue?.id,
        filterStartDateValue,
        filterEndDateValue,
        filterStatusValue?.value
      ); 
    }  
  },[searchValue]);      

  useEffect(() => {
    if (branchList && branchList.length) {
      setBranchOptions(
        branchList.map(({ id, name_en, name_ar }) => ({
          id: id,
          label: lang === 'en' ? name_en : name_ar,
        }))
      );
    }
  }, [branchList, lang]) 

  useEffect(() => {
    if(filterBranchValue){
      setFilterBranchValue(branchOptions.find((option) => option.id === filterBranchValue.id))
    }
    if(filterStatusValue){
      setFilterStatusValue(statusOptions.find((option) => option.id === filterStatusValue.id))
    }

  },[branchOptions, lang])


  const getBranchesLookUp = () => {
    dispatch(
      getBranchesLookUpRequest({
        page: -1,
      })
    );
  };  

  const getBookingsList = (
    page,
    search,
    branchId,
    startDate,
    endDate,
    status,  
  ) => {
    dispatch(
      getBookingsRequest({
        page: page,
        items: 10,
        query: search,
        branch_id: branchId,
        after_date: startDate,
        before_date: endDate,
        status: status, 
      })
    );
  }

  const handleViewbooking = (bookingId) => {
    history.push(ROUTE_PATHS.viewBooking.replace(':id', bookingId));
  }

    const renderHeaderSection = (showActions) => (
        <>
        <div className="services-header mb-4">
          <div className="title-search d-flex gap-2">
            <Typography
              variant="h4"
              sx={{ flexGrow: 1 }}
              className={`page-title flex-grow-0 ${lang === 'en' ? 'me-3' : 'ms-3'}`}>
              {pagesTitles.bookings}
            </Typography>
            {showActions && (
              <SearchInput
                className="filter-label-wrapper"
                placeholder={bookings.list.search}
                name="search"
                value={searchValue}
                onChange={(value) => {
                  setSearchValue(value);
                }}
              />
            )}
          </div>
        </div>
        {showActions && (
          <>
            <div className="d-flex gap-1 align-items-start">
              <div className="mt-2">
                <FilterLabel />
              </div>
              <div className="d-flex flex-column flex-grow-1">
                <div className="filter-label-wrapper d-flex flex-column flex-lg-row align-items-center mb-4 gap-2 flex-wrap flex-md-nowrap">
                  {/* filterByBranchName */}
                  <FilterInput
                    placeholder={bookings.list.filter.filtersPlaceHolders.filterByBranchName}
                    options={branchOptions}
                    value={filterBranchValue}
                    name="Branch Name Filter"
                    onChange={(value) => {
                      setFilterBranchValue(value);
                    }}
                    filterWrapperClass="my-0 w-100"
                  />
                  {/* filterByStartDate */}
                  <FilterDatePicker
                    name="start date filter"
                    placeholder={bookings.list.filter.filtersPlaceHolders.filterByStartDate}
                    value={filterStartDateValue}
                    label={bookings.list.filter.filtersPlaceHolders.filterByStartDate}
                    onChange={(value) => {
                      setFilterStartDateValue(value);
                    }}
                    wrapperClass="my-0 w-100"
                    inputClass="w-100"
                  />
                  {/* filterByEndDate */}
                  <FilterDatePicker
                    name="End date filter"
                    placeholder={bookings.list.filter.filtersPlaceHolders.filterByEndDate}
                    value={filterEndDateValue}
                    label={bookings.list.filter.filtersPlaceHolders.filterByEndDate}
                    onChange={(value) => {
                      setFilterEndDateValue(value);
                    }}
                    wrapperClass="my-0 w-100"
                    inputClass="w-100"
                  />
                  {/* booking status */}
                  <FilterInput
                    placeholder={bookings.list.filter.filtersPlaceHolders.filterByBookingsStatus}
                    options={statusOptions}
                    value={filterStatusValue}
                    name="branch status Filter"
                    onChange={(value) => {
                      setFilterStatusValue(value);
                    }}
                    filterWrapperClass="my-0 w-100"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </>
    )

    const renderBookingsList = () => {
      if(!isLoading &&
         bookingsList?.length === 0 &&
         !searchValue &&
         filterBranchValue === null &&
         filterStartDateValue === null &&
         filterEndDateValue === null &&
         filterStatusValue === null       
        ){
          return (
            <>
              {renderHeaderSection(false)}
              <div className="bg-white rounded-4 categoriess-page d-flex justify-content-center align-items-center flex-column">
                <EmptyState
                  subTitle={shared.noData}
                  showActionButton={false}
                  NoDataListWithNoActionBtn={true}
                />
              </div>
            </>
          );
        } else if (
          !isLoading &&
          (searchValue ||
          filterBranchValue != null ||
          filterStartDateValue != null ||
          filterEndDateValue != null ||
          filterStatusValue != null) &&
          bookingsList?.length === 0
        ){
          return(
            <>
              {renderHeaderSection(true)}
              <div className="bg-white rounded-4 categoriess-page d-flex justify-content-center align-items-center flex-column  mt-3">
                <EmptyState subTitle={shared.noSearch} showActionButton={false} />
              </div>
            </>
          );
        } else {
          return(
            <>    
              <div className="bookings-page">
                {renderHeaderSection(true)}
                <div className="bg-white rounded-4 my-3 bookings-table-list">
                  <Table
                    headlines={[
                      shared.lists.appointmentId,
                      shared.lists.customerName,
                      shared.lists.branchName,
                      shared.lists.date,
                      shared.lists.status,
                    ]}
                    hasActions={true}
                    rows={bookingsList?.map((row, i) => (
                      <TableRow key={i}>
                        {/* apointment Id */}
                        <TableCell className="font-regular text-dark-blue fsize-14 ">
                          {row.appointment_number}
                        </TableCell>
                        {/* customer name */}
                        <TableCell className="font-regular text-dark-blue fsize-14 ">
                          <div className="d-flex align-items-center justify-content-center gap-2">
                            <p className="m-0">
                              {renderTextEllipsis(row.customer.name) }
                            </p>
                          </div>
                        </TableCell>
                        {/* branchName */}
                        <TableCell className="font-regular text-dark-blue fsize-14 ">
                          {lang === 'en'
                            ? renderTextEllipsis(row.branch.name_en) 
                            : renderTextEllipsis(row.branch.name_ar) 
                          }
                        </TableCell>
                        {/* date */}
                        <TableCell className="font-regular text-dark-blue fsize-14 ">
                          {row.date}
                        </TableCell>
                        {/* status */}
                        <TableCell className="font-regular text-dark-blue fsize-14 w-20 status-container">
                          <div className={`p-2 rounded-4 d-flex gap-1 justify-content-center ${row.status}-container`}>
                                <div>
                                  {bookings.status[row.status]}
                                </div>
                                {(row.status==='ongoing' && (row.customer_did_not_show_up || row.employee_did_not_show_up)) &&(
                                    <div><ReportProblemOutlined/></div>
                                )}
                          </div>
                        </TableCell>
                        <TableCell className="font-regular text-dark-blue fsize-14 ">
                            <ViewBgIcon
                              className="pointer"
                              onClick={() => handleViewbooking(row.id)}
                            />
                        </TableCell>
                      </TableRow>
                    ))}
                  />
                  <div className="d-flex justify-content-center py-3">
                    {meta?.pages > 1 && (
                      <Pagination
                        count={meta?.pages}
                        page={meta?.currentPage || page}
                        handleChange={(event, page) => {
                        setPage(page);
                         }}
                        defaultPage={1}
                      />
                    )} 
                  </div>
                </div>
              </div>                 
            </>
          );
        }
    }

    return(<>{renderBookingsList()}</>);
}

export default Bookings;


