import React from "react";
import PropTypes from "prop-types";
import { FilledInput, InputLabel, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";

import "./SearchInput.scss";

const SearchInput = ({
  name,
  value,
  onChange,
  id,
  label,
  placeholder,
  defaultValue,
  disabled,
  fullWidth,
  inputClass,
  inputWrapperClass,
  labelClassName,
}) => (
  <div className={`search-input-wrapper ${inputWrapperClass}`}>
    <InputLabel htmlFor={id || name} className={labelClassName}>
      <>{label}</>
    </InputLabel>
    <FilledInput
      id={id || name}
      name={name}
      type="text"
      autoFocus={!!value}
      value={value}
      defaultValue={defaultValue}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      disabled={disabled}
      fullWidth={fullWidth}
      className={`${inputClass}`}
      disableUnderline={true}
      autoComplete="off"
      startAdornment={
        <InputAdornment position="start">
          <Search fontSize="large" />
        </InputAdornment>
      }
    />
  </div>
);

export default SearchInput;

SearchInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inputClass: PropTypes.string,
  inputWrapperClass: PropTypes.string,
  labelClassName: PropTypes.string,
};

SearchInput.defaultProps = {
  fullWidth: true,
};
