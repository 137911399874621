import { combineReducers } from 'redux';
import locale from './Lang/reducer';
import snackbar from './Snackbar/reducer';
import loader from './Loader/reducer';
import navigation from './Navigation/reducer';
import auth from './Auth/reducer';
import profileDetails from './ProfileDetails/reducer';
import branches from './Branches/reducer';
import googleMaps from './GoogleMaps/reducers';
import googleMapCoords from './GoogleMapCoords/reducer';
import lookUps from './LookUps/reducer';
import employees from './Employees/reducer';
import bookings from './Bookings/reducer';
import paymentTransactions from './PaymentTransactions/reducer';
import notifications from './Notifications/reducer';

export default combineReducers({
  locale,
  snackbar,
  loader,
  navigation,
  auth,
  profileDetails,
  branches,
  googleMaps,
  googleMapCoords,
  lookUps,
  employees,
  bookings,
  paymentTransactions,
  notifications,
});
