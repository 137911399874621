const setupProfile = {
  title: 'Setup',
  subTitle: 'your business',
  labels: {
    email: 'Email',
    category: 'Category',
  },
  placeholders: {
    email: 'Enter email',
    ArName: 'Enter arabic name',
    EnName: 'Enter english name',
    category: 'Choose category',
    uploadLogo: 'Logo',
    banner: 'Banner',
  },
  actionBTN: 'Save',
};

export default setupProfile;
