import React from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import LangSwitcher from "../../LangSwitcher";
import loginVector from "../../../assets/images/vectors/login-vector.png";
import changePasswordVector from "../../../assets/images/vectors/changePasswordVector.png";
import forgetPasswordVector from "../../../assets/images/vectors/forgetPasswordVector.png";
import setUpProfileVector from "../../../assets/images/vectors/setUpProfileVector.png";
import logo from "../../../assets/logo/mimar-logo.png";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import "./AuthLayout.scss";

const AuthLayout = ({ content }) => {
  const location = useLocation();

  const renderVectors = () => {
    if(location.pathname.includes(ROUTE_PATHS.changePassword)){
      return changePasswordVector; 
    }else if (location.pathname.includes(ROUTE_PATHS.forgetPassword)){
      return forgetPasswordVector;
    }else if (location.pathname.includes(ROUTE_PATHS.setupProfile)){
      return setUpProfileVector;
    }else{
      return loginVector;
    }
  };

  return (
    <div className="d-flex">
      <div className="vectors-section d-none d-md-flex justify-content-center align-items-center">
        <img src={renderVectors()} className="w-100" alt="Vector" />
      </div>
      <div className="d-flex justify-content-center align-items-center flex-grow-1">
        <div className="d-flex flex-column align-items-start component-section">
          <div className="d-flex align-items-center w-100">
            {location.pathname === ROUTE_PATHS.login && (
              <>
                <img src={logo} alt="Mimar-logo" className="my-3 w-55" />
              </>
            )}
            <div className="w-100 d-flex justify-content-end my-3">
              <LangSwitcher />
            </div>
          </div>
          <div className="layout-card d-flex flex-column align-items-center">
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

AuthLayout.propTypes = {
  content: PropTypes.element,
};

export default AuthLayout;
