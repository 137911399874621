// get all
export const GET_BRANCHES_REQUEST = 'GET_BRANCHES_REQUEST';
export const GET_BRANCHES_RESPONSE = 'GET_BRANCHES_RESPONSE';
// add
export const ADD_BRANCH_REQUEST = 'ADD_BRANCH_REQUEST';
export const ADD_BRANCH_RESPONSE = 'ADD_BRANCH_RESPONSE';
// edit
export const EDIT_BRANCH_REQUEST = 'EDIT_BRANCH_REQUEST';
export const EDIT_BRANCH_STATUS_REQUEST = 'EDIT_BRANCH_STATUS_REQUEST';
// get by id
export const GET_BRANCH_REQUEST = 'GET_BRANCH_REQUEST';
export const GET_BRANCH_RESPONSE = 'GET_BRANCH_RESPONSE';
// delete
export const DELETE_BRANCH_REQUEST = 'DELETE_BRANCH_REQUEST';

// *****************************************************************************************

// get all branch services
export const GET_BRANCH_SERVICES_REQUEST = 'GET_BRANCH_SERVICES_REQUEST';
export const GET_BRANCH_SERVICES_RESPONSE = 'GET_BRANCH_SERVICES_RESPONSE';
// edit branch service
export const EDIT_BRANCH_SERVICES_REQUEST = 'EDIT_BRANCH_SERVICES_REQUEST';
//  delete branch service
export const DELETE_BRANCH_SERVICE_REQUEST = 'DELETE_BRANCH_SERVICES_REQUEST';

// *****************************************************************************************

// request status
export const GET_REQUEST_STATUS = 'GET_REQUEST_STATUS';

// *****************************************************************************************

// delete branch specialty
export const DELETE_BRANCH_SPECIALTY_REQUEST = 'DELETE_BRANCH_SPECIALTY_REQUEST';

// *****************************************************************************************

// helpers
export const ADD_BRANCH_DATA_REQUEST = 'ADD_BRANCH_DATA_REQUEST';
export const GET_ADDRESS_FROM_COORDS_REQUEST = 'GET_ADDRESS_FROM_COORDS_REQUEST';
export const GET_ADDRESS_FROM_COORDS_RESPONSE = 'GET_ADDRESS_FROM_COORDS_RESPONSE';

// *****************************************************************************************
// *****************************************************************************************
// Employees

export const ADD_BRANCH_EMPLOYEE_REQUEST = 'ADD_BRANCH_EMPLOYEE_REQUEST';
export const DELETE_BRANCH_EMPLOYEE_REQUEST = 'DELETE_BRANCH_EMPLOYEE_REQUEST';
export const GET_BRANCH_EMPLOYEE_REQUEST_STATUS = 'GET_BRANCH_EMPLOYEE_REQUEST_STATUS';

// *****************************************************************************************
// *****************************************************************************************
// services
export const GET_SERVICES_REQUEST = 'GET_SERVICES_REQUEST';
export const GET_SERVICES_RESPONSE = 'GET_SERVICES_RESPONSE';

// *****************************************************************************************
// *****************************************************************************************
// specialties
export const GET_SPECIALTIES_REQUEST = 'GET_SPECIALTIES_REQUEST';
export const GET_SPECIALTIES_RESPONSE = 'GET_SPECIALTIES_RESPONSE';
// *****************************************************************************************
// *****************************************************************************************
// reviews
export const GET_BRANCH_REVIEWS_REQUEST = 'GET_BRANCH_REVIEWS_REQUEST';
export const GET_BRANCH_REVIEWS_RESPONSE = 'GET_BRANCH_REVIEWS_RESPONSE';

export const GET_BRANCH_REVIEW_DETAILS_REQUEST = 'GET_BRANCH_REVIEW_DETAILS_REQUEST';
export const GET_BRANCH_REVIEW_DETAILS_RESPONSE = 'GET_BRANCH_REVIEW_DETAILS_RESPONSE';

export const BRANCH_REVIEW_REQUEST_STATUS = 'BRANCH_REVIEW_REQUEST_STATUS';

export const DELETE_BRANCH_REVIEWS_REQUEST = 'DELETE_BRANCH_REVIEWS_REQUEST';

export const GET_PUBLISH_BRANCH_SERVICE_REQUEST_STATUS = 'GET_PUBLISH_BRANCH_SERVICE_REQUEST_STATUS';

export const GET_DELETE_BRANCH_SERVICE_REQUEST_STATUS = 'GET_DELETE_BRANCH_SERVICE_REQUEST_STATUS';

export const GET_DELETE_BRANCH_SPECIALITY_REQUEST_STATUS = 'GET_DELETE_BRANCH_SPECIALITY_REQUEST_STATUS';