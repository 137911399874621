import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField, FormHelperText, Autocomplete, Box } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

import messages from '../../../assets/locale/messages';
import './FilterInput.scss';
import { StarIcon } from '../../../utils/Icons';

const Filter = ({
  value,
  inputClassName,
  disabled,
  options,
  placeholder,
  id,
  name,
  onChange,
  helperText,
  helperTextClass,
  filterWrapperClass,
  isMultiple,
  renderIcon,
  renderIconOnly,
  icon,
  repeateIconBy,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  const repeateIconFun = (icon, repeateIconBy) => {
    let arr = [];
    for (let i = 0; i < repeateIconBy; i++) {
      arr.push(icon);
    }
    return arr;
  };

  return (
    <div className={`filter-wrapper ${filterWrapperClass}`}>
      <Autocomplete
        name={name || id}
        id={id || name}
        options={options || []}
        disabled={disabled}
        multiple={isMultiple}
        getOptionLabel={(option) => option.label || ''}
        renderOption={(props, option, elementPosition) => {
          return (
            <Box
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
              key={option?.id || elementPosition?.index}>
              {renderIcon && repeateIconBy != null ? repeateIconFun(icon, option.value) : icon}
              {!renderIconOnly && option.label}
            </Box>
          );
        }}
        isOptionEqualToValue={(option, value) => option.id == value.id}
        onChange={(e, value) => {
          onChange(value);
        }}
        className={`${inputClassName}`}
        value={value}
        noOptionsText={shared.noOptions}
        popupIcon={<KeyboardArrowDown />}
        renderInput={(params) => (
          <TextField {...params} label={placeholder} color="primary" className="unify-input-padding" />
        )}
      />
      {helperText && (
        <FormHelperText className={helperTextClass || 'font-medium fsize-12'}>{helperText}</FormHelperText>
      )}
    </div>
  );
};

export default Filter;

Filter.propTypes = {
  disabled: PropTypes.bool,
  renderIcon: PropTypes.bool,
  renderIconOnly: PropTypes.bool,
  icon: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  inputClassName: PropTypes.string,
  filterWrapperClass: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title_en: PropTypes.string,
      title_ar: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  helperTextClass: PropTypes.string,
  value: PropTypes.any,
  isMultiple: PropTypes.bool,
};
Filter.defaultProps = {
  renderIcon: false,
  renderIconOnly: false,
};
