const settings = {
    viewSettings:{
        changePassword:'Change password',
        cancellationPolicy:'Cancellation policy',
        editPofile:'Edit pofile'
    },
    cancellationPolicy:{
        cancellationDuration: 'Cancellation duration',
        cancelationDurationlabel: 'Allow cancellation prior to the appoinment up to ' ,
        consecutiveCancellations: 'Consecutive cancellations',
        consecutiveCancellationsText: 'Customer is allowed to cancel consecutive appointments in a row',
        maxNumberOfConsecutiveCancellations: 'Maximum number of consecutive cancellations',
        numberOfDaysForPreventingFurtherCancelllations : 'Number of days for preventing further cancelllations',
        days: 'Days',
        hours: 'Hours',
        hoursPlaceholders: 'Enter number of hours',
        maxNumberPlaceholder: 'Enter number of consecutive concellation',
        daysPlaceholder: 'Enter number of days ',
        hint: ' Customer will be allowed to cancel appointments up to this limit without having any completed appointments in between and this limit should start from 2',
        maxNumberErrorMsg:'Maximum number of consecutive cancellations should start from 2'

    },
    changePassword:{
        changePassword:'Change password',
    },
    editProfile:'Edit profile'
    
};

export default settings;