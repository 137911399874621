import React, { useState, useEffect }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";

import { getEmployeesRequest } from '../../../../../store/Employees/actions'
import messages from '../../../../../assets/locale/messages';
import {textContainOnlySpaces} from '../../../../../utils/Helpers'
import RadioButtonsGroup from '../../../../../components/RadioButton'
import Modal from '../../../../../components/Modal'
import SearchInput from '../../../../../components/SearchInput';
import EmptyState from '../../../../../components/EmptyState';

const EmployeesPopup = ({
    open,
    setOpen,
    servicesEmployees,
    setServicesEmployees,
    selectedServiceId,
    employeeId,
    isEditMode
}) => {

   const dispatch = useDispatch()
    const lang = useSelector((state) => state.locale.lang);
    const employees = useSelector((state) => state.employees.list);
    const isLoading = useSelector((state) => state.loader);
    const { bookings, shared } = messages[lang];
    const [searchValue, setSearchValue] = useState("")
    const [EmployeesList, setEmployeesList] = useState([]);

    const {values,
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        touched, 
        errors,
        
        } = useFormik({
            initialValues:{
                employeeId:null
            },
            validationSchema: Yup.object({
                employeeId: Yup.number().required('Required')
            }),
            onSubmit: async ({employeeId}) => {
                const employee = employees?.find(employee => employee.id===employeeId)
                if(isEditMode){
                    const serviceList = servicesEmployees.map(service => {
                        if(service.serviceId === selectedServiceId){
                            return{
                                ...service,
                                employeeId,
                                employeeNameEn: employee.first_name_en +" "+ employee.last_name_en,
                                employeeNameAr: employee.first_name_ar +" "+ employee.last_name_ar,
                            }
                        }else{
                            return {...service}
                        }
                    })
                    setServicesEmployees([...serviceList])
                }else{
                    setServicesEmployees([
                        ...servicesEmployees,
                        {
                            serviceId: selectedServiceId,
                            employeeId,
                            employeeNameEn: employee.first_name_en +" "+ employee.last_name_en,
                            employeeNameAr: employee.first_name_ar +" "+ employee.last_name_ar,
                        }
                    ])
                }
                handleCloseEmployeePopup();
            }       
     });

    useEffect(()=>{
        if ((searchValue.length >= 3 || searchValue.length === 0 ) && !textContainOnlySpaces(searchValue) && open){
            dispatch(getEmployeesRequest({
                branch_service_id:selectedServiceId,
                query:searchValue
            }))
        } 
    },[searchValue])

    useEffect(()=>{
        const employeesContent = employees?.map(employee => {
            return {
                id: employee.id,
                label: <div className="fsize-20 fweight-400 mt-4"> 
                           {
                                lang ==="en" 
                                    ? employee.first_name_en +" "+ employee.last_name_en
                                    : employee.first_name_ar +" "+ employee.last_name_ar
                            }
                            <div className="fsize-14 text-gray">
                                {employee.employee_number}
                            </div>
                        </div>,
                value:employee.id,
            }
        })
        setEmployeesList([...employeesContent]);
    },[lang, employees]);

    useEffect(()=>{
        if(open && isEditMode){
            setFieldValue("employeeId", employeeId);
        }  
    },[open])

    const handleCloseEmployeePopup = () => {
        setSearchValue('');
        setOpen(false);
        setFieldValue("employeeId", null);
    };

    const handleDisableSumbit = () => {
        return employees?.length ?
            (!!(!touched["employeeId"] || errors["employeeId"]))
            : true
    }

    const renderEmloyeePopup = () => (
        <div>
            <SearchInput
                inputWrapperClass="mb-2"
                placeholder={bookings.addAnotherService.employeeSearchPlaceolder}
                name="search"
                value={searchValue}
                onChange={(value) => {
                    setSearchValue(value);
                }}
            />
            {!isLoading && employees?.length === 0 && searchValue && (
                <EmptyState subTitle={shared.noSearch} showActionButton={false} />
            )}
            {employees?.length !=0 && (
                <div className="mx-2">
                    <RadioButtonsGroup
                        name="employeeId"
                        labelId="employeeId"
                        defaultValue={employeeId}
                        list={EmployeesList}
                        onChange={(e) => {
                            setFieldTouched("employeeId");
                            setFieldValue("employeeId", +e.target.value);
                        }}      
                    />
                </div>
            )}     
        </div>
    )
    return (
        <Modal
            open={open}
            handleClose={handleCloseEmployeePopup}
            title={<span className="fsize-30 fweight-400">{bookings.addAnotherService.employeeList}</span>}
            contentClass="mb-5 pb-2 text-primary"
            content={<span>{renderEmloyeePopup()}</span>}
            confirmBtnTxt={shared.submit}
            cancelBtnTxt={shared.cancel}
            isContentText={false}
            handleConfirm={handleSubmit}
            handleCancel={handleCloseEmployeePopup}
            maxWidth="sm"
            actionsClass=" px-4"
            confirmBtnClass="btn-secondary fsize-14 text-white py-2 px-5"
            cancelBtnClass="fsize-14 py-2 text-secondary px-5 "
            disableActionBtn={handleDisableSumbit()}
        />
    )
}

export default EmployeesPopup;