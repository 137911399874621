import React, { useState, useEffect }  from 'react';
import { Link, useParams} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/ar';
import { useFormik } from "formik";
import * as Yup from "yup";
import { Divider } from "@mui/material";
import {
    MoreVertOutlined,
    AccessTimeOutlined,
    Person2Outlined,
    ArticleOutlined,
    CreditCardOutlined,
    FlagOutlined,
    Check,
    Clear,
    Add,
    CreditScoreOutlined
    }from '@mui/icons-material';

import { getBookingRequest,
         getBookingResponse,
         editBookingServiceRequest,
         getBookingCancelationReasonsRequest,
         getBookingServiceCancelationReasonsRequest,
         updateBookingRequest,
         getRequestStatus } from '../../../store/Bookings/actions.js'
import messages from '../../../assets/locale/messages';
import { ROUTE_PATHS } from '../../../utils/RoutesPaths';
import { setPageMainTitle, timeConverter } from '../../../utils/Helpers';
import { TWO_DICIMAL_ONLY } from '../../../utils/Patterns'
import BookingCancelationReasonForm from './BookingCancelationReasonForm'
import BookingServiceCancelationReasonForm from './BookingServiceCancelationReasons'
import CustomerHasPaidConfirmationPopup from './CustomerHasPaidConfirmationPopup';
import Breadcrumbs  from'../../../components/BreadCrumbs';
import Button from '../../../components/Button';
import Menu from '../../../components/Menu';
import Modal from '../../../components/Modal';
import Input from '../../../components/Input';
import './ViewBooking.scss';

const ViewBooking = () => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const lang = useSelector((state) => state.locale.lang);
    const bookingData = useSelector((state) => state.bookings.bookingDetails);
    const requestStatus = useSelector((state) => state.bookings.requestStatus);
    const { bookings, shared } = messages[lang];
    const [showFeesPopup, setShowFeesPopup] = useState(false);
    const [hasDoneService, setHasDoneService] = useState(false);
    const [showRefuseToCancelPopup, setShowRefuseToCancelPopup] = useState(false);
    const [showCancelUpcomingPopup, setShowUpcomingAppointmentPopup] = useState(false);
    const [showBookingCancelationReasonPopup, setShowBookingCancelationReasonPopup] = useState(false);
    const [showServiceCancelationReasonPopup, setShowServiceCancelationReasonPopup] =useState(false);
    const [showCustomerHasPaidPopup, setShowCustomerHasPaidPopup] = useState(false);
    const [cancelService, setCancelService] = useState({});
    const [doneServicesCounter,setDoneServicesCounter] = useState(0)
    const [cancelledServicesCounter,setCancelledServicesCounter] = useState(0)
    const [serviceData, setServiceData] = useState({
        id:null,
        title:"",
        status:""
    });
    const [fees, setFees] = useState({
        hasFeesRange:false,
        fromFees:null,
        toFees:null,
        exactFees:null
    })
    const {
        values,
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        touched, 
        errors
    } = useFormik({
        initialValues:{
            exactFees: fees.exactFees
        },
        validationSchema: Yup.object({
            exactFees: Yup.number().when([], {
                is: () => fees.toFees,
                then: Yup.number().typeError('numbersOnly').required('required').min(fees.fromFees).max(fees.toFees)
                        .test(
                                "is-decimal",
                                "floatTwoDecimalOnly",
                                (val) => {
                                    if (val != undefined) {
                                        return TWO_DICIMAL_ONLY.test(val);
                                    }
                                    return true;
                                 }),
                otherwise: Yup.number().typeError('numbersOnly').required('required').min(fees.fromFees)
                            .test(
                                    "is-decimal",
                                    "floatTwoDecimalOnly",
                                    (val) => {
                                        if (val != undefined) {
                                            return TWO_DICIMAL_ONLY.test(val);
                                        }
                                        return true;
                                    })
            }),
        }),
        onSubmit: async ({exactFees}) => {
            dispatch(editBookingServiceRequest({
                id: serviceData.id,
                data: {
                    exact_fees: +exactFees,
                    status: serviceData.status,
                }
            }))
            handleCloseFeesPopup();
            dispatch(getRequestStatus(false));
        }
    })

    useEffect(() => {
        return () => {
          dispatch(
            getBookingResponse({bookingDetails:{}})
          )
        };
      }, []);

    useEffect(()=>{
        setPageMainTitle('bookingsManagement');
        dispatch(getBookingRequest(id));
    },[lang, requestStatus])

    useEffect(() => {
       const doneServices = bookingData?.appointment_branch_services?.filter((service) => service.status==='completed');
       setDoneServicesCounter(doneServices?.length);
       const cancelledServices = bookingData?.appointment_branch_services?.filter((service) => service.status==='canceled');
       setCancelledServicesCounter(cancelledServices?.length)
       if(doneServices?.length > 0) {
        setHasDoneService(true);
       }else{
        setHasDoneService(false);
       }
    }, [bookingData])

    useEffect(() => {
        if(bookingData?.status==='ongoing'){
            dispatch(getBookingCancelationReasonsRequest())
            dispatch(getBookingServiceCancelationReasonsRequest())
        }
    },[lang, bookingData])

    const showMenuList = (service) => {
        if(service.status === 'completed'){
            if(service.is_editable){
                return [
                    {
                        id:1,
                        label:<div>{shared.cancel}</div>,
                    },
                    {
                        id:2,
                        label: <div>{bookings.details.editFees}</div>
                    }
                ]
            }else{
                return [
                    {
                        id:1,
                        label:<div>{shared.cancel}</div>,
                    }
                ]
            }
            
        }else if(service.status === 'canceled'){
            return [
                {
                    id:1,
                    label: <div>{bookings.details.markAsDone}</div>,
                }
            ]
        }else {
            if(service.employee_did_not_show_up){
                return [
                    {
                        id:1,
                        label:<div>{shared.cancel}</div>,
                    },
                    {
                        id:2,
                        label:<div>{bookings.details.resolve}</div>,
                    }
                ]
            }else{
                return [
                    {
                        id:1,
                        label:<div>{shared.cancel}</div>,
                    },
                    {
                        id:2,
                        label:<div>{bookings.details.markAsDone}</div>,
                    }
                ]
            }  
        }
    }

    const handleMarkAsDone = (service) => {
        setServiceData({
            id: service.id,
            title: service.service_title,
            status: 'completed',
        })
        setFees({
            hasFeesRange: service.is_editable,
            fromFees: service.fees_from,
            toFees: service.fees_to,
            exactFees: service.exact_fees
        })
        setFieldValue(['exactFees'],service.exact_fees)
        if(service.exact_fees){
            dispatch(editBookingServiceRequest({
                id: service.id,
                data: {
                    exact_fees: service.exact_fees,
                    status: 'completed',
                }
            }))
            dispatch(getRequestStatus(false));
        }else{
            setShowFeesPopup(true);
        }
    };

    const handleEditFees = (service) => {
        setServiceData({
            id: service.id,
            title: service.service_title,
            status: service.status,
        })
        setFees({
            hasFeesRange: service.is_editable,
            fromFees: service.fees_from,
            toFees: service.fees_to,
            exactFees: service.exact_fees
        });
        setFieldValue(['exactFees'],service.exact_fees);
        setShowFeesPopup(true);
    };

    const handleCancelService = (service) => {
        setShowServiceCancelationReasonPopup(true)
        setCancelService(service);
    };

    const handleResolve = (service) => {
        dispatch(editBookingServiceRequest({
            id: service.id,
            data: {
                employee_did_not_show_up: false
            }
        }))
        dispatch(getRequestStatus(false));
    }

    const handleOnClickItem = (listItem, service) => {
        if(service.status==='canceled'){
            handleMarkAsDone(service);
        }else if (service.status==='completed'){
            if(listItem.id === 1){
                handleCancelService(service);
            }else if(listItem.id === 2){
                handleEditFees(service)
            };
        }else{
            if(listItem.id === 1){
                handleCancelService(service);
            }else if(listItem.id === 2){
                if(service.employee_did_not_show_up){
                    handleResolve(service);
                }else{
                    handleMarkAsDone(service);
                }
                
            };
        }; 
    };

    const handleCloseFeesPopup = () => {
        setShowFeesPopup(false);
        setFieldTouched("exactFees",false);
        setFieldValue("exactFees", null);
    };

    const handlesubmitFees = () => {
        handleSubmit();
    };

    const handleCancelOngoingAppointment = () => {
        if(hasDoneService){
            setShowRefuseToCancelPopup(true);
        }else{
            setShowBookingCancelationReasonPopup(true)
        };
    };

    const handleCloseRefuseToCancel = () => {
        setShowRefuseToCancelPopup(false);
    };

    const handleCancelUpcomingAppointment = () => {
        setShowUpcomingAppointmentPopup(true);
    };

    const handleCloseCancelUpcoming = () => {
        setShowUpcomingAppointmentPopup(false);
    };

    const handleSubmitCancelUpcoming =() => {
        dispatch(updateBookingRequest({
            data:{
                id: bookingData?.id,
                params:{
                    status:"canceled",
                }
            },
            requestType:"cancel"
        }));
        setShowUpcomingAppointmentPopup(false);
        dispatch(getRequestStatus(false));
    };

    const handleCustomerHasPaid = () => {
        setShowCustomerHasPaidPopup(true);
    };

    const renderFeesPopupContent = () => (
        <div className="px-2">
            <form onSubmit={handleSubmit}>
                <div className="text-dark fsize-18 fweight-400 mb-2">
                    {bookings.details.feesInputLabel}
                    <span className= "fweight-600">
                        ({serviceData.title})
                    </span>
                    {lang === "en" && (bookings.details.service)}*
                </div>
                <Input
                    id="exactFees"
                    name="exactFees"
                    type="text"
                    placeholder={bookings.details.feesInputPlaceholder}
                    value={values['exactFees']}
                    onChange={(value) => {
                        setFieldTouched("exactFees");
                        setFieldValue("exactFees", value);
                      }}
                    isInputHasErr={!!(touched["exactFees"] && errors["exactFees"])}
                    errMsg={errors["exactFees"]}
                />
            </form>
            <div className="text-secondary fsize-16 fweight-500 mt-2">
                    {bookings.details.feesHint}
                    {fees.fromFees}{bookings.details.sar}
                    {fees.toFees 
                        ? (<span>
                            {bookings.details.to}
                            {fees.toFees} {bookings.details.sar}
                        </span>)
                        : ''
                    }         
            </div>
        </div>
    );
     
    return(
        <div className="booking-details-container">
            <div className="d-flex justify-content-between flex-wrap" >
                <div className="">
                    <Breadcrumbs
                        list={[
                            {
                                path: ROUTE_PATHS.bookings,
                                label: bookings.list.bookingList
                            },
                            {
                                path: "#",
                                label: bookings.details.booking + '(' +
                                bookingData?.appointment_number + ')',
                            },
                        ]}
                        className="mb-4 fsize-28 fweight-600"
                    />
                </div>          
                {bookingData?.status === "ongoing" && (
                    <div>
                        <Button
                            label={<div className="d-flex gap-1 px-4">
                                    <div><Clear fontSize="medium"/></div>
                                    <div>{shared.cancel}</div>
                                </div>}
                            outlined
                            labelClass="text-primary fsize-16"
                            className="mb-4"
                            color="info"
                            onClick={() => {handleCancelOngoingAppointment()}}
                        />
                    </div>
                )}
                {bookingData?.status === "upcoming" && (
                    <div>
                        <Button
                            label={<span className="d-flex gap-1 px-4">
                                    <div><Clear fontSize="medium"/></div>
                                    <div>{shared.cancel}</div>
                                </span>}
                            outlined
                            labelClass="text-primary fsize-16"
                            className="mb-4"
                            color="info"
                            onClick={() => {handleCancelUpcomingAppointment()}}
                        />
                    </div>
                )}
                {bookingData?.status === "pending_payment" && (
                    <div>
                        <Button
                            label={<div className="d-flex gap-1 px-4">
                                        <div><CreditScoreOutlined fontSize="medium"/></div>
                                        <div>{bookings.details.customerHasPaid}</div>
                                    </div>}
                            labelClass="text-white fsize-16"
                            className="mb-4"
                            onClick={() => {handleCustomerHasPaid()}}
                        />
                    </div>
                )}
            </div>
            {bookingData?.employee_did_not_show_up && (
                <div className=" report-container row p-2 mb-4 fsize-24 fweight-500 rounded-4">
                    <div className="col-md-12 d-flex gap-1">
                        <div ><FlagOutlined fontSize="fweight-500"/></div>
                        <div className="mt-1">{bookings.details.customerReported}</div>
                    </div>
                </div>
            )}
             {bookingData?.customer_did_not_show_up && (
                <div className=" report-container row p-2 mb-4 fsize-24 fweight-500 rounded-4">
                <div className="col-md-12 d-flex gap-1">
                    <div ><FlagOutlined fontSize="fweight-500"/></div>
                    <div className="mt-1">{bookings.details.employeeReporeted}</div>
                </div>
            </div>
            )}
            {bookingData?.customer_appointment_problem_reason  && (
                <div className=" report-container px-3 p-2 mb-4 rounded-4">
                    <div className="d-flex gap-1 fsize-24 fweight-500">
                        <div ><FlagOutlined fontSize="fweight-500"/></div>
                            <div className="mt-1">{bookings.details.problemLabel}</div>
                        </div>
                        <div className="fsize-18 fweight-400 px-4 mx-2">
                            {bookings.details.Customer}: {bookingData.customer_appointment_problem_reason.reason}  
                        </div>
                </div>
            )}
            {bookingData?.employee_appointment_problem_reason && (
                <div className=" report-container px-3 p-2 mb-4 rounded-4">
                    <div className="d-flex gap-1 fsize-24 fweight-500">
                        <div ><FlagOutlined fontSize="fweight-500"/></div>
                        <div className="mt-1">{bookings.details.problemLabel}</div>
                    </div>
                    <div className="fsize-18 fweight-400 px-4 mx-2">
                        {bookings.details.Employee}: {bookingData?.employee_appointment_problem_reason.reason} 
                    </div>
                </div>
            )}
            <div className="bg-white row p-4 mb-3 mx-1 data-container">
                <div className=" col-12 col-md-4">
                    <div className="text-dark text-primary fsize-16 mb-2">
                        {bookings.details.appointmentId}
                    </div>
                    <div className="text-gray fsize-18">
                        {bookingData?.appointment_number}
                    </div>
                </div>  
                <div className=" col-12 col-md-4">
                    <div className="text-dark text-primary fsize-16 mb-2">
                        {bookings.details.date}
                    </div>
                    <div className="text-gray fsize-18">
                        {lang === 'en' ? 
                         dayjs(bookingData?.date).format("dddd, D MMMM  YYYY") :
                         dayjs(bookingData?.date).locale('ar').format("dddd, D MMMM  YYYY")
                        }
                    </div>
                </div> 
                <div className=" col-12 col-md-4 status-container mb-3">
                    <div className="text-dark text-primary fsize-16 mb-2">
                        {bookings.details.status}
                    </div>
                    <div className={`fsize-18 w-60 status-card text-center ${bookingData?.status}-container`}>
                       {bookings.status[bookingData?.status]}
                    </div>
                </div> 
                <div className=" col-12 col-md-4">
                    <div className="text-dark text-primary fsize-16 mb-2">
                        {bookings.details.paymentMethod}
                    </div>
                    <div className="text-gray fsize-18">
                        {bookings.details[bookingData?.payment_method]}
                    </div>
                </div>
                <div className=" col-12 col-md-4">
                    <div className="text-dark text-primary fsize-16 mb-2">
                        {bookings.details.location}
                    </div>
                    <div className="text-gray fsize-18">
                        {bookings.details[bookingData?.location]}
                        {bookingData?.location === 'home' && (
                            <span>
                                ({bookingData?.customer_address?.street_name}.
                                {bookingData?.customer_address?.region}
                                {bookingData?.customer_address?.city},
                                {bookingData?.customer_address?.country}.)
                            </span>
                        )}
                    </div>
                </div>
            </div>
            {bookingData?.status === 'canceled' && (
                <div className="bg-white row p-4 mb-3 mx-1 data-container">
                    {bookingData?.appointment_cancellation_reason && (
                        <div className="my-1 my-md-2 col-12 col-md-4">
                            <div className="text-dark text-primary fsize-16 mb-2">
                                {bookings.details.cancellationReason}
                            </div>
                            <div className="text-gray fsize-18">
                                {bookingData?.appointment_cancellation_reason?.reason}
                            </div>
                        </div>
                    )}
                    <div className="my-1 my-md-2 col-12 col-md-8">
                        <div className="text-dark text-primary fsize-16 mb-2">
                            {bookings.details.cancelledBy}
                        </div>
                        <div className="text-gray fsize-18">
                            {bookings.details[bookingData?.canceller_type]}
                        </div>
                    </div>
                </div>
            )}
            <div className="bg-white row p-4 mb-3 mx-1 data-container">
                <div className="my-1 my-md-2 col-12 col-md-12">
                    <div className="text-dark text-primary fsize-16 mb-2">
                        {bookings.details.branchName}
                    </div>
                    <div className="text-gray fsize-18">
                        {bookingData?.branch?.is_deleted 
                            ? (
                                <> 
                                    {lang === 'en'  
                                      ? bookingData?.branch?.name_en 
                                      : bookingData?.branch?.name_ar
                                    }
                                    <span> ({bookings.details.deletedBranch})</span>
                                </>
                            ):(
                                <Link
                                    to={ROUTE_PATHS.viewBranch.replace(':id', bookingData?.branch?.id)}
                                >
                                    { lang === 'en' ? 
                                        bookingData?.branch?.name_en :
                                        bookingData?.branch?.name_ar
                                    }
                                </Link>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="bg-white row p-4 mb-3 mx-1 data-container">
                <div className="my-1 my-md-2 col-12 col-md-12 mb-3">
                    <div className="text-dark text-primary fsize-16 mb-2">
                        {bookings.details.customerName}
                    </div>
                    <div className="text-gray fsize-18">
                        {bookingData?.customer?.name}
                    </div>
                </div>
                {!bookingData?.customer?.is_deleted && (
                    <div className="my-1 my-md-2 col-12 col-md-12 mb-3">
                        <div className="text-dark text-primary fsize-16 mb-2">
                            {bookings.details.customerPhoneNumber}
                        </div>
                        <  div className="text-gray fsize-18">
                            {bookingData?.customer?.phone_number}
                        </div>
                    </div>
                )}
                <div className="my-1 my-md-2 col-12 col-md-12 mb-3">
                    <div className="text-dark text-primary fsize-16 mb-2">
                        {bookings.details.customerNote}
                    </div>
                    <div className="text-gray fsize-18">
                        {bookingData?.note || bookings.details.noNotes}
                    </div>
                </div>
            </div>
            <div className="bg-white row p-4 mb-3 mx-1 data-container">
                <div className="my-1 my-md-2 col-12 col-md-12 d-flex justify-content-between">
                    <div className="text-dark text-primary fsize-16 mb-2">
                        {bookings.details.services}
                    </div>
                    {bookingData?.status === 'ongoing' && (
                        <div className="text-gray fsize-14">
                            {bookingData?.appointment_branch_services?.length}
                            <span className="mx-1">{bookings.details.services}</span>
                            (<span className="mx-1">{doneServicesCounter}</span>{bookings.status.completed} 
                            <span className="mx-1">&amp;</span> 
                            {cancelledServicesCounter}<span className="mx-1">{bookings.status.canceled}</span> &amp; 
                            <span className="mx-1">{bookingData?.appointment_branch_services?.length-(cancelledServicesCounter+doneServicesCounter)}</span>
                            {bookings.details.remining})
                        </div>)
                    }
                </div>
                {
                  bookingData?.appointment_branch_services?.map((service) => (
                    <div className="service-card mb-3">
                        <div className="row p-3">
                            <div className="row justify-content-between">
                               <div className="fsize-18 fweight-600 mb-1 mb-md-1 col-md-3 col-sm-12">
                                   {service.service_title}
                                </div>
                                {bookingData?.status != 'upcoming' && (
                                    <div className=" d-flex gap-2 justify-content-end align-items-start fsize-18 mb-1 mb-md-1 col-md-7 col-sm-12">
                                        {service.employee_did_not_show_up && 
                                            (<>
                                                <span className="bg-white rounded-4 text-center px-2 py-1 text-coral">
                                                    {bookings.details.reported}
                                                    {service.employee_did_not_show_up && <span>({bookings.details.employeeDidNotShowUp})</span>}
                                                    <FlagOutlined/>
                                                </span>
                                            </>)
                                        }
                                        {service.status === 'completed' &&
                                            (<>
                                                <span className="bg-white rounded-4 text-center px-3 py-1 text-green">
                                                    {bookings.status.completed}
                                                    <Check/>
                                                </span>
                                            </>)
                                        }
                                        {service.status === 'canceled' &&
                                            (<>
                                                <span className="bg-white rounded-4 text-center px-3 py-1 text-gray">
                                                    {bookings.status.canceled}
                                                    {service?.appointment_cancellation_reason && (
                                                        lang === 'en' 
                                                            ? <span>({service.appointment_cancellation_reason.reason_en})</span>
                                                            : <span>({service.appointment_cancellation_reason.reason_ar})</span>
                                                    )}
                                                    <Clear/>
                                                </span>
                                            </>) 
                                        }
                                        {bookingData?.status === 'ongoing' && (
                                            <>
                                                <Divider orientation="vertical" flexItem color="text-gray" />
                                                <Menu
                                                    list={showMenuList(service)}
                                                    onChangeValue={(listItem)=>{
                                                        handleOnClickItem(listItem, service)
                                                    }}
                                                    label={<MoreVertOutlined/>}
                                                    ButtonClassName="bg-white rounded-4 text-secondary"    
                                                />
                                            </>)
                                        }
                                    </div>
                                )}
                            </div>
                            <div className="mb-1 mb-md-1 col-12 col-lg-2">
                                <div className="text-gray fsize-18 d-flex gap-2">
                                    <div><CreditCardOutlined/></div> 
                                    {service.exact_fees ? 
                                        (<div>{bookings.details.fees}:</div>) :
                                        (<div>{bookings.details.feesRange}:</div>)
                                    }
                                </div>
                            </div>
                            <div className="mb-1 mb-md-1 col-12 col-lg-10">
                                <div className="text-gray fsize-18">
                                    {service.exact_fees ?
                                       (<div>{service.exact_fees}{bookings.details.sar} </div>) :
                                       (<div>
                                           {bookings.details.from}
                                           {service.fees_from}
                                           {bookings.details.sar} -
                                           {bookings.details.to}
                                           {service.fees_to}
                                           {bookings.details.sar}
                                        </div>)
                                    } 
                                </div>
                            </div>
                            <div className="mb-1 mb-md-1 col-12 col-lg-2">
                                <div className="text-gray fsize-18 d-flex gap-2">
                                    <div><AccessTimeOutlined/></div> 
                                    <div>{bookings.details.duration}:</div>
                                </div>
                            </div>
                            <div className="mb-1 mb-md-1 col-12 col-lg-10">
                                <div className="text-gray fsize-18 ">
                                    {service.starts_at && service.ends_at && (
                                        <div>
                                            {bookings.details.from}
                                            {timeConverter(service.starts_at, lang)}
                                            {bookings.details.to}
                                            {timeConverter(service.ends_at, lang)}
                                        </div>
                                    )}
                                    {!service.starts_at && !service.ends_at && (
                                        <div>
                                            {service.duration_mins} {bookings.addAnotherService.mins}
                                        </div>
                                    )}
                                    
                                </div>
                            </div>
                            {service.description && (
                                <>
                                    <div className="mb-1 mb-md-1 col-12 col-lg-2">
                                        <div className="text-gray fsize-18 d-flex gap-2">
                                            <div><ArticleOutlined/></div>
                                            <div>{bookings.details.description}:</div>
                                        </div>
                                    </div>
                                    <div className="mb-1 mb-md-1 col-12 col-lg-10">
                                        <div className="text-gray fsize-18">
                                            {service.description}
                                        </div>
                                    </div>
                                </>)
                            }
                            <div className="mb-1 mb-md-1 col-12 col-lg-2">
                                <div className="text-gray fsize-18 d-flex gap-2 mb-1 mb-md-1">
                                   <div><Person2Outlined/></div>
                                   <div>{bookings.details.employeeName}:</div>
                                </div>
                            </div>
                            <div className="mb-1 mb-md-1 col-12 col-lg-10">
                                <div className="text-gray fsize-18">
                                {service?.employee?.is_deleted 
                                        ? service.employee.username
                                        : (
                                            <Link
                                                to={ROUTE_PATHS.viewEmployee.replace(':id', service?.employee?.id)}
                                            >
                                                {service.employee.username}  
                                            </Link>
                                          )
                                    }
                                </div>
                            </div>
                        </div>
                    </div> 
                ))}
                {bookingData?.status === 'ongoing' && (
                    <Link
                        to={
                            ROUTE_PATHS.addAnotherServices.replace(':id', id)
                        }
                        className="pointer"
                    >
                        <div className="d-flex gap-2 fsize-22 fweight-500">
                            <div><Add/></div>
                            <div>{bookings.details.addAnotherService}</div>
                        </div>
                    </Link>
                )}
            </div>
            {(bookingData?.status === 'pending_payment' || bookingData?.status === 'completed') &&(
                <div className="bg-white row p-4 mx-1 data-container">
                    <div className="text-dark text-primary fsize-16 mb-2">
                        {bookings.details.totalAmountWithoutVAT}
                    </div>
                    <div className="text-gray fsize-18 mb-2">
                        {bookingData?.total_exact_fees_without_vat} {bookings.details.sar}
                    </div>
                    <div className="text-dark text-primary fsize-16 mb-2">
                        {bookings.details.VAT}  ({bookingData?.vat}%)
                    </div>
                    <div className="text-gray fsize-18 mb-2">
                        {bookingData?.vat_amount}
                    </div>
                    <div className="text-dark text-primary fsize-16 mb-2">
                        {bookings.details.totalAmountWithVAT}
                    </div>
                    <div className="text-gray fsize-18 mb-2">
                        {bookingData?.total_exact_fees} {bookings.details.sar}
                    </div>
                    {bookingData?.status === 'completed' && bookingData?.payment_transaction?.transaction_type != 'online' && (
                        <>
                            <div className="text-dark text-primary fsize-16 mb-2">
                                {bookings.details.paymentConfirmedBy}
                            </div>
                            <div className="text-gray fsize-18 mb-2">
                                {bookingData?.payment_transaction?.transaction_type === 'admin_resolution' 
                                    ? bookings.details.Admin
                                    : bookings.details[bookingData?.payment_transaction?.payment_confirmer_type]
                                }
                            </div> 
                        </> 
                    )}
                </div>
            )}
            <Modal
                open={showFeesPopup}
                handleClose={handleCloseFeesPopup}
                title={<span className="fsize-26 fweight-400">{bookings.details.editFees}</span>}
                contentClass="mb-3 pb-2 text-primary"
                content={renderFeesPopupContent()}
                confirmBtnTxt={shared.submit}
                cancelBtnTxt={shared.cancel}
                isContentText={false}
                handleConfirm={handlesubmitFees}
                handleCancel={handleCloseFeesPopup}
                maxWidth="sm"
                actionsClass=" px-4"
                confirmBtnClass="btn-secondary fsize-14 text-white py-2 px-5"
                cancelBtnClass="fsize-14 py-2 text-secondary px-5 "
                disableActionBtn={!!(!touched["exactFees"] || errors["exactFees"])}
            />
            <Modal
                open={showCancelUpcomingPopup}
                handleClose={handleCloseCancelUpcoming}
                title={<span className="fsize-26 fweight-400">{bookings.details.cancelBooking}</span>}
                contentClass="mb-3 pb-2 text-primary"
                content={<span className="fsize-20 fweight-400">{bookings.details.cancelUpcomingPopupContent}</span>}
                confirmBtnTxt={shared.confirm}
                cancelBtnTxt={shared.cancel}
                isContentText={false}
                handleConfirm={handleSubmitCancelUpcoming}
                handleCancel={handleCloseCancelUpcoming}
                maxWidth="sm"
                actionsClass=" px-4"
                confirmBtnClass="btn-secondary fsize-14 text-white py-2 px-5"
                cancelBtnClass="fsize-14 py-2 text-secondary px-5 "
            />
            <Modal
                open={showRefuseToCancelPopup}
                handleClose={handleCloseRefuseToCancel}
                title={<span className="fsize-26 fweight-400">{bookings.details.cancelBooking}</span>}
                contentClass="mb-3 pb-2 text-primary"
                content={<span className="fsize-18 fweight-400">{bookings.details.refuseToCancelPopupContent}</span>}
                confirmBtnTxt={shared.cancel}
                isContentText={false}
                handleConfirm={handleCloseRefuseToCancel}
                maxWidth="sm"
                actionsClass=" px-4"
                confirmBtnClass="btn-secondary fsize-14 text-white py-2 px-5"
            />
            <BookingCancelationReasonForm
                open={showBookingCancelationReasonPopup}
                setOpen={()=>{setShowBookingCancelationReasonPopup()}}
            />
            <BookingServiceCancelationReasonForm
                open={showServiceCancelationReasonPopup}
                setOpen={setShowServiceCancelationReasonPopup}
                cancelService={cancelService}
            />
            <CustomerHasPaidConfirmationPopup
                open={showCustomerHasPaidPopup}
                setOpen={setShowCustomerHasPaidPopup}
                bookingId={id}
            />
        </div>    
    )
};

export default ViewBooking;