import React, { useEffect, useState, useMemo  } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import messages from '../../../../assets/locale/messages';
import Switch from '../../../../components/Switch';
import EmptyState from '../../../../components/EmptyState';
import Tooltip from '../../../../components/Tooltip'
import { ViewBgIcon } from '../../../../utils/Icons';
import { renderFormatedDate } from '../../../../utils/DateHelpers';
import { employeeStatusList} from '../../../../utils/Constants';
import { ROUTE_PATHS } from '../../../../utils/RoutesPaths';
import './ViewEmployeesDetails.scss';

const ViewEmployeesDetails = () => {
  const lang = useSelector((state) => state.locale.lang);
  const history = useHistory();
  const { branches, shared, serviceProviders, employees } = messages[lang];

  const branchData = useSelector((state) => state.branches.addEditViewBranch.branchDetails);

  const [branchDetails, setBranchDetails] = useState(null);

  useEffect(() => {
    if (branchData?.employees?.length > 0) {
      setBranchDetails(branchData);
    }
  }, [branchData]);

  const renderStatusCase = (employee) => {
    if (employee.status === employeeStatusList.suspended && employee.deactivationDate) {
      return (
        <Tooltip
          title={`${employees.list.suspendedByDeactivateEmployee} ${renderFormatedDate(
            employee.deactivationDate
          )}`}
          content={<span>{RenderTitle(employee.status)}</span>}
          hasArrow
        />
      );
    } else {
      return <span>{RenderTitle(employee.status)}</span>;
    }
  };
  const RenderTitle = (title) => {
    switch (title) {
      case employeesStatus.active:
        return `${employees.list.statusCases.active}`;
      case employeesStatus.deactivated:
        return `${employees.list.statusCases.deactivated}`;
      case employeesStatus.suspended:
        return `${employees.list.statusCases.suspended}`;
      default:
        return `-`;
    }
  };
  const employeesStatus = useMemo(
    () => ({
      active: 'active',
      deactivated: 'deactivated',
      suspended: 'deactivation_scheduled',
    }),
    []
  );

  const renderBranchEmployeesList = () => {
    return (
      <>
        <div className="view-employees-list">
          <div className="bg-white rounded-4 p-4">
            {branchDetails?.employees?.length > 0 ? (
              <>
                <div>
                  {branchDetails?.employees?.length > 0 && (
                    <div className="d-flex align-items-center justify-content-between p-2 px-4 mb-4">
                      <div className="flex-grow-1 d-flex flex-column flex-md-row align-items-md-center align-items-start gap-2 text-gray">
                        <div className="fsize-16 fweight-600 flex-basis-34">
                          {branches.addBranch.specialtiesAndServices.employeeName}
                        </div>
                        <div className="fsize-16 fweight-600">{branches.addBranch.labels.phone}</div>
                      </div>
                    </div>
                  )}
                  {branchDetails?.employees.map((employee) => {
                    return (
                      <div
                        className="employee d-flex justify-content-between align-items-center px-4 py-2 text-primary fweight-600"
                        key={employee.id}>
                        <div className="flex-grow-1 d-flex flex-column flex-md-row align-items-md-center align-items-start gap-2">
                          <div className="flex-basis-40">
                            {lang === 'en'
                              ? employee.first_name_en + ' ' + employee.last_name_en 
                              : employee.first_name_ar + ' ' + employee.last_name_ar}
                          </div>
                          <div>{employee.phone_number}</div>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                          <div className={`${employee.status}-container py-1 px-3 rounded-4 d-flex gap-1`}>
                            {renderStatusCase(employee)}
                          </div>
                          <div
                            className="small-icon-bg  pointer"
                            onClick={() => {
                              history.push(ROUTE_PATHS.viewEmployee.replace(':id', employee.id));
                            }}>
                            <ViewBgIcon />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div className={` ${!branchDetails?.employees?.length > 0 && 'mx-auto w-50'}`}>
                <EmptyState
                  subTitle={shared.noEmployeesAddedyet}
                  showActionButton={false}
                  NoDataListWithNoActionBtn={true}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return <>{renderBranchEmployeesList()}</>;
};

export default ViewEmployeesDetails;
