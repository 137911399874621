const setupProfile = {
  title: 'تعديل',
  subTitle: 'بيانات الشركة',
  labels: {
    email: 'بريدك الإلكتروني',
    category: 'الفئة',
    uploadLogo: 'شعار',
    banner: 'الغلاف',
  },
  placeholders: {
    email: 'أدخل بريدك الإلكتروني',
    ArName: 'أدخل الاسم بالغة العربية',
    EnName: 'أدخل الاسم بالغة الانجليزية',
    category: 'اختر الفئة',
    uploadLogo: 'شعار',
    banner: 'الغلاف',
  },
  actionBTN: 'حفظ',
};

export default setupProfile;
