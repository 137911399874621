import React from "react";
import PropTypes from "prop-types";
import { Button as MuiButton } from "@mui/material";

import "./Button.scss";

const Button = ({
  className,
  label,
  labelClass,
  type,
  outlined,
  disabled,
  onClick,
  block,
  color,
  noBgColor,
  btnStyle,
}) => (
  <MuiButton
    className={`btn border-radius-16 ${
      block ? "btn-block" : ""
    }  ${className} ${
      btnStyle == "primary"
        ? "btn-primary"
        : btnStyle == "secondary"
        ? "btn-secondary"
        : btnStyle == "outlined"
        ? "btn-outlined"
        : btnStyle == "gray"
        ? "btn-gray"
        : btnStyle == "ghost"
        ? "btn-ghost"
        : ""
    }`}
    variant={noBgColor ? "" : outlined ? "outlined" : "contained"}
    type={type}
    disabled={disabled}
    onClick={onClick}
    color={noBgColor ? "" : color}
  >
    <span className={labelClass}>{label}</span>
  </MuiButton>
);

Button.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired,
  ]),
  labelClass: PropTypes.string,
  type: PropTypes.string,
  outlined: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  block: PropTypes.bool,
  color: PropTypes.string,
  noBgColor: PropTypes.bool,
  btnStyle: PropTypes.oneOf(["secondary", "outlined", "ghost",'gray', "primary"]),
};

Button.defaultProps = {
  type: "button",
  color: "primary",
  noBgColor: false,
  btnStyle: "primary",
};

export default Button;
