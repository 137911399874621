import React from "react";
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

const RadioButtonsGroup = ({
  isLabel,
  label,
  labelId,
  defaultValue,
  name,
  list,
  onChange,
}) => {
  return (
    <FormControl>
      {isLabel && <FormLabel id={labelId}>{label}</FormLabel>}
      <RadioGroup
        aria-labelledby={labelId}
        defaultValue={defaultValue ?? ""}
        name={name}
        onChange={onChange}
      >
        {list?.map((item) => (
          <FormControlLabel
            value={item.value}
            key={item.id}
            control={<Radio />}
            label={item.label}
            id={item.id}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonsGroup;
