const tempPassword = {
  title: "كلمة مرور مؤقتة",
  subTitle:
    "يرجى التحقق من صندوق البريد الإلكتروني الخاص بك ، لقد أرسلنا لك كلمة مرور مؤقتة",
  labels: {
    tempPassword: "كلمة مرور مؤقتة",
  },
  placeholders: {
    tempPassword: "أدخل كلمة المرور المؤقتة الخاصة بك",
  },
  actionBTN: "ارسال",
};

export default tempPassword;
