import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Add, Close, ContentCopy, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Typography } from '@mui/material';
import ar from 'react-phone-input-2/lang/ar.json';
import PhoneInput from 'react-phone-input-2';

import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import TimePicker from '../../../components/TimePicker';
import EmptyState from '../../../components/EmptyState';
import messages from '../../../assets/locale/messages';
import pagesTitles from '../../../assets/locale/En/pagesTitles';
import { Locations, LocationsAr, weekDays, weekDaysAr } from '../../../utils/Constants';
import { ROUTE_PATHS } from '../../../utils/RoutesPaths';
import { ARABIC_REGEX } from '../../../utils/Patterns';
import { ENGLISH_REGEX } from '../../../utils/Patterns';
import { setPageMainTitle } from '../../../utils/Helpers';
import { getServicesRequest } from '../../../store/Branches/actions';
import {
  editEmployeeRequest,
  getBranchDetailsByEmpIdRequest,
  getBranchDetailsByEmpIdResponse,
  getBranchservicesByBranchIdRequest,
  getBranchservicesByBranchIdResponse,
  getEmployeeDetailsRequest,
  getEmployeeDetailsResponse,
} from '../../../store/Employees/actions';
import './AddEditEmployee.scss';

const AddEditEmployee = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { branches, shared, employees } = messages[lang];
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const employeeDetails = useSelector((state) => state.employees.employeeDetails);
  const branchServicesList = useSelector((state) => state.employees.helpers.branchServices);
  const viewBranchDetails = useSelector((state) => state.employees.helpers.relatedBranchDetails);

  const [openAssignServicesModal, setOpenAssignServicesModal] = useState(false);
  const [inputLocalization, setInputLocalization] = useState(lang === 'en' ? {} : ar);
  const [assignedServices, setAssignedServices] = useState([]);
  const [specialties, setSpecialties] = useState(null);
  const [renderServices, setRenderServices] = useState(null);
  const [formHasLocation, setFormHasLocation] = useState(true);

  useEffect(() => {
    setPageMainTitle('employeesManagement');
    return () => {
      dispatch(
        getEmployeeDetailsResponse({
          employeeDetails: {},
        })
      );
      dispatch(getBranchDetailsByEmpIdResponse({ relatedBranchDetails: {} }));
      dispatch(
        getBranchservicesByBranchIdResponse({
          branch_services: [],
        })
      );
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getEmployeeDetailsRequest({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (renderServices && branchServicesList?.length > 0) {
      let servicesList = [];
      branchServicesList?.forEach((service) => {
        let services = {};
        services.id = service.id;
        services.service_id = service.service_id;
        services.branch_specialty_id = service.branch_specialty_id;
        services.name = lang === 'en' ? service.title_en : service.title_ar;
        services.name_en = service.title_en;
        services.name_ar = service.title_ar;
        services.isChecked = false;
        if (assignedServices?.length > 0) {
          assignedServices?.forEach((assignedService) => {
            if (assignedService.id === service.id && assignedService.isChecked) {
              services.isChecked = true;
            }
          });
        }
        servicesList.push(services);
      });
      // setAssignedServices(servicesList);
      setSpecialties(
        specialties?.map((specialty) => {
          servicesList?.forEach((service) => {
            if (specialty.id === service.branch_specialty_id) {
              if (specialty.services?.length > 0) {
                let isExsists = specialty.services?.find((item) => item.id === service.id);
                if (!isExsists) {
                  specialty.services.push(service);
                }
              } else {
                specialty.services.push(service);
              }
            }
          });
          return specialty;
        })
      );
    }
  }, [branchServicesList, renderServices, openAssignServicesModal]);

  useEffect(() => {
    if (renderServices && branchServicesList?.length > 0) {
      let servicesList = [];
      branchServicesList?.forEach((service) => {
        let services = {};
        services.id = service.id;
        services.service_id = service.service_id;
        services.branch_specialty_id = service.branch_specialty_id;
        services.name = lang === 'en' ? service.title_en : service.title_ar;
        services.name_en = service.title_en;
        services.name_ar = service.title_ar;
        services.isChecked = false;
        if (assignedServices?.length > 0) {
          assignedServices?.forEach((assignedService) => {
            if (assignedService.id === service.id) {
              services.isChecked = true;
            }
          });
        }
        servicesList.push(services);
      });
      setSpecialties(
        specialties?.map((specialty) => {
          servicesList?.forEach((service) => {
            if (specialty.id === service.branch_specialty_id) {
              if (specialty.services?.length > 0) {
                let isExsists = specialty.services?.find((item) => item.id === service.id);
                if (!isExsists) {
                  specialty.services.push(service);
                }
              } else {
                specialty.services.push(service);
              }
            }
          });
          return specialty;
        })
      );
      setRenderServices(false);
    }
  }, [branchServicesList, renderServices, openAssignServicesModal]);

  useEffect(() => {
    if (openAssignServicesModal) {
      let listOfSpecialties = [];
      if (viewBranchDetails && viewBranchDetails?.specialties?.length > 0) {
        viewBranchDetails?.specialties?.forEach((specialty) => {
          let specialtyWithServices = {};
          specialtyWithServices.id = specialty.id;
          specialtyWithServices.specialty_id = specialty.specialty_id;
          specialtyWithServices.name = specialty.specialty_title_en;
          specialtyWithServices.nameAr = specialty.specialty_title_ar;
          specialtyWithServices.services = [];
          specialtyWithServices.isOpen = false;
          listOfSpecialties.push(specialtyWithServices);
        });
        setSpecialties(listOfSpecialties);
      }
    }
  }, [openAssignServicesModal]);

  useEffect(() => {
    if (employeeDetails !== null) {
      assignDetachedValues();
      if (employeeDetails?.branch?.id) {
        dispatch(getBranchDetailsByEmpIdRequest(employeeDetails?.branch?.id));
      }
    }
  }, [employeeDetails]);

  useEffect(() => {
    if (lang === 'en') {
      setInputLocalization({});
    } else {
      setInputLocalization(ar);
    }
  }, [lang]);

  const {
    values,
    setFieldValue,
    setValues,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      first_name_en: '',
      last_name_en: '',
      first_name_ar: '',
      last_name_ar: '',
      phone: '',
      dial_code: '',
      employee_working_hours: [
        {
          id: 0,
          label: shared.days.sat,
          value: weekDays.sat,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: null,
          FE_id: 1,
          label: shared.days.sun,
          value: weekDays.sun,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: null,
          FE_id: 2,
          label: shared.days.mon,
          value: weekDays.mon,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: null,
          FE_id: 3,
          label: shared.days.tus,
          value: weekDays.tus,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: null,
          FE_id: 4,
          label: shared.days.wed,
          value: weekDays.wed,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: null,
          FE_id: 5,
          label: shared.days.thurs,
          value: weekDays.thurs,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: null,
          FE_id: 6,
          label: shared.days.fri,
          value: weekDays.fri,
          isChosen: false,
          timeSlots: [],
        },
      ],
      location: [
        {
          id: 0,
          label: branches.list.filter.filtersPlaceHolders.AtHome,
          isChecked: false,
          isEditable: false,
        },
        {
          id: 1,
          label: branches.list.filter.filtersPlaceHolders.AtBranch,
          isChecked: false,
          isEditable: false,
        },
      ],
    },
    validationSchema: Yup.object({
      first_name_en: Yup.string().required('enNameRequired').matches(ENGLISH_REGEX, 'englishLettersOnly'),
      last_name_en: Yup.string().required('enNameRequired').matches(ENGLISH_REGEX, 'englishLettersOnly'),
      first_name_ar: Yup.string().required('arNameRequired').matches(ARABIC_REGEX, 'arabicLettersOnly'),
      last_name_ar: Yup.string().required('arNameRequired').matches(ARABIC_REGEX, 'arabicLettersOnly'),
      phone: Yup.string().required('phoneRequired'),
      employee_working_hours: Yup.array().min(1, 'pickOneWorkingDayAtLeast'),
      location: Yup.array().min(1, 'pickOneCoverageZoneAtLeast'),
    }),
    onSubmit: ({
      first_name_en,
      last_name_en,
      first_name_ar,
      last_name_ar,
      phone,
      dial_code,
      employee_working_hours,
      location,
    }) => {
      let empData = {
        id: employeeDetails?.id,
        first_name_en: first_name_en ?? null,
        last_name_en: last_name_en ?? null,
        first_name_ar: first_name_ar ?? null,
        last_name_ar: last_name_ar ?? null,
        phone_number: phone.replace(dial_code, '') ?? null,
        dial_code: '+' + dial_code ?? null,
        locations: location.filter((loc) => loc.isChecked === true),
        branch_service_ids: [],
        employee_working_hours_attributes: null,
        branch_id: viewBranchDetails?.id,
      };

      empData.employee_working_hours_attributes = extractWorkingHoursValue(employee_working_hours);

      if (assignedServices?.length > 0) {
        empData.branch_service_ids = assignedServices
          .filter((service) => service.isChecked === true)
          ?.map((service) => {
            return service.id;
          });
      } else {
        empData.branch_service_ids = [];
      }

      if (empData.locations?.length > 0) {
        if (empData.locations.length > 1) {
          empData.offered_location = 'both';
        } else {
          if (empData.locations[0].value === Locations.atHome) {
            empData.offered_location = 'home';
          } else if (empData.locations[0].value === Locations.atBranch) {
            empData.offered_location = 'in_place';
          }
        }
      }

      dispatch(
        editEmployeeRequest({
          params:{
            id: empData.id,
            data: empData
          },
          from:'editFrom'
        })
      );
    },
  });

  const extractWorkingHoursValue = (employee_working_hours) => {
    let workingDays = employee_working_hours?.map((day) => {
      if(!day.isChosen){
        Object.defineProperty(day, 'destroy', { 
          value: true,
          writable: true
        });
      };
      return day;
    });
    let filteredWeek = workingDays?.filter((day) => {
      return day.timeSlots?.length > 0 && day.timeSlots[0]?.starts_at !== null && day.timeSlots[0]?.ends_at !== null;
    });
    let workingDaysFilteredSlots = filteredWeek.map((day) => {
      let filteredSlots = day.timeSlots?.filter((slot) => {
        if (slot.starts_at !== null && slot.ends_at !== null) {
          return day;
        }
      });
      day.timeSlots = filteredSlots;
      return day;
    });

    let mappedWorkingDays = workingDaysFilteredSlots.map((day) => {
      let mappedSlots = day.timeSlots.map((slot) => {
        return {
          starts_at: slot.starts_at || '00:00:00',
          ends_at: slot.ends_at || '00:00:00',
        };
      });
      let finalObject = {
        intervals: mappedSlots,
        _destroy: day.destroy,
        week_day: day.value.toLowerCase(),
        id: day.id,
      };

      return finalObject;
    });
    return mappedWorkingDays;
  };

  const assignDetachedValues = () => {
    setFieldTouched({
      first_name_en: true,
      last_name_en: true,
      first_name_ar: true,
      last_name_ar: true,
      phone: true,
      employee_working_hours: true,
      location: true,
    });
    let newValue = {
      id: null,
      first_name_en: '',
      last_name_en: '',
      first_name_ar: '',
      last_name_ar: '',
      dial_code: '',
      phone: '',
      employee_working_hours: [
        {
          id: null,
          FE_id: 0,
          label: shared.days.sat,
          value: weekDays.sat,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: null,
          FE_id: 1,
          label: shared.days.sun,
          value: weekDays.sun,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: null,
          FE_id: 2,
          label: shared.days.mon,
          value: weekDays.mon,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: null,
          FE_id: 3,
          label: shared.days.tus,
          value: weekDays.tus,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: null,
          FE_id: 4,
          label: shared.days.wed,
          value: weekDays.wed,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: null,
          FE_id: 5,
          label: shared.days.thurs,
          value: weekDays.thurs,
          isChosen: false,
          timeSlots: [],
        },
        {
          id: null,
          FE_id: 6,
          label: shared.days.fri,
          value: weekDays.fri,
          isChosen: false,
          timeSlots: [],
        },
      ],
      location: [
        {
          id: 0,
          label: branches.list.filter.filtersPlaceHolders.AtHome,
          value: Locations.atHome,
          isChecked: false,
          isEditable: false,
        },
        {
          id: 1,
          label: branches.list.filter.filtersPlaceHolders.AtBranch,
          value: Locations.atBranch,
          isChecked: false,
          isEditable: false,
        },
      ],
      assignedServices: [],
    };
    newValue.id = employeeDetails?.id;
    newValue.first_name_en = employeeDetails?.first_name_en;
    newValue.last_name_en = employeeDetails?.last_name_en;
    newValue.first_name_ar = employeeDetails?.first_name_ar;
    newValue.last_name_ar = employeeDetails?.last_name_ar;
    newValue.name = employeeDetails?.first_name_en + ' ' + employeeDetails?.last_name_en;
    newValue.phone = employeeDetails?.phone_number;
    if (employeeDetails?.employee_working_hours?.length > 0) {
      for (let i = 0; i < employeeDetails?.employee_working_hours.length; i++) {
        if (employeeDetails?.employee_working_hours[i].week_day === weekDays.sat.toLowerCase()) {
          newValue.employee_working_hours[0].id = employeeDetails?.employee_working_hours[i].id;
          newValue.employee_working_hours[0].isChosen = true;
          newValue.employee_working_hours[0].timeSlots = employeeDetails?.employee_working_hours[i].intervals;
        } else if (employeeDetails?.employee_working_hours[i].week_day === weekDays.sun.toLowerCase()) {
          newValue.employee_working_hours[1].id = employeeDetails?.employee_working_hours[i].id;
          newValue.employee_working_hours[1].isChosen = true;
          newValue.employee_working_hours[1].timeSlots = employeeDetails?.employee_working_hours[i].intervals;
        } else if (employeeDetails?.employee_working_hours[i].week_day === weekDays.mon.toLowerCase()) {
          newValue.employee_working_hours[2].id = employeeDetails?.employee_working_hours[i].id;
          newValue.employee_working_hours[2].isChosen = true;
          newValue.employee_working_hours[2].timeSlots = employeeDetails?.employee_working_hours[i].intervals;
        } else if (employeeDetails?.employee_working_hours[i].week_day === weekDays.tus.toLowerCase()) {
          newValue.employee_working_hours[3].id = employeeDetails?.employee_working_hours[i].id;
          newValue.employee_working_hours[3].isChosen = true;
          newValue.employee_working_hours[3].timeSlots = employeeDetails?.employee_working_hours[i].intervals;
        } else if (employeeDetails?.employee_working_hours[i].week_day === weekDays.wed.toLowerCase()) {
          newValue.employee_working_hours[4].id = employeeDetails?.employee_working_hours[i].id;
          newValue.employee_working_hours[4].isChosen = true;
          newValue.employee_working_hours[4].timeSlots = employeeDetails?.employee_working_hours[i].intervals;
        } else if (employeeDetails?.employee_working_hours[i].week_day === weekDays.thurs.toLowerCase()) {
          newValue.employee_working_hours[5].id = employeeDetails?.employee_working_hours[i].id;
          newValue.employee_working_hours[5].isChosen = true;
          newValue.employee_working_hours[5].timeSlots = employeeDetails?.employee_working_hours[i].intervals;
        } else if (employeeDetails?.employee_working_hours[i].week_day === weekDays.fri.toLowerCase()) {
          newValue.employee_working_hours[6].id = employeeDetails?.employee_working_hours[i].id;
          newValue.employee_working_hours[6].isChosen = true;
          newValue.employee_working_hours[6].timeSlots = employeeDetails?.employee_working_hours[i].intervals;
        }
      }
    }
    if (employeeDetails?.offered_location === 'both') {
      newValue.location[0].isChecked = true;
      newValue.location[1].isChecked = true;
    } else if (employeeDetails?.offered_location === 'home') {
      newValue.location[0].isChecked = true;
    } else if (employeeDetails?.offered_location === 'in_place') {
      newValue.location[1].isChecked = true;
    }
    if (employeeDetails?.branch?.offered_location === 'both') {
      newValue.location[0].isEditable = true;
      newValue.location[1].isEditable = true;
    }
    if (employeeDetails.services?.length > 0) {
      let servicesList = [];
      employeeDetails?.services?.forEach((service) => {
        let services = {};
        services.id = service.id;
        services.service_id = service.service_id;
        services.branch_specialty_id = service.branch_specialty_id;
        services.name = lang === 'en' ? service.title_en : service.title_ar;
        services.name_en = service.title_en;
        services.name_ar = service.title_ar;
        services.isChecked = true;
        servicesList.push(services);
      });
      newValue.assignedServices = servicesList;
      setAssignedServices(servicesList);
    }
    if (employeeDetails?.country) {
      newValue.dial_code = employeeDetails?.country?.dial_code?.replace(/\+/g, '');
      newValue.phone = employeeDetails?.country?.dial_code + newValue.phone;
    }
    setValues(newValue);
  };

  //   start working days functions
  const changeDayToIsChosen = (dayIsCheck, i) => {
    let oldDataOfWeekDays = JSON.parse(JSON.stringify(values['employee_working_hours']));
    oldDataOfWeekDays[i].isChosen = dayIsCheck;
    if (!dayIsCheck) { 
      if (oldDataOfWeekDays[i]?.timeSlots?.length > 0) {
        oldDataOfWeekDays[i].isTimeEnter = false;
      }
    }
    setFieldTouched('employee_working_hours');
    setFieldValue('employee_working_hours', oldDataOfWeekDays);
  };

  const renderFisrtTimeSlot = (day) => {
    if (day) {
      day.timeSlots = [];
      day.timeSlots.push({
        id: 1,
        starts_at: null,
        ends_at: null,
      });
    }
    return;
  };

  const copyToAllWorkingDays = (activeDays, slotToCopy) => {
    let modifyWeekDays = values['employee_working_hours'];
    modifyWeekDays.forEach((day) => {
      if (day.id !== activeDays.id) {
        day.isChosen = true;
        day.timeSlots = [];
        day.isTimeEnter = false;
        activeDays.timeSlots.forEach((slot) => {
          day.timeSlots.push({ ...slot });
        });
      }
    });
    activeDays.isTimeEnter = false;
    setFieldTouched('employee_working_hours');
    setFieldValue('employee_working_hours', modifyWeekDays);
  };

  const addNewSlot = (day) => {
    let modifyWeekDays = values['employee_working_hours'];
    let targetedDay = modifyWeekDays.find((oldDay) => {
      return oldDay.FE_id === day.FE_id;
    });
    targetedDay.timeSlots.push({
      id: targetedDay.timeSlots[targetedDay.timeSlots.length - 1].id++,
      starts_at: '',
      ends_at: '',
    });
    setFieldTouched('employee_working_hours');
    setFieldValue('employee_working_hours', modifyWeekDays);
  };

  const removeSlot = (day, indexOfRemovedSlot) => {
    let modifyWeekDays = values['employee_working_hours'];
    let targetedDay = modifyWeekDays.find((oldDay) => {
      return oldDay.id === day.id;
    });
    targetedDay.timeSlots.splice(indexOfRemovedSlot, 1);
    targetedDay.isTimeEnter = true;
    setFieldTouched('employee_working_hours');
    setFieldValue('employee_working_hours', modifyWeekDays);
  };

  const changeSlotTime = (day, time, indexOfSlot, fromOrTo) => {
    let modifyWeekDays = values['employee_working_hours'];
    let targetedDay = modifyWeekDays.find((oldDay) => {
      return oldDay.FE_id === day.FE_id;
    });
    let targetedSlot = targetedDay.timeSlots.find((slot, i) => i === indexOfSlot);
    if (fromOrTo === 'starts_at') {
      targetedSlot.starts_at = time;
    } else {
      targetedSlot.ends_at = time;
    }
    if (targetedSlot.starts_at && targetedSlot.ends_at) {
      targetedDay.isTimeEnter = true;
    }
    setFieldTouched('employee_working_hours');
    setFieldValue('employee_working_hours', modifyWeekDays);
  };

  const renderWorkingHoursBody = () => {
    return (
      <>
        {values['employee_working_hours']?.map((day, i) => {
          return (
            <div className="d-flex flex-column justify-content-between py-1" key={day?.id || i}>
              <div className="woring-hours-day ">
                <div className="d-flex gap-3 align-items-center">
                  <div className="flex-basis-25">
                    <Checkbox
                      color="secondary"
                      label={lang === 'en' ? renderDayLabel(day) : renderDayLabel(day)}
                      labelClass="font-reguler text-dark fsize-16"
                      name={day.label}
                      id={day.label}
                      checked={day.isChosen}
                      onChange={(elementIsChecked) => {
                        setFieldTouched('employee_working_hours');
                        changeDayToIsChosen(elementIsChecked, i);
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-column gap-3">
                      {day.isChosen ? (
                        <span className="time-slots">{renderTimeSlots(day)}</span>
                      ) : (
                        <span className="time-slots text-red fweight-600">{shared.closed}</span>
                      )}
                    </div>
                    {day.isTimeEnter && (
                      <div
                        className="copy-style copy-working-hours d-flex align-items-center gap-2 text-primary pointer mt-2"
                        id={day.id}
                        onClick={(e) => {
                          copyToAllWorkingDays(day);
                        }}>
                        <ContentCopy />
                        {branches.addBranch.labels.applyToAllWokringDays}
                        <span className="text-secondary text-underline">
                          {branches.addBranch.labels.applyToAllWokringDaysAction}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const renderDayLabel = (day) => {
    switch (day.FE_id) {
      case 0:
        return lang === 'en' ? weekDays.sat : weekDaysAr.sat;
      case 1:
        return lang === 'en' ? weekDays.sun : weekDaysAr.sun;
      case 2:
        return lang === 'en' ? weekDays.mon : weekDaysAr.mon;
      case 3:
        return lang === 'en' ? weekDays.tus : weekDaysAr.tus;
      case 4:
        return lang === 'en' ? weekDays.wed : weekDaysAr.wed;
      case 5:
        return lang === 'en' ? weekDays.thurs : weekDaysAr.thurs;
      case 6:
        return lang === 'en' ? weekDays.fri : weekDaysAr.fri;

      default:
        return '-';
    }
  };

  const renderLocationLabel = (location) => {
    switch (location.value) {
      case Locations.atHome:
        return lang === 'en' ? Locations.atHome : LocationsAr.atHome;
      case Locations.atBranch:
        return lang === 'en' ? Locations.atBranch : LocationsAr.atBranch;

      default:
        return '-';
    }
  };

  const checkFormHasOneChoosenLocation = () => {
    let validation = values?.location?.some((loc) => loc.isChecked);
    setFormHasLocation(validation);
  };
  const renderTimeSlots = (day) => {
    if (day) {
      return day.timeSlots?.length > 0 ? (
        <div className="d-flex flex-column gap-3">
          {day.timeSlots.map((slot, i) => {
            return (
              <div key={slot?.id || i}>
                <div className={`d-flex gap-2 align-items-center adjust-small-screens`} key={slot?.id || i}>
                  <TimePicker
                    label={branches.addBranch.placeholders.from}
                    timeSlot={slot.starts_at}
                    onChange={(time) => {
                      changeSlotTime(day, time, i, 'starts_at');
                    }}
                    className={`${lang === 'en' ? 'timePicker' : 'timePicker reverse-style-ar'}`}
                  />
                  <div className="fsize-22"> - </div>
                  <TimePicker
                    label={branches.addBranch.placeholders.to}
                    timeSlot={slot.ends_at}
                    onChange={(time) => {
                      changeSlotTime(day, time, i, 'ends_at');
                    }}
                    className={`${lang === 'en' ? 'timePicker' : 'timePicker reverse-style-ar'}`}
                  />
                  {i > 0 && (
                    <div
                      className="pointer"
                      onClick={() => {
                        removeSlot(day, i);
                      }}>
                      <Close fontSize="large" />
                    </div>
                  )}
                  {day.timeSlots?.length >= 1 && day.timeSlots?.length < 3 && !i > 0 && (
                    <div className="add-btn-wrapper">
                      <Button
                        label={
                          <span className="d-flex align-items-center">
                            <Add fontSize="large" />
                          </span>
                        }
                        labelClass="fsize-16 text-white"
                        onClick={() => {
                          addNewSlot(day);
                        }}
                        btnStyle="gray"
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <>{renderFisrtTimeSlot(day)}</>
      );
    } else {
      return;
    }
  };
  //   end working days functions

  const handleCloseAssignServicesModal = () => {
    setOpenAssignServicesModal(false);
  };

  const handleAssignServices = () => {
    // reflect the changes in the services
    let old = JSON.parse(JSON.stringify(assignedServices)) || [];
    setAssignedServices(null);
    specialties.map((specialty) => {
      specialty.services?.map((service) => {
        if (service.isChecked === true) {
          let found = false;
          if (assignedServices?.length > 0) {
            assignedServices.map((assignedService) => {
              if (assignedService.id === service.id) {
                found = true;
              }
              return assignedService;
            });
          }
          if (!found) {
            let copy = JSON.parse(JSON.stringify(service));
            old.push(copy);
          } else {
            old.map((ser) => {
              if (ser.id === service.id) {
                ser.isChecked = true;
              }
              return ser;
            });
          }
        } else {
          let foundd = false;
          if (assignedServices?.length > 0) {
            assignedServices.map((assignedService) => {
              if (assignedService.id === service.id) {
                foundd = true;
              }
              return assignedService;
            });
          }
          if (foundd) {
            old = old.filter((assignedService) => {
              return assignedService.id !== service.id;
            });
          }
        }
        return service;
      });
      return specialty;
    });
    setAssignedServices(old);

    setOpenAssignServicesModal(false);
  };

  const getServicesBySpecialtyId = (specialtyId) => {
    dispatch(
      getServicesRequest({
        page: -1,
        specialty_id: specialtyId,
      })
    );
  };

  const checkIsThereAssignServices = () => {
    let hasNotAny = true;
    assignedServices?.forEach((service) => {
      if (service.isChecked) {
        hasNotAny = false;
      }
    });
    return hasNotAny;
  };

  const checkIsThereLocation = () => {
    let hasNotAny = true;
    values?.location?.forEach((service) => {
      if (service?.isChecked) {
        hasNotAny = false;
      }
    });
    return hasNotAny;
  };

  const renderAddEditEmployee = () => {
    return (
      <div className="mt-4">
        {Object.keys(employeeDetails).length > 0 ? (
          <>
            <Input
              id="first_name_en"
              label={branches.addBranch.specialtiesAndServices.first_name}
              placeholder={branches.addBranch.specialtiesAndServices.first_name}
              name="first_name_en"
              type="text"
              onChange={(value) => {
                setFieldTouched('first_name_en');
                setFieldValue('first_name_en', value);
              }}
              value={values['first_name_en']}
              required
              inputWrapperClass="mb-4"
              labelClassName="text-dark font-medium mb-2"
              isInputHasErr={!!(touched['first_name_en'] && errors['first_name_en'])}
              errMsg={errors['first_name_en']}
            />
            <Input
              id="last_name_en"
              label={branches.addBranch.specialtiesAndServices.last_name}
              placeholder={branches.addBranch.specialtiesAndServices.last_name}
              name="last_name_en"
              type="text"
              required
              onChange={(value) => {
                setFieldTouched('last_name_en');
                setFieldValue('last_name_en', value);
              }}
              value={values['last_name_en']}
              inputWrapperClass="mb-4"
              labelClassName="text-dark font-medium mb-2"
              isInputHasErr={!!(touched['last_name_en'] && errors['last_name_en'])}
              errMsg={errors['last_name_en']}
            />
            <Input
              id="first_name_ar"
              label={branches.addBranch.specialtiesAndServices.first_name_ar}
              placeholder={branches.addBranch.specialtiesAndServices.first_name_ar}
              name="first_name_ar"
              type="text"
              required
              onChange={(value) => {
                setFieldTouched('first_name_ar');
                setFieldValue('first_name_ar', value);
              }}
              value={values['first_name_ar']}
              inputWrapperClass="mb-4"
              labelClassName="text-dark font-medium mb-2"
              isInputHasErr={!!(touched['first_name_ar'] && errors['first_name_ar'])}
              errMsg={errors['first_name_ar']}
            />
            <Input
              id="last_name_ar"
              label={branches.addBranch.specialtiesAndServices.last_name_ar}
              placeholder={branches.addBranch.specialtiesAndServices.last_name_ar}
              name="last_name_ar"
              type="text"
              required
              onChange={(value) => {
                setFieldTouched('last_name_ar');
                setFieldValue('last_name_ar', value);
              }}
              value={values['last_name_ar']}
              inputWrapperClass="mb-4"
              labelClassName="text-dark font-medium mb-2"
              isInputHasErr={!!(touched['last_name_ar'] && errors['last_name_ar'])}
              errMsg={errors['last_name_ar']}
            />
            <div className="my-4">
              <div className="text-dark font-medium mb-2">
                {branches.addBranch.labels.phone} <span className="err-asterisk"> *</span>
              </div>
              {Object.keys(inputLocalization).length === 0 ? (
                <>
                  <PhoneInput
                    name="phone"
                    type="text"
                    className={`${
                      (lang === 'ar' && 'phone-arabic-dir') ||
                      (!!(touched['phone'] && errors['phone']) && 'error-border')
                    }`}
                    value={values['phone']}
                    country={'sa'}
                    localization={inputLocalization}
                    onChange={(phone, countryData) => {
                      setFieldTouched('phone');
                      setFieldValue('phone', phone !== '' ? phone : null);
                      setFieldValue('dial_code', countryData.dialCode);
                    }}
                    isInputHasErr={!!(touched['phone'] && errors['phone'])}
                    errMsg={errors['phone']}
                    disabled
                  />
                  {!!touched['phone'] && errors['phone'] && (
                    <div className="font-medium fsize-12 text-red mt-1">{employees.errorMessages.phoneRequired}</div>
                  )}
                </>
              ) : (
                <>
                  <PhoneInput
                    name="phone"
                    type="text"
                    className={`${
                      (lang === 'ar' && 'phone-arabic-dir') ||
                      (!!(touched['phone'] && errors['phone']) && 'error-border')
                    }`}
                    value={values['phone']}
                    country={'sa'}
                    localization={inputLocalization}
                    onChange={(phone, countryData) => {
                      setFieldTouched('phone');
                      setFieldValue('phone', phone !== '' ? phone : null);
                      setFieldValue('dial_code', countryData.dialCode);
                    }}
                    isInputHasErr={!!(touched['phone'] && errors['phone'])}
                    errMsg={errors['phone']}
                    disabled
                  />
                  {!!touched['phone'] && errors['phone'] && (
                    <div className="font-medium fsize-12 text-red mt-1">{employees.errorMessages.phoneRequired}</div>
                  )}
                </>
              )}
            </div>
            {/* Working Hours */}
            <div className="col-12 mt-4">
              <span className="text-dark font-medium">{branches.addBranch.labels.workingHours}</span>
              <div className="fsize-12 text-error font-medium">
                {touched['employee_working_hours'] && errors['employee_working_hours']}
              </div>
              <div className="working-hours-section text-primary">{renderWorkingHoursBody()}</div>
            </div>
            {/* Location */}
            <div className="text-dark font-medium mt-4">
              {branches.addBranch.specialtiesAndServices.location}
              <span className="err-asterisk"> * </span>
            </div>
            <div className="bg-section p-4 my-2">
              {values['location'].map((location, i) => {
                return (
                  <div key={location.id || i}>
                    <Checkbox
                      key={location.id || i}
                      color="secondary"
                      label={lang === 'en' ? renderLocationLabel(location) : renderLocationLabel(location)}
                      labelClass="font-reguler text-dark fsize-16"
                      name={location.value}
                      id={location.value}
                      checked={location.isChecked}
                      onChange={(IsChecked, e) => {
                        setFieldValue(
                          'location',
                          values['location'].map((loc) => {
                            if (loc.value === e.target.id) {
                              loc.isChecked = IsChecked;
                            }
                            return loc;
                          })
                        );
                        checkFormHasOneChoosenLocation();
                      }}
                      disabled={!location.isEditable}
                    />
                    {i !== values['location'].length - 1 && <div className="divider my-3"></div>}
                  </div>
                );
              })}
            </div>
            <div className="text-dark font-medium mt-4">
              {branches.addBranch.specialtiesAndServices.assignServices}
              <span className="err-asterisk"> * </span>
            </div>
            <div className=" my-2">
              {assignedServices?.length === 0 ? (
                <Button
                  label={
                    <span className="d-flex align-items-center">
                      <Add fontSize="medium" />
                      <span>{branches.addBranch.specialtiesAndServices.assignServices}</span>
                    </span>
                  }
                  labelClass="fsize-16 text-white"
                  onClick={() => {
                    setOpenAssignServicesModal(true);
                  }}
                />
              ) : (
                <div className="bg-section p-4 my-2">
                  {assignedServices?.map((service, i) => {
                    return (
                      <div key={service.id || i}>
                        {lang === 'en' ? (
                          <Checkbox
                            key={service.id || i}
                            color="secondary"
                            label={service.name}
                            labelClass="font-reguler text-dark fsize-16"
                            name={service.name}
                            id={service.name}
                            checked={service.isChecked}
                            onChange={(IsChecked, e) => {
                              setAssignedServices(
                                assignedServices?.map((service) => {
                                  if (service.name == e.target.id) {
                                    service.isChecked = IsChecked;
                                  }
                                  return service;
                                })
                              );
                            }}
                            checkboxClass="mb-3"
                          />
                        ) : (
                          <>
                            <Checkbox
                              key={service.id || i}
                              color="secondary"
                              label={service.name_ar}
                              labelClass="font-reguler text-dark fsize-16"
                              name={service.name}
                              id={service.name}
                              checked={service.isChecked}
                              onChange={(IsChecked, e) => {
                                setAssignedServices(
                                  assignedServices?.map((service) => {
                                    if (service.name == e.target.id) {
                                      service.isChecked = IsChecked;
                                    }
                                    return service;
                                  })
                                );
                              }}
                              checkboxClass="mb-3"
                            />
                          </>
                        )}
                        {i !== assignedServices.length - 1 && <div className="divider mb-3"></div>}
                      </div>
                    );
                  })}
                  <Button
                    label={
                      <span className="d-flex align-items-center">
                        <Add fontSize="medium" />
                        {branches.addBranch.specialtiesAndServices.assignServices}
                      </span>
                    }
                    labelClass="fsize-16 text-white"
                    onClick={() => {
                      setOpenAssignServicesModal(true);
                    }}
                  />
                </div>
              )}
            </div>
            <div className="row mt-4 py-4 d-flex justify-content-center">
              <div className="col-4">
                <Button
                  onClick={() => {}}
                  type="submit"
                  className="btn-secondary"
                  block
                  label={shared.save}
                  labelClass="px-4 fsize-16 font-medium text-white"
                  disabled={!dirty || !isValid || !formHasLocation || checkIsThereAssignServices()}
                />
              </div>
            </div>
            <Modal
              open={openAssignServicesModal}
              handleClose={handleCloseAssignServicesModal}
              title={branches.addBranch.specialtiesAndServices.assignServices}
              contentClass="mb-2 pb-2 text-primary"
              content={
                <>
                  {specialties?.map((specialty, i) => {
                    return (
                      <div
                        className="d-flex justify-content-between align-items-top mb-4 custmo-color"
                        key={specialty.id || i}>
                        <div className="flex-grow-1">
                          <div className="text-secondary fsize-16">
                            {lang === 'en' ? specialty.name : specialty.nameAr}
                          </div>
                          {specialty.isOpen && (
                            <div>
                              {specialty?.services?.map((service, i) => {
                                return (
                                  <div className="ms-2 ps-1 my-2" key={service.id || i}>
                                    <Checkbox
                                      key={service.id || i}
                                      color="secondary"
                                      label={service?.name}
                                      labelClass="font-reguler text-dark fsize-16"
                                      name={service?.name}
                                      id={service?.name}
                                      checked={service?.isChecked}
                                      onChange={(IsChecked, e) => {
                                        setSpecialties(
                                          specialties.map((specialty) => {
                                            specialty.services.map((service, index) => {
                                              if (service.name == e.target.id) {
                                                service.isChecked = IsChecked;
                                              }
                                              return service;
                                            });
                                            return specialty;
                                          })
                                        );
                                      }}
                                    />
                                    {i !== specialty?.services.length - 1 && <div className="divider my-3"></div>}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                        <div>
                          {specialty.isOpen ? (
                            <div
                              className="pointer"
                              onClick={() => {
                                setRenderServices(false);
                                setSpecialties(
                                  specialties.map((spec) => {
                                    if (spec.id === specialty.id) {
                                      spec.isOpen = false;
                                    }
                                    return spec;
                                  })
                                );
                              }}>
                              <KeyboardArrowUp />
                            </div>
                          ) : (
                            <div
                              className="pointer"
                              onClick={() => {
                                getServicesBySpecialtyId(specialty.specialty_id);
                                if (viewBranchDetails?.id) {
                                  dispatch(
                                    getBranchservicesByBranchIdRequest({
                                      page: -1,
                                      branch_id: viewBranchDetails?.id,
                                    })
                                  );
                                }
                                setTimeout(() => {
                                  setRenderServices(true);
                                }, 700);
                                setSpecialties(
                                  specialties.map((spec) => {
                                    if (spec.id === specialty.id) {
                                      spec.isOpen = true;
                                    }
                                    return spec;
                                  })
                                );
                              }}>
                              <KeyboardArrowDown />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </>
              }
              isContentText={false}
              confirmBtnTxt={shared.save}
              maxWidth="xs"
              cancelBtnTxt={shared.cancel}
              handleConfirm={handleAssignServices}
              handleCancel={handleCloseAssignServicesModal}
              modalClass="delete-categories-modal"
              actionsClass="container px-4"
              confirmBtnClass="btn-secondary fsize-14 text-white py-2"
              cancelBtnClass="fsize-14 py-2"
            />
          </>
        ) : (
          <div className="d-flex justify-content-center px-2 px-md-5 py-5 bg-white rounded-4">
            <EmptyState
              subTitle={shared.noDataToShow}
              ButtonLabel={shared.back}
              handleBtnAction={() => {
                history.push(ROUTE_PATHS.employees);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="add-edit-employee-page">
      <div className="d-flex mb-4">
        <Typography variant="h4" sx={{ flexGrow: 1 }} className={`page-title`}>
          {pagesTitles.employeesEdit}
        </Typography>
      </div>
      <form noValidate onSubmit={handleSubmit} className="add-edit-employee-section">
        <div className="px-2 px-md-5 bg-white rounded-4">
          <div className="pt-4">{employeeDetails && renderAddEditEmployee()}</div>
        </div>
      </form>
    </div>
  );
};

export default AddEditEmployee;
