import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { PASSWORD_REGEX } from "../../../utils/Patterns";
import { setPageMainTitle } from '../../../utils/Helpers';
import messages from "../../../assets/locale/messages";
import { ROUTE_PATHS } from '../../../utils/RoutesPaths';
import { changePasswordRequest } from "../../../store/Auth/actions";

const ChangePassword = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { changePassword, settings, shared } = messages[lang];
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required("oldPasswordRequired"),
      new_password: Yup.string()
        .required("newPasswordRequired")
        .matches(PASSWORD_REGEX, "passwordNotFollowREGEX"),
      confirm_password: Yup.string()
        .required("confirmNewPasswordRequired")
        .oneOf([Yup.ref("new_password"), null], "passwordIsNotMatched"),
    }),
    onSubmit: ({ old_password, new_password, confirm_password }) => {
      dispatch(
        changePasswordRequest({
          data:{
            old_password,
            new_password,
          },
          from:'settings'
        })
      );
    },
  });

  useEffect(() => {
    setPageMainTitle('SettingsManagment');
  }, []);

  return (
    <div className="change-password-page w-100">
      <div
        className="fsize-30 fweight-600 d-flex gap-1 text-primary mb-4" 
      >
        <span
          className="pointer"
          onClick={()=>{
            history.push(ROUTE_PATHS.settings)
          }}
        >
          { lang === 'en' ? 
            (<ArrowBackIosOutlinedIcon/>) : 
            (<ArrowForwardIosOutlinedIcon/>)
          }
        </span>
        <span  className="mt-1">{settings.changePassword.changePassword}</span>
      </div>
      <form className="w-100" onSubmit={handleSubmit} noValidate>
        <div className="bg-white rounded-4 px-5 py-3 mb-4">
          <Input
            id="oldPassword"
            label={changePassword.labels.oldPassword}
            name="old_password"
            type="password"
            placeholder={changePassword.placeholders.oldPassword}
            required={true}
            onChange={(value) => {
              setFieldTouched("old_password");
              setFieldValue("old_password", value.trim());
            }}
            value={values["old_password"]}
            inputClass="mb-1"
            labelClassName="fsize-16 fweight-400 mb-3"
            inputWrapperClass="my-4"
            isInputHasErr={!!(touched["old_password"] && errors["old_password"])}
            errMsg={errors["old_password"]}
        />
        <Input
          id="newPassword"
          label={changePassword.labels.newPassword}
          name="new_password"
          type="password"
          placeholder={changePassword.placeholders.newPassword}
          required={true}
          onChange={(value) => {
            setFieldTouched("new_password");
            setFieldValue("new_password", value.trim());
          }}
          value={values["new_password"]}
          inputClass="mb-1"
          labelClassName="fsize-16 fweight-400 mb-3"
          inputWrapperClass="my-4"
          isInputHasErr={!!(touched["new_password"] && errors["new_password"])}
          errMsg={errors["new_password"]}
        />
        <Input
          id="confirmNewPassword"
          label={changePassword.labels.confirmNewPassword}
          name="confirm_password"
          type="password"
          placeholder={changePassword.placeholders.confirmNewPassword}
          required={true}
          onChange={(value) => {
            setFieldTouched("confirm_password");
            setFieldValue("confirm_password", value.trim());
          }}
          value={values["confirm_password"]}
          inputClass="mb-1"
          labelClassName="fsize-16 fweight-400 mb-3"
          inputWrapperClass="my-4"
          isInputHasErr={
            !!(touched["confirm_password"] && errors["confirm_password"])
          }
          errMsg={errors["confirm_password"]}
        />
        </div>
        <div className="d-flex justify-content-end gap-2">
          <Button
            label={shared.cancel}
            labelClass="text-secondary px-3"
            outlined
            onClick={()=> {history.push(ROUTE_PATHS.settings)}}
          />
          <Button
            label={changePassword.actionBTN}
            type="submit"
            labelClass="text-white px-3"
            btnStyle="secondary"
            disabled={!dirty || !isValid}
          />
        </div>
      </form>
    </div>
  );
};
export default ChangePassword;
