const inputsValidations = {
  emailRequired: 'Email is required',
  nameRequired: 'Name is required',
  locationNameRequired: 'English location name is required',
  locationNameArRequired: 'Arabic location name is required',
  enNameRequired: 'English name is required',
  arNameRequired: 'Arabic name is required',
  pickOneCategoryAtLeast: 'At least one category must be selected',
  pickOneWorkingDayAtLeast: 'At least one working day must be selected',
  pickOneCoverageZoneAtLeast: 'At least one coverage zone must be selected',
  genderRequired: 'Gender is requierd',
  paymentMenthodRequired: 'Payment method is requierd',
  specialtyRequired: 'Specialty is required',
  roleRequired: 'Role is requierd',
  logoRequired: 'Logo is requierd',
  bannerRequired: 'Banner is requierd',
  categoryRequired: 'Category is requierd',
  passwordRequired: 'Password is required',
  tempPasswordRequired: 'Temporary password is required',
  oldPasswordRequired: 'Current password is required',
  streetNumberRequired: 'Street number is required',
  streetNameRequired: 'Street name is required',
  buildingNumberRequired: 'Building number is required',
  postalCodeNumberOnly: 'Postal code should be 5 numbers only',
  newPasswordRequired: 'New password is required',
  validEmailFormat: 'Please enter a valid email format',
  uniqeEmailRequired: 'This email is used before, email should be uniqe',
  confirmNewPasswordRequired: 'Confirm new password is required',
  passwordIsNotMatched: 'Passwords must match',
  passwordNotFollowREGEX:
    'Password should contain at least 8 characters; mix of letters (upper and lower case), numbers, and symbols',
  nameNotFollowREGEX: 'Name should not contain any special characters',
  englishLettersOnly: 'This field only accepts English input',
  arabicLettersOnly: 'This field only accepts Arabic input',
  numbersOnly: 'This field only accepts numbers input',
  floatTwoDecimalOnly: 'This field only accepts 2 decimal places numbers input',
  invalidDate: 'Date is not valid',
  uploadTypeError: 'Accepted formats are jpg, jpeg, png, and bmp only',
  uploadSizeError: 'Max size is 2MB',
  regionRequired: 'District is required',
  cityRequired: 'City is required',
  countryRequired: 'Country is required',
  required: 'This field is required',
};

export default inputsValidations;
