import React from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import history from './History';
import { ROUTE_PATHS } from '../utils/RoutesPaths';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
// ========= Components =========
import Login from '../containers/Auth/Login';
import TempPassword from '../containers/Auth/TempPassword';
import ChangePassword from '../containers/Auth/ChangePassword';
import ForgetPassword from '../containers/Auth/ForgetPassword';
import SetupProfile from '../containers/Auth/SetupProfile';
import Branches from '../containers/Branches';
import ViewBranch from '../containers/Branches/ViewBranch';
import AddEditBranch from '../containers/Branches/AddEditBranch';
import CoverageAreas from '../containers/Branches/CoverageAreas';
import SelectLocation from '../containers/Branches/SelectLocation';
import Employees from '../containers/Employees';
import ViewEmployeesDetails from '../containers/Employees/ViewEmployee';
import AddEditEmployee from '../containers/Employees/AddEditEmployee';
import Bookings from '../containers/Bookings'
import ViewBooking from '../containers/Bookings/ViewBooking'
import AddAnotherService from '../containers/Bookings/ViewBooking/AddAnotherService'
import Settings from '../containers/Settings';
import ChangePasswordSettings from '../containers/Settings/ChangePasswordSettings';
import EditProfile from '../containers/Settings/EditProfile';
import PaymentTransactions from '../containers/PaymentTransactions';
import Notifications from '../containers/Notifications';

const Routes = (
  <Router history={history}>
    <Switch>
      {/* Authentication */}
      <PublicRoute component={Login} path={ROUTE_PATHS.login} exact />
      <PublicRoute component={ForgetPassword} path={ROUTE_PATHS.forgetPassword} exact />
      <PublicRoute component={TempPassword} path={ROUTE_PATHS.tempPassword} exact />
      <PrivateRoute component={SetupProfile} path={ROUTE_PATHS.setupProfile} exact permission={true} />
      <PrivateRoute component={ChangePassword} path={ROUTE_PATHS.changePassword} exact permission={true} />
      {/* Branches */}
      <PrivateRoute component={AddEditBranch} path={ROUTE_PATHS.editBranch} exact permission={true} />
      <PrivateRoute component={AddEditBranch} path={ROUTE_PATHS.addBranch} exact permission={true} />
      <PrivateRoute component={ViewBranch} path={ROUTE_PATHS.viewBranch} exact permission={true} />
      <PrivateRoute component={Branches} path={ROUTE_PATHS.branches} exact permission={true} />
      <PrivateRoute component={CoverageAreas} path={ROUTE_PATHS.addBranchSupportedRegions} exact permission={false} />
      <PrivateRoute component={SelectLocation} path={ROUTE_PATHS.addBranchBranchLocation} exact permission={false} />

      {/* Employees */}
      <PrivateRoute component={ViewEmployeesDetails} path={ROUTE_PATHS.viewEmployee} exact permission={true} />
      <PrivateRoute component={AddEditEmployee} path={ROUTE_PATHS.editEmployees} exact permission={true} />
      <PrivateRoute component={Employees} path={ROUTE_PATHS.employees} exact permission={true} />

      {/* Bookings */}
      <PrivateRoute component={Bookings} path={ROUTE_PATHS.bookings} exact permission={true} />
      <PrivateRoute component={ViewBooking} path={ROUTE_PATHS.viewBooking} exact permission={true} />
      <PrivateRoute component={AddAnotherService} path={ROUTE_PATHS.addAnotherServices} exact permission={true} />
      
      {/* Settings */}
      <PrivateRoute component={Settings} path={ROUTE_PATHS.settings} exact permission={true} />
      <PrivateRoute component={ChangePasswordSettings} path={ROUTE_PATHS.changePasswordFromSettings} exact permission={true} />
      <PrivateRoute component={EditProfile} path={ROUTE_PATHS.editProfile} exact permission={true} />

      {/* Transactions */}
      <PrivateRoute component={PaymentTransactions} path={ROUTE_PATHS.transactions} exact permission={true} />
      {/* Notifications */}
      <PrivateRoute component={Notifications} path={ROUTE_PATHS.notifications} exact permission={true} />
      
      <Redirect from="/" to={ROUTE_PATHS.branches} />
      <Redirect from="**" to={ROUTE_PATHS.branches} />
    </Switch>
  </Router>
);
export default Routes;
