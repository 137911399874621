import React, { useState, useEffect }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";

import messages from '../../../../assets/locale/messages';
import RadioButtonsGroup from '../../../../components/RadioButton'
import Modal from '../../../../components/Modal'
import { updateBookingRequest, getRequestStatus } from '../../../../store/Bookings/actions'

const BookingCancelationReasonForm = ({open, setOpen}) => {

    const dispatch = useDispatch();
    const lang = useSelector((state) => state.locale.lang);
    const { bookings, shared} = messages[lang];
    const bookingCancelationReasons = useSelector((state) => state.bookings.bookingCancelationReasons);
    const bookingData = useSelector((state) => state.bookings.bookingDetails);
    const [bookingCancelationReasonsList, setBookingCancelationReasonsList] = useState([]);
    const {values,
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        touched, 
        errors,
        
        } = useFormik({
            initialValues:{
                cancelationReason: 1
            },
            validationSchema: Yup.object({
                cancelationReason: Yup.number().required('Required')
            }),
            onSubmit: async ({cancelationReason}) => {
                dispatch(updateBookingRequest({
                    data:{
                        id: bookingData.id,
                        params:{
                            appointment_cancellation_reason_id: cancelationReason
                        }
                    },
                    requestType:"cancel"    
                }))
                setOpen(false);
                dispatch(getRequestStatus(false));
            }       
     }); 

    useEffect(()=>{
        const reasons = bookingCancelationReasons?.map(reason => {
            return {
                id: reason.id,
                label: <div className="fsize-20 fweight-400 text-gray"> {reason.reason}</div>,
                value: reason.id
            }
        })
        setBookingCancelationReasonsList([...reasons]);
    },[lang, bookingCancelationReasons]);

    const handleCloseCancelationReasonPopup = () => {
        setOpen(false);
    };
    const handleSumbitCancelationReason = () => {
        handleSubmit();
    };
    const renderCancelationReasonPopupContent = () => (
        <div>
            <div className="fsize-20 fweight-400 mb-3">{bookings.details.bookingCancelationReasonLabel}*</div>
            <RadioButtonsGroup
                name="cancelationReason"
                labelId="cancelationReason"
                list={bookingCancelationReasonsList}
                onChange={(e) => {
                    setFieldTouched("cancelationReason");
                    setFieldValue("cancelationReason", +e.target.value);
                  }}      
            />
        </div>
    )

    return(
        <Modal
                open={open}
                handleClose={handleCloseCancelationReasonPopup}
                title={<span className="fsize-26 fweight-400">{bookings.details.cancelBooking}</span>}
                contentClass="mb-3 pb-2 text-primary"
                content={renderCancelationReasonPopupContent()}
                confirmBtnTxt={shared.submit}
                cancelBtnTxt={shared.cancel}
                isContentText={false}
                handleConfirm={handleSumbitCancelationReason}
                handleCancel={handleCloseCancelationReasonPopup}
                maxWidth="sm"
                actionsClass=" px-4"
                confirmBtnClass="btn-secondary fsize-14 text-white py-2 px-5"
                cancelBtnClass="fsize-14 py-2 text-secondary px-5 "
                disableActionBtn={!!(!touched["cancelationReason"] || errors["cancelationReason"])}
            />
    )
}

export default BookingCancelationReasonForm;

