import { axiosInstance } from '.';
const getBookingsList = async (params) => await axiosInstance.get('/appointments', { params });
const getBooking = async (id) => await axiosInstance.get(`/appointments/${id}`);
const editBookingService = async ({id, data}) => await axiosInstance.patch(`/appointment_branch_services/${id}`, data);
const listBookingCancelationReasons = async ()=> await axiosInstance.get('/appointment_cancellation_reasons')
const listBookingServiceCancelationReasons = async ()=> await axiosInstance.get('/appointment_branch_service_cancellation_reasons')
const updateBooking = async ({id, params}) => await axiosInstance.patch(`/appointments/${id}`, params);
const getMoreServices = async (params) => await axiosInstance.get('/branch_services',{params})

export {
    getBookingsList,
    getBooking,
    editBookingService,
    listBookingCancelationReasons,
    listBookingServiceCancelationReasons,
    updateBooking,
    getMoreServices
}