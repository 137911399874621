import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as MuiCheckbox, FormGroup, FormControlLabel } from '@mui/material';
import './Checkbox.scss';

import './Checkbox.scss';

const Checkbox = ({ label, checked, onChange, name, color, labelClass, id, disabled, checkboxClass }) => (
  <>
    <FormGroup className={checkboxClass}>
      <FormControlLabel
        control={
          <MuiCheckbox
            className={`check-box ${labelClass}`}
            checked={checked}
            onChange={(e) => onChange(e.target.checked, e)}
            color={color}
            name={name || ''}
            id={id || name}
            disabled={disabled}
          />
        }
        label={label}
      />
    </FormGroup>
  </>
);

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  labelClass: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  checkboxClass: PropTypes.string,
};

Checkbox.defaultProps = {
  color: 'primary',
};

export default Checkbox;
