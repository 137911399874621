const branches = {
  addBranch: {
    firstTab: 'تفاصيل الفرع',
    secondTab: 'التخصصات والخدمات',
    thirdTab: 'موظفين الفرع',
    addBranch: 'اضافة فرع جديد',
    saveAndSendPublishRequest:'حفظ و إرسال طلب نشر',
    labels: {
      enTitle: 'اسم الفرع بالانجليزية',
      arTitle: 'اسم الفرع بالعربية',
      branchImage: ' صورة الفرع ',
      phone: 'رقم الهاتف',
      workingHours: 'ساعات العمل',
      location: 'الموقع',
      ServicedGender: 'الفئة المستهدفة من الخدمة',
      paymenMethods: 'طرق الدفع',
      addName: 'اسم المنطقة باللغة الانجليزية',
      addNameAr: ' اسم المنطقة باللغة العربية',
      applyToAllWokringDays: 'نسخ ساعات العمل هذه إلى جميع الأيام ؟',
      applyToAllWokringDaysAction: 'نعم، نسخ',
      male: 'ذكر',
      female: 'انثي',
      bothGender: 'كلا الجنسين',
      online: 'الدفع الالكتروني',
      cash: 'الدفع نقدا',
      both: 'الدفع الالكتروني والدفع نقدا',
      contryNameEn: 'اسم المدينة باللغة الانجليزية',
      contryNameAr: 'اسم المدينة باللغة العربية',
      city: 'المحافظة',
      region: 'الاقليم',
      streetNumber: 'رقم الشارع',
      streetName: 'اسم الشارع',
      buildingNumber: 'رقم المبني',
      postalNumber: 'رقم البريد',
      secondaryNumber: 'رقم الثانوي',
      landmark: 'علامة مميزة',
      addSepicialtiesAndServices: 'أضف التخصصات والخدمات',
      addServicesOnSpecialty: 'لا توجد خدمات، يرجى اضافة خدمات لهذا التخصص',
      addEmployee: 'اضافة موظف', 
      editEmployee: 'تعديل موظفين',
      employessDetails: 'تفاصيل الموظفين',
      deleteEmployee: 'حذف الموظف',
      deleteEmployeeMessage: 'هل انت متأكد من حذف الموظف ؟',
      confirmCancel: 'تأكيد الالغاء',
      confirmCancelMessage: 'سيتم فقدان البيانات غير المحفوظة ، هل أنت متأكد من الإلغاء ؟',
      AddSpecialties: 'أضف تخصص',
    },
    placeholders: {
      enTitle: 'أدخل اسم الفرع بالانجليزية',
      arTitle: 'أدخل اسم الفرع بالعربية',
      branchImage: 'ارفع صورة الفرع',
      phone: 'ادخل ؤقم الهاتف',
      location: 'اختر موقع الفرع',
      ServicedGender: 'اختر نوع الفئة المستهدفة',
      paymenMethods: 'اختر طرق الدفع',
      addName: 'ادخل اسم المنطقة باللغة الانجليزية',
      addNameAr: 'ادخل اسم المنطقة باللغة العربية',
      searchForLocations: 'ابحث عن الموقع',
      from: 'من',
      to: 'الي',
      atHome: 'في مكان العميل',
      atBranch: 'مكان الفرع',
      supportedRegions: 'المناطق المدعومة',
      selectFromMap: 'اختر من الخريطة',
      contryNameEn: 'أدخل / اختر البلد بالإنجليزية',
      contryNameAr: 'أدخل / اختر أسم البلد باللغه العربيه',
      city: 'أدخل / اختر المدينة',
      region: 'أدخل / اختر منطقة',
      streetNumber: 'أدخل رقم الشارع',
      streetName: 'أدخل اسم الشارع',
      buildingNumber: 'أدخل رقم المبني',
      postalNumber: 'أدخل رقم البريد',
      secondaryNumber: 'أدخل رقم الثانوي',
      landmark: 'اقرب علامة مميزة',
    },
    specialtiesAndServices: {
      onlyNumbersAreAllowed: 'الرجاء ادخال ارقام فقط',
      fromIsRequired: 'من مطلوب',
      currencyIsRequired: 'العملة مطلوبة',
      durationIsRequired: 'المدة مطلوبة',
      feesRange: 'نطاق الأجر',
      from: 'من',
      to: 'الي',
      employeeName: 'اسم الموظف',
      employeeFirstNameEn: 'الاسم الأول للموظف  باللغة الانجليزية',
      employeeLastNameEn: 'الاسم الأخير للموظف  باللغة الانجليزية',
      employeeFirstNameAr: 'الاسم الأول للموظف  باللغة العربية',
      employeeLastNameAr: 'الاسم الأخير للموظف  باللغة العربية',
      branchName: 'اسم الفرع',
      currency: 'العملة',
      duration: 'المدة',
      minutes: 'دقائق',
      description: 'الوصف',
      location: 'الموقع',
      first_name: 'اسم الموظف الأول باللغة الانجليزية',
      last_name: 'اسم الموظف الأخير باللغة الانجليزية',
      first_name_ar: 'اسم الموظف الأول باللغة العربية',
      last_name_ar: 'اسم الموظف الأخير باللغة العربية',
      assignServices: 'تخصيص الخدمات',
      publishRequest: 'طلب النشر',
      publishedRequest: 'تم ارسال طلب النشر',
      deleteSpecialty: 'حذف التخصص',
      deleteSpecialtyMsg: 'هل انت متأكد من حذف التخصص ؟',
      deleteService: 'حذف الخدمة',
      deleteServiceMsg: 'هل انت متأكد من حذف الخدمة ؟',
      note:'لاحظ أن',
      deleteServiceNote:'سوف يتم تعطيل الفرع إذا كانت آخر خدمة موجودة.',
      deletSpecialityNote:'سوف يتم تعطيل الفرع إذا كانت آخر تخصص موجود.',
      deactivateService: 'إيقاف الخدمة',
      deactivateServiceMsg:'يمكن أن تجد حجز قادم يحتوي على هذه الخدمة. ',
      deactivateServiceNote:'سوف يتم إيفاف هذا الفرع إذا كانت هذه آخر خدمة موجوة به.',
    },
    validationPopupTitle:'موافقة على التعديل',
    validationPopupContent:' هذا التعديل قد يسبب تضارب مع الحجوزات القادمة، هل تريد المتابعة؟',
    workingHoursHint: 'قد يتأثر ساعات عمل الموظفون  إذا تغيرت ساعات عمل الفروع ',
    locationHint:'قد يتأثر مواقع الموظفون إذا تغيرت مواقع الفروع المتاحة'
  },
  list: {
    search: 'ابحث عن اسم الفرع او الموقع',
    noDataDescription: 'ابدأ بإضافة فرع جديد',
    filter: {
      filtersPlaceHolders: {
        filterByRating: 'التقييم',
        filterByLocations: 'المواقع',
        filterByServices: 'الخدمات',
        filterByServiceProvider: 'مقدم الخدمة',
        AtHome: 'في مكان العميل',
        AtBranch: 'في الفرع',
        both: 'في الفرع و المنزل',
      },
    },
    atHomeService: 'خدمة في مكان العميل',
    suspendedByDeactivateBranch: 'سيتم ايقاف الفرع بداية من',
  },
  viewBranch: {
    title: 'تفاصيل الفرع',
    firstTab: 'المعلومات الاساسية',
    secondTab: 'الخدمات و التخصصات',
    thirdTab: 'الموظفين',
    fourthTab: 'الحجوزات',
    fifthTab: 'التقييمات',
    rejectionFlag: 'طلب النشر تم رفضه',
    seeMore: 'مشاهدة المزيد',
    rejectionReason: 'سبب الرفض',
    sendPublishRequest: 'إرسال طلب نشر',
    requestPublishing:'قام بطلب نشر',
    published:'نُشر',
    labels: {
      enTitle: 'الاسم الفرع بالانجليزية',
      arTitle: 'الاسم الفرع بالعربية',
      phone: 'رقم الهاتف',
      category: 'التصنيف',
      serviceProvider: 'مقدم الخدمة',
      rating: 'التقييم',
      noRating: 'لا يوجد تقييم',
      noCategory: 'لا يوجد تصنيف',
      noProvider: 'لا يوجد مقدم خدمة',
      workingHours: 'ساعات العمل',
      location: 'الموقع',
      home: 'في مكان العميل',
      branch: 'موقع الفرع',
      supportedRegions: 'المناطق المدعومة',
      viewOnMap: 'عرض على الخريطة',
      none: 'لا يوجد',
      servicedGender: 'الفئة المستهدفة',
      paymenMethods: 'طرق الدفع',
    },
    branchReviews: {
      customerName: 'اسم العميل',
      appointmentId: 'رقم الحجز',
      reviewsContent: 'محتوى التقييم',
      submittedBranchRating: 'تقييم الفرع',
      submittedEmployeeRating: 'تقييم الموظفين',
      noSubmittedEmployeeRating: 'لا يوجد تقييم للموظف',
      actions: 'الاجراءات',
      deleteReview: 'حذف التقييم',
      deleteReviewMsg: 'هل انت متأكد من حذف التقييم ؟',
    },
  },
  deleteBranch: {
    title: 'حذف الفرع',
    titleSpecialty: 'حذف التخصص',
    titleService: 'حذف الخدمة',
    titleEmployee: 'حذف الموظف',
    deleteConfirmationMsg: 'هل انت متأكد من حذف هذا الفرع؟',
    deleteSpecialtyConfirmationMsg: 'هل انت متأكد من حذف هذا التخصص؟',
    deleteServiceConfirmationMsg: 'هل انت متأكد من حذف هذه الخدمة؟',
    deleteEmployeeConfirmationMsg: 'هل انت متأكد من حذف هذا الموظف؟',
  },
};

export default branches;
