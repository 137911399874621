import { v4 as uuid } from 'uuid';
import { browserName } from 'react-device-detect';

export const isAuth = () => {
  return !!localStorage.getItem('token') || !!sessionStorage.getItem('token');
};

export const generateDeviceId = () => {
  return `${browserName}-${uuid()}`;
};

export const setDeviceId = (device_id) => {
  return localStorage.setItem('device_id', device_id);
};

export const getDeviceId = () => {
  return localStorage.getItem('device_id');
};

export const setServiceProvidar = (serviceProvider, storage = 'session') => {
  if (storage === 'session') {
    sessionStorage.setItem('serviceProvider', JSON.stringify(serviceProvider));
  } else {
    localStorage.setItem('serviceProvider', JSON.stringify(serviceProvider));
  }
};

export const setServiceProvidarToken = (token, storage = 'session') => {
  if (storage === 'session') {
    sessionStorage.setItem('token', JSON.stringify(token));
  } else {
    localStorage.setItem('token', JSON.stringify(token));
  }
};

export const clearserviceProviderData = () => {
  localStorage.removeItem('device_id');
  localStorage.removeItem('serviceProvider');
  localStorage.removeItem('token');
  sessionStorage.removeItem('serviceProvider');
  sessionStorage.removeItem('serviceProvider');
  sessionStorage.removeItem('token');
};
