import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";
import { TableCell, TableRow } from '@mui/material';
import { ReportProblemOutlined } from '@mui/icons-material'

import messages from '../../../../assets/locale/messages';
import { renderTextEllipsis } from "../../../../utils/Helpers";
import { ViewBgIcon } from '../../../../utils/Icons';
import { ROUTE_PATHS } from '../../../../utils/RoutesPaths';
import { getBookingsRequest } from '../../../../store/Bookings/actions'
import EmptyState from '../../../../components/EmptyState';
import Table from '../../../../components/Table';
import Pagination from '../../../../components/Pagination';
import './ViewBookingsList.scss'

const ViewBookingsDetails = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loader);
  const lang = useSelector((state) => state.locale.lang);
  const bookingsList = useSelector((state) => state.bookings.list);
  const meta = useSelector((state) => state.bookings.meta);
  const { bookings, shared } = messages[lang];
  const [page, setPage] = useState(1);
  const { id:branchId } = useParams();

  useEffect(()=>{
    getBookingsList(
      page
    );
  },[page]);

  const getBookingsList = (
    page 
  ) => {
    dispatch(
      getBookingsRequest({
        branch_id:branchId,
        page,
        items:10
      })
    );
  }

  const handleViewbooking = (bookingId) => {
    history.push(ROUTE_PATHS.viewBooking.replace(':id', bookingId));
  }

  const renderBookingsList = () => {
    if(!isLoading &&
       bookingsList?.length === 0
      ){
        return(
          <div className="bg-white rounded-4 categoriess-page d-flex justify-content-center align-items-center flex-column">
            <EmptyState
              subTitle={bookings.list.noData}
              showActionButton={false}
              NoDataListWithNoActionBtn={true}
            />
          </div>
        )
      }else{
        return(
          <div className="branch-bookings-page">
          <div className="bg-white rounded-4 my-3 bookings-table-list">
            <Table
              headlines={[
              shared.lists.appointmentId,
              shared.lists.customerName,
              shared.lists.date,
              shared.lists.status
              ]}
              hasActions={true}
              rows={bookingsList?.map((row, i) => (
                <TableRow key={i}>
                  {/* apointment Id */}
                  <TableCell className="font-regular text-dark-blue fsize-14 ">
                    {row.appointment_number}
                  </TableCell>
                  {/* customer name */}
                  <TableCell className="font-regular text-dark-blue fsize-14 ">
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      <p className="m-0">
                        {renderTextEllipsis(row.customer.name) }
                      </p>
                    </div>
                  </TableCell>
                  {/* date */}
                  <TableCell className="font-regular text-dark-blue fsize-14 ">
                    {renderTextEllipsis(row.date)}
                  </TableCell>
                  {/* status */}
                  <TableCell className="font-regular text-dark-blue fsize-14 status-container">
                    <div className={`p-2 rounded-4 d-flex gap-1 justify-content-center ${row.status}-container`}>
                          <div>
                            {bookings.status[row.status]}
                          </div>
                          {(row.status==='ongoing' && (row.customer_did_not_show_up || row.employee_did_not_show_up)) &&(
                            <div><ReportProblemOutlined/></div>
                          )}
                    </div>
                  </TableCell>
                  <TableCell className="font-regular text-dark-blue fsize-14 ">
                    <div className="table-icons-container gap-4">
                      <ViewBgIcon
                        className="pointer"
                        onClick={() => handleViewbooking(row.id)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            />
            <div className="d-flex justify-content-center py-3">
              {meta?.pages > 1 && (
                <Pagination
                  count={meta?.pages}
                  page={meta?.currentPage || page}
                  handleChange={(event, page) => {
                    setPage(page);
                  }}
                  defaultPage={1}
                />
              )}
            </div>
          </div>
          </div>
        )
      }  
    };

  return(
  <>
    {renderBookingsList()}
  </>);
};

export default ViewBookingsDetails;
