import React from "react";

export const EmployeesTabIcon = () => (
  <svg
    width="97"
    height="97"
    viewBox="0 0 97 97"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="48.5" cy="48.5" r="48.5" fill="white" />
    <g clipPath="url(#clip0_1_86)">
      <path
        d="M66.8062 58.4977C64.2462 58.4977 61.8831 59.0837 59.7169 60.2558L61.4892 62.6C63.0646 61.8186 64.8369 61.4279 66.6092 61.4279C72.32 61.4279 77.0462 65.1395 77.0462 69.6326C77.0462 70.414 77.6369 71.1954 78.6216 71.1954C79.4092 71.1954 80.1969 70.6093 80.1969 69.6326C80 63.3814 74.0923 58.4977 66.8062 58.4977ZM72.7139 43.2605C71.1385 41.5023 68.9723 40.7209 66.8062 40.7209C64.64 40.7209 62.4739 41.5023 60.8985 43.2605C59.3231 44.8233 58.5354 46.9721 58.5354 49.1209C58.5354 51.2698 59.3231 53.4186 60.8985 54.9814C62.4739 56.5442 64.64 57.5209 66.8062 57.5209C68.9723 57.5209 71.1385 56.5442 72.7139 54.9814C74.2892 53.4186 75.0769 51.2698 75.0769 49.1209C75.0769 46.7767 74.2892 44.6279 72.7139 43.2605ZM70.5477 52.8326C68.5785 54.786 65.0339 54.786 62.8677 52.8326C61.8831 51.8558 61.2923 50.4884 61.2923 49.1209C61.2923 47.7535 61.8831 46.386 62.8677 45.214C63.8523 44.2372 65.2308 43.6512 66.6092 43.6512C67.9877 43.6512 69.3662 44.2372 70.3508 45.214C71.3354 46.1907 71.9262 47.5581 71.9262 49.1209C72.1231 50.4884 71.5323 51.8558 70.5477 52.8326Z"
        fill="#888D94"
      />
      <path
        d="M36.2831 60.0605C35.6923 59.6698 35.1015 59.4744 34.5108 59.2791C32.9354 58.693 31.1631 58.3023 29.3908 58.3023C21.9077 58.4977 16 63.3814 16 69.6326C16 70.414 16.5908 71.1954 17.5754 71.1954C18.3631 71.1954 19.1508 70.6093 19.1508 69.6326C19.1508 65.1395 23.8769 61.4279 29.5877 61.4279C30.5723 61.4279 31.7538 61.6233 32.7385 61.8186C33.3292 62.014 34.1169 62.2093 34.7077 62.4047C34.3138 62.2093 35.1015 62.6 34.7077 62.4047C35.2985 61.6233 36.2123 60.5863 37 60.0002C36.2123 59.8049 36.6769 60.2558 36.2831 60.0605ZM35.2985 43.2605C33.7231 41.5023 31.5569 40.7209 29.3908 40.7209C27.2246 40.7209 25.0585 41.5023 23.4831 43.2605C21.9077 44.6279 20.9231 46.7767 20.9231 49.1209C20.9231 51.2698 21.7108 53.4186 23.2862 54.9814C24.8615 56.5442 27.0277 57.5209 29.1938 57.5209C31.36 57.5209 33.5262 56.5442 35.1015 54.9814C36.6769 53.4186 37.4646 51.2698 37.4646 49.1209C37.6615 46.7767 36.8738 44.6279 35.2985 43.2605ZM33.1323 52.8326C31.1631 54.786 27.6185 54.786 25.4523 52.8326C24.4677 51.8558 23.8769 50.4884 23.8769 49.1209C23.8769 47.7535 24.4677 46.386 25.4523 45.214C26.4369 44.2372 27.8154 43.6512 29.3908 43.6512C30.7692 43.6512 32.1477 44.2372 33.1323 45.214C34.1169 46.1907 34.7077 47.5581 34.7077 49.1209C34.7077 50.4884 34.1169 51.8558 33.1323 52.8326Z"
        fill="#888D94"
      />
      <path
        d="M48.0985 54.3953C42.7815 54.3953 37.8585 56.3488 34.5108 59.2791C33.7231 60.0605 32.9354 60.8419 32.3446 61.6233C30.7692 63.9674 29.7846 66.507 29.7846 69.4372C29.7846 70.2186 30.3754 71 31.36 71C32.1477 71 32.9354 70.414 32.9354 69.4372C32.9354 68.4605 33.1323 67.4837 33.3292 66.507C33.7231 65.1395 34.5108 63.7721 35.2985 62.7953C35.8892 62.014 36.6769 61.2326 37.4646 60.6465C38.0554 60.2558 38.4492 59.8651 39.04 59.6698C41.6 58.107 44.7508 57.3256 48.0985 57.3256C56.5661 57.3256 63.4585 62.7953 63.4585 69.6326C63.4585 70.4139 64.0492 71.1953 65.0338 71.1953C65.8215 71.1953 66.6092 70.6093 66.6092 69.6326C66.2154 61.2326 58.1415 54.3953 48.0985 54.3953ZM55.9754 32.3209C53.8092 30.1721 51.0523 29 48.0985 29C45.1446 29 42.3877 30.1721 40.2215 32.3209C38.0554 34.4698 36.8738 37.2047 36.8738 40.3302C36.8738 43.2605 38.0554 46.1907 40.2215 48.3395C42.3877 50.4884 45.1446 51.6605 48.0985 51.6605C51.0523 51.6605 54.0061 50.4884 55.9754 48.3395C58.1415 45.9953 59.3231 43.2605 59.3231 40.3302C59.3231 37.2047 58.1415 34.4698 55.9754 32.3209ZM53.8092 46.1907C52.2338 47.7535 50.2646 48.5349 47.9015 48.5349C45.7354 48.5349 43.5692 47.7535 41.9938 46.1907C40.4185 44.6279 39.6308 42.4791 39.6308 40.3302C39.6308 38.1814 40.4185 36.0326 41.9938 34.4698C43.5692 32.907 45.5385 32.1256 47.9015 32.1256C50.0677 32.1256 52.2338 32.907 53.8092 34.4698C55.3846 36.0326 56.1723 38.1814 56.1723 40.3302C56.3692 42.4791 55.3846 44.4326 53.8092 46.1907Z"
        fill="#888D94"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_86">
        <rect
          width="64"
          height="42"
          fill="white"
          transform="translate(16 29)"
        />
      </clipPath>
    </defs>
  </svg>
);
