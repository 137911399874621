import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { TableCell, TableRow, Typography } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {  ContentCopy, Check, FileDownload } from '@mui/icons-material';

import { getPaymentTransactionRequest, getExportTransactionsRequest } from '../../store/PaymentTransactions/actions'
import { setPageMainTitle, textContainOnlySpaces, renderTextEllipsis } from "../../utils/Helpers";
import { transactionsStatusList } from '../../utils/Constants';
import { ROUTE_PATHS } from '../../utils/RoutesPaths';
import messages from '../../assets/locale/messages';
import SearchInput from '../../components/SearchInput';
import FilterLabel from '../../components/Filter/FilterLabel';
import FilterInput from '../../components/Filter/FilterInput';
import FilterDatePicker from '../../components/Filter/FilterDatePicker';
import EmptyState from '../../components/EmptyState';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Modal from '../../components/Modal'
import Button from '../../components/Button';
import './PaymentTransactions.scss'

const PaymentTransactions = () => {

    const dispatch =  useDispatch();
    const history = useHistory();
    const isLoading = useSelector((state) => state.loader);
    const lang = useSelector((state) => state.locale.lang);
    const transactionsList = useSelector((state)=>state.paymentTransactions.list)
    const meta = useSelector((state) => state.paymentTransactions.meta);
    const { shared, pagesTitles, paymentTransactions } = messages[lang];
    const [search, setSearch] = useState('');
    const [page ,setPage] = useState(1);
    const [transactionTypeFilterValue, setTransationTypeFilterValue] = useState(null);
    const [fromDateFilterValue, setFromDateFilterValue] = useState(null);
    const [toDateFilterValue, setToDateFilterValue] = useState(null);
    const [showSettlementsEquation, setShowSettlementsEquation] = useState(false);
    const [showTransactionId, setShowTransactionId] = useState(false);
    const [transactionId, setTransactionId] = useState(null)
    const [showtransactionIdCopy, setShowtransactionIdCopy] = useState(false);
    const transactionsTypeOptions = [
        {
          id: transactionsStatusList.cash,
          label: paymentTransactions.list.transactionsTypes.cash,
          value: 'cash'
        },
        {
          id: transactionsStatusList.online,
          label: paymentTransactions.list.transactionsTypes.online,
          value: 'online'
        },
        {
          id: transactionsStatusList.resolvedByAdmin,
          label: paymentTransactions.list.transactionsTypes.admin_resolution,
          value: 'admin_resolution'
        }
      ];
    
    useEffect(() => {
        setPageMainTitle("paymentTransactionsManagement");
    }, []);
    
    useEffect(()=>{
        setPage(1)
        getTransactionsList(
            1,
            search,
            transactionTypeFilterValue?.value,
            fromDateFilterValue,
            toDateFilterValue,
        )   
    },[ transactionTypeFilterValue, fromDateFilterValue, toDateFilterValue ]);

    useEffect(()=>{
        if ((search.length >= 3 || search.length === 0) && !textContainOnlySpaces(search)){
          setPage(1);
          getTransactionsList(
            1,
            search,
            transactionTypeFilterValue?.value,
            fromDateFilterValue,
            toDateFilterValue,
        )  
        }  
      },[search]);

    useEffect(()=>{
        getTransactionsList(
            page,
            search,
            transactionTypeFilterValue?.value,
            fromDateFilterValue,
            toDateFilterValue,
        )   
    },[ page ]);

    useEffect(() => {
        if(transactionTypeFilterValue){
          setTransationTypeFilterValue(transactionsTypeOptions.find((option) => option.id === transactionTypeFilterValue.id))
        }
    },[ lang ]);

    useEffect(() => {
      if (showtransactionIdCopy) {
        setTimeout(() => {
          setShowtransactionIdCopy(false);
        }, 3000);
      }
    }, [showtransactionIdCopy]);

    const getTransactionsList = (
        page,
        search,
        status,
        fromDate,
        toDate,
    ) => {
        dispatch(getPaymentTransactionRequest(
            {
                page: page,
                query: search,
                transaction_type: status,
                after_date: fromDate,
                before_date: toDate,
                items:10,
            }
        ))
    };

    const renderTransactionId = (row) => (
      <div 
        className="pointer"
        onClick={()=>{
          setTransactionId(row.transaction_id)
          setShowTransactionId(true)
        }}
      >
          <ErrorOutlineOutlinedIcon color="secondary" />
      </div>
    );

    const handleCloseTransactionIdPop = () => {
      setShowTransactionId(false)
      setTransactionId(null)
    };

    const copy = () => {
      navigator.clipboard.writeText(transactionId).then(() => setShowtransactionIdCopy(true));
    };

    const handleExportTransactions = () => {
      dispatch(getExportTransactionsRequest({
        query:search,
        before_date: toDateFilterValue,
        after_date: fromDateFilterValue,
        transaction_type: transactionTypeFilterValue?.value,
        page:-1
      }))
    };

    const renderTransactionIdContent = () => (
      <div className='d-flex justify-content-between'>
        <div className="fsize-16 fweight-400">
          {paymentTransactions.list.transationId}: 
          <span className="fweight-500"> {transactionId}</span>
        </div>
        <Button
                label={
                  <div className="d-flex">
                    {showtransactionIdCopy ? <Check /> : <ContentCopy />}
                    <span className="mx-1">{showtransactionIdCopy ? shared.copied : shared.copy}</span>
                  </div>
                }
                outlined
                color="info"
                className="fsize-14"
                onClick={() => copy()}
              />
      </div>
      
  );

    const renderHeaderSection = (showActions) => (
        <>
          <div className="header-section mb-4">
          <div className=" d-flex justify-content-between flex-wrap w-100">
            <div className="title-search d-flex flex-wrap">
              <Typography
                variant="h4"
                sx={{ flexGrow: 1 }}
                className={`page-title flex-grow-0 ${lang === 'en' ? 'me-3' : 'ms-3'}`}
              >
                  {pagesTitles.paymentTransactions}
              </Typography>
              {showActions && (
                <div className="search-label-wrapper">
                  <SearchInput
                  inputClass="w-100"
                  placeholder={paymentTransactions.list.search}
                  name="search"
                  value={search}
                  onChange={(value) => {
                    setSearch(value);
                  }}
                />
                </div>
              )}
            </div>
            <Button
                label={
                  <div className="d-flex gap-1">
                    <FileDownload />
                    <span>{paymentTransactions.list.exportTransactions}</span>
                  </div>
                }
                outlined
                color="info"
                className="fsize-14 align-self-center"
                onClick={()=>{handleExportTransactions()}}
              />
            </div>
          </div>
          {showActions && (
            <>
              <div className="d-flex gap-1 align-items-start">
                <div className="mt-2">
                  <FilterLabel />
                </div>
                <div className="d-flex flex-column flex-grow-1">
                  <div className="filter-label-wrapper d-flex flex-column flex-lg-row align-items-center mb-4 gap-2 flex-wrap flex-md-nowrap">
                    {/* filterByFromDate */}
                    <FilterDatePicker
                      name="from date filter"
                      placeholder={paymentTransactions.list.filters.fromDatePlaceholder}
                      value={fromDateFilterValue}
                      label={paymentTransactions.list.filters.fromDateLabel}
                      onChange={(value) => {
                        setFromDateFilterValue(value);
                      }}
                      wrapperClass="my-0 w-100"
                      inputClass="w-100"
                    />
                    {/* filterByToDate */}
                    <FilterDatePicker
                      name="to date filter"
                      placeholder={paymentTransactions.list.filters.toDatePlaceholder}
                      value={toDateFilterValue}
                      label={paymentTransactions.list.filters.toDateLabel}
                      onChange={(value) => {
                        setToDateFilterValue(value);
                      }}
                      wrapperClass="my-0 w-100"
                      inputClass="w-100"
                    />
                    {/* transactions status */}
                    <FilterInput
                      placeholder={paymentTransactions.list.filters.transactionsStatusPlaceholder}
                      options={transactionsTypeOptions}
                      value={transactionTypeFilterValue}
                      name="branch status Filter"
                      onChange={(value) => {
                        setTransationTypeFilterValue(value);
                      }}
                      filterWrapperClass="my-0 w-100"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );

    const renderTransactionsList = () => {
        if(!isLoading &&
            transactionsList?.length === 0 &&
           !search &&
           fromDateFilterValue === null &&
           toDateFilterValue === null &&
           transactionTypeFilterValue === null       
          ){
            return (
              <>
                {renderHeaderSection(false)}
                <div className="bg-white rounded-4 categoriess-page d-flex justify-content-center align-items-center flex-column">
                  <EmptyState
                    subTitle={shared.noData}
                    showActionButton={false}
                    NoDataListWithNoActionBtn={true}
                  />
                </div>
              </>
            );
          } else if (
            !isLoading &&
            (search ||
            fromDateFilterValue != null ||
            toDateFilterValue != null ||
            transactionTypeFilterValue != null) &&
            transactionsList?.length === 0
          ){
            return(
              <>
                {renderHeaderSection(true)}
                <div className="bg-white rounded-4 categoriess-page d-flex justify-content-center align-items-center flex-column  mt-3">
                  <EmptyState subTitle={shared.noSearch} showActionButton={false}/>
                </div>
              </>
            );
          } else {
            return(
              <>    
                <div className="transactions-page">
                  {renderHeaderSection(true)}
                  <div className="bg-white rounded-4 my-3 transactions-table-list">
                    <div className={`${showSettlementsEquation 
                                            ? `table-with-equ`
                                            : `table-without-equ` }`}
                    >
                      <Table
                        headlines={[
                            shared.lists.appointmentId,
                            shared.lists.branchName,
                            shared.lists.date,
                            shared.lists.transactionType,
                            shared.lists.paidAmount,
                        ]}
                        rows={transactionsList?.map((row, i) => (
                          <TableRow key={i}>
                            {/* apointment Id */}
                            <TableCell className="font-regular text-dark-blue fsize-14">
                              <div
                                className="pointer appoinment-id-contanier"
                                onClick={() => {history.push(ROUTE_PATHS.viewBooking.replace(':id', row.appointment.id))}}
                              >
                                {row.appointment.appointment_number}
                              </div>
                            </TableCell>
                            {/* branchName */}
                            <TableCell className="font-regular text-dark-blue fsize-14">
                              {lang === 'en'
                                ? renderTextEllipsis(row.branch_name_en) 
                                : renderTextEllipsis(row.branch_name_ar) 
                              }
                            </TableCell>
                            {/* date */}
                            <TableCell className="font-regular text-dark-blue fsize-14">
                              {row.date}
                            </TableCell>
                            {/* Transactions Type */}
                            <TableCell className="font-regular text-dark-blue fsize-14">
                              <div className="d-flex justify-content-center gap-1">
                                {paymentTransactions.list.transactionsTypes[row.transaction_type]}
                                {row.transaction_type === 'online' && renderTransactionId(row)}
                              </div>
                            </TableCell>
                            {/* Paid Amount */}
                            <TableCell className="font-regular text-dark-blue fsize-14">
                              {row.paid_amount}
                            </TableCell>
                          </TableRow>
                        ))}
                      />
                    </div>
                    <div className="d-flex justify-content-center py-3">
                      {meta?.pages > 1 && (
                        <Pagination
                          count={meta?.pages}
                          page={meta?.currentPage || page}
                          handleChange={(event, page) => {
                            setPage(page);
                          }}
                          defaultPage={1}
                        />
                      )} 
                    </div>
                  </div>
                </div>
                <Modal
                open={showTransactionId}
                handleClose={handleCloseTransactionIdPop}
                title={<span className="fsize-24 fweight-400">{paymentTransactions.list.transactionDetails}</span>}
                contentClass="mb-5 pb-2 text-primary"
                content={renderTransactionIdContent()}
                cancelBtnTxt={shared.cancel}
                isContentText={false}
                handleCancel={handleCloseTransactionIdPop}
                maxWidth="sm"
                actionsClass=" px-4"
                cancelBtnClass="fsize-14 py-2 text-secondary px-5 "
            />                 
              </>
            );
          }
    };

    return (renderTransactionsList());
}

export default PaymentTransactions;