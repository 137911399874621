const changePassword = {
  title: "تغيير",
  subTitle: "كلمة المرور",
  labels: {
    oldPassword: "كلمة المرور الحالية",
    newPassword: "كلمة المرور الجديدة",
    confirmNewPassword: "تأكيد كلمة المرور الجديدة",
  },
  placeholders: {
    oldPassword: "أدخل كلمة المرور الحالية",
    newPassword: "أدخل كلمة المرور الجديدة",
    confirmNewPassword: "تأكيد كلمة المرور الجديدة",
  },
  actionBTN: "حفظ",
};

export default changePassword;
