import {
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_RESPONSE,
  EDIT_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_DETAILS_REQUEST,
  GET_EMPLOYEE_DETAILS_RESPONSE,
  DELETE_EMPLOYEE_REQUEST,
  GET_BRANCH_DETAILS_BY_EMP_ID_REQUEST,
  GET_BRANCH_DETAILS_BY_EMP_ID_RESPONSE,
  GET_BRANCH_SERVICES_BY_BRANCH_ID_REQUEST,
  GET_BRANCH_SERVICES_BY_BRANCH_ID_RESPONSE,
  GET_EDIT_EMPLOYEE_STATUS_REQUEST_STATUS
} from './types';

// list
export const getEmployeesRequest = (payload) => ({
  type: GET_EMPLOYEES_REQUEST,
  payload,
});

export const getEmployeesResponse = (payload) => ({
  type: GET_EMPLOYEES_RESPONSE,
  payload,
});

// edit
export const editEmployeeRequest = (payload) => ({
  type: EDIT_EMPLOYEE_REQUEST,
  payload,
});

// get by id (view)
export const getEmployeeDetailsRequest = (payload) => ({
  type: GET_EMPLOYEE_DETAILS_REQUEST,
  payload,
});

export const getEmployeeDetailsResponse = (payload) => ({
  type: GET_EMPLOYEE_DETAILS_RESPONSE,
  payload,
});

export const deleteEmployeeRequest = (payload) => ({
  type: DELETE_EMPLOYEE_REQUEST,
  payload,
});

// HELPERS

// Usage: to get branch details by employee id
export const getBranchDetailsByEmpIdRequest = (payload) => ({
  type: GET_BRANCH_DETAILS_BY_EMP_ID_REQUEST,
  payload,
});

export const getBranchDetailsByEmpIdResponse = (payload) => ({
  type: GET_BRANCH_DETAILS_BY_EMP_ID_RESPONSE,
  payload,
});

// Usage: to get branch services by branch id after came from employee id
export const getBranchservicesByBranchIdRequest = (payload) => ({
  type: GET_BRANCH_SERVICES_BY_BRANCH_ID_REQUEST,
  payload,
});

export const getBranchservicesByBranchIdResponse = (payload) => ({
  type: GET_BRANCH_SERVICES_BY_BRANCH_ID_RESPONSE,
  payload,
});

export const getEditEmployeeStatusRequestStatus = (payload) => ({
  type: GET_EDIT_EMPLOYEE_STATUS_REQUEST_STATUS,
  payload,
});
