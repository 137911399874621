import React from "react";
import { useSelector } from "react-redux";
import { Tune } from "@mui/icons-material";

import messages from "../../../assets/locale/messages";

const FilterLabel = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];
  return (
    <div
      className={`filter-label d-flex align-items-center ${
        lang === "en" ? "me-3" : "ms-3"
      }`}
    >
      <Tune
        className={`text-gray fsize-26 font-semibold ${
          lang === "en" ? "me-2" : "ms-2"
        }`}
      />
      <span className="text-gray fsize-20 font-semibold">
        {shared.filter}
      </span>
    </div>
  );
};

export default FilterLabel;
