import { axiosInstance } from ".";

const getEmployeesList = async (params) =>
  await axiosInstance.get("/employees", { params });

const addEmployee = async (payload) =>
  await axiosInstance.post("/employees", payload);

const getEmployeeDetails = async ({ id }) =>
  await axiosInstance.get(`/employees/${id}`);

const editEmployee = async ({ id, data }) =>
  await axiosInstance.put(`/employees/${id}`, data);

const deleteEmployee = async ({ id }) =>
  await axiosInstance.delete(`/employees/${id}`);

export {
  getEmployeesList,
  addEmployee,
  getEmployeeDetails,
  editEmployee,
  deleteEmployee,
};
