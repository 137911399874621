import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  AccessTimeOutlined,
  CreditCardOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ReceiptLongOutlined,
} from '@mui/icons-material';

import messages from '../../../../assets/locale/messages';
import { getCurranciesLookUpRequest } from '../../../../store/LookUps/actions';
import {
  deleteBranchServiceRequest,
  deleteBranchSpecialtyRequest,
  editBranchRequest,
  editBranchServicesRequest,
  getBranchRequest,
  getBranchResponse,
  getBranchServicesRequest,
  getRequestStatus,
  getServicesRequest,
  getServicesResponse,
  getSpecialtiesRequest,
  getSpecialtiesResponse,
  getPublishBranchServiceRequestStatus,
  getDeleteBranchSpecialityStatusRequest,
  getDeleteBranchServiceStatusRequest,
} from '../../../../store/Branches/actions';
import { getGoogleMapsPolygon } from '../../../../store/GoogleMapCoords/actions';
import Modal from '../../../../components/Modal';
import Input from '../../../../components/Input';
import Switch from '../../../../components/Switch';
import Select from '../../../../components/Select';
import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Checkbox';
import Accordion from '../../../../components/Accordion';
import EmptyState from '../../../../components/EmptyState';
import { EditBgIcon } from '../../../../utils/Icons/EditBg';
import { ONE_NUMBERS_ONLY, TWO_DICIMAL_ONLY } from '../../../../utils/Patterns';
import { ROUTE_PATHS } from '../../../../utils/RoutesPaths';
import { DeleteBgIcon } from '../../../../utils/Icons/DeleteBg';
import './AddEditServicesDetails.scss';

const AddEditServicesDetails = ({
  changeSubTitle,
  renderList,
  changeServiceTitle,
  proceedToNextStep,
  btnClicked,
  ressetBtnClicked,
  backToPrevStep,
  parentProps,
  removeMainAddBtn,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { branches, shared } = messages[lang];
  const history = useHistory();
  const { providerid } = useParams();
  const { id } = useParams();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.loader);
  const specialtiesList = useSelector((state) => state.branches.addEditViewBranch.specialties.specialtiesList.list);
  const serviceslist = useSelector((state) => state.branches.addEditViewBranch.services.servicesList.list);
  const currencyOptions = useSelector((state) => state.lookUps.currancies);
  const viewBranchDetails = useSelector((state) => state.branches.addEditViewBranch.branchDetails); // get branch detauls by id original BE data
  const createdBranch = useSelector((state) => state.branches.addEditViewBranch.helpers.addedBranch);
  const branchServicesList = useSelector((state) => state.branches.addEditViewBranch.helpers.branchServices);
  const requestStatus = useSelector((state) => state.branches.addEditViewBranch.requestStatus);
  const publishBranchServiceStatusRequest = useSelector((state) => state.branches.publishBranchServiceStatusRequest);
  const deleteBranchServiceStatusRequest = useSelector((state) => state.branches.deleteBranchServiceStatusRequest);
  const deleteBranchSpecialityStatusRequest = useSelector((state) => state.branches.deleteBranchSpecialityStatusRequest);

  const [mappedServices, setMappedServices] = useState([]);
  const [nativeServices, setNativeServices] = useState(null);
  const [deleteServiceId, setdeleteServiceId] = useState(null);
  const [deleteSpecialtyId, setdeleteSpecialtyId] = useState(null);
  const [choosenSpecialtyId, setChoosenSpecialtyId] = useState(null);
  const [currencyOptionsList, setCurrencyOptionsList] = useState(null);
  const [renderServicesSection, setRenderServicesSection] = useState(false);
  const [showLeaveWarningModal, setShowLeaveWarningModal] = useState(false);
  const [renderEmpatyStateSection, setRenderEmpatyStateSection] = useState(false);
  const [renderAddedServiceSection, setRenderAddedServiceSection] = useState(false);
  const [renderSpecialtiesSection, setRenderSpecialtiesSection] = useState(false);
  const [openDeleteSpecilatyConfirm, setOpenDeleteSepecialtyConfirm] = useState(false);
  const [openDeleteServiceConfirm, setOpenDeleteServiceConfirm] = useState(false);
  const [addedSepacialtyWithServices, setAddedSepacialtyWithServices] = useState(null);
  const [noAssgiendServicesOnSpecialtyAlert, setNoAssgiendServicesOnSpecialtyAlert] = useState(false);
  const [isAddSpecialtiesAndServicesBTNClicked, setIsAddSpecialtiesAndServicesBTNClicked] = useState(false);
  const [serviceTitle, setServiceTitle] = useState(null);
  const [servicesStatus, setServicesStatus] = useState([]);
  const [showDeactivateServicePopup, setShowDeactivateServicePopup] = useState(false);
  const [deactivatedService, setDeactivatedService] = useState({});

  useEffect(() => {
    dispatch(getCurranciesLookUpRequest());
    dispatch(getSpecialtiesResponse([]));
    dispatch(getRequestStatus(null));
    dispatch(getGoogleMapsPolygon([]));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(getSpecialtiesResponse([]));
    };
  }, []);

  useEffect(() => {
    if(publishBranchServiceStatusRequest != null) {
      dispatch(
        getBranchServicesRequest({
          page: -1,
          branch_id: viewBranchDetails?.id,
        })
      );
    }
    dispatch(getPublishBranchServiceRequestStatus(null))
  }, [publishBranchServiceStatusRequest]);

  useEffect(() => {
    if(deleteBranchServiceStatusRequest) {
      dispatch(
        getBranchServicesRequest({
          page: -1,
          branch_id: viewBranchDetails?.id,
        })
      );
      dispatch(
        getBranchRequest(createdBranch.id || parentProps?.location?.state?.branchDataToEdit?.id)
      );
    }
  }, [deleteBranchServiceStatusRequest]);

  useEffect(() => {
    if(deleteBranchSpecialityStatusRequest) {
      dispatch(
        getBranchRequest(createdBranch?.id || parentProps?.location?.state?.branchDataToEdit?.id)
      );
    }
  }, [deleteBranchSpecialityStatusRequest])

  const errorss = [
    {
      errorTitle: 'currencyIsRequired',
      errorMessaged: branches.addBranch.specialtiesAndServices.currencyIsRequired,
    },
    {
      errorTitle: 'onlyNumbersAreAllowed',
      errorMessaged: branches.addBranch.specialtiesAndServices.onlyNumbersAreAllowed,
    },
    {
      errorTitle: 'fromIsRequired',
      errorMessaged: branches.addBranch.specialtiesAndServices.fromIsRequired,
    },
    {
      errorTitle: 'durationIsRequired',
      errorMessaged: branches.addBranch.specialtiesAndServices.durationIsRequired,
    },
  ];

  useEffect(() => {
    if (currencyOptions?.length > 0) {
      let mappedCurrencyOptions = currencyOptions.map((item) => {
        return {
          label: lang === 'en' ? item.name_en : item.name_ar,
          value: item.id,
          id: item.id,
        };
      });
      setCurrencyOptionsList(mappedCurrencyOptions);
    }
  }, [currencyOptions, lang]);

  useEffect(() => {
    if (renderServicesSection && choosenSpecialtyId !== null) {
      getServicesBySpecialtyId(choosenSpecialtyId);
    }
  }, [choosenSpecialtyId, renderServicesSection]);

  useEffect(() => {
    if (renderAddedServiceSection) {
      dispatch(getBranchRequest(createdBranch?.id || parentProps?.location?.state?.branchDataToEdit?.id));
    }
  }, [renderAddedServiceSection]);

  useEffect(() => {
    setTimeout(() => {
      renderServicesContent();
    }, 500);
  }, [viewBranchDetails, choosenSpecialtyId, renderServicesSection, serviceslist]);

  useEffect(() => {
    assignAddedSpecialtyAndServicesFromViewBranchData();
  }, [lang, viewBranchDetails]);

  useEffect(() => {
    checkSetctionToRender();
  }, []);

  // to update accordion
  useEffect(() => {
    // TODO: add branch service list to depandanced array
    updateAccordionData();
  }, [branchServicesList, currencyOptionsList, lang, deleteServiceId, serviceslist, publishBranchServiceStatusRequest]);

  useEffect(() => {
    if (specialtiesList?.length > 0) {
      checkSetctionToRender();
      setIsAddSpecialtiesAndServicesBTNClicked(false);
    } else {
      if (isAddSpecialtiesAndServicesBTNClicked) {
        setRenderSpecialtiesSection(true);
        setRenderEmpatyStateSection(false);
      }
    }
  }, [specialtiesList]);

  useEffect(() => {
    if (addedSepacialtyWithServices?.length > 0 && specialtiesList?.length > 0) {
      setAddedSepacialtyWithServices(
        addedSepacialtyWithServices?.map((specialty) => {
          specialtiesList?.forEach((item) => {
            if (item.title === specialty.specialtyName) {
              specialty.realSpecialtyId = item.id;
            }
          });
          return specialty;
        })
      );
    }
  }, [specialtiesList]);

  useEffect(() => {
    if (renderServicesSection || renderSpecialtiesSection) {
      if (serviceTitle) {
        changeServiceTitle(lang === 'en' ? serviceTitle.name_en : serviceTitle.name_ar);
      }
    }
  }, [lang, renderServicesSection]);

  useEffect(() => {
    if (btnClicked) {
      checkSectionToRenderBtnClicked();
      setTimeout(() => {
        ressetBtnClicked();
      }, 1000);
    }
  }, [btnClicked]);

  useEffect(() => {
    removeMainAddBtn(renderEmpatyStateSection);
  }, [renderEmpatyStateSection]);

  useEffect(() => {
    showAddedServicesAfterSave();
  }, [requestStatus]);

  useEffect(() => {
    if (addedSepacialtyWithServices?.length === 0) {
      removeMainAddBtn(true);
    } else {
      removeMainAddBtn(false);
    }
  }, [addedSepacialtyWithServices]);

  const assignAddedSpecialtyAndServicesFromViewBranchData = () => {
    let mappedarray = [];
    if (viewBranchDetails?.specialties?.length > 0) {
      viewBranchDetails.specialties?.map((item) => {
        let specialty = {
          id: null,
          specialtyName: '',
          isExpanded: false,
          realSpecialtyId: null,
          services: [],
        };
        specialty = {
          ...specialty,
          id: item.id,
          specialtyName: lang === 'en' ? item.specialty_title_en : item.specialty_title_ar,
          realSpecialtyId: item.specialty_id,
        };
        mappedarray.push(specialty);
        return item;
      });
    }
    setAddedSepacialtyWithServices(mappedarray);
  };
  const updateAccordionData = () => {
    if (branchServicesList?.length > 0) {
      const branchServiceStatus = branchServicesList.map((service) => {
        return {
          service_id : service.service_id,
          isPublished: service.status === 'published' ? true : false
        }
      });
      setServicesStatus(branchServiceStatus);
      let newAddedSepacialtyWithServices = addedSepacialtyWithServices?.map((specialty) => {
        let services = branchServicesList?.filter(
          (branchService) => branchService.branch_specialty_id === specialty.id
        );
        if (services?.length > 0) {
          services?.forEach((singleService) => {
            let service = {
              id: singleService.id,
              service_id: singleService.service_id,
              name_en: singleService.title_en,
              name_ar: singleService.title_ar,
              details: {
                feesRange: {
                  from: singleService.fees_from,
                  to: singleService.fees_to,
                  hasErrors: false,
                  errors: [],
                },
                currency: currencyOptionsList?.find(
                  (currency) => currency.id === singleService.currency_id || currency.id === 1
                ),
                duration: singleService.duration_mins,
                description: lang === 'en' ? singleService.description_en : singleService.description_ar,
                hasErrors: false,
                errors: [],
              },
              _destroy: false,
              editMood: false,
              isActive: singleService.is_active,
            };
            if (specialty.id === singleService.branch_specialty_id) {
              if (specialty.services?.length === 0) {
                specialty.services.push(service);
              } else {
                let isServiceExists = specialty.services?.findIndex((item) => item.id === singleService.id);
                if (isServiceExists === -1) {
                  specialty.services.push(service);
                }
              }
            }
          });
        }

        return specialty;
      });
      setAddedSepacialtyWithServices(newAddedSepacialtyWithServices);
    }
  };

  const clearServicesState = () => {
    dispatch(getServicesResponse({ list: [] }));
  };

  const {
    values,
    setFieldValue,
    setValues,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      choosenServices: [],
    },
    validationSchema: Yup.object({
      choosenServices: Yup.array().of(
        Yup.object().shape({
          serviceId: Yup.number(),
          details: Yup.object().shape({
            feesRange: Yup.object().shape({
              from: Yup.number(),
              to: Yup.number(),
            }),
            currency: Yup.object().shape({
              label: Yup.string(),
              value: Yup.number(),
              id: Yup.number(),
            }),
            duration: Yup.number(),
            description: Yup.string(),
          }),
        })
      ),
    }),
    onSubmit: (obj) => {},
  });
  const checkSetctionToRender = () => {
    if (!btnClicked) {
      if (viewBranchDetails) {
        if (viewBranchDetails?.specialties?.length > 0) {
          setRenderSpecialtiesSection(false);
          setRenderServicesSection(false);
          setRenderEmpatyStateSection(false);
          changeSubTitle(null);
          changeSubTitle(null);
          setTimeout(() => {
            setRenderAddedServiceSection(true);
          }, 1000);
        } else {
          // render empty state
          setRenderEmpatyStateSection(true);
          setRenderSpecialtiesSection(false);
          changeSubTitle(null);
          changeServiceTitle(null);
          setRenderServicesSection(false);
          setRenderAddedServiceSection(false);
        }
      } else {
        if (specialtiesList) {
          if (specialtiesList?.length > 0) {
            setRenderSpecialtiesSection(true);
            changeSubTitle('AddSpecialties');
            setRenderServicesSection(false);
            setRenderEmpatyStateSection(false);
            setRenderAddedServiceSection(false);
          } else {
            // // render empty state
            // setRenderEmpatyStateSection(true);
            // setRenderSpecialtiesSection(false);
            // changeSubTitle(null);
            // changeServiceTitle(null);
            // setRenderServicesSection(false);
            // setRenderAddedServiceSection(false);
          }
        } else {
          // render empty state
          const x = () => {};
          setRenderEmpatyStateSection(true);
          setRenderSpecialtiesSection(false);
          changeSubTitle(null);
          changeServiceTitle(null);
          setRenderServicesSection(false);
          setRenderAddedServiceSection(false);
        }
      }
    }
  };

  const checkSectionToRenderBtnClicked = () => {
    if (btnClicked === 'list') {
      if (addedSepacialtyWithServices?.length > 0) {
        setRenderAddedServiceSection(true);
        setRenderEmpatyStateSection(false);
        setRenderServicesSection(false);
        setRenderSpecialtiesSection(false);
        changeSubTitle('AddedServices');
      } else {
        setRenderAddedServiceSection(true);
        setRenderEmpatyStateSection(false);
        setRenderServicesSection(false);
        setRenderSpecialtiesSection(false);
        changeSubTitle(null);
      }
      setIsAddSpecialtiesAndServicesBTNClicked(false);
    } else if (btnClicked === 'listSpecialties') {
      getSpecialties();
      dispatch(
        getBranchServicesRequest({
          page: -1,
          branch_id: viewBranchDetails?.id,
        })
      );
      setRenderAddedServiceSection(false);
      setServiceTitle(null);
      changeServiceTitle(null);
      setRenderEmpatyStateSection(false);
      setRenderServicesSection(false);
      setRenderSpecialtiesSection(true);
      changeSubTitle('AddSpecialties');
      setIsAddSpecialtiesAndServicesBTNClicked(true);
    }
  };

  const renderServicesContent = () => {
    if (serviceslist?.length === 0) {
      setNoAssgiendServicesOnSpecialtyAlert(true);
    } else {
      setNoAssgiendServicesOnSpecialtyAlert(false);
    }
    const servicesListMapped = serviceslist?.map((service, i) => {
      return {
        serviceId: service.id,
        relatedSpecialtyId: service?.specialty?.id,
        name_en: service.title_en,
        name_ar: service.title_ar,
        isChoosen: false,
        details: {
          feesRange: {
            from: '',
            to: '',
            hasErrors: false,
            errors: [],
          },
          currency: null,
          duration: '',
          description: '',
          hasErrors: false,
          errors: [],
        },
      };
    });
    setMappedServices(servicesListMapped);
    if (viewBranchDetails?.specialties?.length > 0) {
      setNativeServices(viewBranchDetails.specialties);
    }
    if (addedSepacialtyWithServices?.length > 0) {
      const newMappedServices = servicesListMapped.map((service) => {
        const targerSpecialty = addedSepacialtyWithServices.find((elt) => {
          return elt.realSpecialtyId === service.relatedSpecialtyId;
        });
        const ServiceFound = targerSpecialty?.services?.find((x) => x.service_id === service.serviceId);
        let serviceCopy = {
          ...service,
          id: service.id,
          service_id: service.service_id,
          isChoosen: false,
          details: {
            feesRange: {
              from: '',
              to: '',
              hasErrors: false,
              errors: [],
            },
            currency: null,
            duration: '',
            description: '',
            hasErrors: false,
            errors: [],
          },
        };
        if (ServiceFound) {
          serviceCopy.isChoosen = true;
          serviceCopy.id = ServiceFound.id;
          serviceCopy.service_id = service.service_id;
          serviceCopy.details = {
            ...ServiceFound.details,
            currency: currencyOptionsList?.find(
              (currency) => currency.id === ServiceFound.currency_id || currency.id === 1
            ),
            duration: ServiceFound.details.duration,
            description: ServiceFound.details.description,
            feesRange: {
              from: ServiceFound.details.feesRange.from,
              to: ServiceFound.details.feesRange.to,
              hasErrors: false,
              errors: [],
            },
          };
        }
        return serviceCopy;
      });
      setFieldValue('choosenServices', newMappedServices);
    } else {
      setFieldValue('choosenServices', [...servicesListMapped]);
    }
  };

  const updateServicesContent = () => {
    setFieldValue('choosenServices', []);
    setMappedServices([]);
    const servicesListMapped = serviceslist?.map((service, i) => {
      return {
        serviceId: service.id,
        name_en: service.title_en,
        name_ar: service.title_ar,
        isChoosen: false,
        details: {
          feesRange: {
            from: '',
            to: '',
            hasErrors: false,
            errors: [],
          },
          currency: null,
          duration: '',
          description: '',
          hasErrors: false,
          errors: [],
        },
      };
    });
    setFieldValue('choosenServices', servicesListMapped);
    setMappedServices(servicesListMapped);
    if (viewBranchDetails?.specialties?.length > 0) {
      setNativeServices(viewBranchDetails.specialties);
      if (addedSepacialtyWithServices?.length > 0) {
        addedSepacialtyWithServices.forEach((addedSepacialty) => {
          if (addedSepacialty.services.length > 0) {
            addedSepacialty.services.forEach((service) => {
              setFieldValue(
                'choosenServices',
                values['choosenServices']?.map((choosenService) => {
                  if (choosenService.name_en === service.name_en) {
                    choosenService.isChoosen = true;
                    choosenService.service_id = service.service_id;
                    choosenService.id = service.id;
                    choosenService.details.feesRange.from = service.details.feesRange.from;
                    choosenService.details.feesRange.to = service.details.feesRange.to;
                    choosenService.details.currency = currencyOptionsList?.find(
                      (currency) => currency.id === service.currency_id || currency.id === 1
                    );
                    choosenService.details.duration = service.details.duration;
                    choosenService.details.description = service.details.description;
                  }
                  return choosenService;
                })
              );
            });
          } else {
            dispatch(
              getBranchServicesRequest({
                page: -1,
                branch_id: viewBranchDetails?.id,
              })
            );
          }
        });
      }
    }
  };

  const handleExpandFuntion = (isExpanded, id) => {
    if (isExpanded) {
      dispatch(
        getBranchServicesRequest({
          page: -1,
          branch_id: viewBranchDetails?.id,
        })
      );
    }
  };

  const getSpecialties = () => {
    dispatch(
      getSpecialtiesRequest({
        page: -1,
        service_provider_id: providerid,
      })
    );
  };

  const getServicesBySpecialtyId = (specialtyId) => {
    dispatch(
      getServicesRequest({
        specialty_id: specialtyId,
      })
    );
  };

  const handleToggleButtonValue  = (serviceId) => {
    const targetService = servicesStatus.find(service => service.service_id === serviceId)
    return targetService?.isPublished
  };

  const handleActivateOrDeactivateService = (service) => {
    dispatch(
      editBranchServicesRequest({
        id: service.id,
        data: {
          status: handleToggleButtonValue(service.service_id) ? 'unpublished' : 'published' 
        },
      })
    );
    setAddedSepacialtyWithServices(
      addedSepacialtyWithServices.map((specialty) => {
        specialty.services?.map((mappedService) => {
          if (mappedService.id === service?.id) {
              service.status = handleToggleButtonValue(service.service_id) ? 'unpublished' : 'published'
          }
          return mappedService;
        });
        return specialty;
      })
    );
  };

  const handleConfirmDecativateService = () => {
    handleActivateOrDeactivateService(deactivatedService);
    handleCloseDeactivatePopup();
  };

  const handleCloseDeactivatePopup = () => {
    setShowDeactivateServicePopup(false);
    setDeactivatedService({});
  }

  const renderEmptyState = () => {
    return (
      !isLoading && (
        <div className="bg-white rounded-4 services-page d-flex justify-content-center align-items-center flex-column m-4 mt-0">
          <EmptyState
            subTitle={shared.noServicesAddedyet}
            ButtonLabel={branches.addBranch.labels.addSepicialtiesAndServices}
            handleBtnAction={() => {
              dispatch(getBranchResponse({}));
              getSpecialties();
              setNoAssgiendServicesOnSpecialtyAlert(false);
              setIsAddSpecialtiesAndServicesBTNClicked(true);
            }}
          />
        </div>
      )
    );
  };

  const renderSpecialties = () => {
    return (
      !isLoading && (
        <>
          {specialtiesList?.length > 0 ? (
            <div>
              {specialtiesList?.map((specialty, index) => {
                return (
                  <div key={specialty.id}>
                    <div
                      className="d-flex justify-content-between align-items-center pointer"
                      onClick={() => {
                        clearServicesState();
                        setChoosenSpecialtyId(specialty.id);
                        setRenderSpecialtiesSection(false);
                        setRenderServicesSection(true);
                        changeSubTitle('AddSpecialties');
                        changeServiceTitle(lang === 'en' ? specialty.name_en : specialty.name_ar);
                        setRenderAddedServiceSection(false);
                      }}>
                      <p className="text-gray fsize-16 mb-0">{lang === 'en' ? specialty.name_en : specialty.name_ar}</p>
                      <div className="arrow-bg">{lang === 'en' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}</div>
                    </div>
                    {index !== specialtiesList?.length - 1 && <div className="line-separator"></div>}
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <div className="bg-white rounded-4 services-page d-flex justify-content-center align-items-center flex-column m-4 mt-0">
                <EmptyState subTitle={shared.noSpecialtiesOnCategoryyet} showActionButton={false} />
              </div>
            </>
          )}
        </>
      )
    );
  };
  const handleCloseShowLeaveWarningModal = () => {
    setShowLeaveWarningModal(false);
  };

  const handleConfirmShowLeaveWarning = () => {
    setServiceTitle(null);
    changeSubTitle(null);
    changeServiceTitle(null);
    setRenderServicesSection(false);
    setRenderAddedServiceSection(false);
    setShowLeaveWarningModal(false);
    setRenderAddedServiceSection(true);
  };

  const showAddedServicesAfterSave = () => {
    if (requestStatus) {
      setRenderSpecialtiesSection(false);
      changeSubTitle(null);
      changeSubTitle(null);
      setRenderEmpatyStateSection(false);
      setRenderServicesSection(false);
      setRenderAddedServiceSection(true);
      if (createdBranch != null && Object.keys(createdBranch).length !== 0) {
        dispatch(getBranchRequest(createdBranch.id));
      }
      if (parentProps?.location?.state?.branchDataToEdit) {
        dispatch(getBranchRequest(parentProps?.location?.state?.branchDataToEdit?.id));
      }
      setFieldValue('choosenServices', []);
      setMappedServices([]);
      setAddedSepacialtyWithServices([]);
      ressetBtnClicked();
      dispatch(getRequestStatus(false));
    }
  };
  const renderServices = () => {
    return (
      !isLoading && (
        <>
          {!noAssgiendServicesOnSpecialtyAlert ? (
            <>
              <div>
                {values['choosenServices']?.map((service, i) => {
                  return (
                    <div key={service.serviceId}>
                      <Checkbox
                        color="secondary"
                        label={
                          <span className="text-gray fsize-16">
                            {lang === 'en' ? service.name_en : service.name_ar}
                          </span>
                        }
                        labelClass="font-reguler text-dark fsize-16"
                        checked={values['choosenServices'][i].isChoosen}
                        name={lang === 'en' ? service.name_en : service.name_ar}
                        id={lang === 'en' ? service.name_en : service.name_ar}
                        onChange={(elementIsChecked, e) => {
                          // update service variable
                          setFieldValue(
                            'choosenServices',
                            values['choosenServices'].map((service) => {
                              if (service.name_en == e.target.id || service.name_ar == e.target.id) {
                                service.isChoosen = elementIsChecked;
                              }
                              return service;
                            })
                          );
                          setMappedServices(
                            mappedServices?.map((service) => {
                              if (service.name_en == e.target.id || service.name_ar == e.target.id) {
                                service.isChoosen = elementIsChecked;
                              }
                              return service;
                            })
                          );
                        }}
                      />
                      {/* render details */}
                      {service.isChoosen && (
                        <div className="ms-4 ps-2 service-details-section">
                          <div className="mb-3">
                            <div className="d-flex align-items-center gap-2">
                              <p className="text-gray fsize-16 mb-0">
                                {branches.addBranch.specialtiesAndServices.feesRange}
                              </p>
                              <Input
                                id="feesRangeFrom"
                                label={branches.addBranch.specialtiesAndServices.from}
                                name="feesRangeFrom"
                                type="text"
                                placeholder={branches.addBranch.specialtiesAndServices.from}
                                value={values['choosenServices'][i]?.details.feesRange.from}
                                inputWrapperClass="select-bg-white"
                                labelClassName="text-dark font-medium mb-2"
                                onChange={(value) => {
                                  if (ONE_NUMBERS_ONLY.test(value) || !value) {
                                    setFieldValue(values['choosenServices'], [
                                      ...values['choosenServices'],
                                      values['choosenServices'].map((mappedService) => {
                                        if (mappedService.serviceId === service.serviceId) {
                                          if (
                                            mappedService.details.feesRange.errors.includes('onlyNumbersAreAllowed') ||
                                            mappedService.details.feesRange.errors.includes('fromIsRequired')
                                          ) {
                                            mappedService.details.feesRange.hasErrors = false;
                                            mappedService.details.feesRange.errors =
                                              mappedService.details.feesRange.errors.filter(
                                                (error) =>
                                                  error !== 'onlyNumbersAreAllowed' && error !== 'fromIsRequired'
                                              );
                                          }
                                          if (TWO_DICIMAL_ONLY.test(value)) {
                                            mappedService.details.feesRange.from = value;
                                          }
                                        }
                                        return service;
                                      }),
                                    ]);
                                  } else {
                                    setFieldValue(values['choosenServices'], [
                                      ...values['choosenServices'],
                                      values['choosenServices'].map((mappedService) => {
                                        if (mappedService.serviceId === service.serviceId) {
                                          if (
                                            !mappedService.details.feesRange.errors.includes('onlyNumbersAreAllowed')
                                          ) {
                                            mappedService.details.feesRange.hasErrors = true;
                                            mappedService.details.feesRange.errors.push('onlyNumbersAreAllowed');
                                          }
                                        }
                                        return service;
                                      }),
                                    ]);
                                  }
                                }}
                                required
                              />
                              <Input
                                id="feesRangeTo"
                                label={branches.addBranch.specialtiesAndServices.to}
                                name="feesRangeTo"
                                type="text"
                                placeholder={branches.addBranch.specialtiesAndServices.to}
                                value={values['choosenServices'][i]?.details.feesRange.to}
                                inputWrapperClass="select-bg-white"
                                labelClassName="text-dark font-medium mb-2"
                                onChange={(value) => {
                                  if (ONE_NUMBERS_ONLY.test(value) || !value) {
                                    setFieldValue(values['choosenServices'], [
                                      ...values['choosenServices'],
                                      values['choosenServices'].map((mappedService) => {
                                        if (mappedService.serviceId === service.serviceId) {
                                          if (TWO_DICIMAL_ONLY.test(value)) {
                                            mappedService.details.feesRange.to = value;
                                          }
                                        }
                                        return service;
                                      }),
                                    ]);
                                  } else {
                                    setFieldValue(values['choosenServices'], [
                                      ...values['choosenServices'],
                                      values['choosenServices'].map((mappedService) => {
                                        if (mappedService.serviceId === service.serviceId) {
                                          if (
                                            !mappedService.details.feesRange.errors.includes('onlyNumbersAreAllowed')
                                          ) {
                                            mappedService.details.feesRange.hasErrors = true;
                                            mappedService.details.feesRange.errors.push('onlyNumbersAreAllowed');
                                          }
                                        }
                                        return service;
                                      }),
                                    ]);
                                  }
                                }}
                              />
                            </div>
                            {values['choosenServices'][i]?.details.feesRange.hasErrors && (
                              <span>
                                {values['choosenServices'][i]?.details.feesRange.errors.map((err) => {
                                  return (
                                    <div className="text-red fweight-600">
                                      {lang == 'en' ? (
                                        errorss.find((x) => {
                                          return x.errorTitle == err;
                                        })?.errorMessaged
                                      ) : (
                                        <>
                                          {
                                            errorss.find((x) => {
                                              return x.errorTitle == err;
                                            })?.errorMessaged
                                          }
                                        </>
                                      )}
                                    </div>
                                  );
                                })}
                              </span>
                            )}
                          </div>
                          <div className="mb-3">
                            <div className="d-flex align-items-center">
                              <Select
                                wrapperClass="label-inline"
                                name="currency"
                                labelClassName={`text-dark font-medium mb-2`}
                                label={branches.addBranch.specialtiesAndServices.currency}
                                options={currencyOptionsList}
                                inputClassName={`input-wrapper select-bg-white ${lang == 'ar' ? 'handle-padding' : ''}`}
                                placeholder={branches.addBranch.specialtiesAndServices.currency}
                                value={values['choosenServices'][i]?.details.currency}
                                onChange={(value) => {
                                  setFieldValue(values['choosenServices'], [
                                    ...values['choosenServices'],
                                    values['choosenServices'].map((mappedService) => {
                                      if (mappedService.serviceId === service.serviceId) {
                                        if (mappedService.details.errors.includes('currencyIsRequired')) {
                                          if (mappedService.details.errors.length === 0) {
                                            mappedService.details.hasErrors = false;
                                          }
                                          mappedService.details.errors = mappedService.details.errors.filter(
                                            (error) => error !== 'currencyIsRequired'
                                          );
                                        }
                                        mappedService.details.currency = value;
                                      }
                                      return service;
                                    }),
                                  ]);
                                }}
                                required
                              />
                            </div>
                            {values['choosenServices'][i]?.details.hasErrors &&
                              values['choosenServices'][i]?.details.errors.includes('currencyIsRequired') && (
                                <span>
                                  {values['choosenServices'][i]?.details.errors.map((err) => {
                                    return (
                                      <div className="text-red fweight-600">
                                        {lang == 'en' ? (
                                          errorss.find((x) => {
                                            return x.errorTitle == err && err == 'currencyIsRequired';
                                          })?.errorMessaged
                                        ) : (
                                          <>
                                            {
                                              errorss.find((x) => {
                                                return x.errorTitle == err && err == 'currencyIsRequired';
                                              })?.errorMessaged
                                            }
                                          </>
                                        )}
                                      </div>
                                    );
                                  })}
                                </span>
                              )}
                          </div>
                          <div className="mb-3">
                            <div className="d-flex align-items-center">
                              <Input
                                id="duration"
                                label={branches.addBranch.specialtiesAndServices.duration}
                                name="duration"
                                type="text"
                                placeholder={branches.addBranch.specialtiesAndServices.duration}
                                value={values['choosenServices'][i]?.details.duration}
                                inputWrapperClass=" select-bg-white"
                                labelClassName="text-dark font-medium mb-2"
                                onChange={(value) => {
                                  if (ONE_NUMBERS_ONLY.test(value) || !value) {
                                    setFieldValue(values['choosenServices'], [
                                      ...values['choosenServices'],
                                      values['choosenServices'].map((mappedService) => {
                                        if (mappedService.serviceId === service.serviceId) {
                                          if (
                                            mappedService.details.errors.includes('onlyNumbersAreAllowed') ||
                                            mappedService.details.errors.includes('durationIsRequired')
                                          ) {
                                            if (mappedService.details.errors.length === 0) {
                                              mappedService.details.hasErrors = false;
                                            }
                                            mappedService.details.errors = mappedService.details.errors.filter(
                                              (error) =>
                                                error !== 'onlyNumbersAreAllowed' && error !== 'durationIsRequired'
                                            );
                                          }
                                          mappedService.details.duration = value;
                                        }
                                        return service;
                                      }),
                                    ]);
                                  } else {
                                    setFieldValue(values['choosenServices'], [
                                      ...values['choosenServices'],
                                      values['choosenServices'].map((mappedService) => {
                                        if (mappedService.serviceId === service.serviceId) {
                                          if (!mappedService.details.errors.includes('onlyNumbersAreAllowed')) {
                                            mappedService.details.hasErrors = true;
                                            mappedService.details.errors.push('onlyNumbersAreAllowed');
                                          }
                                        }
                                        return service;
                                      }),
                                    ]);
                                  }
                                }}
                                required
                              />
                              <p className="text-gray fsize-16  mb-0 mx-2 mb-3">
                                {branches.addBranch.specialtiesAndServices.minutes}
                              </p>
                            </div>
                            {values['choosenServices'][i]?.details.hasErrors && (
                              <span>
                                {values['choosenServices'][i]?.details.errors.map((err) => {
                                  return (
                                    <div className="text-red fweight-600">
                                      {lang == 'en' ? (
                                        errorss.find((x) => {
                                          return x.errorTitle == err && err !== 'currencyIsRequired';
                                        })?.errorMessaged
                                      ) : (
                                        <>
                                          {
                                            errorss.find((x) => {
                                              return x.errorTitle == err && err !== 'currencyIsRequired';
                                            })?.errorMessaged
                                          }
                                        </>
                                      )}
                                    </div>
                                  );
                                })}
                              </span>
                            )}
                          </div>
                          <Input
                            id="description"
                            label={branches.addBranch.specialtiesAndServices.description}
                            name="description"
                            type="text"
                            placeholder={branches.addBranch.specialtiesAndServices.description}
                            value={values['choosenServices'][i]?.details.description}
                            inputWrapperClass="mb-3 select-bg-white"
                            labelClassName="text-dark font-medium mb-2"
                            onChange={(value) => {
                              setFieldValue(values['choosenServices'], [
                                ...values['choosenServices'],
                                values['choosenServices'].map((mappedService) => {
                                  if (mappedService.serviceId === service.serviceId) {
                                    mappedService.details.description = value;
                                  }
                                  return service;
                                }),
                              ]);
                            }}
                          />
                        </div>
                      )}
                      {/* line */}
                      {<div className="line-separator"></div>}
                    </div>
                  );
                })}
              </div>
              <div className="mt-4 py-4 d-flex justify-content-end gap-2">
                <Button
                  onClick={() => {
                    // go back to specialties section
                    checkIfThereIsDataBeforeLeave();
                  }}
                  outlined
                  color={'info'}
                  type="button"
                  label={shared.cancel}
                  labelClass="fsize-16 py-1"
                />
                <Button
                  onClick={() => {
                    let valid = true;
                    // check manual validation
                    values['choosenServices'].map((service) => {
                      if (service.isChoosen) {
                        if (!service.details.feesRange.from) {
                          valid = false;
                          service.details.feesRange.hasErrors = true;
                          if (!service.details.feesRange.errors.includes('fromIsRequired')) {
                            service.details.feesRange.errors.push('fromIsRequired');
                          }
                        }
                        if (service.details.currency == null) {
                          valid = false;
                          service.details.hasErrors = true;
                          if (!service.details.errors.includes('currencyIsRequired')) {
                            service.details.errors.push('currencyIsRequired');
                          }
                        }
                        if (service.details.currency == null) {
                          valid = false;
                          service.details.hasErrors = true;
                          if (!service.details.errors.includes('currencyIsRequired')) {
                            service.details.errors.push('currencyIsRequired');
                          }
                        }
                        if (!service.details.duration) {
                          valid = false;
                          service.details.hasErrors = true;
                          if (!service.details.errors.includes('durationIsRequired')) {
                            service.details.errors.push('durationIsRequired');
                          }
                        }
                      }
                      return service;
                    });
                    // add all checked service with details to branch services Call API
                    if (valid) {
                      let arr = [];
                      values['choosenServices'].forEach((service) => {
                        if (service.isChoosen) {
                          let obj = {};
                          obj.id = service.id; // if service is already added to branch
                          obj.service_id = service.serviceId;
                          obj.fees_from = service.details.feesRange.from;
                          obj.fees_to = service.details.feesRange.to;
                          obj.duration_mins = service.details.duration;
                          obj.currency_id = service.details.currency?.id;
                          lang === 'en'
                            ? (obj.description_en = service.details.description)
                            : (obj.description_ar = service.details.description);
                          obj.title_en = service.name_en;
                          obj.title_ar = service.name_ar;
                          arr.push(obj);
                        } else {
                          let obj = {};
                          obj.id = service.id; // if service is already added to branch
                          obj.service_id = service.serviceId;
                          obj.fees_from = service.details.feesRange.from;
                          obj.fees_to = service.details.feesRange.to;
                          obj.duration_mins = service.details.duration;
                          obj.currency_id = service.details.currency?.id;
                          lang === 'en'
                            ? (obj.description_en = service.details.description)
                            : (obj.description_ar = service.details.description);
                          obj.title_en = service.name_en;
                          obj.title_ar = service.name_ar;
                          obj._destroy = true;
                          arr.push(obj);
                        }
                      });

                      dispatch(
                        editBranchRequest({
                          id:
                            createdBranch != null && Object.keys(createdBranch).length !== 0
                              ? createdBranch.id
                              : parentProps?.location?.state?.branchDataToEdit?.id,
                          data: {
                            branch_services_attributes: arr,
                          },
                        })
                      );
                      setServiceTitle(null);
                    }
                  }}
                  type="submit"
                  label={shared.save} 
                  labelClass="fsize-16 text-white py-1"
                />
              </div>
              <Modal
                open={showLeaveWarningModal}
                handleClose={handleCloseShowLeaveWarningModal}
                title={branches.addBranch.labels.confirmCancel}
                contentClass="mb-5 pb-2 text-primary"
                content={<span>{branches.addBranch.labels.confirmCancelMessage}</span>}
                confirmBtnTxt={shared.confirm}
                maxWidth="xs"
                cancelBtnTxt={shared.cancel}
                isContentText={false}
                handleConfirm={handleConfirmShowLeaveWarning}
                handleCancel={handleCloseShowLeaveWarningModal}
                modalClass="delete-services-modal"
                actionsClass="container px-4"
                confirmBtnClass="btn-secondary fsize-14 text-white py-2 "
                cancelBtnClass="fsize-14 py-2"
              />
            </>
          ) : (
            <div className="bg-white rounded-4 services-page d-flex justify-content-center align-items-center flex-column m-4 mt-0">
              <EmptyState
                subTitle={shared.noServicesAddedyet}
                ButtonLabel={shared.back}
                handleBtnAction={() => {
                  setRenderSpecialtiesSection(true);
                  changeSubTitle('AddSpecialties');
                  setRenderServicesSection(false);
                  setRenderAddedServiceSection(false);
                }}
              />
            </div>
          )}
        </>
      )
    );
  };

  const checkIfThereIsDataBeforeLeave = () => {
    if (values['choosenServices']?.length > 0) {
      let ShowAlert = false;
      values['choosenServices'].forEach((service) => {
        if (service.isChoosen) {
          ShowAlert = true;
        }
      });
      if (ShowAlert) {
        setShowLeaveWarningModal(true);
      } else {
        clearServicesState();
        setRenderSpecialtiesSection(true);
        changeSubTitle('AddSpecialties');
        changeServiceTitle(null);
        setRenderServicesSection(false);
        setRenderAddedServiceSection(false);
      }
    }
  };

  const handleDeleteClick = (id) => {
    setdeleteSpecialtyId(id);
    setOpenDeleteSepecialtyConfirm(true);
  };

  const handleDeleteSpecialty = () => {
    dispatch(
      deleteBranchSpecialtyRequest({
        id: deleteSpecialtyId,
      })
    );
    dispatch(getDeleteBranchSpecialityStatusRequest(null))
    setTimeout(() => {
      dispatch(getBranchRequest(createdBranch?.id || parentProps?.location?.state?.branchDataToEdit?.id));
    }, 1000);
    setOpenDeleteSepecialtyConfirm(false);
    // Check if there is only one specialty in the branch
    if (branchServicesList?.length === 1) {
      setRenderEmpatyStateSection(false);
      setRenderSpecialtiesSection(false);
      changeSubTitle(null);
      setRenderServicesSection(false);
      setRenderAddedServiceSection(true);
    } else {
      dispatch(getBranchRequest(createdBranch?.id));
      setRenderAddedServiceSection(false);
      setTimeout(() => {
        setRenderAddedServiceSection(true);
      }, 500);
    }
    setdeleteSpecialtyId(null);
  };

  const handleCloseDeleteSpecilatyConfirmModal = () => {
    setOpenDeleteSepecialtyConfirm(false);
  };

  const handleDeleteServiceClick = (service) => {
    setdeleteServiceId(service.id);
    setOpenDeleteServiceConfirm(true);
  };

  const handleDeleteService = () => {
    dispatch(
      deleteBranchServiceRequest({
        id: deleteServiceId,
      })
    );
    dispatch(getDeleteBranchServiceStatusRequest(null));
    resetForm();
    updateServicesContent();
    setdeleteServiceId(null);
    setOpenDeleteServiceConfirm(false);
    setRenderEmpatyStateSection(true);
    setRenderAddedServiceSection(false);
    setTimeout(() => {
      setRenderEmpatyStateSection(false);
      setRenderAddedServiceSection(true);
    }, 200);
  };

  const handleCloseDeleteServiceConfirmModal = () => {
    setOpenDeleteServiceConfirm(false);
  };

  const viewAddedServices = () => {
    return (
      <>
        {addedSepacialtyWithServices?.length > 0 ? (
          <>
            <div>
              {addedSepacialtyWithServices?.map((specialty, index) => {
                return (
                  <div key={specialty.id}>
                    <Accordion
                      wrapperClassName={`services-section ${index !== 0 && 'mt-4'}`}
                      label={
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="fsize-16 fweight-600 text-primary">{specialty?.specialtyName}</div>
                          <div>
                            <div className="d-flex gap-2 align-items-center">
                              <div
                                className="small-icon-bg"
                                onClick={(e) => {
                                  // delete specialty with all services
                                  e.stopPropagation();
                                  handleDeleteClick(specialty.id);
                                }}>
                                <DeleteBgIcon />
                              </div>
                              <div
                                className="small-icon-bg"
                                onClick={() => {
                                  // clearServicesState();
                                  setChoosenSpecialtyId(specialty.realSpecialtyId);
                                  setRenderSpecialtiesSection(false);
                                  setTimeout(() => {
                                    setRenderServicesSection(true);
                                  }, 1000);
                                  changeSubTitle('AddSpecialties');
                                  changeServiceTitle(lang === 'en' ? specialty.title_en : specialty.title_ar);
                                  setRenderAddedServiceSection(false);
                                  setServiceTitle(specialty);
                                }}>
                                <EditBgIcon />
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      labelClassName={'text-primary flex-grow-1'}
                      body={
                        <>
                          {specialty?.services?.map((service, i) => {
                            return (
                              <div key={service.id}>
                                <div key={service.id} className={`${i !== 0 && 'mt-4'} mb-2 px-4`}>
                                  <div className="d-flex justify-content-between align-items-center mb-2">
                                    <p className="mb-0 text-primary fsize-16">
                                      {lang === 'en' ? service.name_en : service.name_ar}
                                    </p>
                                    <div className="d-flex h-fit-content">
                                      <div>
                                        <Switch
                                          checked={handleToggleButtonValue(service.service_id)}
                                          name="published"
                                          onChange={(e) => {
                                            if(e.target.checked){
                                              handleActivateOrDeactivateService(service)
                                            }else{
                                              setDeactivatedService(service)
                                              setShowDeactivateServicePopup(true)
                                            }
                                          }}
                                          disabled={viewBranchDetails.status === 'deactivated'  || viewBranchDetails.status === 'unpublished' ? true : false}
                                        />
                                      </div>

                                      <div
                                        className="small-icon-bg pointer"
                                        onClick={() => {
                                          handleDeleteServiceClick(service);
                                        }}>
                                        <DeleteBgIcon />
                                      </div>
                                      <div
                                        className="small-icon-bg pointer"
                                        onClick={() => {
                                          setAddedSepacialtyWithServices(
                                            addedSepacialtyWithServices.map((specialty) => {
                                              specialty.services?.map((mappedService) => {
                                                if (mappedService.id === service?.id) {
                                                  service.editMood = true;
                                                }
                                                return mappedService;
                                              });
                                              return specialty;
                                            })
                                          );
                                        }}>
                                        <EditBgIcon />
                                      </div>
                                    </div>
                                  </div>
                                  {/* details only */}
                                  {service.editMood === false && (
                                    <div>
                                      <div className="d-flex text-gray align-items-center gap-2 text-gray fsize-16">
                                        <CreditCardOutlined className="text-gray" />
                                        <div className="text-gray fsize-16 mb-0">
                                          {branches.addBranch.specialtiesAndServices.feesRange}:
                                        </div>
                                        <div>
                                          {service.details.feesRange.to && (
                                            <span>{branches.addBranch.specialtiesAndServices.from}</span>
                                          )}
                                          <span className="mx-1">{service.details.feesRange.from ?? ' ... '}</span>
                                          {lang === 'en' ? (
                                            currencyOptionsList.find(
                                              (currency) => currency.id === service?.details?.currency?.id
                                            )?.label
                                          ) : (
                                            <>
                                              {
                                                currencyOptionsList.find(
                                                  (currency) => currency.id === service?.details?.currency?.id
                                                )?.label
                                              }
                                            </>
                                          )}
                                        </div>
                                        {service.details.feesRange.to && (
                                          <>
                                            <div>
                                              <span>-</span>
                                            </div>
                                            <div>
                                              {branches.addBranch.specialtiesAndServices.to}
                                              <span className="mx-1">{service.details.feesRange.to ?? ' ... '}</span>
                                              {lang === 'en' ? (
                                                currencyOptionsList.find(
                                                  (currency) => currency.id === service?.details?.currency?.id
                                                )?.label
                                              ) : (
                                                <>
                                                  {
                                                    currencyOptionsList.find(
                                                      (currency) => currency.id === service?.details?.currency?.id
                                                    )?.label
                                                  }
                                                </>
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      <div className="d-flex my-1 gap-2 align-items-center">
                                        <AccessTimeOutlined className="text-gray" />
                                        <div className="text-gray fsize-16 mb-0 d-flex gap-2">
                                          <span>{branches.addBranch.specialtiesAndServices.duration}:</span>
                                          <span>{service.details.duration}</span>
                                          <span> {branches.addBranch.specialtiesAndServices.minutes}</span>
                                        </div>
                                      </div>
                                      <div className="d-flex gap-2 align-items-start">
                                        <ReceiptLongOutlined className="text-gray" />
                                        <div className="text-gray fsize-16 mb-0 d-flex gap-2">
                                          <span>{branches.addBranch.specialtiesAndServices.description}:</span>
                                          <span>{service.details.description ? service.details.description : '-'}</span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {/* edit input */}
                                  {service.editMood === true && (
                                    <>
                                      <div className="service-details-section flex-grow-1">
                                        <div className=" mb-2">
                                          <div className="d-flex align-items-center gap-2">
                                            <p className="text-gray fsize-16 mb-0">
                                              {branches.addBranch.specialtiesAndServices.feesRange}
                                            </p>
                                            <Input
                                              id="feesRangeFrom"
                                              label={branches.addBranch.specialtiesAndServices.from}
                                              name="feesRangeFrom"
                                              type="text"
                                              placeholder={branches.addBranch.specialtiesAndServices.from}
                                              value={service.details.feesRange.from}
                                              inputWrapperClass="select-bg-white"
                                              labelClassName="text-dark font-medium mb-2"
                                              onChange={(value) => {
                                                if (ONE_NUMBERS_ONLY.test(value) || !value) {
                                                  setAddedSepacialtyWithServices(
                                                    addedSepacialtyWithServices.map((specialty) => {
                                                      specialty.services?.map((mappedService) => {
                                                        if (mappedService.id === service?.id) {
                                                          if (
                                                            mappedService.details.feesRange.errors.includes(
                                                              'onlyNumbersAreAllowed'
                                                            ) ||
                                                            mappedService.details.errors.includes('fromIsRequired')
                                                          ) {
                                                            mappedService.details.feesRange.hasErrors = false;
                                                            mappedService.details.feesRange.errors =
                                                              mappedService.details.feesRange.errors.filter(
                                                                (error) =>
                                                                  error !== 'onlyNumbersAreAllowed' ||
                                                                  error !== 'fromIsRequired'
                                                              );
                                                          }
                                                          if (TWO_DICIMAL_ONLY.test(value)) {
                                                            mappedService.details.feesRange.from = value;
                                                          }
                                                        }
                                                        return mappedService;
                                                      });
                                                      return specialty;
                                                    })
                                                  );
                                                } else {
                                                  setAddedSepacialtyWithServices(
                                                    addedSepacialtyWithServices.map((specialty) => {
                                                      specialty.services?.map((mappedService) => {
                                                        if (mappedService.id === service?.id) {
                                                          if (
                                                            !mappedService.details.feesRange.errors.includes(
                                                              'onlyNumbersAreAllowed'
                                                            )
                                                          ) {
                                                            mappedService.details.feesRange.hasErrors = true;
                                                            mappedService.details.feesRange.errors.push(
                                                              'onlyNumbersAreAllowed'
                                                            );
                                                          }
                                                        }
                                                        return mappedService;
                                                      });
                                                      return specialty;
                                                    })
                                                  );
                                                }
                                              }}
                                            />
                                            <Input
                                              id="feesRangeTo"
                                              label={branches.addBranch.specialtiesAndServices.to}
                                              name="feesRangeTo"
                                              type="text"
                                              placeholder={branches.addBranch.specialtiesAndServices.to}
                                              value={service?.details.feesRange.to}
                                              inputWrapperClass="select-bg-white"
                                              labelClassName="text-dark font-medium mb-2"
                                              onChange={(value) => {
                                                setAddedSepacialtyWithServices(
                                                  addedSepacialtyWithServices.map((specialty) => {
                                                    specialty.services?.map((mappedService) => {
                                                      if (mappedService.id === service?.id) {
                                                        if (TWO_DICIMAL_ONLY.test(value)) {
                                                          mappedService.details.feesRange.to = value;
                                                        }
                                                      }
                                                      return mappedService;
                                                    });
                                                    return specialty;
                                                  })
                                                );
                                              }}
                                            />
                                          </div>
                                          {service?.details.feesRange.hasErrors && (
                                            <span>
                                              {service?.details.feesRange.errors.map((err) => {
                                                return (
                                                  <div className="text-red fweight-600">
                                                    {lang == 'en' ? (
                                                      errorss.find((x) => {
                                                        return x.errorTitle == err;
                                                      })?.errorMessaged
                                                    ) : (
                                                      <>
                                                        {
                                                          errorss.find((x) => {
                                                            return x.errorTitle == err;
                                                          })?.errorMessaged
                                                        }
                                                      </>
                                                    )}
                                                  </div>
                                                );
                                              })}
                                            </span>
                                          )}
                                        </div>
                                        <div className="d-flex align-items-center">
                                          <Select
                                            wrapperClass="label-inline"
                                            name="currency"
                                            labelClassName="text-dark font-medium mb-2"
                                            label={branches.addBranch.specialtiesAndServices.currency}
                                            options={currencyOptionsList}
                                            inputClassName={`input-wrapper mb-3 select-bg-white ${
                                              lang === 'ar' ? 'handle-padding' : ''
                                            }`}
                                            placeholder={branches.addBranch.specialtiesAndServices.currency}
                                            value={currencyOptionsList?.find(
                                              (x) => x.id === service?.details?.currency?.id
                                            )}
                                            onChange={(value) => {
                                              setAddedSepacialtyWithServices(
                                                addedSepacialtyWithServices.map((specialty) => {
                                                  specialty.services?.map((mappedService) => {
                                                    if (mappedService.id === service?.id) {
                                                      mappedService.details.currency = value;
                                                    }
                                                    return mappedService;
                                                  });
                                                  return specialty;
                                                })
                                              );
                                            }}
                                          />
                                        </div>
                                        <div className="d-flex align-items-center">
                                          <Input
                                            id="duration"
                                            label={branches.addBranch.specialtiesAndServices.duration}
                                            name="duration"
                                            type="text"
                                            placeholder={branches.addBranch.specialtiesAndServices.duration}
                                            value={service.details.duration}
                                            inputWrapperClass="mb-3 select-bg-white"
                                            labelClassName="text-dark font-medium mb-2"
                                            onChange={(value) => {
                                              if (ONE_NUMBERS_ONLY.test(value) || !value) {
                                                setAddedSepacialtyWithServices(
                                                  addedSepacialtyWithServices.map((specialty) => {
                                                    specialty.services?.map((mappedService) => {
                                                      if (mappedService.id === service?.id) {
                                                        if (
                                                          mappedService.details.errors.includes(
                                                            'onlyNumbersAreAllowed'
                                                          ) ||
                                                          mappedService.details.errors.includes('durationIsRequired')
                                                        ) {
                                                          mappedService.details.hasErrors = false;
                                                          mappedService.details.errors =
                                                            mappedService.details.errors.filter(
                                                              (error) =>
                                                                error !== 'onlyNumbersAreAllowed' ||
                                                                error !== 'durationIsRequired'
                                                            );
                                                        }
                                                        mappedService.details.duration = value;
                                                      }
                                                      return mappedService;
                                                    });
                                                    return specialty;
                                                  })
                                                );
                                              } else {
                                                setAddedSepacialtyWithServices(
                                                  addedSepacialtyWithServices.map((specialty) => {
                                                    specialty.services?.map((mappedService) => {
                                                      if (mappedService.id === service?.id) {
                                                        if (
                                                          !mappedService.details.errors.includes(
                                                            'onlyNumbersAreAllowed'
                                                          )
                                                        ) {
                                                          mappedService.details.hasErrors = true;
                                                          mappedService.details.errors.push('onlyNumbersAreAllowed');
                                                        }
                                                      }
                                                      return mappedService;
                                                    });
                                                    return specialty;
                                                  })
                                                );
                                              }
                                            }}
                                          />
                                          <p className="text-gray fsize-16 mx-2 mb-0">
                                            {branches.addBranch.specialtiesAndServices.minutes}
                                          </p>
                                        </div>
                                        {service?.details.hasErrors && (
                                          <span>
                                            {service?.details.errors.map((err) => {
                                              return (
                                                <div className="text-red fweight-600">
                                                  {lang == 'en' ? (
                                                    errorss.find((x) => {
                                                      return x.errorTitle == err;
                                                    })?.errorMessaged
                                                  ) : (
                                                    <>
                                                      {
                                                        errorss.find((x) => {
                                                          return x.errorTitle == err;
                                                        })?.errorMessaged
                                                      }
                                                    </>
                                                  )}
                                                </div>
                                              );
                                            })}
                                          </span>
                                        )}
                                        <Input
                                          id="description"
                                          label={branches.addBranch.specialtiesAndServices.description}
                                          name="description"
                                          type="text"
                                          placeholder={branches.addBranch.specialtiesAndServices.description}
                                          value={service.details.description}
                                          inputWrapperClass="mb-3 select-bg-white"
                                          labelClassName="text-dark font-medium mb-2"
                                          onChange={(value) => {
                                            setAddedSepacialtyWithServices(
                                              addedSepacialtyWithServices.map((specialty) => {
                                                specialty.services?.map((mappedService) => {
                                                  if (mappedService.id === service?.id) {
                                                    mappedService.details.description = value;
                                                  }
                                                  return mappedService;
                                                });
                                                return specialty;
                                              })
                                            );
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                                {service.editMood === true && (
                                  <div className="d-flex ms-auto gap-2 justify-content-end px-4 mb-4">
                                    {/* <Button /> */}
                                    <Button
                                      onClick={() => {
                                        setAddedSepacialtyWithServices(
                                          addedSepacialtyWithServices.map((specialty) => {
                                            specialty.services?.map((mappedService) => {
                                              if (mappedService.id === service?.id) {
                                                service.editMood = false;
                                              }
                                              return mappedService;
                                            });
                                            return specialty;
                                          })
                                        );
                                      }}
                                      outlined
                                      color={'info'}
                                      type="button"
                                      label={shared.cancel}
                                      labelClass="fsize-16 text-gray py-1"
                                    />
                                    <Button
                                      onClick={() => {
                                        let valid = true;
                                        // check manual validation
                                        setAddedSepacialtyWithServices(
                                          addedSepacialtyWithServices.map((specialty) => {
                                            specialty.services?.map((service) => {
                                              if (service.editMood) {
                                                if (!service.details.feesRange.from) {
                                                  valid = false;
                                                  service.details.feesRange.hasErrors = true;
                                                  if (!service.details.feesRange.errors.includes('fromIsRequired')) {
                                                    service.details.feesRange.errors.push('fromIsRequired');
                                                  }
                                                }
                                                if (!service.details.duration) {
                                                  valid = false;
                                                  service.details.hasErrors = true;
                                                  if (!service.details.errors.includes('durationIsRequired')) {
                                                    service.details.errors.push('durationIsRequired');
                                                  }
                                                }
                                              }
                                              return service;
                                            });
                                            return specialty;
                                          })
                                        );
                                        // add all checked service with details to branch services Call API
                                        if (valid) {
                                          let editedData = {};
                                          addedSepacialtyWithServices?.forEach((specialty) => {
                                            specialty.services?.forEach((service) => {
                                              if (service.editMood) {
                                                editedData = {
                                                  service_id: service.service_id,
                                                  id: service.id,
                                                  currency_id: currencyOptionsList?.find(
                                                    (currency) =>
                                                      currency.id === service.currency_id || currency.id === 1
                                                  ).id,
                                                  fees_from: service.details.feesRange.from,
                                                  fees_to: service.details.feesRange.to,
                                                  duration_mins: service.details.duration,
                                                  description_en: service.details.description,
                                                  description_ar: service.details.description,
                                                };
                                              }
                                            });
                                          });
                                          dispatch(
                                            editBranchServicesRequest({
                                              id: editedData.id,
                                              data: editedData,
                                            })
                                          );
                                          editedData = {};
                                          setAddedSepacialtyWithServices(
                                            addedSepacialtyWithServices.map((specialty) => {
                                              specialty.services?.map((mappedService) => {
                                                if (mappedService.id === service?.id) {
                                                  service.editMood = false;
                                                }
                                                return mappedService;
                                              });
                                              return specialty;
                                            })
                                          );
                                          setRenderEmpatyStateSection(true);
                                          setRenderAddedServiceSection(false);
                                          setRenderServicesSection(false);
                                          setTimeout(() => {
                                            setRenderEmpatyStateSection(false);
                                            setRenderAddedServiceSection(true);
                                          }, 500);
                                        }
                                      }}
                                      type="button"
                                      outlined
                                      color={'info'}
                                      label={shared.save}
                                      labelClass="fsize-16  py-1"
                                    />
                                  </div>
                                )}
                                {/* line */}
                                {i !== specialty?.services?.length - 1 && (
                                  <div className="line-separator-96 mx-auto"></div>
                                )}
                              </div>
                            );
                          })}
                        </>
                      }
                      arrowRotate={specialty?.isExpanded}
                      setArrowRotateValue={(value) => {
                        ArrowChange(value, specialty);
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <div className="mt-4 py-4 d-flex justify-content-end gap-2">
              {/* <Button
                onClick={() => {
                  // go back to specialties section
                  updateServicesContent();
                  setRenderSpecialtiesSection(false);
                  setRenderServicesSection(true);
                  changeSubTitle('AddSpecialties');
                  changeServiceTitle(null);
                  setRenderAddedServiceSection(false);
                }}
                outlined
                type="button"
                color={'info'}
                label={shared.previous}
                labelClass="fsize-16 py-1"
              /> */}
              <Button
                onClick={() => {
                  // API CALL TO SEND ALL SERVICES DATA
                  saveAndProcced();
                }}
                type="button"
                label={parentProps?.location?.state?.cameFrom ? shared.save : shared.saveAndProcced}
                labelClass="fsize-16 text-white py-1"
              />
            </div>
            <Modal
              open={openDeleteSpecilatyConfirm}
              handleClose={handleCloseDeleteSpecilatyConfirmModal}
              title={branches.addBranch.specialtiesAndServices.deleteSpecialty}
              contentClass="mb-5 pb-2 text-primary"
              content={<span>
                          {branches.addBranch.specialtiesAndServices.deleteSpecialtyMsg}
                          <div className="my-3">
                            <span className="text-secondary">{branches.addBranch.specialtiesAndServices.note}: </span>
                            {branches.addBranch.specialtiesAndServices.deletSpecialityNote}
                          </div>
                       </span>
                      }
              confirmBtnTxt={shared.delete}
              maxWidth="xs"
              cancelBtnTxt={shared.cancel}
              isContentText={false}
              handleConfirm={handleDeleteSpecialty}
              handleCancel={handleCloseDeleteSpecilatyConfirmModal}
              modalClass="delete-speciialty-modal"
              actionsClass="container px-4"
              confirmBtnClass="btn-secondary fsize-14 text-white py-2 "
              cancelBtnClass="fsize-14 py-2"
            />
            <Modal
              open={openDeleteServiceConfirm}
              handleClose={handleCloseDeleteServiceConfirmModal}
              title={branches.addBranch.specialtiesAndServices.deleteService}
              contentClass="mb-3 pb-2 text-primary"
              content={<span>
                          {branches.addBranch.specialtiesAndServices.deleteServiceMsg}
                          <div className="my-3">
                            <span className="text-secondary">{branches.addBranch.specialtiesAndServices.note}: </span>
                            {branches.addBranch.specialtiesAndServices.deleteServiceNote}
                          </div>
                       </span>
                      }
              confirmBtnTxt={shared.delete}
              maxWidth="xs"
              cancelBtnTxt={shared.cancel}
              isContentText={false}
              handleConfirm={handleDeleteService}
              handleCancel={handleCloseDeleteServiceConfirmModal}
              modalClass="delete-service-modal"
              actionsClass="container px-4"
              confirmBtnClass="btn-secondary fsize-14 text-white py-2 "
              cancelBtnClass="fsize-14 py-2"
            />
            <Modal
              open={showDeactivateServicePopup}
              handleClose={handleCloseDeactivatePopup}
              title={<span>{branches.addBranch.specialtiesAndServices.deactivateService}</span>}
              contentClass="mb-3 pb-2 text-primary"
              content={<span>
                          {branches.addBranch.specialtiesAndServices.deactivateServiceMsg}
                          <div className="my-3">
                            <span className="text-secondary">{branches.addBranch.specialtiesAndServices.note}: </span>
                            {branches.addBranch.specialtiesAndServices.deactivateServiceNote}
                          </div>
                       </span>
              }
              confirmBtnTxt={shared.procced}
              maxWidth="xs"
              cancelBtnTxt={shared.cancel}
              isContentText={false}
              handleConfirm={handleConfirmDecativateService}
              handleCancel={handleCloseDeactivatePopup}
              modalClass="delete-service-modal"
              actionsClass="container px-4"
              confirmBtnClass="btn-secondary fsize-14 text-white py-2 "
              cancelBtnClass="fsize-14 py-2"
            />
          </>
        ) : (
          !isLoading && (<div className="bg-white rounded-4 services-page d-flex justify-content-center align-items-center flex-column m-4 mt-0">
            <EmptyState 
              subTitle={shared.noServicesAddedyet}
              ButtonLabel={branches.addBranch.labels.addSepicialtiesAndServices}
              handleBtnAction={() => {
                dispatch(getBranchResponse({}));
                getSpecialties();
                setNoAssgiendServicesOnSpecialtyAlert(false);
                setRenderAddedServiceSection(false)
                setIsAddSpecialtiesAndServicesBTNClicked(true);
              }}
            />
          </div>)
        )}
      </>
    );
  };

  const ArrowChange = (value, specialty) => {
    if (value) {
      handleExpandFuntion(value, specialty.id);
      getServicesBySpecialtyId(specialty.realSpecialtyId);
      assignAddedSpecialtyAndServicesFromViewBranchData();
      updateServicesContent();
    } else {
      handleExpandFuntion(value, specialty.id);
      getServicesBySpecialtyId(specialty.realSpecialtyId);
      assignAddedSpecialtyAndServicesFromViewBranchData(); //haya di ely bet8ayar el conttent al outer we haya kamanl aly bet5ali eldata te5tafy
      updateServicesContent();
    }
  };

  const saveAndProcced = () => {
    if (parentProps?.location?.state?.cameFrom) {
      history.push({
        pathname: ROUTE_PATHS.viewBranch.replace(':id', id),
        state: {
          setTabIndex: 1,
        },
      });
    } else {
      proceedToNextStep();
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit} className="add-specialties-services-section">
      <div className="px-2 px-md-5 bg-white rounded-4 py-4">
        <div className="row">
          <div className="col-12">
            {renderEmpatyStateSection && renderEmptyState()}
            {renderSpecialtiesSection && renderSpecialties()}
            {renderServicesSection && renderServices()}
            {renderAddedServiceSection && viewAddedServices()}
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddEditServicesDetails;
