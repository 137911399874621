import shared from './shared';
import login from './login';
import changePassword from './changePassword';
import forgetPassword from './forgetPassword';
import tempPassword from './tempPassword';
import inputsValidations from './inputsValidations';
import pagesTitles from './pagesTitles';
import successMsgs from './successMsgs';
import setupProfile from './setupProfile';
import branches from './branches';
import serviceProviders from './serviceProviders';
import services from './services';
import serviceProviderCategories from './serviceProviderCategories';
import employees from './employees';
import bookings from './bookings';
import settings from './settings';
import paymentTransactions from './paymentTransactions';
import notifications from './notifications';

const ar = {
  shared,
  login,
  forgetPassword,
  tempPassword,
  changePassword,
  inputsValidations,
  pagesTitles,
  successMsgs,
  setupProfile,
  branches,
  serviceProviders,
  services,
  serviceProviderCategories,
  employees,
  bookings,
  settings,
  paymentTransactions,
  notifications,
};

export default ar;
