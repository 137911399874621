import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Rating from '@mui/material/Rating';

const Rate = ({ name, rateValue, readOnly, precision, assignNewValue, id }) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (rateValue != null) {
      setValue(rateValue);
    } else {
      setValue(0);
    }
  }, [rateValue]);

  return (
    <>
      <Rating
        name={name}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          assignNewValue(newValue);
        }}
        precision={precision}
        readOnly={readOnly}
        id={id}
      />
    </>
  );
};
export default Rate;

Rate.propTypes = {
  name: PropTypes.string,
  rateValue: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  precision: PropTypes.number,
  assignNewValue: PropTypes.func,
  id: PropTypes.number,
};

Rate.defaultProps = {
  readOnly: true,
  precision: 0.5,
};
