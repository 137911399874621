import {
  GET_BRANCH_DETAILS_BY_EMP_ID_RESPONSE,
  GET_BRANCH_SERVICES_BY_BRANCH_ID_RESPONSE,
  GET_EMPLOYEES_RESPONSE,
  GET_EMPLOYEE_DETAILS_RESPONSE,
  GET_EDIT_EMPLOYEE_STATUS_REQUEST_STATUS
} from './types';

const INITIAL_STATE = {
  list: [],
  meta: {},
  employeeDetails: {},
  editEmployeeStatusRequestStatus: null,
  helpers: {
    relatedBranchDetails: {},
    branchServices: [],
  },
};

const employees = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EMPLOYEES_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta === null ? {} : action.payload.meta,
      };
    case GET_EMPLOYEE_DETAILS_RESPONSE:
      return {
        ...state,
        employeeDetails: action.payload.employeeDetails,
      };
    case GET_BRANCH_DETAILS_BY_EMP_ID_RESPONSE:
      return {
        ...state,
        helpers: {
          ...state.helpers,
          relatedBranchDetails: action.payload.relatedBranchDetails,
        },
      };
    case GET_BRANCH_SERVICES_BY_BRANCH_ID_RESPONSE:
      return {
        ...state,
        helpers: {
          ...state.helpers,
          branchServices: action.payload.branch_services,
        },
      };
    case GET_EDIT_EMPLOYEE_STATUS_REQUEST_STATUS:
      return {
        ...state,
        editEmployeeStatusRequestStatus: action.payload === null ? null : action.payload,
      };
    default:
      return state;
  }
};

export default employees;
