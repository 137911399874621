import React from "react";
import PropTypes from "prop-types";
import { Pagination as MuiPagination } from "@mui/material";

import "./Pagination.scss";

const Pagination = ({ count, defaultPage, handleChange, page }) => {
  return (
    <div className="pagination-wrapper">
      <MuiPagination
        onChange={handleChange}
        count={count}
        defaultPage={defaultPage}
        page={page}
      />
    </div>
  );
};

Pagination.propTypes = {
  count: PropTypes.number,
  defaultPage: PropTypes.number,
  page: PropTypes.number,
  handleChange: PropTypes.func,
};

Pagination.defaultProps = {
  count: 0,
  defaultPage: 1,
  page: 1,
  handleChange: () => {},
};
export default Pagination;
